import { FC, useEffect } from "react";
import { getApolloContextFormToken } from "shared/utils";
import { PatientProfileComponent } from "../components/PatientProfile.screen";
import { ProfileContextProvider } from "../context/ProfileContextProvider";
import { useGetPatientProfileDataByIdLazyQuery, useGetPatientProfileDataByVisitIdLazyQuery } from "../gql";

type PatientProfilePageProps = {
  patientId?: string | null;
  nationalId?: string | null;
  visitId?: string | null;
  token?: string | null;
  hideClose?: boolean;
};

export const PatientProfilePage: FC<PatientProfilePageProps> = ({ patientId, nationalId, visitId, token, hideClose = false }) => {
  const [getPatientById, { data: dataByPatientId, loading: loadingByPatientId }] = useGetPatientProfileDataByIdLazyQuery({
    context: getApolloContextFormToken(token!),
  });
  const [getPatientByVisitId, { data: dataByVisitId, loading: loadingByVisitId }] = useGetPatientProfileDataByVisitIdLazyQuery({
    context: getApolloContextFormToken(token!),
  });

  const patient = dataByPatientId?.patient || dataByVisitId?.visit?.patient;
  const loading = loadingByPatientId || loadingByVisitId;

  useEffect(() => {
    if (visitId) {
      getPatientByVisitId({
        variables: {
          visitId,
        },
      });
    } else if (patientId || nationalId) {
      getPatientById({
        variables: {
          patientId,
          nationalId,
        },
      });
    } else {
      console.error("patientId or nationalId or visitId is required");
    }
  }, [patientId, nationalId, visitId, token, getPatientByVisitId, getPatientById]);

  return (
    <ProfileContextProvider patientId={patient?.id} token={token!} isOpen={true}>
      {loading ? "loading..." : <PatientProfileComponent hideClose={hideClose} />}
    </ProfileContextProvider>
  );
};
