import { useMemo } from "react";
import { useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps } from "@toolkit/ui";
import { OptimaClaimActivitiesQuery } from "@/pages/OptimaClaims/gql";
import { ExtractNodeType } from "@toolkit/apollo";
import { useSiteSettingsGetQuery } from "@/shared/gql/queries";
import { toTitleCase } from "@toolkit/core";

export const useOptimaClaimActivitiesColumns = (): CustomTableColumnProps<ExtractNodeType<OptimaClaimActivitiesQuery>>[] => {
  const { t } = useTranslation("domains");

  const { data } = useSiteSettingsGetQuery({
    fetchPolicy: "cache-first",
  });
  const defaultCurrency = data?.siteSettings?.defaultCurrency || "";

  return useMemo(() => {
    return [
      {
        key: "code",
        header: t("Code"),
        accessor: ({ codeLookup, code }) => toTitleCase(codeLookup?.name || code || "-"),
      },
      {
        key: "type",
        header: t("Type"),
        accessor: ({ typeLookup }) => typeLookup?.name || "-",
      },
      {
        key: "quantity",
        header: t("Quantity"),
        accessor: "quantity",
      },
      {
        key: "Amount",
        header: t("Amount"),
        accessor: ({ net }) => (net ? net + " " + defaultCurrency : "-"),
      },
    ];
  }, [t]);
};
