import { zodEnumSchema } from "@health/enum-options";
import { z } from "zod";

export const virtualIntegrationFormSchema = z.object({
  providerType: zodEnumSchema.conferenceProviderType,
  antmediaConfiguration: z
    .object({
      applicationName: z.string().min(1),
      baseUrl: z.string().min(1),
      sdkSecret: z.string().min(1),
    })
    .nullable()
    .optional(),
  expirationDuration: z.coerce.number().min(0),
  zoomConfiguration: z
    .object({
      apiKey: z.string().min(1),
      apiSecret: z.string().min(1),
      sdkKey: z.string().min(1),
      sdkSecret: z.string().min(1),
      secretKey: z.string().min(1),
      secretToken: z.string().min(1),
      url: z.string().min(1),
    })
    .nullable()
    .optional(),
});

export type IVirtualIntegrationFormValues = z.infer<typeof virtualIntegrationFormSchema>;

export const virtualIntegrationFormDefaultValues: Partial<IVirtualIntegrationFormValues> = {
  providerType: undefined,
  antmediaConfiguration: undefined,
  expirationDuration: undefined,
  zoomConfiguration: undefined,
};
