import { useTranslation } from "@toolkit/i18n";
import { CustomDialog, Button, CircularProgress } from "@toolkit/ui";
import { FC, useEffect, useRef, useState } from "react";
import { useOpenState } from "@toolkit/core";

import { ModalApi } from "../../others/types";
import {
  ITransactionValidateRequestCareTeamFormValues,
  ITransactionValidateRequestCareTeamUpsertFormEvent,
} from "../../forms/TransactionValidateRequestCareTeam/TransactionValidateRequestCareTeamFormSchema";
import {
  TransactionValidateRequestCareTeamUpsertFormRef,
  TransactionValidateRequestCareTeamUpsertForm,
} from "../../forms/TransactionValidateRequestCareTeam/TransactionValidateRequestCareTeamUpsertForm";

export const TransactionValidateRequestCareTeamUpsertModalApi: ModalApi<ITransactionValidateRequestCareTeamFormValues & { index: string }> =
  {
    open: () => {},
    close: () => {},
  };

type TransactionValidateRequestCareTeamUpsertModalProps = {
  isLoading?: boolean;
  hasClose?: boolean;
  onChange: (event: ITransactionValidateRequestCareTeamUpsertFormEvent) => void;
};

export const TransactionValidateRequestCareTeamUpsertModal: FC<TransactionValidateRequestCareTeamUpsertModalProps> = ({
  isLoading = false,
  hasClose = true,
  onChange,
}) => {
  const { t } = useTranslation("provider");
  const { open: isOpen, handleClose, handleOpen } = useOpenState();

  const [careTeamMember, setCareTeamMember] = useState<(ITransactionValidateRequestCareTeamFormValues & { index: string }) | null>(null);

  const formRef = useRef<TransactionValidateRequestCareTeamUpsertFormRef>(null);

  const handleSubmit = () => formRef.current?.submit();

  const onOpen = (data?: ITransactionValidateRequestCareTeamFormValues & { index: string }) => {
    setCareTeamMember(data || null);
    handleOpen();
  };

  useEffect(() => {
    TransactionValidateRequestCareTeamUpsertModalApi.open = onOpen;
    TransactionValidateRequestCareTeamUpsertModalApi.close = handleClose;
  }, []);

  return (
    <CustomDialog
      type='base'
      maxWidth='md'
      open={isOpen}
      onClose={hasClose ? handleClose : undefined}
      DialogTitleProps={{
        title: careTeamMember ? t("Update Care Team Member") : t("Add Care Team Member"),
        hasCloseButton: hasClose,
      }}
      DialogActionsProps={{
        children: (
          <Button disabled={isLoading} endIcon={isLoading ? <CircularProgress color='inherit' size={20} /> : null} onClick={handleSubmit}>
            {careTeamMember ? t("Update") : t("Add")}
          </Button>
        ),
      }}
    >
      <TransactionValidateRequestCareTeamUpsertForm
        index={careTeamMember?.index}
        careTeamMember={careTeamMember || undefined}
        ref={formRef}
        onChange={onChange}
      />
    </CustomDialog>
  );
};
