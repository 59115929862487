import { GridProvider } from "@/shared/components";
import { useTranslation } from "@toolkit/i18n";
import { FilterGrid, MainListActions, PageWrapper, TableGrid, useBreadCrumbs } from "@toolkit/ui";
import { useEffect } from "react";
import { useGuidedCareJourneyListQuery } from "../../gql";
import { useGuidedCareJourneyListContainerColumns } from "./useGuidedCareJourneyListContainerColumns";

export const GuidedCareJourneyListContainer = () => {
  const { t } = useTranslation("provider");

  const { setBreadCrumb } = useBreadCrumbs();

  useEffect(() => {
    setBreadCrumb({ title: t("Guided Care Patients"), values: [] });
  }, []);

  return (
    <GridProvider
      gridName={"guidedCareJourneyList"}
      query={useGuidedCareJourneyListQuery}
      columns={useGuidedCareJourneyListContainerColumns()}
      hasTableSetting
      skipUrlState={false}
    >
      <PageWrapper filters={<FilterGrid />} actions={<MainListActions />}>
        <TableGrid />
      </PageWrapper>
    </GridProvider>
  );
};
