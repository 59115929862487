import { MedicalForm, MedicalFormSortingField, SortDirection } from "@/schema/types";
import { useTranslation } from "@toolkit/i18n";
import { FilterGrid, MainListActions, PageWrapper, TableGrid, TableSettingComponent, useBreadCrumbs } from "@toolkit/ui";
import { createMedicalFormsBreadcrumbs, medicalFormsPaths } from "pages/MedicalForms/constants";
import { useProviderMedicalFormListQuery } from "pages/MedicalForms/gql";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { GridProvider } from "shared/components";
import { useProviderMedicalFormsTableColumns } from "./useMedicalFormsTableColumns";

export const ProviderMedicalFormsContainer = () => {
  const { setBreadCrumb } = useBreadCrumbs();
  const navigate = useNavigate();
  const { t } = useTranslation("provider");

  const onAddNewItemClick = () => {
    navigate(medicalFormsPaths.new.fullPath);
  };

  const onEditRowClick = (item: MedicalForm) => {
    navigate(medicalFormsPaths.update.fullPathWithParams({ medicalFormId: item.id! }));
  };

  useEffect(() => {
    setBreadCrumb({ title: createMedicalFormsBreadcrumbs(t).title });
  }, [setBreadCrumb, t]);

  return (
    <GridProvider
      gridName='providerMedicalFormList'
      columns={useProviderMedicalFormsTableColumns()}
      query={useProviderMedicalFormListQuery}
      dataAccessor={"medicalForms"}
      hasTableSetting
      skipUrlState={false}
      tableAction={{
        isEditVisible: true,
        isDeleteVisible: false,
        onEditRow: onEditRowClick,
      }}
      variables={{
        sortBy: {
          direction: SortDirection.Desc,
          field: MedicalFormSortingField.CreatedDate,
        },
      }}
    >
      <PageWrapper
        filters={<FilterGrid />}
        actions={
          <>
            <TableSettingComponent />
            <MainListActions hasAddNew onAddNewItem={onAddNewItemClick} />
          </>
        }
      >
        <TableGrid />
      </PageWrapper>
    </GridProvider>
  );
};
