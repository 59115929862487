import { getMarketplaceItemInfoItems } from "@/pages/Marketplace/others/cellInfo.utils";
import { MarketplaceDiscountSortingField, MarketplaceDiscountType } from "@/schema/types";
import { getMarketplaceHealthPackagesAutocompleteFilter, getMarketplaceProductsAutocompleteFilter } from "@health/autocompletes";
import { getAutocompleteEnumFilter, marketplaceDiscountTypeOptionsMap } from "@health/enum-options";
import { useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps } from "@toolkit/ui";
import { useMemo } from "react";
import { ToggleDiscountsPublishStatus } from "../../components";
import { DiscountsQueryNode } from "../../types";

export const useDiscountsListTableColumns = (): CustomTableColumnProps<DiscountsQueryNode>[] => {
  const { t } = useTranslation("provider");

  return useMemo(() => {
    return [
      {
        key: "name",
        header: t("Name"),
        accessor: "name",
        filter: {
          type: "string",
          name: "name",
        },
        sort: {
          columnEnum: MarketplaceDiscountSortingField.Name,
        },
      },
      {
        key: "type",
        header: t("Discount Type"),
        accessor: ({ type }) => marketplaceDiscountTypeOptionsMap[type]?.label,
      },
      {
        key: "Products",
        header: t("Products"),
        type: "info",
        infoCellOptions: {
          title: t("Discount Products"),
          items: ({ products }) => getMarketplaceItemInfoItems(products ?? []),
          layout: "one-column",
          disableShowButton: ({ products }) => !products?.length,
          emptyMessage: t("No product provided"),
        },
        filter: getMarketplaceProductsAutocompleteFilter({ name: "productId" }),
      },
      {
        key: "health-packages",
        header: t("Health Packages"),
        type: "info",
        infoCellOptions: {
          title: t("Discount Health Packages"),
          items: ({ healthPackages }) => getMarketplaceItemInfoItems(healthPackages ?? []),
          layout: "one-column",
          disableShowButton: ({ healthPackages }) => !healthPackages?.length,
          emptyMessage: t("No health Package provided"),
        },
        filter: getMarketplaceHealthPackagesAutocompleteFilter({ name: "healthPackageIds" }),
      },
      {
        key: "discount",
        header: t("Discount"),
        accessor: ({ amount, percentage, type }) => {
          if (type === MarketplaceDiscountType.Fixed) {
            return amount || undefined;
          } else if (percentage) {
            return percentage + "%";
          } else {
            return undefined;
          }
        },
      },
      {
        key: "startDate",
        header: t("Start Date"),
        type: "datetime",
        accessor: "startDate",
        filter: { type: "date", name: "startDate" },
        sort: {
          columnEnum: MarketplaceDiscountSortingField.StartDate,
        },
      },
      {
        key: "endDate",
        header: t("End Date"),
        type: "datetime",
        accessor: "endDate",
        filter: {
          type: "date",
          name: "endDate",
        },
        sort: {
          columnEnum: MarketplaceDiscountSortingField.EndDate,
        },
      },

      {
        key: "isPublished",
        header: t("Published"),
        accessor: row => <ToggleDiscountsPublishStatus row={row} />,
        filter: getAutocompleteEnumFilter("YesNo", "isPublished"),
      },
    ];
  }, []);
};
