import { useTranslation } from "@toolkit/i18n";
import { useBreadCrumbs } from "@toolkit/ui";
import { FC, useEffect } from "react";
import { GridProvider } from "shared/components";
import { useVisitsQuery } from "../gql";
import { useVisitsColumns } from "./useVisitsColumns";
import { useVisitsHistoryListPage } from "./useVisitsHistoryListPage";
import { VisitsHistoryListPageWrapper } from "./VisitsHistoryListPageWrapper";

const VisitsHistoryListPage: FC = () => {
  const { t } = useTranslation("provider");
  const { setBreadCrumb } = useBreadCrumbs();

  const { filters, handleToggleMyOnly, isMeOnly, isVendorAdmin, selfOnly, skipCall } = useVisitsHistoryListPage();

  useEffect(() => {
    setBreadCrumb({ title: t("Visit History"), values: [] });
  }, []);

  return (
    <GridProvider
      gridName='visits'
      columns={useVisitsColumns({ selfOnly: !!selfOnly })}
      query={useVisitsQuery}
      hideFilterInput
      variables={{
        filter: { selfOnly: isVendorAdmin ? isMeOnly : undefined, ...filters },
      }}
      hasTableSetting
      skipCall={skipCall}
      skipUrlState={false}
    >
      <VisitsHistoryListPageWrapper isMeOnly={isMeOnly} onMeOnlyClick={handleToggleMyOnly} isVendorAdmin={isVendorAdmin} />
    </GridProvider>
  );
};
export default VisitsHistoryListPage;
