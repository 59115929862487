import { z } from "zod";

export const transactionValidateRequestItemFormSchema = z.object({
  sequence: z.string(),
  code: z.string(),
  display: z.string(),
  codeSystem: z.string(),
  type: z.string(),
  serviceStart: z.string(),
  serviceEnd: z.string(),
  quantity: z.number(),
  authorizationId: z.string(),
  unitPrice: z.number(),
  patientShare: z.number(),
  net: z.number(),
  tax: z.number(),
  currency: z.string(),
  linkedCareTeam: z.string(),
  linkedDiagnoses: z.array(z.string()).min(1),
  linkedObservations: z.array(z.string()),
});

export type ITransactionValidateRequestItemFormValues = z.infer<typeof transactionValidateRequestItemFormSchema>;

export const transactionValidateRequestItemFormDefaultValues: ITransactionValidateRequestItemFormValues = {
  sequence: "1",
  code: "73590",
  display: "RADIOLOGIC EXAMINATION TIBIA & FIBULA 2 VIEWS",
  codeSystem: "CPT",
  type: "Procedure",
  serviceStart: "2025-03-18T09:00:00Z",
  serviceEnd: "2025-03-19T09:30:00Z",
  quantity: 1,
  authorizationId: "AUTH123456",
  unitPrice: 200.0,
  patientShare: 20.0,
  net: 180.0,
  tax: 20.0,
  currency: "AED",
  linkedCareTeam: "1",
  linkedDiagnoses: ["1"],
  linkedObservations: ["1"],
};

export type ITransactionValidateRequestItemUpsertFormEvent =
  | { type: "CREATE"; payload: { values: ITransactionValidateRequestItemFormValues } }
  | { type: "UPDATE"; payload: { values: ITransactionValidateRequestItemFormValues & { index: string } } };
