import { useTranslation } from "@toolkit/i18n";
import { CustomDialog, Button, CircularProgress } from "@toolkit/ui";
import { FC, useEffect, useRef, useState } from "react";
import { useOpenState } from "@toolkit/core";

import { ModalApi } from "../../others/types";
import {
  ITransactionValidateRequestItemFormValues,
  ITransactionValidateRequestItemUpsertFormEvent,
} from "../../forms/TransactionValidateRequestItem/TransactionValidateRequestItemFormSchema";
import {
  TransactionValidateRequestItemUpsertForm,
  TransactionValidateRequestItemUpsertFormRef,
} from "../../forms/TransactionValidateRequestItem/TransactionValidateRequestItemUpsertForm";

export const TransactionValidateRequestItemUpsertModalApi: ModalApi<ITransactionValidateRequestItemFormValues & { index: string }> = {
  open: () => {},
  close: () => {},
};

type TransactionValidateRequestItemUpsertModalProps = {
  isLoading?: boolean;
  hasClose?: boolean;
  onChange: (event: ITransactionValidateRequestItemUpsertFormEvent) => void;
};

export const TransactionValidateRequestItemUpsertModal: FC<TransactionValidateRequestItemUpsertModalProps> = ({
  isLoading = false,
  hasClose = true,
  onChange,
}) => {
  const { t } = useTranslation("provider");
  const { open: isOpen, handleClose, handleOpen } = useOpenState();

  const [item, setItem] = useState<(ITransactionValidateRequestItemFormValues & { index: string }) | null>(null);

  const formRef = useRef<TransactionValidateRequestItemUpsertFormRef>(null);

  const handleSubmit = () => formRef.current?.submit();

  const onOpen = (data?: ITransactionValidateRequestItemFormValues & { index: string }) => {
    setItem(data || null);
    handleOpen();
  };

  useEffect(() => {
    TransactionValidateRequestItemUpsertModalApi.open = onOpen;
    TransactionValidateRequestItemUpsertModalApi.close = handleClose;
  }, []);

  return (
    <CustomDialog
      type='base'
      maxWidth='md'
      open={isOpen}
      onClose={hasClose ? handleClose : undefined}
      DialogTitleProps={{
        title: item ? t("Update Item") : t("Add Item"),
        hasCloseButton: hasClose,
      }}
      DialogActionsProps={{
        children: (
          <Button disabled={isLoading} endIcon={isLoading ? <CircularProgress color='inherit' size={20} /> : null} onClick={handleSubmit}>
            {item ? t("Update") : t("Add")}
          </Button>
        ),
      }}
    >
      <TransactionValidateRequestItemUpsertForm index={item?.index} item={item || undefined} ref={formRef} onChange={onChange} />
    </CustomDialog>
  );
};
