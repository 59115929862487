import { IAutocompleteFilter, getAutocompleteFilter, createAutocompleteOption } from "@toolkit/ui";
import { useBranchesHealthLicenseAutocompleteQuery } from "./gql";
import { pickLocalizedValue } from "@toolkit/i18n";
import { IBranchHealthLicenseAutocomplete } from "./schema";

export const createBranchHealthLicenseAutocompleteOption = (branch: IBranchHealthLicenseAutocomplete) => {
  return createAutocompleteOption(
    {
      id: branch?.id!,
      name: branch?.name!,
      nameAr: branch?.nameAr!,
      healthLicense: branch?.healthLicense,
    },
    "healthLicense",
    item => pickLocalizedValue(item?.name, item?.nameAr)
  );
};

export const getBranchesHealthLicenseAutocompleteFilter = ({ name, multiple }: IAutocompleteFilter) => {
  return getAutocompleteFilter({
    name,
    multiple,
    accessor: "branches",
    query: useBranchesHealthLicenseAutocompleteQuery,
    labelBy: item => pickLocalizedValue(item?.name, item?.nameAr!),
    backendAccessor: option => option?.healthLicense || "",
    filterSearchKey: "name_Icontains",
  });
};
