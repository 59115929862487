import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetConferenceConfigurationQueryVariables = Types.Exact<{
  vendorId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
}>;


export type GetConferenceConfigurationQuery = { __typename?: 'Query', getConferenceConfiguration?: { __typename?: 'ConferenceConfiguration', expirationDuration?: number | null, id?: string | null, providerType?: Types.ConferenceProviderType | null, antmediaConfiguration?: { __typename?: 'AntMediaConfiguration', applicationName?: string | null, id?: string | null, url?: string | null } | null, zoomConfiguration?: { __typename?: 'ZoomConfiguration', apiKey?: string | null, id?: string | null, sdkKey?: string | null, secretToken?: string | null, sessionStatusUpdateUri?: string | null, url?: string | null } | null, vendor?: { __typename?: 'Vendor', id: string, name: string, nameAr?: string | null } | null } | null };


export const GetConferenceConfigurationDocument = gql`
    query GetConferenceConfiguration($vendorId: ID) {
  getConferenceConfiguration(vendorId: $vendorId) {
    antmediaConfiguration {
      applicationName
      id
      url
    }
    expirationDuration
    id
    providerType
    zoomConfiguration {
      apiKey
      id
      sdkKey
      secretToken
      sessionStatusUpdateUri
      url
    }
    vendor {
      id
      name
      nameAr
    }
  }
}
    `;

/**
 * __useGetConferenceConfigurationQuery__
 *
 * To run a query within a React component, call `useGetConferenceConfigurationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConferenceConfigurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConferenceConfigurationQuery({
 *   variables: {
 *      vendorId: // value for 'vendorId'
 *   },
 * });
 */
export function useGetConferenceConfigurationQuery(baseOptions?: Apollo.QueryHookOptions<GetConferenceConfigurationQuery, GetConferenceConfigurationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetConferenceConfigurationQuery, GetConferenceConfigurationQueryVariables>(GetConferenceConfigurationDocument, options);
      }
export function useGetConferenceConfigurationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetConferenceConfigurationQuery, GetConferenceConfigurationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetConferenceConfigurationQuery, GetConferenceConfigurationQueryVariables>(GetConferenceConfigurationDocument, options);
        }
export function useGetConferenceConfigurationSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetConferenceConfigurationQuery, GetConferenceConfigurationQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetConferenceConfigurationQuery, GetConferenceConfigurationQueryVariables>(GetConferenceConfigurationDocument, options);
        }
export type GetConferenceConfigurationQueryHookResult = ReturnType<typeof useGetConferenceConfigurationQuery>;
export type GetConferenceConfigurationLazyQueryHookResult = ReturnType<typeof useGetConferenceConfigurationLazyQuery>;
export type GetConferenceConfigurationSuspenseQueryHookResult = ReturnType<typeof useGetConferenceConfigurationSuspenseQuery>;
export type GetConferenceConfigurationQueryResult = Apollo.QueryResult<GetConferenceConfigurationQuery, GetConferenceConfigurationQueryVariables>;