import { useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps } from "@toolkit/ui";
import { useMemo } from "react";
import { ITransactionValidateRequestEncounterFormValues } from "../TransactionValidateRequestEncounter/TransactionValidateRequestEncounterFormSchema";

export const useTransactionValidateRequestEncountersColumns =
  (): CustomTableColumnProps<ITransactionValidateRequestEncounterFormValues>[] => {
    const { t } = useTranslation("provider");
    return useMemo(() => {
      return [
        {
          key: "sequence",
          header: t("Sequence"),
          accessor: ({ sequence }) => sequence,
        },
        {
          key: "status",
          header: t("Status"),
          accessor: ({ status }) => status,
        },
        {
          key: "type",
          header: t("Type"),
          accessor: ({ type }) => type,
        },
        {
          key: "start",
          header: t("Start"),
          type: "datetime",
          accessor: ({ start }) => start,
        },
        {
          key: "startType",
          header: t("Start Type"),
          accessor: ({ startType }) => startType,
        },
        {
          key: "end",
          header: t("End"),
          type: "datetime",
          accessor: ({ end }) => end,
        },
        {
          key: "endType",
          header: t("End Type"),
          accessor: ({ endType }) => endType,
        },
        {
          key: "transferSource",
          header: t("Transfer Source"),
          accessor: ({ transferSource }) => transferSource,
        },
        {
          key: "transferDestination",
          header: t("Transfer Destination"),
          accessor: ({ transferDestination }) => transferDestination,
        },
        {
          key: "eligibilityId",
          header: t("Eligibility ID"),
          accessor: ({ eligibilityId }) => eligibilityId,
        },
      ];
    }, [t]);
  };
