import { ManualOrderStatus, OrderType } from "@/schema/types";
import { Button, CustomDialog, useAddToast } from "@toolkit/ui";
import { FC, useRef } from "react";
import {
  useSetDeliveredManualOrderMutation,
  useSetManualOrderPickedUpMutation,
  useSetOutForDeliveryManualOrderMutation,
} from "../../gql/mutations";
import { useTranslation } from "@toolkit/i18n";
import { useOpenState } from "@toolkit/core";
import { ManualSubmittedOrderListDocument } from "../../gql";

type AuthorizedOrderActionsProps = {
  orderId: string;
  orderStatus: ManualOrderStatus;
  orderType: OrderType;
};

export const AuthorizedOrderActions: FC<AuthorizedOrderActionsProps> = props => {
  const { orderId, orderStatus, orderType } = props;
  const { t } = useTranslation();
  const { failed, succeeded } = useAddToast();
  const { open: isOpen, handleOpen, handleClose } = useOpenState();
  const activeActionRef = useRef<"pick" | "outForDelivery" | "deliver" | null>(null);

  const [setPickedUp, { loading: pickingUp }] = useSetManualOrderPickedUpMutation({
    onCompleted: data => {
      if (data?.setManualOrderPickedUp?.id) {
        succeeded(t("Order marked as picked up successfully!"));
      } else {
        failed(t("Failed to mark order as picked up!"));
      }
      handleClose();
    },
    onError: () => {
      failed(t("Failed to mark order as picked up!"));
      handleClose();
    },
    refetchQueries: [ManualSubmittedOrderListDocument],
  });

  const [setOutForDelivery, { loading: settingOutForDelivery }] = useSetOutForDeliveryManualOrderMutation({
    onCompleted: data => {
      if (data?.setOutForDeliveryManualOrder?.id) {
        succeeded(t("Order marked as out for delivery successfully!"));
      } else {
        failed(t("Failed to mark order as out for delivery!"));
      }
      handleClose();
    },
    onError: () => {
      failed(t("Failed to mark order as out for delivery!"));
      handleClose();
    },
    refetchQueries: [ManualSubmittedOrderListDocument],
  });

  const [setDelivered, { loading: delivering }] = useSetDeliveredManualOrderMutation({
    onCompleted: data => {
      if (data?.setDeliveredManualOrder?.id) {
        succeeded(t("Order marked as delivered successfully!"));
      } else {
        failed(t("Failed to mark order as delivered!"));
      }
      handleClose();
    },
    onError: () => {
      failed(t("Failed to mark order as delivered!"));
      handleClose();
    },
    refetchQueries: [ManualSubmittedOrderListDocument],
  });

  const handleConfirm = () => {
    switch (activeActionRef.current) {
      case "pick":
        setPickedUp({ variables: { orderId } });
        break;
      case "outForDelivery":
        setOutForDelivery({ variables: { orderId } });
        break;
      case "deliver":
        setDelivered({ variables: { orderId } });
        break;
      default:
        break;
    }
  };

  const isDeliveryOrder = orderType === OrderType.Delivery;
  const isPickupOrder = orderType === OrderType.Pickup;

  const getConfirmationMessage = () => {
    switch (activeActionRef.current) {
      case "pick":
        return t("Are you sure you want to mark this order as picked up?");
      case "outForDelivery":
        return t("Are you sure you want to mark this order as out for delivery?");
      case "deliver":
        return t("Are you sure you want to mark this order as delivered?");
      default:
        return t("Are you sure you want to perform this action?");
    }
  };

  return (
    <>
      {orderStatus === ManualOrderStatus.AuthorizationApproved && isPickupOrder && (
        <Button
          onClick={() => {
            activeActionRef.current = "pick";
            handleOpen();
          }}
          disabled={pickingUp}
          sx={{ minWidth: "max-content" }}
          aria-label={t("Mark as Picked Up")}
          aria-disabled={pickingUp}
        >
          {pickingUp ? t("Marking as Picked Up...") : t("Mark as Picked Up")}
        </Button>
      )}

      {orderStatus === ManualOrderStatus.AuthorizationApproved && isDeliveryOrder && (
        <Button
          onClick={() => {
            activeActionRef.current = "outForDelivery";
            handleOpen();
          }}
          disabled={settingOutForDelivery}
          sx={{ minWidth: "max-content" }}
          aria-label={t("Mark as Out for Delivery")}
          aria-disabled={settingOutForDelivery}
        >
          {settingOutForDelivery ? t("Marking as Out for Delivery...") : t("Mark as Out for Delivery")}
        </Button>
      )}

      {orderStatus === ManualOrderStatus.OutForDelivery && isDeliveryOrder && (
        <Button
          onClick={() => {
            activeActionRef.current = "deliver";
            handleOpen();
          }}
          disabled={delivering}
          sx={{ minWidth: "max-content" }}
          aria-label={t("Mark as Delivered")}
          aria-disabled={delivering}
        >
          {delivering ? t("Marking as Delivered...") : t("Mark as Delivered")}
        </Button>
      )}

      <CustomDialog
        type={"warning"}
        isOpen={isOpen}
        text={{
          title: t("Confirmation"),
          body: getConfirmationMessage(),
        }}
        onClose={handleClose}
        onConfirm={handleConfirm}
      />
    </>
  );
};
