import { FC } from "react";
import { FormTextField, Grid, FormCard, FormNumberField, FormDatePickerField, FormAutocomplete } from "@toolkit/ui";
import { useTranslation } from "@toolkit/i18n";
import { userGenderOptions } from "@health/enum-options";

export const PatientInformationForm: FC = () => {
  const { t } = useTranslation("provider");

  return (
    <FormCard title={t("Patient Information")} loading={false} doYouHaveData>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <FormTextField name='patientName' label={t("Patient Name")} placeholder={t("Patient Name")} />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormTextField name='patientId' label={t("Patient ID")} placeholder={t("Patient ID")} />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormAutocomplete name='patientGender' label={t("Gender")} placeholder={t("Gender")} options={userGenderOptions} />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormDatePickerField name='patientDoB' label={t("Date of Birth")} placeholder={t("Date of Birth")} />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormNumberField isFloatAllowed name='patientWeight' label={t("Weight")} placeholder={t("Weight")} />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormNumberField isFloatAllowed name='patientHeight' label={t("Height")} placeholder={t("Height")} />
        </Grid>
      </Grid>
    </FormCard>
  );
};
