/* eslint-disable max-lines */
import { Visit, VisitSortField, VisitStatus } from "@/schema/types";
import { getBranchesAutocompleteFilter, getPayersAutocompleteFilter, getVendorDepartmentsAutocompleteFilter } from "@health/autocompletes";
import { getAutocompleteEnumFilter } from "@health/enum-options";
import { BACKEND_DATE_TIME_FORMAT } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Button, CustomTableColumnProps, useTheme } from "@toolkit/ui";
import { produce } from "immer";
import moment from "moment/moment";
import { useGetDoctorsFilters } from "pages/VisitHistory/home/useGetDoctorsFilters.hook";
import { VisitDetailsDialog } from "pages/e-visit/VitalSigns/components";
import { useMemo } from "react";
import { openMeetingPlatformLink } from "shared/components/VisitStartingNowNotification/utils";
import { PatientProfile } from "shared/modules/patient";
import { useGetVisitDetailsStatusQuery } from "../gql";
import { VisitTabsStatus } from "../utils";
import { RejoinCallButton } from "./ActiveCall/RejoinCallButton";

export const useVisitColumns = ({ status, isSelfOnly }): CustomTableColumnProps<Partial<Visit>>[] => {
  const { t } = useTranslation("provider");
  const { data } = useGetVisitDetailsStatusQuery();
  const displayVisitDetails = data?.me?.vendor?.enableVisitDetails;
  const doctorFilters = useGetDoctorsFilters(isSelfOnly);
  const theme = useTheme();
  const handleJoinCall = (visitId: string) => () => {
    openMeetingPlatformLink(visitId!);
  };
  // eslint-disable-next-line sonarjs/cognitive-complexity
  return useMemo(() => {
    return [
      {
        key: "created",
        header: t("Visit ID"),
        accessor: "id",
        sort: {
          columnEnum: VisitSortField.Created,
        },
        filter: {
          name: "id",
          type: "string",
        },
      },
      {
        key: "Patient Name",
        header: t("Patient Name"),
        accessor: ({ patient }) => (
          <PatientProfile
            patientId={patient?.id}
            patientName={{
              firstName: patient?.firstName,
              lastName: patient?.lastName,
            }}
          />
        ),
        filter: {
          type: "string",
          name: "patientName",
        },
      },
      {
        key: "Doctor Name",
        header: t("Doctor Name"),
        accessor: ({ doctor }) => doctor?.user?.fullName,
        isHidden: status === VisitTabsStatus.completed || status === VisitTabsStatus.activeCall || isSelfOnly,
      },
      {
        key: "Call start time",
        header: t("Call start time"),
        type: "datetime",
        accessor: "startTime",
      },
      {
        key: "Call Duration",
        header: t("Call Duration"),
        accessor: ({ duration }) => {
          if (duration) {
            return moment.utc(duration * 1000).format("HH:mm:ss");
          } else return "0";
        },
        isHidden: status === VisitTabsStatus.activeCall,
      },
      {
        key: "payers",
        header: t("Operator Name"),
        showOnlyForFilterField: true,
        filter: getPayersAutocompleteFilter({ name: "payers", multiple: true }),
      },
      {
        key: "National ID",
        header: t("National ID"),
        showOnlyForFilterField: true,
        filter: {
          type: "string",
          name: "nationalIds",
          getValueForBackend: value => [value],
        },
      },
      {
        key: "join-call",
        header: t("Join Call"),
        accessor: ({ id, status }) =>
          status === VisitStatus.CallRejoinable ? (
            <RejoinCallButton visitId={id!} />
          ) : (
            <Button color={"success"} disabled={status !== VisitStatus.CallInProgress} onClick={handleJoinCall(id!)}>
              {t("Join Now")}
            </Button>
          ),
        isHidden: status !== VisitTabsStatus.activeCall,
        settings: {
          hideFromPreferencesAndTable: !displayVisitDetails,
        },
      },
      {
        key: "Visit details",
        header: t("Visit Details"),
        accessor: ({ id, status: visitStatus, healthProgramMember }) => (
          <VisitDetailsDialog
            visitId={id as string}
            visitStatus={visitStatus as VisitStatus}
            status={status}
            payerId={healthProgramMember?.insuranceCompanyId!}
            color={status !== VisitTabsStatus.incomplete ? theme.palette.stale.main : theme.palette.warning.main}
          />
        ),
        isHidden: status === VisitTabsStatus.canceled || status == VisitTabsStatus.newRequested,
        settings: {
          hideFromPreferencesAndTable: !displayVisitDetails,
        },
      },
      {
        key: "startDate",
        header: t("Visit Date From"),
        showOnlyForFilterField: true,
        filter: {
          type: "dateTime",
          name: "date.gte",
          label: t("Visit Date From"),
        },
      },
      {
        key: "endDate",
        header: t("Visit Date To"),
        showOnlyForFilterField: true,
        filter: {
          type: "dateTime",
          name: "date.lte",
          label: t("Visit Date To"),
          getValueForBackend: value =>
            produce(value, draft => {
              draft["gte"] = draft?.gte ? moment(draft?.gte).format(BACKEND_DATE_TIME_FORMAT) : null;
              draft["lte"] = draft?.lte ? moment(draft?.lte).format(BACKEND_DATE_TIME_FORMAT) : null;
            }),
        },
      },
      {
        key: "careType",
        header: t("Care Type"),
        showOnlyForFilterField: true,
        filter: getAutocompleteEnumFilter("VisitCareType", "careType"),
      },
      {
        key: "careInitialType",
        header: t("Care Initial Type"),
        showOnlyForFilterField: true,
        filter: getAutocompleteEnumFilter("CareType", "careInitialType"),
      },
      {
        key: "type",
        header: t("Visit Type"),
        showOnlyForFilterField: true,
        filter: getAutocompleteEnumFilter("VisitType", "types"),
      },
      {
        key: "Branches",
        header: t("Branches"),
        showOnlyForFilterField: true,
        filter: getBranchesAutocompleteFilter({ name: "branches", multiple: true }),
      },
      {
        key: "Departments",
        header: t("Departments"),
        showOnlyForFilterField: true,
        filter: getVendorDepartmentsAutocompleteFilter({ name: "departments", multiple: true }),
      },
      ...doctorFilters,
      {
        key: "cancellationReason",
        header: t("Cancellation Reason"),
        accessor: ({ cancellationReason }) => cancellationReason,
        type: "truncated-text",
        isHidden: !(status === VisitTabsStatus.canceled),
      },
    ];
  }, [t, status, displayVisitDetails, isSelfOnly]);
};
