import { User } from "@/schema/types";
import { useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps } from "@toolkit/ui";
import { useMemo } from "react";

export const useFiltersFields = (): CustomTableColumnProps<Partial<User>>[] => {
  const { t } = useTranslation("provider");

  return useMemo(() => {
    return [
      {
        key: "fromDate",
        header: t("From Date"),
        showOnlyForFilter: true,
        filter: {
          type: "date",
          name: "fromDate",
        },
      },
      {
        key: "toDate",
        header: t("To Date"),
        showOnlyForFilter: true,
        filter: {
          type: "date",
          name: "toDate",
        },
      },
    ];
  }, [t]);
};
