import { PayerCredentials } from "@/schema/types";
import { formGirdSpacing, useCustomForm } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FormActions, FormCard, Grid, PageWrapper } from "@toolkit/ui";
import { FC, useEffect } from "react";
import { FormProvider } from "react-hook-form";
import { convertOptimaPayerCredentialToFormValues } from "../../others";
import {
  optimaPayerCredentialUpsertFormDefaultValues,
  optimaPayerCredentialUpsertFormSchema,
  IOptimaPayerCredentialUpsertFormValues,
} from "./OptimaPayerCredentialUpsertFormSchema";
import { OptimaPayerCredentialInformationForm } from "../OptimaPayerCredentiaInformation/OptimaPayerCredentialInformationForm";

export type IOptimaPayerCredentialUpsertFormEvent = {
  type: "SUBMIT";
  payload: {
    values: IOptimaPayerCredentialUpsertFormValues;
  };
};

type OptimaPayerCredentialUpsertFormProps = {
  optimaPayerCredential?: PayerCredentials;
  buttonLabel: string;
  isLoading?: boolean;
  isSubmitting?: boolean;
  onChange: (event: IOptimaPayerCredentialUpsertFormEvent) => void;
  mode: "create" | "update";
};

export const OptimaPayerCredentialUpsertForm: FC<OptimaPayerCredentialUpsertFormProps> = props => {
  const { mode, buttonLabel, optimaPayerCredential, isLoading, isSubmitting, onChange } = props;

  const { t } = useTranslation("provider");

  const form = useCustomForm<IOptimaPayerCredentialUpsertFormValues>({
    defaultValues: optimaPayerCredentialUpsertFormDefaultValues,
    schema: optimaPayerCredentialUpsertFormSchema,
  });

  const { handleSubmit, setValues } = form;

  const onSubmit = (values: IOptimaPayerCredentialUpsertFormValues) => {
    onChange({
      type: "SUBMIT",
      payload: { values },
    });
  };

  useEffect(() => {
    if (optimaPayerCredential) {
      const _optimaPayerCredential = convertOptimaPayerCredentialToFormValues(optimaPayerCredential, mode);
      setValues(_optimaPayerCredential);
    }
  }, [optimaPayerCredential, setValues]);

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PageWrapper
          actions={
            <FormActions
              hasCancel
              hasFormButton
              isLoading={isLoading}
              formButtonTitle={buttonLabel}
              newButtonDisabled={isLoading || isSubmitting}
            />
          }
        >
          <Grid container spacing={formGirdSpacing}>
            <Grid item xs={12}>
              <FormCard title={t("Optima Payer Portal Credential Information")} loading={isLoading} doYouHaveData>
                <OptimaPayerCredentialInformationForm />
              </FormCard>
            </Grid>
          </Grid>
        </PageWrapper>
      </form>
    </FormProvider>
  );
};
