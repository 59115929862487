import { useTranslation } from "@toolkit/i18n";
import { PageWrapper, TableGrid, TableSettingComponent, useBreadCrumbs, ActiveFilters, StaticFilterDialog } from "@toolkit/ui";
import { FC, useEffect, useMemo } from "react";
import { GridProvider } from "shared/components";
import { RequestListKpis } from "../../components/RequestListKpis/RequestListKpis";
import { optimaValidationRequestsRoutesBreadcrumb } from "../../constants";
import { useGetRcmOptimaValidationRequestsQuery } from "../../gql";
import { useOptimaValidationRequestsListContainerColumns } from "./useOptimaValidationRequestsListContainerColumns";
import { useOptimaValidationRequestsListKpiController } from "./useOptimaValidationRequestsListKpiController";
import { useFiltersFields } from "./useFilterFields";

export const OptimaValidationRequestsListContainer: FC = () => {
  const { t } = useTranslation("provider");

  const { setBreadCrumb } = useBreadCrumbs();

  useEffect(() => {
    setBreadCrumb({
      title: optimaValidationRequestsRoutesBreadcrumb(t),
    });
  }, []);

  const { selectedKpiMode, handleKpiModeChange, totalCounts, activeFiltersProps, staticFiltersProps } =
    useOptimaValidationRequestsListKpiController();

  const queryVars = useMemo(() => {
    return {
      filter: activeFiltersProps.requestedFilters || selectedKpiMode.filters,
    };
  }, [activeFiltersProps.requestedFilters, selectedKpiMode.filters]);

  return (
    <GridProvider
      gridName={"optimaRcmValidationRequests" + selectedKpiMode.type}
      query={useGetRcmOptimaValidationRequestsQuery}
      columns={useOptimaValidationRequestsListContainerColumns()}
      variables={queryVars}
      hasTableSetting
      hideFilterInput
      onFiltersChanged={staticFiltersProps.onApplyFilters}
    >
      <PageWrapper
        contentContainerSX={{
          pt: 0,
        }}
        filters={
          <>
            <ActiveFilters {...activeFiltersProps} />
            <StaticFilterDialog filterFields={useFiltersFields()} {...staticFiltersProps} />
          </>
        }
        actions={<TableSettingComponent />}
      >
        <RequestListKpis totalCounts={totalCounts} selectedKpiMode={selectedKpiMode} handleKpiModeChange={handleKpiModeChange} />
        <TableGrid />
      </PageWrapper>
    </GridProvider>
  );
};
