import { Box, Typography } from "@toolkit/ui";
import { useTranslation } from "@toolkit/i18n";
import moment from "moment";
import { FC } from "react";
import { IOptimaPatientEligibilityRequestHistoryDataType } from "@/pages/OptimaPatientEligibilityScan/types";

type DetailedInfoSectionProps = {
  eligibilityData: IOptimaPatientEligibilityRequestHistoryDataType["patientEligibility"];
};

export const DetailedInfoSection: FC<DetailedInfoSectionProps> = ({ eligibilityData }) => {
  const { t } = useTranslation("optima");

  return (
    <Box sx={{ display: "flex", columnGap: 4, mb: 2, mx: 2 }}>
      <Typography>
        <b>{t("DHA Member Id")}:</b> <br />
        {eligibilityData?.dhaId || "N/A"}
      </Typography>

      <Typography>
        <b>{t("Policy No")}:</b> <br />
        {eligibilityData?.policyNumber || "N/A"}
      </Typography>

      <Typography>
        <b> {t("Expiry Date")}: </b>
        <br />
        {moment(eligibilityData?.effectiveTo).format("YYYY-MM-DD")}
      </Typography>

      <Typography>
        <b>{t("Company Name")}:</b> <br />
        {eligibilityData?.companyName || "N/A"}
      </Typography>

      <Typography>
        <b> {t("Network")}: </b>
        <br />
        {eligibilityData?.network || "N/A"}
      </Typography>
    </Box>
  );
};
