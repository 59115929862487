import { DepartmentMedicalServiceCountableEdge, Maybe } from "@/schema/types";
import { InfoItems } from "@toolkit/ui";

export const getDepartmentMedicalServiceInfoItems = (medicalServices: Maybe<DepartmentMedicalServiceCountableEdge>[]): InfoItems => {
  const medicalServicesList = medicalServices?.map(service => service?.node?.code || "-");
  return medicalServicesList
    ? [
        {
          value: medicalServicesList,
          valueDisplayMode: "chips",
          label: "Medical Services",
          direction: "column",
        },
      ]
    : undefined;
};
