import { useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps } from "@toolkit/ui";
import { useMemo } from "react";
import { ITransactionValidateRequestItemFormValues } from "../TransactionValidateRequestItem/TransactionValidateRequestItemFormSchema";

export const useTransactionValidateRequestItemsColumns = (): CustomTableColumnProps<ITransactionValidateRequestItemFormValues>[] => {
  const { t } = useTranslation("provider");
  return useMemo(() => {
    return [
      {
        key: "sequence",
        header: t("Sequence"),
        accessor: ({ sequence }) => sequence,
      },
      {
        key: "code",
        header: t("Code"),
        accessor: ({ code }) => code,
      },
      {
        key: "display",
        header: t("Display"),
        accessor: ({ display }) => display,
      },
      {
        key: "codeSystem",
        header: t("Code System"),
        accessor: ({ codeSystem }) => codeSystem,
      },
      {
        key: "type",
        header: t("Type"),
        accessor: ({ type }) => type,
      },
      {
        key: "serviceStart",
        header: t("Service Start"),
        type: "datetime",
        accessor: ({ serviceStart }) => serviceStart,
      },
      {
        key: "serviceEnd",
        header: t("Service End"),
        type: "datetime",
        accessor: ({ serviceEnd }) => serviceEnd,
      },
      {
        key: "quantity",
        header: t("Quantity"),
        accessor: ({ quantity }) => quantity,
      },
      {
        key: "authorizationId",
        header: t("Authorization ID"),
        accessor: ({ authorizationId }) => authorizationId,
      },
      {
        key: "unitPrice",
        header: t("Unit Price"),
        accessor: ({ unitPrice }) => unitPrice,
      },
      {
        key: "patientShare",
        header: t("Patient Share"),
        accessor: ({ patientShare }) => patientShare,
      },
      {
        key: "net",
        header: t("Net Amount"),
        accessor: ({ net }) => net,
      },
      {
        key: "tax",
        header: t("Tax"),
        accessor: ({ tax }) => tax,
      },
      {
        key: "currency",
        header: t("Currency"),
        accessor: ({ currency }) => currency,
      },
      {
        key: "linkedCareTeam",
        header: t("Linked Care Team"),
        accessor: ({ linkedCareTeam }) => linkedCareTeam,
      },
      {
        key: "linkedDiagnoses",
        header: t("Linked Diagnoses"),
        accessor: ({ linkedDiagnoses }) => linkedDiagnoses.join(", "),
      },
      {
        key: "linkedObservations",
        header: t("Linked Observations"),
        accessor: ({ linkedObservations }) => linkedObservations.join(", "),
      },
    ];
  }, [t]);
};
