import { makeStyles } from "@toolkit/ui";

export const useDownloadButtonStyles = makeStyles()({
  button: {
    textDecoration: "underline",
    "& svg": {
      width: "14px",
      height: "14px",
      position: "relative",
      right: "-3px",
    },
  },
});
