import { patientEligibilityBenefitsEnumOptions } from "@health/enum-options";
import { useTranslation } from "@toolkit/i18n";
import { Box, Button, Typography } from "@toolkit/ui";
import { useCallback, useEffect, useState } from "react";
import { IOptimaBenefitOptionType, IOptimaPatientEligibilityRequestHistoryDataType } from "../../types";
import { EligibilityCardsLayout } from "../EligibilityCardsLayout/EligibilityCardsLayout";
import { EligibilityDetails } from "../EligibilityDetails/EligibilityDetails";
import { LoadingIndicator } from "../LoadingIndicator/LoadingIndicator";
import { ScanHistoryCard } from "../ScanHistoryCard/ScanHistoryCard";
import { Benefit } from "@/schema/types";

interface EligibilityScanStatusResultProps {
  nodeData: IOptimaPatientEligibilityRequestHistoryDataType;
  loading: boolean;
  isScanCompleted: boolean;
  isFounded: boolean;
  initialBenefit?: Benefit;
  eligibilityData: IOptimaPatientEligibilityRequestHistoryDataType["patientEligibility"];
  isDoingOptimisticScanCheck: boolean;
  payerEligibilityRequestHistories: IOptimaPatientEligibilityRequestHistoryDataType["payerEligibilityRequestHistories"];
  handleOpenCreateModal: () => void;
}

export const EligibilityScanStatusResult: React.FC<EligibilityScanStatusResultProps> = ({
  nodeData,
  loading,
  isScanCompleted,
  isFounded,
  initialBenefit,
  eligibilityData,
  isDoingOptimisticScanCheck,
  payerEligibilityRequestHistories,
  handleOpenCreateModal,
}) => {
  const { t } = useTranslation("provider");
  const [benefit, setBenefit] = useState<IOptimaBenefitOptionType>(patientEligibilityBenefitsEnumOptions[0]);

  const handleBenefitAutocompleteChange = useCallback((_, value: IOptimaBenefitOptionType | null) => {
    if (value) {
      setBenefit(value);
    }
  }, []);
  const benefitData = eligibilityData?.benefitEligibility?.find(
    b => b?.benefit?.toUpperCase() === benefit?.value?.toString().toUpperCase()
  );

  useEffect(() => {
    const eligibleBenefit = eligibilityData?.benefitEligibility?.find(b => b?.isEligible)?.benefit;

    const selectedBenefitOption = patientEligibilityBenefitsEnumOptions.find(
      option => option.value === (initialBenefit || eligibleBenefit)
    );

    setBenefit(selectedBenefitOption || patientEligibilityBenefitsEnumOptions[0]);
  }, [eligibilityData, initialBenefit, setBenefit]);

  return (
    <>
      {((nodeData && !isScanCompleted) || isDoingOptimisticScanCheck) && <LoadingIndicator message={t("Scan in Progress...")} />}

      {isFounded && eligibilityData && (
        <EligibilityDetails
          benefitData={benefitData!}
          benefit={benefit}
          nodeData={nodeData!}
          eligibilityData={eligibilityData}
          handleBenefitAutocompleteChange={handleBenefitAutocompleteChange}
        />
      )}
      {isFounded && eligibilityData && (
        <EligibilityCardsLayout
          payerEligibilityRequestHistories={payerEligibilityRequestHistories}
          eligibilityData={eligibilityData!}
          benefitData={benefitData!}
          isScanCompleted={isScanCompleted}
          handleOpenCreateModal={handleOpenCreateModal}
        />
      )}

      {!loading && !nodeData?.id && !isDoingOptimisticScanCheck && (
        <Box sx={{ p: 2.5, mt: 2, textAlign: "center", display: "flex", flexDirection: "column", alignItems: "center", rowGap: 2 }}>
          <Typography fontSize={18}>{t("Not scanned yet, you can scan now.")}</Typography>
          <Button onClick={handleOpenCreateModal} sx={{ maxWidth: 200, mx: "auto" }}>
            {t("Scan Now")}
          </Button>
        </Box>
      )}

      {!(isFounded && eligibilityData) && payerEligibilityRequestHistories && (
        <ScanHistoryCard
          payerEligibilityRequestHistories={payerEligibilityRequestHistories}
          isScanCompleted={isScanCompleted}
          handleOpenCreateModal={handleOpenCreateModal}
        />
      )}
    </>
  );
};
