import { EChart, EChartOption, useTheme } from "@toolkit/ui";
import { FC } from "react";
import { getPatientHealthParameterChartTooltip } from "../../others";
import { IHealthParameterChartDataSet } from "../../types";
import { graphic } from "echarts";

type PatientHealthParameterChartSmoothLineProps = {
  dataSets: IHealthParameterChartDataSet[];
  unit?: string;
  isLoading?: boolean;
};

export const PatientHealthParameterChartSmoothLine: FC<PatientHealthParameterChartSmoothLineProps> = props => {
  const { dataSets, unit, isLoading } = props;

  const theme = useTheme();

  const defaultColors = [theme.palette.primary.main, theme.palette.secondary.main];

  const chartOption: EChartOption = {
    grid: {
      left: "2%",
      right: "2%",
      bottom: "2%",
    },
    tooltip: {
      trigger: dataSets.length === 1 ? "item" : "axis",
      formatter: params => getPatientHealthParameterChartTooltip(params, dataSets?.length, unit),
      extraCssText: "border: 1px solid",
    },
    xAxis: {
      type: "category",
      axisTick: {
        show: false,
      },
      axisLine: {
        show: false,
      },
      axisLabel: {
        show: false,
      },
    },
    yAxis: {
      type: "value",
    },
    series: dataSets?.map((dataSet, index) => ({
      name: `Smooth Line ${index + 1}`,
      type: "line",
      data: dataSet.data,
      symbol: "circle",
      smooth: true,
      symbolSize: 10,
      lineStyle: {
        color: "transparent",
      },
      itemStyle: {
        color: dataSet.color || defaultColors[index] || theme.palette.gray.main,
      },
      areaStyle: {
        color: new graphic.LinearGradient(0, 0, 0, 1, [
          { offset: 0, color: dataSet.color || defaultColors[index] || theme.palette.gray.main },
          { offset: 1, color: theme.palette.common.white },
        ]),
      },
    })),
  };

  return <EChart option={chartOption} isLoading={isLoading} />;
};
