import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ActivityBasedDoctorsQueryVariables = Types.Exact<{
  doctorsWithActivities: Types.Scalars['Boolean']['input'];
  fromDate: Types.Scalars['Date']['input'];
  toDate: Types.Scalars['Date']['input'];
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type ActivityBasedDoctorsQuery = { __typename?: 'Query', activityBasedDoctors?: { __typename?: 'UserCountableConnection', totalCount?: number | null, pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null }, edges: Array<{ __typename?: 'UserCountableEdge', node: { __typename?: 'User', id: string, fullName?: string | null, email?: string | null, mobile?: string | null, healthLicenseEndDate?: any | null, healthLicenseStartDate?: any | null, dateJoined: any } }> } | null };


export const ActivityBasedDoctorsDocument = gql`
    query ActivityBasedDoctors($doctorsWithActivities: Boolean!, $fromDate: Date!, $toDate: Date!, $before: String, $after: String, $first: Int, $last: Int) {
  activityBasedDoctors(
    doctorsWithActivities: $doctorsWithActivities
    fromDate: $fromDate
    toDate: $toDate
    before: $before
    after: $after
    first: $first
    last: $last
  ) {
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
    edges {
      node {
        id
        fullName
        email
        mobile
        healthLicenseEndDate
        healthLicenseStartDate
        dateJoined
      }
    }
    totalCount
  }
}
    `;

/**
 * __useActivityBasedDoctorsQuery__
 *
 * To run a query within a React component, call `useActivityBasedDoctorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useActivityBasedDoctorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActivityBasedDoctorsQuery({
 *   variables: {
 *      doctorsWithActivities: // value for 'doctorsWithActivities'
 *      fromDate: // value for 'fromDate'
 *      toDate: // value for 'toDate'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useActivityBasedDoctorsQuery(baseOptions: Apollo.QueryHookOptions<ActivityBasedDoctorsQuery, ActivityBasedDoctorsQueryVariables> & ({ variables: ActivityBasedDoctorsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ActivityBasedDoctorsQuery, ActivityBasedDoctorsQueryVariables>(ActivityBasedDoctorsDocument, options);
      }
export function useActivityBasedDoctorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ActivityBasedDoctorsQuery, ActivityBasedDoctorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ActivityBasedDoctorsQuery, ActivityBasedDoctorsQueryVariables>(ActivityBasedDoctorsDocument, options);
        }
export function useActivityBasedDoctorsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ActivityBasedDoctorsQuery, ActivityBasedDoctorsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ActivityBasedDoctorsQuery, ActivityBasedDoctorsQueryVariables>(ActivityBasedDoctorsDocument, options);
        }
export type ActivityBasedDoctorsQueryHookResult = ReturnType<typeof useActivityBasedDoctorsQuery>;
export type ActivityBasedDoctorsLazyQueryHookResult = ReturnType<typeof useActivityBasedDoctorsLazyQuery>;
export type ActivityBasedDoctorsSuspenseQueryHookResult = ReturnType<typeof useActivityBasedDoctorsSuspenseQuery>;
export type ActivityBasedDoctorsQueryResult = Apollo.QueryResult<ActivityBasedDoctorsQuery, ActivityBasedDoctorsQueryVariables>;