import { z } from "zod";

export const transactionValidateRequestObservationFormSchema = z.object({
  sequence: z.string(),
  code: z.string(),
  codeSystem: z.string(),
  type: z.string(),
  periodStart: z.string(),
  periodEnd: z.string(),
  value: z.string(),
});

export type ITransactionValidateRequestObservationFormValues = z.infer<typeof transactionValidateRequestObservationFormSchema>;

export const transactionValidateRequestObservationFormDefaultValues: ITransactionValidateRequestObservationFormValues = {
  sequence: "1",
  code: "OBS123456",
  codeSystem: "Observation",
  type: "Attachment",
  periodStart: "2023-01-15T07:00:00Z",
  periodEnd: "2023-01-15T08:00:00Z",
  value: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAoAAAAHgCAIAAAD7k0...",
};

export type ITransactionValidateRequestObservationUpsertFormEvent =
  | { type: "CREATE"; payload: { values: ITransactionValidateRequestObservationFormValues } }
  | { type: "UPDATE"; payload: { values: ITransactionValidateRequestObservationFormValues & { index: string } } };
