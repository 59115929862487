import { z } from "zod";

export const transactionValidateRequestAuditFormSchema = z.object({
  sequence: z.string(),
  userName: z.string(),
  source: z.string(),
  reference: z.string(),
  details: z.string(),
  created: z.string(),
});

export type ITransactionValidateRequestAuditFormValues = z.infer<typeof transactionValidateRequestAuditFormSchema>;

export const transactionValidateRequestAuditFormDefaultValues: ITransactionValidateRequestAuditFormValues = {
  sequence: "1",
  userName: "User123",
  source: "System",
  reference: "REF123456",
  details: "Initial submission of the claim",
  created: "2023-01-15T08:45:00Z",
};

export type ITransactionValidateRequestAuditUpsertFormEvent =
  | { type: "CREATE"; payload: { values: ITransactionValidateRequestAuditFormValues } }
  | { type: "UPDATE"; payload: { values: ITransactionValidateRequestAuditFormValues & { index: string } } };
