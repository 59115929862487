import { DoctorNurseAssignment } from "@/schema/types";
import { useReactiveVar } from "@apollo/client";
import { Typography } from "@toolkit/ui";
import { useEffect, useMemo, useRef } from "react";
import { IUserWorkspaceNurseUpsertFormEvent } from "../../forms/UserWorkspaceNurseUpsert/UserWorkspaceNurseUpsertForm";
import { NurseDoctorListDocument, useNurseDoctorListQuery, useNurseDoctorsAssignMutation } from "../../gql";
import {
  UserWorkspaceNurseUpsertModal,
  UserWorkspaceNurseUpsertModalApi,
} from "../../modals/UserWorkspaceNurseUpsert/UserWorkspaceNurseUpsertModal";
import { convertUserWorkspaceNurseToVarValues, getNurseCurrentWorkspaceMessage, updateUserWorkspaceNurseData } from "../../others";
import { userWorkspaceNurseVar } from "../../vars";
import { useUserWorkspaceNurseStyle } from "./useUserWorkspaceNurseStyle";
import { isEmpty } from "lodash";

export const UserWorkspaceNurse = () => {
  const { classes } = useUserWorkspaceNurseStyle();

  const nurseCurrentWorkspace = useReactiveVar(userWorkspaceNurseVar);

  const assignedDoctorsLength = nurseCurrentWorkspace?.doctors?.length || 0;

  const nurseCurrentWorkspaceMessage = getNurseCurrentWorkspaceMessage(assignedDoctorsLength);

  const { data, loading } = useNurseDoctorListQuery();

  const haveRequiredActionRef = useRef(!nurseCurrentWorkspace);
  const isAssignActionDoneRef = useRef(false);

  const doctorNurseAssignments = useMemo(() => {
    return (data?.doctorNurseAssignments?.edges?.map(item => item?.node) || []) as DoctorNurseAssignment[];
  }, [data]);

  const [assignNurseDoctors, { loading: isSubmitting }] = useNurseDoctorsAssignMutation({
    refetchQueries: [NurseDoctorListDocument],
    onCompleted: () => {
      UserWorkspaceNurseUpsertModalApi.close();
      isAssignActionDoneRef.current = true;
    },
  });

  const onNurseDoctorsAssignModalChange = (event: IUserWorkspaceNurseUpsertFormEvent) => {
    if (event.type === "SUBMIT") {
      const { branch, department, doctors } = event.payload.values;

      const nurseCurrentWorkspaceData = {
        branch: branch?.value,
        department: department?.value,
        doctors: doctors?.map(item => item?.value!),
      };

      assignNurseDoctors({
        variables: {
          input: nurseCurrentWorkspaceData?.doctors?.map(item => ({
            departmentId: nurseCurrentWorkspaceData?.department?.id,
            doctorId: item?.id,
          })),
        },
      });
    }
  };

  const handleOpen = () => {
    UserWorkspaceNurseUpsertModalApi.open({
      userWorkspaceNurse: nurseCurrentWorkspace,
    });
  };

  useEffect(() => {
    if (isAssignActionDoneRef.current) {
      haveRequiredActionRef.current = false;
    }
    if (loading || (isAssignActionDoneRef.current ? false : haveRequiredActionRef.current)) return;
    if (!isEmpty(doctorNurseAssignments)) {
      updateUserWorkspaceNurseData(doctorNurseAssignments, nurseCurrentWorkspace);
    }
  }, [loading, doctorNurseAssignments, nurseCurrentWorkspace]);

  useEffect(() => {
    if (!haveRequiredActionRef.current || loading) return;
    UserWorkspaceNurseUpsertModalApi.open({
      userWorkspaceNurse: !isEmpty(doctorNurseAssignments) ? convertUserWorkspaceNurseToVarValues(doctorNurseAssignments) : undefined,
    });
  }, [doctorNurseAssignments, loading, nurseCurrentWorkspace]);

  return (
    <>
      <Typography className={classes.message} onClick={handleOpen}>
        {nurseCurrentWorkspaceMessage}
      </Typography>

      <UserWorkspaceNurseUpsertModal
        isLoading={isSubmitting}
        hasClose={!!nurseCurrentWorkspace}
        onChange={onNurseDoctorsAssignModalChange}
      />
    </>
  );
};
