import { TransactionRequestInput } from "@/schema/types";
import { ITransactionValidateRequestFormValues } from "../forms/TransactionValidateRequest/TransactionValidateRequestFormSchema";
import { ITransactionValidateRequestAuditFormValues } from "../forms/TransactionValidateRequestAudit/TransactionValidateRequestAuditFormSchema";
import { ITransactionValidateRequestCareTeamFormValues } from "../forms/TransactionValidateRequestCareTeam/TransactionValidateRequestCareTeamFormSchema";
import { ITransactionValidateRequestDiagnosisFormValues } from "../forms/TransactionValidateRequestDiagnosisUpsert/TransactionValidateRequestDiagnosisFormSchema";
import { ITransactionValidateRequestEncounterFormValues } from "../forms/TransactionValidateRequestEncounter/TransactionValidateRequestEncounterFormSchema";
import { ITransactionValidateRequestItemFormValues } from "../forms/TransactionValidateRequestItem/TransactionValidateRequestItemFormSchema";
import { ITransactionValidateRequestObservationFormValues } from "../forms/TransactionValidateRequestObservation/TransactionValidateRequestObservationFormSchema";

export const convertFormValuesToBackendValues = (values: ITransactionValidateRequestFormValues): TransactionRequestInput => {
  return {
    requestId: values.requestId,
    visitId: values.visitId,
    patientName: values.patientName,
    patientId: values.patientId,
    patientGender: values.patientGender?.value,
    patientDoB: values.patientDoB,
    patientWeight: values.patientWeight,
    patientHeight: values.patientHeight,
    providerId: values.providerId,
    status: values.status,
    type: values.type,
    subType: values.subType,
    use: values.use,
    priority: values.priority,
    insurancePayer: values.insurancePayer,
    insuranceReceiver: values.insuranceReceiver,
    insuranceMemberId: values.insuranceMemberId,
    insurancePolicy: values.insurancePolicy,
    insurancePolicyLocation: values.insurancePolicyLocation,
    insurancePlan: values.insurancePlan,
    facility: values.facility,
    prescriptionId: values.prescriptionId,
    payeeType: values.payeeType,
    referral: values.referral,
    gross: values.gross,
    patientShare: values.patientShare,
    net: values.net,
    tax: values.tax,
    diagnoses: convertDiagnosesToBackendValues(values.diagnoses),
    items: convertItemsToBackendValues(values.items),
    careTeam: convertCareTeamToBackendValues(values.careTeam),
    observations: convertObservationsToBackendValues(values.observations),
    encounter: convertEncounterToBackendValues(values.encounter),
    audit: convertAuditToBackendValues(values.audit),
  };
};

const convertDiagnosesToBackendValues = (
  diagnoses: ITransactionValidateRequestDiagnosisFormValues[]
): TransactionRequestInput["diagnoses"] => {
  return diagnoses.map(diagnosis => ({
    sequence: diagnosis.sequence,
    code: diagnosis.code,
    display: diagnosis.display,
    codeSystem: diagnosis.codeSystem,
    type: diagnosis.type,
    onAdmission: diagnosis.onAdmission,
  }));
};

const convertItemsToBackendValues = (items: ITransactionValidateRequestItemFormValues[]): TransactionRequestInput["items"] => {
  return items.map(item => ({
    sequence: item.sequence,
    code: item.code,
    display: item.display,
    codeSystem: item.codeSystem,
    type: item.type,
    serviceStart: item.serviceStart,
    serviceEnd: item.serviceEnd,
    quantity: item.quantity,
    authorizationId: item.authorizationId,
    unitPrice: item.unitPrice,
    patientShare: item.patientShare,
    net: item.net,
    tax: item.tax,
    currency: item.currency,
    linkedCareTeam: item.linkedCareTeam.toString(),
    linkedDiagnoses: item.linkedDiagnoses,
    linkedObservations: item.linkedObservations,
  }));
};

const convertCareTeamToBackendValues = (careTeam: ITransactionValidateRequestCareTeamFormValues[]): TransactionRequestInput["careTeam"] => {
  return careTeam.map(member => ({
    sequence: member.sequence,
    practitionerId: member.practitionerId,
    practitionerRole: member.practitionerRole,
    speciality: member.speciality,
  }));
};

const convertObservationsToBackendValues = (
  observations: ITransactionValidateRequestObservationFormValues[]
): TransactionRequestInput["observations"] => {
  return observations.map(observation => ({
    sequence: observation.sequence,
    code: observation.code,
    codeSystem: observation.codeSystem,
    type: observation.type,
    periodStart: observation.periodStart,
    periodEnd: observation.periodEnd,
    value: observation.value,
  }));
};

const convertEncounterToBackendValues = (
  encounters: ITransactionValidateRequestEncounterFormValues[]
): TransactionRequestInput["encounter"] => {
  return encounters.map(encounter => ({
    sequence: encounter.sequence,
    status: encounter.status,
    type: encounter.type,
    start: encounter.start,
    startType: encounter.startType,
    end: encounter.end,
    endType: encounter.endType,
    transferSource: encounter.transferSource,
    transferDestination: encounter.transferDestination,
    eligibilityId: encounter.eligibilityId,
  }));
};

const convertAuditToBackendValues = (audits: ITransactionValidateRequestAuditFormValues[]): TransactionRequestInput["audit"] => {
  return audits.map(audit => ({
    sequence: audit.sequence,
    userName: audit.userName,
    source: audit.source,
    reference: audit.reference,
    details: audit.details,
    created: audit.created,
  }));
};
