/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable max-lines */
import { TransactionClaimSortField } from "@/schema/types";
import { transactionSourceTypeOptionsMap, transactionStatusTypeOptionsMap } from "@health/enum-options";
import { ExtractNodeType } from "@toolkit/apollo";
import { toTitleCase } from "@toolkit/core";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps, Typography, useTheme } from "@toolkit/ui";
import { ClaimActivitiesDrawer } from "@/pages/OptimaClaims/Components/ClaimActivitiesDrawer/ClaimActivitiesDrawer";
import { RemittanceDrawer } from "@/pages/OptimaClaims/Components/RemittanceDrawer/RemittanceDrawer";
import { ResubmissionDrawer } from "@/pages/OptimaClaims/Components/ResubmissionDrawer/ResubmissionDrawer";
import { OptimaClaimsListQuery } from "@/pages/OptimaClaims/gql";
import { useMemo } from "react";
import { GridCellText } from "../../../../components";
import { formatFilterDateForBackend, getOptimaAuthorizationStatusColor } from "../../../../utils";
import { getBranchesAutocompleteFilter, getOptimaEncounterTypesAutocompleteFilter } from "@health/autocompletes";

type OptimaClaimListContainerColumnsProps = {
  isProviderAdmin?: boolean;
  formattedCurrency: string;
};

export const useOptimaClaimListContainerColumns = (
  props: OptimaClaimListContainerColumnsProps
): CustomTableColumnProps<ExtractNodeType<OptimaClaimsListQuery>>[] => {
  const { isProviderAdmin, formattedCurrency } = props;
  const { t } = useTranslation("domains");
  const theme = useTheme();

  return useMemo(() => {
    return [
      {
        key: "transactionId",
        header: t("Transaction Id"),
        accessor: ({ transactionId }) => <GridCellText text={transactionId} />,
        filter: {
          type: "string",
          name: "transactionId",
        },
      },
      {
        key: "transactionDate",
        header: t("Transaction Date"),
        sort: {
          columnEnum: TransactionClaimSortField.TransactionDate,
        },
        type: "datetime",
        accessor: "transactionDate",
      },
      {
        key: "authorizationStatus",
        header: t("Status"),
        accessor: ({ remittance }) => (
          <Typography
            fontWeight={theme.mixins.fonts.fontWeight.medium}
            color={getOptimaAuthorizationStatusColor(remittance?.status || "", theme)}
          >
            {transactionStatusTypeOptionsMap[remittance?.status || ""]?.label || t("Pending")}
          </Typography>
        ),
      },
      {
        key: "transactionType",
        header: t("Transaction Type"),
        accessor: ({ transactionType }) => transactionType,
      },
      {
        key: "provider",
        header: t("Provider"),
        type: "truncated-text",
        accessor: ({ provider }) => (provider?.name ? toTitleCase(provider?.name) : undefined),
      },
      {
        key: "branches",
        header: t("Branches"),
        accessor: ({ Branch }) => pickLocalizedValue(Branch?.name, Branch?.nameAr),
        filter: getBranchesAutocompleteFilter({ name: "branches", multiple: true }),
        hideFromPreferencesAndTable: !isProviderAdmin,
      },
      {
        key: "branches",
        header: t("Branches"),
        accessor: ({ Branch }) => pickLocalizedValue(Branch?.name, Branch?.nameAr),
        filter: getBranchesAutocompleteFilter({ name: "branches", multiple: true }),
        hideFromPreferencesAndTable: !isProviderAdmin,
      },
      {
        key: "patientId",
        header: t("Patient Id"),
        accessor: ({ patientId }) => <GridCellText text={patientId} />,
        filter: {
          type: "string",
          name: "patientId",
        },
      },
      {
        key: "memberId",
        header: t("Member Id"),
        accessor: ({ memberId }) => <GridCellText text={memberId} />,
        filter: {
          type: "string",
          name: "memberId",
        },
      },
      {
        key: "emiratesIDNumber",
        header: t("Emirate ID"),
        accessor: ({ emiratesIDNumber }) => <GridCellText text={emiratesIDNumber} />,
        filter: {
          type: "string",
          name: "emiratesIDNumber",
        },
      },
      {
        key: "payer",
        header: t("Payer"),
        type: "truncated-text",
        accessor: ({ payer }) => (payer?.name ? toTitleCase(payer?.name) : undefined),
      },
      {
        key: "payerId",
        header: t("Payer Id"),
        showOnlyForFilter: true,
        hideFromPreferencesAndTable: true,
        filter: {
          type: "string",
          name: "payerId",
        },
      },
      {
        key: "receiver",
        header: t("Receiver"),
        type: "truncated-text",
        accessor: ({ receiver }) => (receiver?.name ? toTitleCase(receiver?.name) : undefined),
      },
      {
        key: "receiverId",
        header: t("Receiver Id"),
        showOnlyForFilter: true,
        hideFromPreferencesAndTable: true,
        filter: {
          type: "string",
          name: "receiverId",
        },
      },
      {
        key: "encounterType",
        header: t("Encounter  Type"),
        accessor: ({ encounterType, encounterTypeLookup }) => encounterTypeLookup?.name || encounterType,
        filter: getOptimaEncounterTypesAutocompleteFilter({ name: "encounterType", multiple: true }),
        showOnlyForFilter: true,
      },
      {
        key: "encounterStartType",
        header: t("Encounter Start Type"),
        accessor: ({ encounterStartType, encounterStartTypeLookup }) => encounterStartTypeLookup?.name || encounterStartType,
      },
      {
        key: "encounterStart",
        header: t("Encounter Start Date"),
        type: "datetime",
        accessor: "encounterStart",
      },
      {
        key: "encounterEndType",
        header: t("encounter End Type"),
        accessor: ({ encounterEndType, encounterEndTypeLookup }) => encounterEndTypeLookup?.name || encounterEndType,
      },
      {
        key: "encounterEnd",
        header: t("Encounter End Date"),
        type: "datetime",
        accessor: "encounterEnd",
      },
      {
        key: "gross",
        header: t("Gross"),
        accessor: ({ gross }) => (gross ? gross + formattedCurrency : undefined),
      },
      {
        key: "net",
        header: t("Net"),
        accessor: ({ net }) => (net ? net + formattedCurrency : undefined),
      },
      {
        key: "source",
        header: t("Source"),
        accessor: ({ source }) => transactionSourceTypeOptionsMap[source].label,
      },
      {
        key: "claimActivities",
        header: t("Activities"),
        accessor: claim => <ClaimActivitiesDrawer claim={claim} />,
      },
      {
        key: "Remittance",
        header: t("Remittance"),
        accessor: claim => <RemittanceDrawer claim={claim} />,
      },
      {
        key: "Resubmission",
        header: t("Resubmission"),
        accessor: claim => <ResubmissionDrawer claim={claim} />,
      },
      {
        key: "transactionDateInputGte",
        header: t("Transaction Date From"),
        showOnlyForFilter: true,
        settings: {
          hideFromPreferencesAndTable: true,
        },
        filter: {
          type: "date",
          name: "transactionDateInput.gte",
        },
      },
      {
        key: "transactionDateInputLte",
        header: t("Transaction Date To"),
        showOnlyForFilter: true,
        settings: {
          hideFromPreferencesAndTable: true,
        },
        filter: {
          type: "date",
          name: "transactionDateInput.lte",
          getValueForBackend: formatFilterDateForBackend,
        },
      },
      {
        key: "encounterStartDateInputGte",
        header: t("Encounter Start Date From"),
        showOnlyForFilter: true,
        settings: {
          hideFromPreferencesAndTable: true,
        },
        filter: {
          type: "date",
          name: "encounterStartDateInput.gte",
        },
      },
      {
        key: "encounterStartDateInputLte",
        header: t("Encounter Start Date To"),
        showOnlyForFilter: true,
        settings: {
          hideFromPreferencesAndTable: true,
        },
        filter: {
          type: "date",
          name: "encounterStartDateInput.lte",
          getValueForBackend: formatFilterDateForBackend,
        },
      },
      {
        key: "encounterEndDateInputGte",
        header: t("Encounter End Date From"),
        showOnlyForFilter: true,
        settings: {
          hideFromPreferencesAndTable: true,
        },
        filter: {
          type: "date",
          name: "encounterEndDateInput.gte",
        },
      },
      {
        key: "encounterEndDateInputLte",
        header: t("Encounter End Date To"),
        showOnlyForFilter: true,
        settings: {
          hideFromPreferencesAndTable: true,
        },
        filter: {
          type: "date",
          name: "encounterEndDateInput.lte",
          getValueForBackend: formatFilterDateForBackend,
        },
      },
    ];
  }, [t]);
};
