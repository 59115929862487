import { formGirdBreakPoints, useCustomFormContext } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { AutocompleteController, Grid } from "@toolkit/ui";
import { FC } from "react";
import { IVirtualIntegrationFormValues } from "./VirtualIntegrationFormSchema";
import { conferenceProviderTypeOptions } from "@health/enum-options";

export const ProvidersType: FC<{ defaultValue }> = ({ defaultValue }) => {
  const { t } = useTranslation("admin");
  const {
    control,
    formState: { errors: formErrors },
  } = useCustomFormContext<IVirtualIntegrationFormValues>();

  const typeValue = defaultValue ? conferenceProviderTypeOptions?.find(item => item.value === defaultValue) : undefined;

  return (
    <Grid item {...formGirdBreakPoints}>
      <AutocompleteController
        ControllerProps={{
          control: control,
          name: "providerType",
          defaultValue: typeValue,
        }}
        TextFieldProps={{
          placeholder: t("Provider Type"),
          error: Boolean(formErrors?.providerType?.message),
          helperText: String(formErrors?.providerType?.message || ""),
        }}
        getOptionLabel={option => option.label}
        options={conferenceProviderTypeOptions || []}
      />
    </Grid>
  );
};
