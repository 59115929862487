import { ProviderSettings, ProviderSettingsInput } from "@/schema/types";
import { formGirdSpacing } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FormCard, Grid, TextField } from "@toolkit/ui";
import { FC } from "react";
import { useFormContext } from "react-hook-form";

export type OutPatientJourneyFormProps = {
  providerSettings?: ProviderSettings | null;
  isLoading?: boolean;
};

const AppointmentTimeOutSettings: FC<OutPatientJourneyFormProps> = props => {
  const { providerSettings, isLoading: loading = false } = props;
  const { t } = useTranslation("provider");

  const {
    register,
    formState: { errors },
  } = useFormContext<ProviderSettingsInput>();

  return (
    <FormCard title={t("Appointments TimeOut")} loading={loading} doYouHaveData>
      <Grid container spacing={formGirdSpacing}>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            size='small'
            type='number'
            label={t("Appointment Confirmation TimeOut (Minutes)")}
            error={Boolean(errors.appointmentConfirmationTimeoutSeconds?.message)}
            helperText={t(errors.appointmentConfirmationTimeoutSeconds?.message!)}
            {...register("appointmentConfirmationTimeoutSeconds", {
              required: { value: true, message: t("Required") },
              valueAsNumber: true,
            })}
            InputProps={{
              inputProps: { min: 0 },
            }}
            defaultValue={providerSettings?.appointmentConfirmationTimeoutSeconds! / 60}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            size='small'
            type='number'
            label={t("Appointment Reminder TimeOut (Minutes)")}
            error={Boolean(errors.appointmentReminderTimeoutSeconds?.message)}
            helperText={t(errors.appointmentReminderTimeoutSeconds?.message!)}
            {...register("appointmentReminderTimeoutSeconds", {
              required: { value: true, message: t("Required") },
              valueAsNumber: true,
            })}
            InputProps={{
              inputProps: { min: 0 },
            }}
            defaultValue={providerSettings?.appointmentReminderTimeoutSeconds! / 60}
          />
        </Grid>
      </Grid>
    </FormCard>
  );
};

export default AppointmentTimeOutSettings;
