import { getLocalStorageItem, removeLocalStorageItem, setLocalStorageItem } from "@toolkit/core";
import { IUserWorkspaceNurseVar } from "@/pages/UserWorkspace/types";
import { IUserWorkspaceDoctorValue } from "@health/domains";
type ProviderLocalStorageValues = {
  UserWorkspaceDoctor: IUserWorkspaceDoctorValue;
  UserWorkspaceNurse: IUserWorkspaceNurseVar;
};

type ProviderLocalStorageKeys = keyof ProviderLocalStorageValues;

export const getProviderLocalStorageItem = <K extends ProviderLocalStorageKeys>(key: K): ProviderLocalStorageValues[K] | null => {
  return getLocalStorageItem(key);
};

export const setProviderLocalStorageItem = <K extends ProviderLocalStorageKeys>(key: K, value: ProviderLocalStorageValues[K]) => {
  setLocalStorageItem(key, value);
};

export const removeProviderLocalStorageItem = (key: ProviderLocalStorageKeys) => {
  removeLocalStorageItem(key);
};
