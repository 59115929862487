import { useOnAppointmentStatusUpdate } from "@health/sse";
import { useTranslation } from "@toolkit/i18n";
import {
  ActiveFilters,
  CustomToggleButton,
  FormControlLabel,
  PageWrapper,
  StaticFilterDialog,
  TableGrid,
  TableSettingComponent,
  useGridContext,
  useTheme,
} from "@toolkit/ui";
import { FC } from "react";

export const VisitsHistoryListPageWrapper: FC<{ onMeOnlyClick; isMeOnly: boolean; isVendorAdmin: boolean }> = ({
  onMeOnlyClick,
  isVendorAdmin,
  isMeOnly,
}) => {
  const theme = useTheme();
  const { t } = useTranslation("provider");
  const { customTableProps, activeFiltersProps, staticFiltersProps, fields } = useGridContext();

  useOnAppointmentStatusUpdate(({ data }) => {
    if (data.graphqlType === "Visit" && data?.id) {
      customTableProps?.onUpdateGrid && customTableProps?.onUpdateGrid();
    }
  });

  return (
    <PageWrapper
      filters={<ActiveFilters {...(activeFiltersProps as any)} />}
      actions={
        <>
          {isVendorAdmin && (
            <FormControlLabel
              label={t("Me Only")}
              sx={{ fontSize: theme.mixins.fonts.fontSize.xs, minWidth: 120, margin: 1 }}
              control={<CustomToggleButton value={isMeOnly} defaultChecked={isMeOnly} onChange={onMeOnlyClick} />}
            />
          )}
          <TableSettingComponent />
          <StaticFilterDialog
            {...{
              ...staticFiltersProps,
              isOpen: staticFiltersProps?.isOpen!,
              onApplyFilters: staticFiltersProps?.onApplyFilters!,
              onToggleDialog: staticFiltersProps?.onToggleDialog!,
              filterFields: fields!,
              filters: activeFiltersProps?.filters!,
            }}
          />
        </>
      }
    >
      <TableGrid />
    </PageWrapper>
  );
};
