import { useOpenState } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Box, MuiDrawer, ShowButton, Typography } from "@toolkit/ui";
import { FC } from "react";
import { usePriorAuthorizationDrawerStyles } from "./PriorAuthorizationDrawerStyle";
import { PriorAuthorizationDrawerSummary } from "../PriorAuthorizationDrawerSummary/PriorAuthorizationDrawerSummary";
import { PriorAuthorizationDrawerDetails } from "../PriorAuthorizationDrawerDetails/PriorAuthorizationDrawerDetails";
import { PriorAuthorizationActivitiesList } from "@/pages/OptimaPriorRequests/containers/PriorAuthorizationActivitiesList/PriorAuthorizationActivitiesList";
import { OptimaPriorRequestNodeFragmentFragment } from "@/shared/gql/fragments";

type PriorAuthorizationDrawerProps = {
  request: OptimaPriorRequestNodeFragmentFragment;
};

export const PriorAuthorizationDrawer: FC<PriorAuthorizationDrawerProps> = props => {
  const { request } = props;
  const { priorAuthorization } = request;
  const { classes } = usePriorAuthorizationDrawerStyles();

  const { t } = useTranslation("domains");
  const { handleOpen, handleToggle, open } = useOpenState();
  return priorAuthorization?.id ? (
    <>
      <ShowButton onClick={handleOpen} />
      <MuiDrawer
        anchor='right'
        open={open}
        onClose={handleToggle}
        PaperProps={{
          className: classes.drawer,
        }}
      >
        <Box padding={2}>
          <Typography className={classes.sectionTitle}>{t("Authorization Summary")}</Typography>
          <Box className={classes.sectionContainer}>
            <PriorAuthorizationDrawerSummary request={request} />
          </Box>
          <Typography className={classes.sectionTitle}>{t("Authorization Details")}</Typography>
          <Box className={classes.sectionContainer}>
            <PriorAuthorizationDrawerDetails request={request} />
          </Box>
          <PriorAuthorizationActivitiesList id={priorAuthorization.id} />
          <Typography className={classes.sectionTitle} mt={3}>
            {t("Comments")}
          </Typography>
          <Typography className={classes.comments}>{priorAuthorization?.comments || "-"}</Typography>
        </Box>
      </MuiDrawer>
    </>
  ) : (
    <Typography className={classes.pending}>{t("Pending")}</Typography>
  );
};
