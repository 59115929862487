import { TransactionPriorRequestFilterInput } from "@/schema/types";
import { useMemo, useState } from "react";
import { useOptimaPriorRequestKpIsTotalCountsQuery } from "../../gql";
import { IOptimaRequestKpi, optimaRequestKpi, requestsKpiMode } from "../../utils";

type UseOptimaPriorRequestListKpiControllerProps = {
  externalFilters?: TransactionPriorRequestFilterInput;
};

export const useOptimaPriorRequestListKpiController = ({ externalFilters }: UseOptimaPriorRequestListKpiControllerProps) => {
  const [selectedKpiMode, setSelectedKpiMode] = useState<IOptimaRequestKpi>(optimaRequestKpi.all);

  const [activeInnerFilters, setActiveInnerFilters] = useState<TransactionPriorRequestFilterInput>();

  const activeFiltersWithExternal = useMemo(() => {
    return {
      ...activeInnerFilters,
      ...(externalFilters || {}),
      ["branches"]: activeInnerFilters?.branches || externalFilters?.branches || [],
    };
  }, [externalFilters, activeInnerFilters]);

  const { data, loading } = useOptimaPriorRequestKpIsTotalCountsQuery({
    variables: {
      filter: activeFiltersWithExternal,
    },
  });

  const hasFilter = selectedKpiMode?.type === "all";

  const onActiveFiltersChange = (filtersValue: TransactionPriorRequestFilterInput | undefined) => {
    setActiveInnerFilters(filtersValue);
  };

  const totalCounts = {
    all: data?.optimaPriorRequests?.totalCount || 0,
    rejected: data?.optimaPriorRequestsRejectedAuth?.totalCount || 0,
    withoutClaim: data?.optimaPriorRequestsWithoutClaim?.totalCount || 0,
  };

  const handleKpiModeChange = (mode: requestsKpiMode) => {
    setSelectedKpiMode(optimaRequestKpi[mode]);
  };
  return {
    selectedKpiMode,
    handleKpiModeChange,
    totalCounts,
    isCountsLoading: loading,
    hasFilter,
    activeFilters: activeFiltersWithExternal,
    onActiveFiltersChange,
  };
};
