import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import { useSetOptimaTransactionValidateRequestBreadcrumbs } from "../../hooks";
import {
  TransactionValidateRequestForm,
  TransactionValidateRequestFormRef,
} from "../../forms/TransactionValidateRequest/TransactionValidateRequestForm";
import { ITransactionValidateRequestFormValues } from "../../forms/TransactionValidateRequest/TransactionValidateRequestFormSchema";
import { convertFormValuesToBackendValues } from "../../others/utils";
import { useRef, useState } from "react";
import { useAuth } from "react-oidc-context";
import { get } from "lodash";
import { getEnvVariable } from "@/env";
import { ValidationResultModal, ValidationResultModalApi } from "../../modals/ValidationResultModal/ValidationResultModal";

const API_URL = getEnvVariable("SERVER_URL") + "/optima/api/validate";

export const OptimaTransactionValidateRequestContainer = () => {
  const { t } = useTranslation("provider");
  const { succeeded, failed } = useAddToast();
  const [loading, setLoading] = useState(false);
  const formRef = useRef<TransactionValidateRequestFormRef>(null);

  const { user } = useAuth();

  const handleFormSubmit = async (values: ITransactionValidateRequestFormValues) => {
    setLoading(true);
    try {
      const response = await fetch(API_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user?.access_token}`,
        },
        body: JSON.stringify(convertFormValuesToBackendValues(values)),
      });

      const errorData = !response.ok ? await response.json().catch(() => ({})) : null;

      if (!response.ok) {
        const errorMessages = get(errorData, "errors", [])
          .map((error: { message: string }) => error.message)
          .join("\n");

        throw new Error(errorMessages || `Error: ${response.status} ${response.statusText}`);
      }

      const data = await response.json();
      succeeded(t("Transaction validation successful"));
      ValidationResultModalApi.open(JSON.stringify(data, null, 2));

      return data;
    } catch (error) {
      failed(get(error, "message") || t("An unknown error occurred."));
    } finally {
      setLoading(false);
      if (formRef.current) {
        formRef.current.randomizeRequestId();
      }
    }
  };

  useSetOptimaTransactionValidateRequestBreadcrumbs("VALIDATE");

  return (
    <>
      <TransactionValidateRequestForm
        ref={formRef}
        isSubmitting={loading}
        onChange={event => {
          if (event.type === "SUBMIT") {
            handleFormSubmit(event.payload.values);
          }
        }}
      />
      <ValidationResultModal />
    </>
  );
};
