import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OptimaPayerCredentialUpdateMutationVariables = Types.Exact<{
  payerCredentialsUpdateId: Types.Scalars['ID']['input'];
  input: Types.PayerCredentialsInput;
}>;


export type OptimaPayerCredentialUpdateMutation = { __typename?: 'Mutation', payerCredentialsUpdate?: { __typename?: 'PayerCredentials', id: string } | null };


export const OptimaPayerCredentialUpdateDocument = gql`
    mutation OptimaPayerCredentialUpdate($payerCredentialsUpdateId: ID!, $input: PayerCredentialsInput!) {
  payerCredentialsUpdate(id: $payerCredentialsUpdateId, input: $input) {
    id
  }
}
    `;
export type OptimaPayerCredentialUpdateMutationFn = Apollo.MutationFunction<OptimaPayerCredentialUpdateMutation, OptimaPayerCredentialUpdateMutationVariables>;

/**
 * __useOptimaPayerCredentialUpdateMutation__
 *
 * To run a mutation, you first call `useOptimaPayerCredentialUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOptimaPayerCredentialUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [optimaPayerCredentialUpdateMutation, { data, loading, error }] = useOptimaPayerCredentialUpdateMutation({
 *   variables: {
 *      payerCredentialsUpdateId: // value for 'payerCredentialsUpdateId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOptimaPayerCredentialUpdateMutation(baseOptions?: Apollo.MutationHookOptions<OptimaPayerCredentialUpdateMutation, OptimaPayerCredentialUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OptimaPayerCredentialUpdateMutation, OptimaPayerCredentialUpdateMutationVariables>(OptimaPayerCredentialUpdateDocument, options);
      }
export type OptimaPayerCredentialUpdateMutationHookResult = ReturnType<typeof useOptimaPayerCredentialUpdateMutation>;
export type OptimaPayerCredentialUpdateMutationResult = Apollo.MutationResult<OptimaPayerCredentialUpdateMutation>;
export type OptimaPayerCredentialUpdateMutationOptions = Apollo.BaseMutationOptions<OptimaPayerCredentialUpdateMutation, OptimaPayerCredentialUpdateMutationVariables>;