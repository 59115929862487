import React, { FC, useEffect, useRef, useState } from "react";
import { useTranslation } from "@toolkit/i18n";
import { Button, CircularProgress, CustomDialog } from "@toolkit/ui";
import {
  UserWorkspaceNurseUpsertForm,
  UserWorkspaceNurseUpsertFormRef,
  IUserWorkspaceNurseUpsertFormEvent,
} from "../../forms/UserWorkspaceNurseUpsert/UserWorkspaceNurseUpsertForm";
import { useOpenState } from "@toolkit/core";
import { IUserWorkspaceNurseVar } from "../../types";

type IUserWorkspaceNurseUpsertModalData = {
  userWorkspaceNurse?: IUserWorkspaceNurseVar | null;
};

type IOpen = (data: IUserWorkspaceNurseUpsertModalData) => void;
type IClose = () => void;

export const UserWorkspaceNurseUpsertModalApi: { open: IOpen; close: IClose } = {
  open: () => {},
  close: () => {},
};

export const UserWorkspaceNurseUpsertModal: FC<{
  isLoading: boolean;
  hasClose: boolean;
  onChange: (values: IUserWorkspaceNurseUpsertFormEvent) => void;
}> = props => {
  const { isLoading, hasClose, onChange } = props;

  const { t } = useTranslation("provider");

  const { open: isOpen, handleClose, handleOpen } = useOpenState();
  const [data, setData] = useState<IUserWorkspaceNurseUpsertModalData | null>(null);

  const userWorkspaceNurseUpsertFormRef = useRef<UserWorkspaceNurseUpsertFormRef>(null);

  const open: IOpen = _data => {
    setData(_data);
    handleOpen();
  };

  const close: IClose = () => {
    handleClose();
  };

  const onAssignClick = () => {
    userWorkspaceNurseUpsertFormRef.current?.submit();
  };

  useEffect(() => {
    UserWorkspaceNurseUpsertModalApi.open = open;
    UserWorkspaceNurseUpsertModalApi.close = close;
  }, []);

  return (
    <CustomDialog
      type={"base"}
      maxWidth={"sm"}
      open={isOpen}
      onClose={hasClose ? close : undefined}
      DialogTitleProps={{
        title: t("Complete Your Current Working Data"),
        hasCloseButton: hasClose,
      }}
      DialogActionsProps={{
        children: (
          <Button
            disabled={isLoading}
            endIcon={isLoading ? <CircularProgress color={"inherit"} size={20} /> : null}
            onClick={onAssignClick}
          >
            {t("Assign")}
          </Button>
        ),
      }}
    >
      <UserWorkspaceNurseUpsertForm
        onChange={onChange}
        nurseCurrentWorkspace={data?.userWorkspaceNurse}
        ref={userWorkspaceNurseUpsertFormRef}
      />
    </CustomDialog>
  );
};
