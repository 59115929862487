import React from "react";
import { Box, Typography, SxProps, Theme, ImagesViewer } from "@toolkit/ui";
import { useTranslation } from "@toolkit/i18n";
import { MuiErrorIcon } from "@toolkit/ui";

type ScanHistoryCardFailureViewProps = {
  title: string;
  errorMessage?: string;
  screenshotFileName?: string | null;
  sx?: SxProps<Theme>;
  showImage?: boolean;
};

export const ScanHistoryCardFailureView: React.FC<ScanHistoryCardFailureViewProps> = ({
  title,
  errorMessage,
  screenshotFileName,
  sx,
  showImage = true,
}) => {
  const { t } = useTranslation("optima");

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 1,
        p: 1.5,
        borderRadius: 1,
        backgroundColor: "error.lighten",
        ...sx,
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <MuiErrorIcon color='error' fontSize='small' />
        <Typography variant='body2' color='error'>
          {t("Failed")}: {title}
        </Typography>
      </Box>

      {errorMessage && (
        <Typography variant='caption' color='text.secondary'>
          {t("Reason")}: {errorMessage}
        </Typography>
      )}

      {showImage && screenshotFileName && (
        <Box sx={{ mt: 1 }}>
          <Box sx={{ mt: 0.5 }}>
            <ImagesViewer images={screenshotFileName ? [screenshotFileName] : []} canDeleteImage={false} />
          </Box>
        </Box>
      )}
    </Box>
  );
};
