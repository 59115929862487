import { Box, Typography } from "@toolkit/ui";
import { useTranslation } from "@toolkit/i18n";
import { CheckMarkIcon } from "@toolkit/ui";
import { FC } from "react";

type ScanHistoryCardSuccessViewProps = {
  insuranceName: string;
  isFounded?: boolean;
};

export const ScanHistoryCardSuccessView: FC<ScanHistoryCardSuccessViewProps> = ({ insuranceName, isFounded }) => {
  const { t } = useTranslation("optima");

  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
      <CheckMarkIcon color={isFounded ? "success" : "warning"} />
      <Typography>{insuranceName}</Typography>
      <Typography color={isFounded ? "success.main" : "warning.main"}>{isFounded ? t("Found") : t("Not Found")}</Typography>
    </Box>
  );
};
