import { OptimaValidationRequest } from "@/schema/types";
import { Box, Divider, Typography, useTheme } from "@toolkit/ui";
import { FC } from "react";
import { OptimaEditCard } from "../OptimaEditCard/OptimaEditCard";
import { isEmpty, noop } from "lodash";
import { useTranslation } from "@toolkit/i18n";

interface OptimaTransactionEditsListProps {
  display?: string;
  edits?: OptimaValidationRequest["edits"];
}

export const OptimaTransactionEditsList: FC<OptimaTransactionEditsListProps> = ({ display = "", edits }) => {
  const { t } = useTranslation("provider");
  const theme = useTheme();

  if (isEmpty(edits)) {
    return null;
  }

  return (
    <Box sx={{ marginBottom: 2 }}>
      <Typography fontWeight={theme.mixins.fonts.fontWeight.medium} textAlign='start' sx={{ marginBottom: 1.5 }}>
        {t("Transaction Level Edits")}
      </Typography>
      <Typography textAlign='start' sx={{ marginBottom: 2 }}>
        {t("As per medical coding and insurance guidelines, the following has been flagged")}:
      </Typography>
      <Box sx={{ display: "flex", flexDirection: "column", rowGap: 2 }}>
        {edits?.map(edit => (
          <OptimaEditCard
            key={edit?.id}
            display={display}
            edit={edit?.edit}
            isLikeDislikeEnabled={false}
            onDislikeClick={noop}
            onLikeClick={noop}
          />
        ))}
      </Box>
      <Divider sx={{ marginBlock: 2 }} />
    </Box>
  );
};
