import { useTranslation } from "@toolkit/i18n";
import { CustomDialog, Button, CircularProgress } from "@toolkit/ui";
import { FC, useEffect, useRef, useState } from "react";
import { useOpenState } from "@toolkit/core";
import {
  ITransactionValidateRequestDiagnosisFormValues,
  ITransactionValidateRequestDiagnosisUpsertFormEvent,
} from "../../forms/TransactionValidateRequestDiagnosisUpsert/TransactionValidateRequestDiagnosisFormSchema";
import {
  TransactionValidateRequestDiagnosisUpsertForm,
  TransactionValidateRequestDiagnosisUpsertFormRef,
} from "../../forms/TransactionValidateRequestDiagnosisUpsert/TransactionValidateRequestDiagnosisUpsertFrom";
import { ModalApi } from "../../others/types";

export const TransactionValidateRequestDiagnosisUpsertModalApi: ModalApi<
  ITransactionValidateRequestDiagnosisFormValues & { index: string }
> = {
  open: () => {},
  close: () => {},
};

type TransactionValidateRequestDiagnosisUpsertModalProps = {
  isLoading?: boolean;
  hasClose?: boolean;
  onChange: (event: ITransactionValidateRequestDiagnosisUpsertFormEvent) => void;
};

export const TransactionValidateRequestDiagnosisUpsertModal: FC<TransactionValidateRequestDiagnosisUpsertModalProps> = ({
  isLoading = false,
  hasClose = true,
  onChange,
}) => {
  const { t } = useTranslation("provider");
  const { open: isOpen, handleClose, handleOpen } = useOpenState();

  const [diagnosis, setDiagnosis] = useState<(ITransactionValidateRequestDiagnosisFormValues & { index: string }) | null>(null);

  const formRef = useRef<TransactionValidateRequestDiagnosisUpsertFormRef>(null);

  const handleSubmit = () => formRef.current?.submit();

  const onOpen = (data?: ITransactionValidateRequestDiagnosisFormValues & { index: string }) => {
    setDiagnosis(data || null);
    handleOpen();
  };

  useEffect(() => {
    TransactionValidateRequestDiagnosisUpsertModalApi.open = onOpen;
    TransactionValidateRequestDiagnosisUpsertModalApi.close = handleClose;
  }, []);

  return (
    <CustomDialog
      type='base'
      maxWidth='md'
      open={isOpen}
      onClose={hasClose ? handleClose : undefined}
      DialogTitleProps={{
        title: diagnosis ? t("Update Diagnosis") : t("Add Diagnosis"),
        hasCloseButton: hasClose,
      }}
      DialogActionsProps={{
        children: (
          <Button disabled={isLoading} endIcon={isLoading ? <CircularProgress color='inherit' size={20} /> : null} onClick={handleSubmit}>
            {diagnosis ? t("Update") : t("Add")}
          </Button>
        ),
      }}
    >
      <TransactionValidateRequestDiagnosisUpsertForm
        index={diagnosis?.index}
        diagnosis={diagnosis || undefined}
        ref={formRef}
        onChange={onChange}
      />
    </CustomDialog>
  );
};
