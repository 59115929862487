/* eslint-disable react/prop-types */
import { formatGraphQLError } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast, useBreadCrumbs } from "@toolkit/ui";
import { forwardRef, useEffect, useImperativeHandle, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { discountsBreadcrumb, discountsPaths } from "../../constants";
import { DiscountUpsertForm, DiscountUpsertFormRef, IDiscountUpsertFormValues } from "../../forms";
import { MarketplaceDiscountCreateMutation, useMarketplaceDiscountCreateMutation } from "../../gql";
import { convertDiscountFormValuesToBackEndValues } from "../../utils";

type DiscountCreateContainerProps = {
  isDiscountItemsDisplayed?: boolean;
  onSubmittingValueChange?: (isSubmitting: boolean) => void;
  discountItems?: Pick<IDiscountUpsertFormValues, "product" | "healthPackage">;
};
export type DiscountCreateContainerFormRef = {
  submit: () => void;
};

const DiscountCreateContainerRef: React.ForwardRefRenderFunction<DiscountCreateContainerFormRef, DiscountCreateContainerProps> = (
  props,
  ref
) => {
  const { isDiscountItemsDisplayed = true, onSubmittingValueChange, discountItems } = props;
  const { t } = useTranslation("provider");
  const navigate = useNavigate();
  const { setBreadCrumb } = useBreadCrumbs();
  const { succeeded, failed } = useAddToast();
  const discountUpsertFormRef = useRef<DiscountUpsertFormRef>(null);
  const [fetchDiscountCreateMutation, { loading: isSubmitting }] = useMarketplaceDiscountCreateMutation({
    onCompleted: (data: MarketplaceDiscountCreateMutation) => {
      if (data?.marketplaceDiscountCreate?.id) {
        succeeded(t("Discount created successfully!"));
        navigate(discountsPaths.main.fullPath);
      } else {
        failed(t("Discount creation failed!"));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(t(formatGraphQLError(graphQLErrors)));
    },
  });
  useImperativeHandle(ref, () => ({
    submit: () => discountUpsertFormRef?.current?.submit(),
  }));

  const onDiscountUpsertFormChange = (values: IDiscountUpsertFormValues) => {
    const _values = convertDiscountFormValuesToBackEndValues(values);

    fetchDiscountCreateMutation({
      variables: {
        input: _values,
      },
    });
  };
  useEffect(() => {
    onSubmittingValueChange?.(isSubmitting);
  }, [isSubmitting]);

  useEffect(() => {
    const { title, main, create } = discountsBreadcrumb();
    isDiscountItemsDisplayed && setBreadCrumb({ title, values: [main, create] });
  }, [setBreadCrumb, t]);
  return (
    <>
      <DiscountUpsertForm
        ref={discountUpsertFormRef}
        isSubmitting={isSubmitting}
        submitButtonLabel={t("Create")}
        onChange={onDiscountUpsertFormChange}
        discountItems={discountItems}
      />
    </>
  );
};

export const DiscountCreateContainer = forwardRef(DiscountCreateContainerRef);
