import { useOidcUserProfile } from "@/shared/hooks/useOidcUserProfile";
import { isVendorAdmin } from "@/utils";
import { OptimaPriorRequestListContainer } from "@health/domains";
import { useTranslation } from "@toolkit/i18n";
import { useBreadCrumbs } from "@toolkit/ui";
import { useEffect, useMemo } from "react";
import { OptimaPriorRequestsRoutesBreadcrumb } from "../../constants/optima-prior-requests-routes-breadcrumbs";
import { userWorkspaceDoctorVar } from "@/pages/UserWorkspace/vars";
import { useReactiveVar } from "@apollo/client";

export const ProviderOptimaPriorRequestsListContainer = () => {
  const { setBreadCrumb } = useBreadCrumbs();
  const { userInfo } = useOidcUserProfile();
  const userWorkspaceDoctor = useReactiveVar(userWorkspaceDoctorVar);
  const { t } = useTranslation("provider");
  useEffect(() => {
    setBreadCrumb({
      title: OptimaPriorRequestsRoutesBreadcrumb(t),
    });
  }, []);
  const filters = useMemo(() => {
    return userWorkspaceDoctor?.branch?.id ? { branches: [userWorkspaceDoctor?.branch?.id!] } : {};
  }, [userWorkspaceDoctor]);
  const isProviderAdmin = isVendorAdmin(userInfo);
  return <OptimaPriorRequestListContainer isProviderAdmin={isProviderAdmin} filters={filters} />;
};
