import { ManualOrderStatus } from "@/schema/types";
import { useOpenState } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Button, CheckMarkIcon, CustomDialog, IconButton, Tooltip, useAddToast } from "@toolkit/ui";
import { useApproveManualOrderMutation } from "../../gql/mutations";
import { INoteFormValues, NoteForm, NoteFormRef } from "../../forms";
import { useRef } from "react";
import { ManualSubmittedOrderListDocument } from "../../gql";

type OrderApprovalModalProps = {
  orderId: string;
  orderStatus: ManualOrderStatus;
};

export const OrderApprovalModal: React.FC<OrderApprovalModalProps> = props => {
  const { orderId, orderStatus } = props;
  const { t } = useTranslation();
  const { handleToggle, open } = useOpenState();
  const { failed, succeeded } = useAddToast();
  const noteFormRef = useRef<NoteFormRef>(null);

  const [orderApproveMutation, { loading }] = useApproveManualOrderMutation({
    onCompleted: data => {
      if (data?.approveManualOrder?.id) {
        handleToggle();
        succeeded(t("Order approved successfully!"));
      } else {
        failed(t("Failed to approve order!"));
      }
    },
    onError: () => {
      failed(t("Failed to approve order!"));
    },
    refetchQueries: [ManualSubmittedOrderListDocument],
  });

  const isApproveDisabled = loading || ManualOrderStatus.WaitingProviderApproval !== orderStatus;

  const handleApproveOrder = async () => {
    noteFormRef.current?.submit();
  };

  const handleApproveOrderWithNote = (values: INoteFormValues) => {
    orderApproveMutation({
      variables: {
        orderId: orderId,
        note: values?.note,
      },
    });
  };

  return (
    <CustomDialog
      type='base'
      title={t("Approve Order")}
      open={open}
      DialogTitleProps={{
        title: t("Approve Order"),
        onClose: handleToggle,
      }}
      button={
        <Tooltip title={t("Approve the order")}>
          <IconButton disabled={isApproveDisabled} onClick={handleToggle}>
            <CheckMarkIcon color='success' />
          </IconButton>
        </Tooltip>
      }
      DialogActionsProps={{
        children: (
          <Button onClick={handleApproveOrder} disabled={loading} color='success'>
            {t("Approve")}
          </Button>
        ),
      }}
    >
      <NoteForm ref={noteFormRef} label='Approval Note' onChange={handleApproveOrderWithNote} />
    </CustomDialog>
  );
};
