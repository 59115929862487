import { PatientHealthParameterFilterInput } from "@/schema/types";
import { CustomTable, Grid, WaveSurferAudio } from "@toolkit/ui";
import React, { FC } from "react";
import { usePatientHealthParametersHistory } from "../../hooks";
import { usePatientHealthParameterHistoryAudioColumns } from "./usePatientHealthParameterHistoryAudioColumns";

type PatientHealthParameterHistoryAudioProps = {
  token?: string;
  filter: PatientHealthParameterFilterInput;
};

export const PatientHealthParameterHistoryAudio: FC<PatientHealthParameterHistoryAudioProps> = props => {
  const { token, filter } = props;

  const { selectedItem, tableProps, handleSelectItem } = usePatientHealthParametersHistory({
    token,
    filter,
  });

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {selectedItem?.valueAudioFile && <WaveSurferAudio url={selectedItem?.valueAudioFile} isAutoplayEnabled />}
      </Grid>

      <Grid item xs={12}>
        <CustomTable {...tableProps} columns={usePatientHealthParameterHistoryAudioColumns({ selectedItem, onChange: handleSelectItem })} />
      </Grid>
    </Grid>
  );
};
