import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HealthParameterListQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  filter?: Types.InputMaybe<Types.HealthParameterFilterInput>;
}>;


export type HealthParameterListQuery = { __typename?: 'Query', getHealthParameters?: { __typename?: 'HealthParameterConnection', edges?: Array<{ __typename?: 'HealthParameterEdge', node?: { __typename?: 'HealthParameter', id?: string | null, code?: string | null, type?: Types.TemplateFieldType | null, chartType?: Types.ChartType | null, display?: string | null, arabicDisplay?: string | null, unit?: { __typename?: 'FieldUnit', id?: string | null, display?: string | null, arabicDisplay?: string | null } | null } | null } | null> | null } | null };


export const HealthParameterListDocument = gql`
    query HealthParameterList($first: Int, $filter: HealthParameterFilterInput) {
  getHealthParameters(first: $first, filter: $filter) {
    edges {
      node {
        id
        code
        type
        chartType
        display
        arabicDisplay
        unit {
          id
          display
          arabicDisplay
        }
      }
    }
  }
}
    `;

/**
 * __useHealthParameterListQuery__
 *
 * To run a query within a React component, call `useHealthParameterListQuery` and pass it any options that fit your needs.
 * When your component renders, `useHealthParameterListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHealthParameterListQuery({
 *   variables: {
 *      first: // value for 'first'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useHealthParameterListQuery(baseOptions?: Apollo.QueryHookOptions<HealthParameterListQuery, HealthParameterListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HealthParameterListQuery, HealthParameterListQueryVariables>(HealthParameterListDocument, options);
      }
export function useHealthParameterListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HealthParameterListQuery, HealthParameterListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HealthParameterListQuery, HealthParameterListQueryVariables>(HealthParameterListDocument, options);
        }
export function useHealthParameterListSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<HealthParameterListQuery, HealthParameterListQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<HealthParameterListQuery, HealthParameterListQueryVariables>(HealthParameterListDocument, options);
        }
export type HealthParameterListQueryHookResult = ReturnType<typeof useHealthParameterListQuery>;
export type HealthParameterListLazyQueryHookResult = ReturnType<typeof useHealthParameterListLazyQuery>;
export type HealthParameterListSuspenseQueryHookResult = ReturnType<typeof useHealthParameterListSuspenseQuery>;
export type HealthParameterListQueryResult = Apollo.QueryResult<HealthParameterListQuery, HealthParameterListQueryVariables>;