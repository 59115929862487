import { useCustomForm } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FormChipsInput, FormDateTimePickerField, FormNumberField, FormTextField, Grid, Typography, useTheme } from "@toolkit/ui";
import { ForwardRefRenderFunction, forwardRef, useEffect, useImperativeHandle } from "react";
import { FormProvider, UseFormReturn } from "react-hook-form";
import {
  ITransactionValidateRequestItemFormValues,
  ITransactionValidateRequestItemUpsertFormEvent,
  transactionValidateRequestItemFormDefaultValues,
  transactionValidateRequestItemFormSchema,
} from "./TransactionValidateRequestItemFormSchema";

type TransactionValidateRequestItemUpsertFormProps = {
  item?: ITransactionValidateRequestItemFormValues;
  index?: string;
  onChange: (event: ITransactionValidateRequestItemUpsertFormEvent) => void;
};

export type TransactionValidateRequestItemUpsertFormRef = {
  getForm: () => UseFormReturn<ITransactionValidateRequestItemFormValues>;
  submit: () => void;
};

const TransactionValidateRequestItemUpsertFormForwardRef: ForwardRefRenderFunction<
  TransactionValidateRequestItemUpsertFormRef,
  TransactionValidateRequestItemUpsertFormProps
> = (props, ref) => {
  const { item, index, onChange } = props;
  const theme = useTheme();

  const { t } = useTranslation("provider");

  const form = useCustomForm<ITransactionValidateRequestItemFormValues>({
    defaultValues: transactionValidateRequestItemFormDefaultValues,
    schema: transactionValidateRequestItemFormSchema,
  });

  const { setValues, handleSubmit } = form;

  const onSubmit = (values: ITransactionValidateRequestItemFormValues) => {
    if (item) {
      if (index) {
        onChange({
          type: "UPDATE",
          payload: { values: { ...values, index } },
        });
      }
    } else {
      onChange({
        type: "CREATE",
        payload: { values },
      });
    }
  };

  useImperativeHandle(ref, () => ({
    getForm: () => form,
    submit: handleSubmit(onSubmit),
  }));

  useEffect(() => {
    if (item) {
      setValues(item);
    }
  }, [item, setValues]);

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Typography my={2} fontSize={theme.mixins.fonts.fontSize.lg} fontWeight={theme.mixins.fonts.fontWeight.medium}>
          {t("General Information")}
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FormTextField name='sequence' label={t("Sequence")} placeholder={t("Sequence")} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTextField name='code' label={t("Code")} placeholder={t("Code")} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTextField name='display' label={t("Display")} placeholder={t("Display")} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTextField name='codeSystem' label={t("Code System")} placeholder={t("Code System")} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTextField name='type' label={t("Type")} placeholder={t("Type")} />
          </Grid>
        </Grid>

        <Typography my={2} fontSize={theme.mixins.fonts.fontSize.lg} fontWeight={theme.mixins.fonts.fontWeight.medium}>
          {t("Service Details")}
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FormDateTimePickerField name='serviceStart' label={t("Service Start")} placeholder={t("Service Start")} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormDateTimePickerField name='serviceEnd' label={t("Service End")} placeholder={t("Service End")} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormNumberField name='quantity' label={t("Quantity")} placeholder={t("Quantity")} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTextField name='authorizationId' label={t("Authorization ID")} placeholder={t("Authorization ID")} />
          </Grid>
        </Grid>

        <Typography my={2} fontSize={theme.mixins.fonts.fontSize.lg} fontWeight={theme.mixins.fonts.fontWeight.medium}>
          {t("Financial Information")}
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FormNumberField name='unitPrice' label={t("Unit Price")} placeholder={t("Unit Price")} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormNumberField name='patientShare' label={t("Patient Share")} placeholder={t("Patient Share")} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormNumberField name='net' label={t("Net Amount")} placeholder={t("Net Amount")} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormNumberField name='tax' label={t("Tax")} placeholder={t("Tax")} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTextField name='currency' label={t("Currency")} placeholder={t("Currency")} />
          </Grid>
        </Grid>

        <Typography my={2} fontSize={theme.mixins.fonts.fontSize.lg} fontWeight={theme.mixins.fonts.fontWeight.medium}>
          {t("Linked Entities")}
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FormTextField name='linkedCareTeam' label={t("Linked Care Team")} placeholder={t("Linked Care Team")} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormChipsInput name='linkedDiagnoses' placeholder={t("Linked Diagnoses")} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormChipsInput name='linkedObservations' placeholder={t("Linked Observations")} />
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export const TransactionValidateRequestItemUpsertForm = forwardRef(TransactionValidateRequestItemUpsertFormForwardRef);
