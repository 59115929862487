import { MarketplaceApprovalStatus, MarketplaceProductSortingField, OrderDirection } from "@/schema/types";
import { useTranslation } from "@toolkit/i18n";
import { FilterGrid, MainListActions, PageWrapper, TableGrid, TableSettingComponent, useBreadCrumbs } from "@toolkit/ui";
import { useCurrentUserQuery } from "gql/queries";
import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { GridProvider } from "shared/components";
import { useFetchHealthPackagesQuery } from "../gql";
import { healthPackagesManagementPaths } from "../route";
import { getHealthPackagesColumns } from "./components/healthPackagesColumns";

export const HealthPackagesList: FC = () => {
  const navigate = useNavigate();
  const { setBreadCrumb } = useBreadCrumbs();
  const { data } = useCurrentUserQuery();

  const { t } = useTranslation("provider");

  const handleAddPackage = () => {
    navigate(healthPackagesManagementPaths.addNew.fullPath);
  };

  const handleEdit = row => {
    const route = healthPackagesManagementPaths.edit.fullPath.replace(":id", String(row.id));
    navigate(route);
  };

  useEffect(() => {
    setBreadCrumb({ title: t("Health Packages Management"), values: [] });
  }, []);
  const variables = { imagesFirst2: 10, sortBy: { direction: OrderDirection.Desc, field: MarketplaceProductSortingField.Created } };

  return (
    <GridProvider
      hasTableSetting
      gridName='healthPackages'
      columns={getHealthPackagesColumns(t)}
      query={useFetchHealthPackagesQuery}
      hideFilterInput
      skipUrlState={false}
      tableAction={{
        isEditVisible: true,
        onEditRow: handleEdit,
        isRowEditable: row =>
          !!row?.updatable &&
          (row?.approvalStatus === MarketplaceApprovalStatus.Draft || row?.approvalStatus === MarketplaceApprovalStatus.Rejected),
      }}
      filterInput={{
        vendorId: data?.me?.vendor?.id,
      }}
      variables={variables as any}
    >
      <PageWrapper
        filters={<FilterGrid />}
        actions={
          <>
            <TableSettingComponent />
            <MainListActions hasAddNew onAddNewItem={handleAddPackage} />
          </>
        }
      >
        <TableGrid />
      </PageWrapper>
    </GridProvider>
  );
};
