import { FC } from "react";
import { Box, Grid } from "@toolkit/ui";
import { BenefitsExtractedCard } from "../BenefitsExtractedCard/BenefitsExtractedCard";
import { AutoCoverageCard } from "../AutoCoverageCard/AutoCoverageCard";
import { ScreenshotCard } from "../ScreenshotCard/ScreenshotCard";
import { ScanHistoryCard } from "../ScanHistoryCard/ScanHistoryCard";
import { BenefitsCard } from "../BenefitsCard/BenefitsCard";
import { IOptimaBenefitEligibilityType, IOptimaPatientEligibilityRequestHistoryDataType } from "@/pages/OptimaPatientEligibilityScan/types";

interface EligibilityCardsLayoutProps {
  eligibilityData: IOptimaPatientEligibilityRequestHistoryDataType["patientEligibility"];
  benefitData: IOptimaBenefitEligibilityType;
  payerEligibilityRequestHistories?: IOptimaPatientEligibilityRequestHistoryDataType["payerEligibilityRequestHistories"]; // Type should be refined based on your actual data structure
  isScanCompleted: boolean;
  handleOpenCreateModal: () => void;
}

export const EligibilityCardsLayout: FC<EligibilityCardsLayoutProps> = ({
  eligibilityData,
  benefitData,
  payerEligibilityRequestHistories,
  isScanCompleted,
  handleOpenCreateModal,
}) => {
  const snapshotList = eligibilityData?.snapshots?.map(item => item!);
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <BenefitsExtractedCard eligibilityData={eligibilityData} />
        </Grid>
        <Grid item xs={12} md={4}>
          <Grid flexDirection={"column"} display={"flex"} spacing={2} gap={2} justifyContent={"stretch"}>
            <Box sx={{ height: "40%" }}>
              <AutoCoverageCard benefitData={benefitData} />
            </Box>
            <Box sx={{}}>
              <ScreenshotCard snapshotList={snapshotList} eligibilityData={eligibilityData} />
            </Box>
            <Box sx={{}}>
              {payerEligibilityRequestHistories && (
                <ScanHistoryCard
                  payerEligibilityRequestHistories={payerEligibilityRequestHistories}
                  isScanCompleted={isScanCompleted}
                  handleOpenCreateModal={handleOpenCreateModal}
                />
              )}
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <BenefitsCard eligibilityData={eligibilityData} />
    </>
  );
};
