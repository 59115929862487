import { Theme } from "@toolkit/ui";
import { i18n } from "@toolkit/i18n";
import moment from "moment/moment";

export const getDigitalTwinPatientHealthParameterChartColor = (value: number | undefined, theme: Theme) => {
  if (value === 1) {
    return theme.palette.success.main;
  } else if (value === 2) {
    return theme.palette.warning.main;
  } else if (value === 3) {
    return theme.palette.error.main;
  }

  return theme.palette.primary.main;
};

export const getDigitalTwinPatientHealthParameterChartLabel = (value: number) => {
  let label = `${value}`;

  if (value === 1) {
    label = i18n.t("Low Risk");
  } else if (value === 2) {
    label = i18n.t("Medium Risk");
  } else if (value === 3) {
    label = i18n.t("High Risk");
  }

  return label;
};

export const getDigitalTwinPatientHealthParameterChartXAxisLabels = (value: string) => {
  let label = "";

  if (value) {
    const year = moment(value).format("YYYY");
    const month = moment(value).format("MMM");

    label = `${year}\n${month}`;
  }

  return label;
};

export const getDigitalTwinPatientHealthParameterChartYAxisLabels = (value: number) => {
  let label = "";

  if (value === 1) {
    label = i18n.t("Low");
  } else if (value === 2) {
    label = i18n.t("Med");
  } else if (value === 3) {
    label = i18n.t("High");
  }

  return label;
};
