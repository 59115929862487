import React, { FC } from "react";
import { Grid, VideoPlayer } from "@toolkit/ui";
import { detectFileType, FileType } from "@toolkit/core";
import { PatientHealthParameterFilterInput, TemplateFieldType } from "@/schema/types";
import { PatientHealthParameterCard } from "../PatientHealthParameterCard/PatientHealthParameterCard";
import { usePatientHealthParameters } from "../../hooks";
import { PatientHealthParameterHistoryModalApi } from "../../modals";
import { PatientHealthParameterPicture } from "../PatientHealthParameterPicture/PatientHealthParameterPicture";

type PatientHealthParameterVideoProps = {
  token?: string;
  filter: PatientHealthParameterFilterInput;
};

export const PatientHealthParameterVideo: FC<PatientHealthParameterVideoProps> = props => {
  const { token, filter } = props;

  const { healthParameter, patientHealthParameters, loading } = usePatientHealthParameters({
    first: 1,
    token,
    filter,
  });

  const patientHealthParameter = patientHealthParameters?.[0];
  const patientHealthParameterFileUrl = patientHealthParameter?.valueVideoFile!;
  const patientHealthParameterFileType = detectFileType(patientHealthParameterFileUrl);

  const onPatientHealthParameterCardChange = () => {
    PatientHealthParameterHistoryModalApi.open({
      type: TemplateFieldType.VideoFile,
      healthParameter,
      filter,
    });
  };

  return (
    <PatientHealthParameterCard
      healthParameter={healthParameter}
      patientHealthParameter={patientHealthParameter}
      isLoading={loading}
      isExpandIconShown={patientHealthParameterFileUrl ? !filter?.visitId : false}
      onChange={onPatientHealthParameterCardChange}
    >
      {patientHealthParameterFileUrl && (
        <Grid item xs={4}>
          {patientHealthParameterFileType === FileType.IMAGE && (
            <PatientHealthParameterPicture src={patientHealthParameterFileUrl} alt={patientHealthParameter?.healthParameter?.display} />
          )}

          {patientHealthParameterFileType === FileType.VIDEO && <VideoPlayer src={patientHealthParameterFileUrl} />}
        </Grid>
      )}
    </PatientHealthParameterCard>
  );
};
