import { TransactionPriorRequestFilterInput, TransactionSortDirection, TransactionSortField } from "@/schema/types";
import { useOnOptimaHaveNewNotification } from "@health/sse";
import { Box, FilterGrid, PageWrapper, TableGrid, TableSettingComponent, useGridContext } from "@toolkit/ui";
import { useOptimaPriorRequestsWithKpiQuery } from "@/pages/OptimaPriorRequests/gql";
import { FC } from "react";
import { GridProvider } from "@/shared/components";
import { useOptimaPriorRequestListContainerColumns } from "./useOptimaPriorRequestListContainerColumns";
import { useOptimaPriorRequestListKpiController } from "./useOptimaPriorRequestListKpiController";
import { RequestListKpis } from "@/pages/OptimaPriorRequests/components/RequestListKpis/RequestListKpis";

export type IOptimaPriorRequestListContainerEvent = {
  type: "SHOW_AUTHORIZATION_CLAIMS_ACTIVITIES";
  payload: {
    authorizationID: string;
  };
};

type OptimaPriorRequestListContainerProps = {
  isProviderAdmin?: boolean;
  filters?: TransactionPriorRequestFilterInput;
};

export const OptimaPriorRequestListContainer: FC<OptimaPriorRequestListContainerProps> = props => {
  const { isProviderAdmin, filters } = props;

  const { selectedKpiMode, totalCounts, hasFilter, handleKpiModeChange, onActiveFiltersChange, activeFilters } =
    useOptimaPriorRequestListKpiController({ externalFilters: filters });

  const { refetch } = useGridContext();
  useOnOptimaHaveNewNotification(() => {
    refetch({ first: 10 });
  });
  return (
    <GridProvider
      gridName={"optimaPriorRequestList"}
      query={useOptimaPriorRequestsWithKpiQuery}
      columns={useOptimaPriorRequestListContainerColumns({
        isProviderAdmin,
        selectedKpiMode: selectedKpiMode?.type,
      })}
      hasTableSetting
      filterInput={filters}
      variables={{
        ...selectedKpiMode.variables,
        filter: activeFilters || {},
        after: null,
        last: null,
        before: null,
        sortBy: {
          field: TransactionSortField.TransactionDate,
          direction: TransactionSortDirection.Desc,
        },
      }}
      dataAccessor={selectedKpiMode.accessor}
      hideFilterInput
      onFiltersChanged={onActiveFiltersChange}
    >
      <PageWrapper
        contentContainerSX={{
          pt: 0,
        }}
        filters={hasFilter ? <FilterGrid /> : <Box height={60}></Box>}
        actions={<TableSettingComponent />}
      >
        <RequestListKpis selectedKpiMode={selectedKpiMode} totalCounts={totalCounts} handleKpiModeChange={handleKpiModeChange} />
        <TableGrid />
      </PageWrapper>
    </GridProvider>
  );
};
