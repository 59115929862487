import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SetOutForDeliveryManualOrderMutationVariables = Types.Exact<{
  orderId: Types.Scalars['ID']['input'];
}>;


export type SetOutForDeliveryManualOrderMutation = { __typename?: 'Mutation', setOutForDeliveryManualOrder?: { __typename?: 'ManualOrder', id: string, status: Types.ManualOrderStatus } | null };


export const SetOutForDeliveryManualOrderDocument = gql`
    mutation SetOutForDeliveryManualOrder($orderId: ID!) {
  setOutForDeliveryManualOrder(orderId: $orderId) {
    id
    status
  }
}
    `;
export type SetOutForDeliveryManualOrderMutationFn = Apollo.MutationFunction<SetOutForDeliveryManualOrderMutation, SetOutForDeliveryManualOrderMutationVariables>;

/**
 * __useSetOutForDeliveryManualOrderMutation__
 *
 * To run a mutation, you first call `useSetOutForDeliveryManualOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetOutForDeliveryManualOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setOutForDeliveryManualOrderMutation, { data, loading, error }] = useSetOutForDeliveryManualOrderMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useSetOutForDeliveryManualOrderMutation(baseOptions?: Apollo.MutationHookOptions<SetOutForDeliveryManualOrderMutation, SetOutForDeliveryManualOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetOutForDeliveryManualOrderMutation, SetOutForDeliveryManualOrderMutationVariables>(SetOutForDeliveryManualOrderDocument, options);
      }
export type SetOutForDeliveryManualOrderMutationHookResult = ReturnType<typeof useSetOutForDeliveryManualOrderMutation>;
export type SetOutForDeliveryManualOrderMutationResult = Apollo.MutationResult<SetOutForDeliveryManualOrderMutation>;
export type SetOutForDeliveryManualOrderMutationOptions = Apollo.BaseMutationOptions<SetOutForDeliveryManualOrderMutation, SetOutForDeliveryManualOrderMutationVariables>;