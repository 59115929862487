import { FilterGrid, MainListActions, PageWrapper, TableGrid, TableSettingComponent } from "@toolkit/ui";
import { useNavigate } from "react-router-dom";
import { GridProvider } from "shared/components/GridProvider/GridProvider";
import { doctorsPaths } from "../../constants";
import { useDoctorListQuery } from "../../gql";
import { useSetDoctorsBreadcrumbs } from "../../hooks";
import { IDoctorListNode } from "../../types";
import { useDoctorListContainerColumns } from "./useDoctorListContainerColumns";

export const DoctorListContainer = () => {
  const navigate = useNavigate();

  const onEditRowClick = (item: IDoctorListNode) => {
    navigate(doctorsPaths.update.fullPathWithParams({ doctorId: item?.id }));
  };

  useSetDoctorsBreadcrumbs("LIST");

  return (
    <GridProvider
      gridName={"doctorList"}
      query={useDoctorListQuery}
      columns={useDoctorListContainerColumns()}
      tableAction={{
        isEditVisible: true,
        onEditRow: onEditRowClick,
      }}
      hasTableSetting
      skipUrlState={false}
    >
      <PageWrapper
        filters={<FilterGrid />}
        actions={
          <>
            <TableSettingComponent />
            <MainListActions />
          </>
        }
      >
        <TableGrid />
      </PageWrapper>
    </GridProvider>
  );
};
