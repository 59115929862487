import { GuidedCareJourney, GuidedCareJourneySortField } from "@/schema/types";
import { PatientProfile } from "@/shared/modules/patient";
import { ProfileContentTab } from "@/shared/modules/patient/types";
import { getGuidedCareProgramsAutocompleteFilter } from "@health/autocompletes";
import { getMediaLink } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Avatar, CustomTableColumnProps, ShowButton } from "@toolkit/ui";
import { useMemo } from "react";
import { GuidedCareJourneyActions } from "../../components/GuidedCareJourneyActions/GuidedCareJourneyActions";
import { GuidedCareJourneyAdherence } from "../../components/GuidedCareJourneyAdherence/GuidedCareJourneyAdherence";
import { GuidedCareJourneyGaps } from "../../components/GuidedCareJourneyGaps/GuidedCareJourneyGaps";

export const useGuidedCareJourneyListContainerColumns = (): CustomTableColumnProps<GuidedCareJourney>[] => {
  const { t } = useTranslation("provider");

  return useMemo(
    () => [
      {
        key: "photo",
        header: "",
        accessor: ({ patientMedicalProfile }) => <Avatar src={getMediaLink(patientMedicalProfile?.patient?.user?.photo!)} />,
      },
      {
        key: "patient",
        header: t("Patient"),
        accessor: ({ patientMedicalProfile, guidedCareHealthProgram }) => (
          <PatientProfile
            patientId={patientMedicalProfile?.patient?.id}
            defaultProgramId={guidedCareHealthProgram?.id}
            patientName={{
              firstName: patientMedicalProfile?.patient?.firstName,
              lastName: patientMedicalProfile?.patient?.lastName,
            }}
          />
        ),
        filter: {
          type: "string",
          name: "patientName",
        },
      },
      {
        key: "program",
        header: t("Program"),
        accessor: ({ guidedCareHealthProgram }) => guidedCareHealthProgram?.name,
        filter: getGuidedCareProgramsAutocompleteFilter({ name: "healthProgramId" }),
      },
      {
        key: "team",
        header: t("Team"),
        accessor: ({ providerGuidedCareHealthProgramTeam }) => providerGuidedCareHealthProgramTeam?.name,
      },
      {
        key: "activities",
        header: t("Activities"),
        accessor: "activities",
      },
      {
        key: "adherence",
        header: t("Adherence"),
        accessor: ({ adherence }) => <GuidedCareJourneyAdherence value={adherence!} />,
        sort: {
          columnEnum: GuidedCareJourneySortField.Adherence,
        },
      },
      {
        key: "gaps",
        header: t("Gaps"),
        accessor: ({ gaps }) => <GuidedCareJourneyGaps value={gaps!} />,
        sort: {
          columnEnum: GuidedCareJourneySortField.Gaps,
        },
      },
      {
        key: "tasks",
        header: t("Tasks"),
        accessor: ({ patientMedicalProfile }) => (
          <PatientProfile
            patientId={patientMedicalProfile?.patient?.id}
            defaultSelectedTab={ProfileContentTab.Tasks}
            renderModalButton={modalButtonProps => <ShowButton {...modalButtonProps} />}
          />
        ),
      },
      {
        key: "actions",
        header: t("Actions"),
        accessor: ({ patientMedicalProfile, guidedCareHealthProgram }) => (
          <GuidedCareJourneyActions patient={patientMedicalProfile?.patient!} programId={guidedCareHealthProgram?.id!} />
        ),
      },
    ],
    [t]
  );
};
