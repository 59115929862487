import { useTranslation } from "@toolkit/i18n";
import { formatGraphQLError } from "@toolkit/apollo";
import { useNavigate } from "react-router-dom";
import { useAddToast } from "@toolkit/ui";
import {
  IOptimaPayerCredentialUpsertFormEvent,
  OptimaPayerCredentialUpsertForm,
} from "../../forms/OptimaPayerCredentialUpsert/OptimaPayerCredentialUpsertForm";
import { convertOptimaPayerCredentialFormValuesToBackEndValues } from "../../others";
import { optimaPayerCredentialPaths } from "../../constants";
import { useSetOptimaPayerCredentialBreadcrumbs } from "../../hooks/useSetOptimaPayerCredentialBreadcrumbs";
import { useOptimaPayerCredentialCreateMutation } from "../../gql/mutations/__generated__/OptimaPayerCredentialCreate";

export const OptimaPayerCredentialCreateContainer = () => {
  const { t } = useTranslation("provider");
  const navigate = useNavigate();
  const { succeeded, failed } = useAddToast();

  const [createOptimaPayerCredential, { loading: isSubmitting }] = useOptimaPayerCredentialCreateMutation({
    onCompleted: mutationData => {
      if (mutationData?.payerCredentialsCreate?.id) {
        succeeded(t("Optima Payer Credential created successfully"));
        navigate(optimaPayerCredentialPaths.list.fullPath);
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  const onOptimaPayerCredentialUpsertFormChange = (event: IOptimaPayerCredentialUpsertFormEvent) => {
    if (event.type === "SUBMIT") {
      const values = convertOptimaPayerCredentialFormValuesToBackEndValues(event.payload.values);

      createOptimaPayerCredential({
        variables: {
          input: values,
        },
      });
    }
  };

  useSetOptimaPayerCredentialBreadcrumbs("CREATE");

  return (
    <OptimaPayerCredentialUpsertForm
      mode='create'
      buttonLabel={t("Create")}
      isSubmitting={isSubmitting}
      onChange={onOptimaPayerCredentialUpsertFormChange}
    />
  );
};
