import { branchHealthLicenseSchema } from "@health/autocompletes";
import { zodEnumSchema } from "@health/enum-options";
import { i18n } from "@toolkit/i18n";
import { z } from "zod";

export const optimaPayerCredentialUpsertFormSchema = z
  .object({
    mode: z.enum(["create", "update"]).optional(),
    password: z.string(),
    username: z.string().min(1, "Username must be at least 3 characters"),
    name: zodEnumSchema.optimaEligabilityPayers.required(),
    license: branchHealthLicenseSchema,
  })
  .superRefine((data, ctx) => {
    if (data.mode === "create" && !data?.password) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: i18n.t("Required", { ns: "provider" }),
        path: ["password"],
      });
    }
  });
export type IOptimaPayerCredentialUpsertFormValues = z.infer<typeof optimaPayerCredentialUpsertFormSchema>;

export const optimaPayerCredentialUpsertFormDefaultValues: Partial<IOptimaPayerCredentialUpsertFormValues> = {
  password: "",
  username: "",
  name: undefined,
  license: undefined,
  mode: "create",
};
