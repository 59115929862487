import RouteItem from "@/shared/components/Root/routeTypes";
import { i18n } from "@toolkit/i18n";
import { NavigateFunction } from "react-router-dom";
import {
  OptimaPayerCredentialCreateContainer,
  OptimaPayerCredentialListContainer,
  OptimaPayerCredentialUpdateContainer,
} from "../containers";
import { optimaPayerCredentialPaths, optimaPayerCredentialRoute } from "./OptimaPayerCredentialPaths";
import { hasPermission } from "@toolkit/core";
import { PermissionEnum } from "@/schema/types";

export const optimaPayerCredentialRoutes: (navigate: NavigateFunction) => RouteItem = navigate => {
  return {
    id: "optima-optima-payer-credential-routes",
    text: i18n.t("Optima Payer Credential", { ns: "provider" }),
    route: optimaPayerCredentialRoute,
    hidden: !hasPermission(PermissionEnum.ManageOptimaPayerCredentials),
    isProhibited: !hasPermission(PermissionEnum.ManageOptimaPayerCredentials),
    subItems: [
      {
        id: "optima-payer-credential-list-route",
        route: optimaPayerCredentialPaths.list.route,
        fullPath: optimaPayerCredentialPaths.list.fullPath,
        element: <OptimaPayerCredentialListContainer />,
        onClick: (route: string) => navigate(route),
      },
      {
        id: "optima-payer-credential-create-route",
        route: optimaPayerCredentialPaths.create.route,
        fullPath: optimaPayerCredentialPaths.create.fullPath,
        hidden: true,
        element: <OptimaPayerCredentialCreateContainer />,
        onClick: (route: string) => navigate(route),
      },
      {
        id: "optima-payer-credential-update-route",
        route: optimaPayerCredentialPaths.update.route,
        fullPath: optimaPayerCredentialPaths.update.fullPath,
        hidden: true,
        element: <OptimaPayerCredentialUpdateContainer />,
        onClick: route => navigate(route),
      },
    ],
  };
};
