import { useTranslation } from "@toolkit/i18n";
import { ReactNode } from "react";
import { useProfileContext } from "../context/ProfileContext";
import { Assessments } from "./Assessments";
import { PatientJourney } from "./Journey";
import { PatientOverview } from "./Overview";
import { Readings } from "./Reading";
import { PatientProfileTabContainer } from "shared/modules/patient/components/PatientProfileTabContainer";
import { GuidedCareTaskList } from "pages/GuidedCareTasks/components/GuidedCareTaskList/GuidedCareTaskList";
import { ProfileContentTab } from "@/shared/modules/patient/types";
import { HealthDocumentsTab } from "@/shared/modules/patient/components/HealthDocuments/HealthDocumentsTab/HealthDocumentsTab";
import { DigitalTwin } from "@/shared/modules/patient/components/DigitalTwin/components/DigitalTwin/DigitalTwin";

type ProfileContentTabsReturn = {
  title: string;
  index: string;
  content: ReactNode;
  disabled?: boolean;
  display?: boolean;
};

export const ProfileContentTabs: () => ProfileContentTabsReturn[] = () => {
  const { t } = useTranslation("provider");

  const { userId, hasGuidedCareHealthProgramTeam, selectedHealthProgram } = useProfileContext();

  return [
    {
      title: t("Overview"),
      index: ProfileContentTab.Overview,
      content: <PatientOverview />,
      disabled: false,
    },
    {
      title: t("Journey"),
      index: ProfileContentTab.Journey,
      content: <PatientJourney />,
      disabled: false,
      hidden: !hasGuidedCareHealthProgramTeam,
    },
    {
      title: t("Assessments"),
      index: ProfileContentTab.Assessments,
      content: <Assessments />,
      disabled: false,
    },
    {
      title: t("Readings"),
      index: ProfileContentTab.Readings,
      content: <Readings />,
      disabled: false,
    },
    {
      title: t("Documents"),
      index: ProfileContentTab.Documents,
      content: <HealthDocumentsTab />,
      disabled: false,
    },
    {
      title: t("Digital Twin"),
      index: ProfileContentTab.DigitalTwin,
      content: (
        <PatientProfileTabContainer
          isContainer
          isRoot
          sections={[
            {
              content: <DigitalTwin />,
            },
          ]}
        />
      ),
      disabled: false,
    },
    {
      title: t("Tasks"),
      index: ProfileContentTab.Tasks,
      content: (
        <PatientProfileTabContainer
          isContainer
          isRoot
          sections={[
            {
              content: (
                <GuidedCareTaskList patientUserId={userId} selectedHealthProgram={selectedHealthProgram} isComingFromPatientProfile />
              ),
            },
          ]}
        />
      ),
      disabled: false,
    },
  ];
};
