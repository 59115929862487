import { MarketplaceOrderSortingField, MarketplaceOrderStatus } from "@/schema/types";
import { getOrderStatusColor } from "@health/domains";
import { getAutocompleteEnumFilter } from "@health/enum-options";
import { useTranslation } from "@toolkit/i18n";
import { Box, CustomTableColumnProps, OrderDirection, OrderStatusTypography, Typography } from "@toolkit/ui";
import { useMemo } from "react";
import { OrderEventsDrawer } from "../../components/OrderEvents";
import { GetProductOrderNode } from "../types";
import { AcceptProductOrderButton } from "./AcceptProductOrderButton";
import { OrderAddressModal } from "./OrderAddressModal";
import { ProductsModal } from "./ProductModal/ProductModal";
import { RejectProductOrderButton } from "./RejectProductOrderButton";
import { SetProductOrderAsConsumedButton } from "./SetProductOrderAsConsumedButton";
import { SetProductOrderAsDeliveredButton } from "./SetProductOrderAsDeliveredButton";

export type PatientsColumnsType = CustomTableColumnProps<GetProductOrderNode>[];

export const useGetProductOrdersListColumns = (): PatientsColumnsType => {
  const { t, i18n } = useTranslation("provider");
  return useMemo(() => {
    return [
      {
        key: "number",
        header: t("Number"),
        accessor: ({ id }) => {
          return <Typography> {id}</Typography>;
        },
        isHidden: false,
        filter: {
          type: "string",
          name: "numbers",
        },
      },
      {
        key: "customerName",
        header: t("Customer Name"),
        type: "truncated-text",
        accessor: ({ user }) => user?.fullName,
      },
      {
        key: "contactNumber",
        header: t("Customer Mobile"),
        accessor: ({ user }) => user?.mobile,
        type: "mobile",
      },
      {
        key: "contactEmail",
        header: t("Customer Email"),
        accessor: ({ user }) => user?.email ?? "",
      },
      {
        header: t("Price"),
        key: "totalAmount",
        accessor: "totalNetAmount",
      },

      {
        key: "Created",
        header: t("Created"),
        type: "datetime",
        accessor: "createdDate",
        sort: {
          columnEnum: MarketplaceOrderSortingField.Created,
          direction: OrderDirection.Asc,
        },
      },
      {
        key: "status",
        header: t("Status"),
        accessor: ({ status }) => <OrderStatusTypography status={status} color={getOrderStatusColor(status)} />,
        filter: getAutocompleteEnumFilter("OrderStatusActive", "status", { multiple: true }),
      },
      {
        key: "orderItems",
        header: t("Order Items"),
        accessor: ({ id }) => <ProductsModal orderId={id} title={t("Items")} />,
      },
      {
        key: "orderAddress",
        header: t("Order Address"),
        accessor: ({ id, status }) => {
          if (status === MarketplaceOrderStatus.New) {
            return <OrderAddressModal orderId={id} title={t("Order Address")} />;
          }
        },
      },

      {
        key: "orderEvents",
        header: t("Order Events"),
        accessor: ({ id, orderEvents }) => (
          <OrderEventsDrawer
            orderId={id}
            events={orderEvents.map(event => ({
              date: event?.date,
              id: event?.id,
              status: event?.status,
              firstName: event?.user?.firstName!,
              lastName: event?.user?.lastName!,
              defaultBranchName: event?.user?.defaultBranch?.name!,
            }))}
          />
        ),
      },
      {
        key: "actions",
        header: t("Actions"),
        accessor: ({ status, id }) => {
          if (status === MarketplaceOrderStatus.New) {
            return (
              <Box sx={{ width: "200px" }}>
                <AcceptProductOrderButton orderId={id} />
                <RejectProductOrderButton orderId={id} />
              </Box>
            );
          }
          if (status === MarketplaceOrderStatus.Accepted) {
            return <SetProductOrderAsConsumedButton orderId={id} />;
          }
          if (status === MarketplaceOrderStatus.OutForDelivery) {
            return <SetProductOrderAsDeliveredButton orderId={id} />;
          }
        },
      },
      {
        key: "toDate",
        header: t("To Date"),
        accessor: "toDate",
        settings: {
          hideFromPreferencesAndTable: true,
        },
        filter: {
          type: "date",
          name: "toDate",
        },
      },
      {
        key: "fromDate",
        header: t("From Date"),
        accessor: "fromDate",
        settings: {
          hideFromPreferencesAndTable: true,
        },
        filter: {
          type: "date",
          name: "fromDate",
        },
      },
    ] as PatientsColumnsType;
  }, [i18n.language, t]);
};
