import { z } from "zod";

export const transactionValidateRequestEncounterFormSchema = z.object({
  sequence: z.string(),
  status: z.string(),
  type: z.string(),
  start: z.string(),
  startType: z.string(),
  end: z.string(),
  endType: z.string(),
  transferSource: z.string(),
  transferDestination: z.string(),
  eligibilityId: z.string(),
});

export type ITransactionValidateRequestEncounterFormValues = z.infer<typeof transactionValidateRequestEncounterFormSchema>;

export const transactionValidateRequestEncounterFormDefaultValues: ITransactionValidateRequestEncounterFormValues = {
  sequence: "1",
  status: "Completed",
  type: "NO_BED_NO_ER",
  start: "2023-01-15T09:00:00Z",
  startType: "Scheduled",
  end: "2023-01-15T09:30:00Z",
  endType: "Completed",
  transferSource: "FacilityA",
  transferDestination: "FacilityB",
  eligibilityId: "ELIG123456",
};

export type ITransactionValidateRequestEncounterUpsertFormEvent =
  | { type: "CREATE"; payload: { values: ITransactionValidateRequestEncounterFormValues } }
  | { type: "UPDATE"; payload: { values: ITransactionValidateRequestEncounterFormValues & { index: string } } };
