import { useMemo } from "react";
import { useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps, Tooltip, Typography } from "@toolkit/ui";
import { GridCellText } from "@health/domains";
import { OptimaValidationRequestDialog } from "../../components/OptimaValidationRequestDialog/OptimaValidationRequestDialog";
import { OptimaValidationResponseDialog } from "../../components/OptimaValidationResponseDialog/OptimaValidationResponseDialog";
import { getAutocompleteEnumFilter } from "@health/enum-options";
import { IOptimaValidatedRequestsListNode } from "../../types";

export const useOptimaValidatedRequestsListContainerColumns = (): CustomTableColumnProps<IOptimaValidatedRequestsListNode>[] => {
  const { t } = useTranslation("provider");

  return useMemo(() => {
    return [
      {
        key: "visitId",
        header: t("Visit Id"),
        accessor: ({ visitId }) => <GridCellText text={visitId} />,
        filter: {
          type: "string",
          name: "visitId",
          label: t("Visit Id"),
        },
      },
      {
        key: "patientId",
        header: t("patient Id"),
        accessor: ({ patientId }) => <GridCellText text={patientId} />,
        filter: {
          type: "string",
          name: "patientId",
          label: t("Patient Id"),
        },
      },
      {
        key: "number-of-edits",
        header: t("Number of edits"),
        accessor: ({ edits, items }) => {
          const itemsLevelEditCounts = items?.map(item => item?.edits?.length || 0).reduce((total, num) => total + num, 0) || 0;
          const transactionLevelEditCounts = edits?.length || 0;
          return <Typography>{transactionLevelEditCounts + itemsLevelEditCounts} </Typography>;
        },
      },
      {
        key: "low-ai-score",
        header: t("Low ai score"),
        accessor: ({ items }) => {
          const lowAiScore = items?.filter(item => item?.percentageOfAcceptance! < 50);
          return <Typography>{lowAiScore?.length || 0} </Typography>;
        },
      },
      {
        key: "has-medical-necessity-issues",
        header: t("Has Medical Necessity Issues"),
        accessor: ({ items }) => {
          const hasMedicalNecessityIssues = items?.some(item => Number(item?.edits?.length!) > 0) ? t("Yes") : t("No");
          const numberOfEdits = items?.reduce((total, item) => total + Number(item?.edits?.length), 0);

          return (
            <Tooltip title={t("The Number of Edits") + " " + numberOfEdits!}>
              <Typography>{hasMedicalNecessityIssues}</Typography>
            </Tooltip>
          );
        },
      },

      {
        key: "billNumber",
        header: t("Bill Number"),
        accessor: ({ billNumber }) => <GridCellText text={billNumber} />,
        filter: {
          type: "string",
          name: "billNumber",
        },
      },
      {
        key: "request",
        header: t("Request"),
        accessor: ({ request }) => <OptimaValidationRequestDialog request={request!} />,
      },
      {
        key: "response",
        header: t("Response"),
        accessor: row => <OptimaValidationResponseDialog requestResponse={row} />,
      },
      {
        key: "hasDislikedEdits",
        header: t("Has Disliked Edits"),
        showOnlyForFilterField: true,
        filter: getAutocompleteEnumFilter("YesNo", "hasDislikedEdits"),
      },
    ];
  }, [t]);
};
