import { AntMediaConfiguration, ConferenceConfiguration, ConferenceProviderType, ZoomConfiguration } from "@/schema/types";
import { useTranslation } from "@toolkit/i18n";
import { Button, FormCard, Grid } from "@toolkit/ui";
import { FC } from "react";
import { FormProvider } from "react-hook-form";
import { useSettingsForCallsForm } from "./useSettingsForCallsFrom";
import { ProvidersType } from "./ProvidersType.component";
import { ZoomConfigurationForm } from "./ZoomConfigurationForm";
import { SettingsForCallsForm } from "./SettingsForCallsForm";
import { AntMediaConfigurationForm } from "./AntMedeaConfigurationsForm";

export const SettingsForCalls: FC = () => {
  const { t } = useTranslation("admin");
  const { handleSubmit, providerType, methods, isLoading, onSubmit, callsIntegration } = useSettingsForCallsForm();

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormCard title={t("Calls Settings Type")} loading={isLoading} doYouHaveData={Boolean(callsIntegration)}>
              <Grid container spacing={2}>
                <ProvidersType defaultValue={callsIntegration?.providerType} />
                <Grid item xs={12}>
                  <SettingsForCallsForm callsIntegration={callsIntegration as ConferenceConfiguration} />
                </Grid>
              </Grid>
            </FormCard>
          </Grid>
          <Grid item xs={12}>
            <FormCard title={t("Calls Settings Configurations")} loading={isLoading} doYouHaveData={Boolean(callsIntegration)}>
              {(providerType as any)?.value === ConferenceProviderType.Zoom && (
                <ZoomConfigurationForm zoomConfigurations={callsIntegration?.zoomConfiguration as ZoomConfiguration} />
              )}

              {(providerType as any)?.value === ConferenceProviderType.AntMedia && (
                <AntMediaConfigurationForm antMediaConfigurations={callsIntegration?.antmediaConfiguration as AntMediaConfiguration} />
              )}
            </FormCard>
          </Grid>
          <Grid item xs={12}>
            <Button type='submit' disabled={isLoading}>
              {t("Save")}
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};
