import { TransactionRemittanceActivityFilterInput } from "@/schema/types";
import { ExtractNodeType } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { OptimaRemittanceActivitiesQuery, useOptimaRemittanceActivitiesQuery } from "@/pages/OptimaClaims/gql";
import { useState } from "react";
import { useRemittanceActivitiesListColumns } from "./useRemittanceActivitiesListColumns";

type InputState = {
  first: number | null;
  after: string | null;
  before: string | null;
  last: number | null;
  filter: TransactionRemittanceActivityFilterInput;
};

export const useRemittanceActivitiesList = (id?: string) => {
  const { t } = useTranslation("domains");
  const columns = useRemittanceActivitiesListColumns();
  const pageSize = 3;
  const [input, setInput] = useState<InputState>({
    first: pageSize,
    after: null,
    before: null,
    last: null,
    filter: {
      remittanceId: id ? id : "",
    },
  });
  const { data: activitiesData, loading: isLoading } = useOptimaRemittanceActivitiesQuery({
    variables: input,
    fetchPolicy: "no-cache",
  });
  const pageInfo = activitiesData?.optimaRemittanceActivities?.pageInfo;
  const tableData = activitiesData?.optimaRemittanceActivities?.edges?.map(
    e => e?.node
  ) as ExtractNodeType<OptimaRemittanceActivitiesQuery>[];
  const hasNextPage = pageInfo?.hasNextPage;
  const hasPreviousPage = pageInfo?.hasPreviousPage;
  const handleGotoNext = () => {
    setInput(prev => ({
      ...prev,
      first: pageSize,
      after: pageInfo?.endCursor || null,
      last: null,
      before: null,
    }));
  };

  const handleGoToPrevious = () => {
    setInput(prev => ({
      ...prev,
      last: pageSize,
      before: pageInfo?.startCursor || null,
      first: null,
      after: null,
    }));
  };
  const totalCount = activitiesData?.optimaRemittanceActivities?.totalCount || 0;
  return {
    t,
    tableData,
    hasNextPage,
    hasPreviousPage,
    isLoading,
    handleGotoNext,
    handleGoToPrevious,
    columns,
    pageSize,
    totalCount,
  };
};
