import { VisitStatus } from "@/schema/types";
import { useOpenState } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Box, Button, CustomDialog, ErrorIcon, Typography, useTheme } from "@toolkit/ui";
import { FC } from "react";

export const DeclineModel: FC<{ onDecline: () => void; status?: VisitStatus }> = ({ onDecline, status }) => {
  const { t } = useTranslation("provider");
  const { open, handleToggle } = useOpenState();
  const theme = useTheme();
  const handleDecline = () => {
    handleToggle();
    onDecline();
  };
  const declineButtonText = status === VisitStatus.CallInProgress ? t("Decline") : t("Cancel");

  return (
    <>
      <CustomDialog
        type='base'
        maxWidth='sm'
        open={open}
        DialogTitleProps={{
          onClose: handleToggle,
        }}
        DialogActionsProps={{
          children: (
            <Button variant='outlined' color='error' onClick={handleDecline}>
              {declineButtonText}
            </Button>
          ),
        }}
        button={
          <Button variant='outlined' onClick={handleToggle}>
            {declineButtonText}
          </Button>
        }
      >
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: 120 }}>
          <Box
            sx={{
              "& svg": {
                width: 50,
                height: 50,
              },
            }}
          >
            <ErrorIcon />
          </Box>
          <Typography
            color={theme.palette.primary.main}
            mt={1}
            fontSize={theme.mixins.fonts.fontSize.lg}
            fontWeight='bold'
            width={342}
            align='center'
          >
            {t("Are you sure you need to Decline Call?")}
          </Typography>
        </Box>
      </CustomDialog>
    </>
  );
};
