import { ExtractNodeType } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { Box, ValueWithLabel } from "@toolkit/ui";
import { OptimaClaimsListQuery } from "@/pages/OptimaClaims/gql";
import { useResubmissionDrawerStyles } from "./ResubmissionDrawerStyles";
import { FC } from "react";
import { toTitleCase } from "@toolkit/core";

type ResubmissionDrawerDetailsProps = {
  claim: ExtractNodeType<OptimaClaimsListQuery>;
};

export const ResubmissionDrawerDetails: FC<ResubmissionDrawerDetailsProps> = ({ claim }) => {
  const { classes } = useResubmissionDrawerStyles();
  const { t } = useTranslation("domains");

  const commonValueProps = {
    labelProps: {
      className: classes.label,
    },
    valueProps: {
      className: classes.value,
    },
  };

  return (
    <Box className={classes.sectionContainer}>
      <ValueWithLabel {...commonValueProps} label={t("Resubmission Type")} value={claim.resubmissionType || "-"} />
      <ValueWithLabel {...commonValueProps} label={t("Submission Cycle")} value={toTitleCase(claim?.submissionCycle || "-")} />
      <ValueWithLabel {...commonValueProps} label={t("Resubmission Related Claim Id")} value={claim.resubmissionRelatedClaimId || "-"} />
      <ValueWithLabel {...commonValueProps} label={t("Resubmission Attachment")} value={claim.resubmissionAttachment || "-"} />
    </Box>
  );
};
