import { ManualOrderStatus } from "@/schema/types";
import { useOpenState } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Box, Button, CustomDialog, CustomIcon, IconButton, Tooltip, Typography, useAddToast, useTheme } from "@toolkit/ui";
import { useRequestPatientApprovalManualOrderMutation } from "../../gql/mutations";
import { INoteFormValues, NoteForm, NoteFormRef } from "../../forms";
import { useRef } from "react";
import { formatGraphQLError } from "@toolkit/apollo";
import { OrderNotesList } from "../OrderNotesList/OrderNotesList";
import { Notes } from "../../types";
import { ManualSubmittedOrderListDocument } from "../../gql";

type RequestPatientApprovalModalProps = {
  orderId: string;
  orderStatus: ManualOrderStatus;
  notes?: Notes;
};

export const RequestPatientApprovalModal: React.FC<RequestPatientApprovalModalProps> = props => {
  const { orderId, orderStatus, notes } = props;
  const { t } = useTranslation();
  const { handleToggle, open } = useOpenState();
  const { failed, succeeded } = useAddToast();
  const noteFormRef = useRef<NoteFormRef>(null);
  const theme = useTheme();

  const [requestPatientApproval, { loading }] = useRequestPatientApprovalManualOrderMutation({
    onCompleted: data => {
      if (data?.requestPatientApprovalManualOrder?.id) {
        handleToggle();
        succeeded(t("Request sent successfully!"));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
    refetchQueries: [ManualSubmittedOrderListDocument],
  });

  const isRequestDisabled = loading;

  const handleRequestApproval = async () => {
    noteFormRef.current?.submit();
  };

  const handleRequestApprovalWithNote = (values: INoteFormValues) => {
    requestPatientApproval({
      variables: {
        orderId: orderId,
        note: values?.note,
      },
    });
  };

  return (
    <CustomDialog
      type='base'
      title={t("Request Patient Approval")}
      open={open}
      DialogTitleProps={{
        title: t("Request Patient Approval"),
        onClose: handleToggle,
      }}
      button={
        <Tooltip title={t("Request Patient Approval")}>
          <IconButton disabled={isRequestDisabled} onClick={handleToggle}>
            <CustomIcon icon='note-activity' />
          </IconButton>
        </Tooltip>
      }
      DialogActionsProps={{
        children: (
          <Button
            onClick={handleRequestApproval}
            disabled={loading || ManualOrderStatus.WaitingProviderApproval !== orderStatus}
            color='primary'
          >
            {t("Request Approval")}
          </Button>
        ),
      }}
    >
      <OrderNotesList orderNotes={notes} />
      <Box display='flex' flexDirection='column'>
        <Typography my={1} fontSize={theme.mixins.fonts.fontSize.lg} fontWeight={theme.mixins.fonts.fontWeight.medium}>
          {t("Add Your Note")}:
        </Typography>
        <NoteForm ref={noteFormRef} label='Approval Note' onChange={handleRequestApprovalWithNote} />
      </Box>
    </CustomDialog>
  );
};
