import { Box, Typography, Card, Button } from "@toolkit/ui";
import { useTranslation } from "@toolkit/i18n";
import { FC } from "react";
import { IOptimaPatientEligibilityRequestHistoryDataType } from "../../types";
import { useCardStyles } from "@/pages/OptimaPatientEligibilityScan/styles/useCard.styles";
import { ScanHistoryCardFailureView } from "../ScanHistoryCardFailureView/ScanHistoryCardFailureView";
import { ScanHistoryCardSuccessView } from "../ScanHistoryCardSuccessView/ScanHistoryCardSuccessView";
import { last } from "lodash";

type ScanHistoryCardProps = {
  payerEligibilityRequestHistories: IOptimaPatientEligibilityRequestHistoryDataType["payerEligibilityRequestHistories"];
  isScanCompleted: boolean;
  handleOpenCreateModal: () => void;
};

export const ScanHistoryCard: FC<ScanHistoryCardProps> = ({ payerEligibilityRequestHistories, isScanCompleted, handleOpenCreateModal }) => {
  const { t } = useTranslation("optima");
  const { classes } = useCardStyles();
  const lastPayerEligibilityRequestHistory = last(payerEligibilityRequestHistories);
  const isLastScanFailed = lastPayerEligibilityRequestHistory?.failed;

  return (
    <Card className={classes.cardBase} sx={{ mb: 2 }}>
      <Typography className={classes.cardTitle}>{t("Patient Scans History")}</Typography>

      <Box sx={{ display: "flex", flexDirection: "column", gap: 1.5 }}>
        {payerEligibilityRequestHistories?.map(item => (
          <Box
            key={item?.id}
            sx={{
              p: 1.5,
              borderRadius: 1,
              ...(item?.failed && {
                backgroundColor: "error.lighten",
                borderLeft: "3px solid",
                borderColor: "error.main",
              }),
            }}
          >
            {item?.failed ? (
              <ScanHistoryCardFailureView
                title={item?.insuranceName || ""}
                errorMessage={item?.exceptionMessage || "-"}
                screenshotFileName={item?.exceptionScreenshotFileName}
              />
            ) : (
              <ScanHistoryCardSuccessView insuranceName={item?.insuranceName || ""} isFounded={!!item?.isFounded} />
            )}
          </Box>
        ))}
      </Box>

      {payerEligibilityRequestHistories?.every(payer => !payer?.isFounded) && isScanCompleted && (
        <Box sx={{ mt: 2, textAlign: "center" }}>
          <Typography sx={{ mb: 2 }}>
            {isLastScanFailed ? t("The last scan failed. Please try scanning again.") : t("Not found yet, you can scan other insurances.")}
          </Typography>
          <Button onClick={handleOpenCreateModal} sx={{ maxWidth: 200, mx: "auto" }}>
            {t("Scan Now")}
          </Button>
        </Box>
      )}
    </Card>
  );
};
