import { ConferenceConfigurationInput, ConferenceProviderType } from "@/schema/types";
import { formatGraphQLError } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import {
  GetConferenceConfigurationDocument,
  useGetConferenceConfigurationQuery,
  useGetVendorIdQuery,
  useSetConferenceConfigurationMutation,
} from "../gql";
import { useCustomForm } from "@toolkit/core";
import { IVirtualIntegrationFormValues, virtualIntegrationFormSchema } from "./VirtualIntegrationFormSchema";
import { useEffect } from "react";

export const useSettingsForCallsForm = () => {
  const { t } = useTranslation("provider");
  const { succeeded, failed } = useAddToast();

  const methods = useCustomForm<IVirtualIntegrationFormValues>({
    schema: virtualIntegrationFormSchema,
  });

  const { handleSubmit, setValue, watch } = methods;
  const providerType = watch("providerType");

  const { data } = useGetVendorIdQuery();
  const id = data?.me?.vendor?.id;

  const { data: callsIntegrationData, loading: isLoadingData } = useGetConferenceConfigurationQuery({
    variables: {
      vendorId: id!,
    },
    skip: !id,
  });
  const [setConferenceConfiguration, { loading }] = useSetConferenceConfigurationMutation({
    onCompleted: response => {
      if (response?.setConferenceConfiguration) {
        succeeded(t("Call Settings Updated Successfully"));
      }
    },
    onError: ({ graphQLErrors }) => failed(formatGraphQLError(graphQLErrors)),
    refetchQueries: [
      {
        query: GetConferenceConfigurationDocument,
        variables: {
          vendorId: id,
        },
      },
    ],
  });

  const onSubmit = (input: IVirtualIntegrationFormValues) => {
    const request: ConferenceConfigurationInput = {
      vendorId: id,
      expirationDuration: input.expirationDuration,
      providerType: input.providerType.value,
      ...(input.providerType.value === ConferenceProviderType.AntMedia && { antmediaConfiguration: input?.antmediaConfiguration }),
      ...(input.providerType.value === ConferenceProviderType.Zoom && { zoomConfiguration: input?.zoomConfiguration }),
    };

    setConferenceConfiguration({
      variables: {
        input: request,
      },
    });
  };

  const callsIntegration = callsIntegrationData?.getConferenceConfiguration;

  useEffect(() => {
    if (providerType?.value !== ConferenceProviderType.AntMedia) {
      setValue("antmediaConfiguration", null);
    }
    if (providerType?.value !== ConferenceProviderType.Zoom) {
      setValue("zoomConfiguration", null);
    }
  }, [providerType, setValue]);

  return {
    handleSubmit,
    providerType,
    isLoading: isLoadingData || loading,
    methods,
    onSubmit,
    callsIntegration,
  };
};
