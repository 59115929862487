import { DoctorSpecialization, Maybe, Qualification } from "@/schema/types";
import { pickLocalizedValue } from "@toolkit/i18n";
import { InfoItems } from "@toolkit/ui";

export const getDoctorBioInfoItems = (bio?: string | null): InfoItems => {
  return bio
    ? [
        {
          value: bio,
          valueDisplayMode: "multiple-line-string",
        },
      ]
    : undefined;
};

export const getDoctorSpecialtiesInfoItems = (specialties?: Array<Maybe<DoctorSpecialization | null>> | null): InfoItems => {
  const specialtiesList = specialties?.map(
    specialty => specialty?.code + " - " + pickLocalizedValue(specialty?.display, specialty?.arabicDisplay)
  );
  return specialties
    ? [
        {
          value: specialtiesList,
          valueDisplayMode: "chips",
        },
      ]
    : undefined;
};

export const getDoctorQualificationsInfoItems = (
  qualifications?: Array<Maybe<Pick<Qualification, "code" | "issuer">>> | null
): InfoItems => {
  const qualificationsList = qualifications?.map(qualificate => qualificate?.code + " - " + qualificate?.issuer);
  return qualifications
    ? [
        {
          value: qualificationsList,
          valueDisplayMode: "list",
        },
      ]
    : undefined;
};
