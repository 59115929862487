import { ProviderSettingsInput } from "@/schema/types";
import { formGirdSpacing } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Button, Grid } from "@toolkit/ui";
import { FC } from "react";
import { FormProvider, useForm } from "react-hook-form";
import AppointmentTimeOutSettings from "./forms/AppointmentTimeOutSettings/AppointmentTimeOutSettings";
import OutPatientJourneyForm from "./forms/OutPatientJourney/OutPatientJourneyForm";
import useOutPatientJourneyForm from "./forms/OutPatientJourney/useOutPatientJourneyForm";
import useGeneralSettings from "./useGeneralSettings";

interface GeneralSettingsProps {}

export const GeneralSettings: FC<GeneralSettingsProps> = () => {
  const { t } = useTranslation("provider");

  const { providerSettings, loading } = useGeneralSettings();
  const { loadingMutation, onSubmit } = useOutPatientJourneyForm();

  const methods = useForm<ProviderSettingsInput>({
    mode: "onChange",
  });
  const { handleSubmit } = methods;

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={formGirdSpacing}>
          <Grid item xs={12}>
            <OutPatientJourneyForm providerSettings={providerSettings} isLoading={loading || loadingMutation} />
          </Grid>
          <Grid item xs={12}>
            <AppointmentTimeOutSettings providerSettings={providerSettings} isLoading={loading || loadingMutation} />
          </Grid>
          <Grid item xs={12}>
            <Button type='submit' disabled={loading || loadingMutation}>
              {t("Save")}
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};
