import { useCustomForm } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FormDateTimePickerField, FormTextField, Grid } from "@toolkit/ui";
import { ForwardRefRenderFunction, forwardRef, useEffect, useImperativeHandle } from "react";
import { FormProvider, UseFormReturn } from "react-hook-form";
import {
  ITransactionValidateRequestEncounterFormValues,
  ITransactionValidateRequestEncounterUpsertFormEvent,
  transactionValidateRequestEncounterFormDefaultValues,
  transactionValidateRequestEncounterFormSchema,
} from "./TransactionValidateRequestEncounterFormSchema";

type TransactionValidateRequestEncounterUpsertFormProps = {
  encounter?: ITransactionValidateRequestEncounterFormValues;
  index?: string;
  onChange: (event: ITransactionValidateRequestEncounterUpsertFormEvent) => void;
};

export type TransactionValidateRequestEncounterUpsertFormRef = {
  getForm: () => UseFormReturn<ITransactionValidateRequestEncounterFormValues>;
  submit: () => void;
};

const TransactionValidateRequestEncounterUpsertFormForwardRef: ForwardRefRenderFunction<
  TransactionValidateRequestEncounterUpsertFormRef,
  TransactionValidateRequestEncounterUpsertFormProps
> = (props, ref) => {
  const { encounter, index, onChange } = props;

  const { t } = useTranslation("provider");

  const form = useCustomForm<ITransactionValidateRequestEncounterFormValues>({
    defaultValues: transactionValidateRequestEncounterFormDefaultValues,
    schema: transactionValidateRequestEncounterFormSchema,
  });

  const { setValues, handleSubmit } = form;

  const onSubmit = (values: ITransactionValidateRequestEncounterFormValues) => {
    if (encounter) {
      if (index) {
        onChange({
          type: "UPDATE",
          payload: { values: { ...values, index } },
        });
      }
    } else {
      onChange({
        type: "CREATE",
        payload: { values },
      });
    }
  };

  useImperativeHandle(ref, () => ({
    getForm: () => form,
    submit: handleSubmit(onSubmit),
  }));

  useEffect(() => {
    if (encounter) {
      setValues(encounter);
    }
  }, [encounter, setValues]);

  return (
    <FormProvider {...form}>
      <form>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <FormTextField name='sequence' label={t("Sequence")} placeholder={t("Sequence")} />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormTextField name='status' label={t("Status")} placeholder={t("Status")} />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormDateTimePickerField name='start' label={t("Start")} placeholder={t("Start")} />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormTextField name='startType' label={t("Start Type")} placeholder={t("Start Type")} />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormDateTimePickerField name='end' label={t("End")} placeholder={t("End")} />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormTextField name='endType' label={t("End Type")} placeholder={t("End Type")} />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormTextField name='type' label={t("Type")} placeholder={t("Type")} />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormTextField name='eligibilityId' label={t("Eligibility ID")} placeholder={t("Eligibility ID")} />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormTextField name='transferSource' label={t("Transfer Source")} placeholder={t("Transfer Source")} />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormTextField name='transferDestination' label={t("Transfer Destination")} placeholder={t("Transfer Destination")} />
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export const TransactionValidateRequestEncounterUpsertForm = forwardRef(TransactionValidateRequestEncounterUpsertFormForwardRef);
