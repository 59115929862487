import { useEffect } from "react";
import { useReactiveVar } from "@apollo/client";
import { isDoctorUser, setProviderLocalStorageItem } from "@/utils";
import { userWorkspaceDoctorVar } from "../../vars";
import { useFetchDefaultBranchQuery, FetchDefaultBranchDocument } from "../../gql";
import { BaseWorkspaceDoctor, UserWorkspaceDoctorUpsertModalApi } from "@health/domains";
import { useOidcUserProfile } from "@/shared/hooks/useOidcUserProfile";

export const UserWorkspaceDoctor = () => {
  const doctorCurrentWorkspace = useReactiveVar(userWorkspaceDoctorVar);
  const { userInfo } = useOidcUserProfile();

  const { data: meData, loading: meLoading } = useFetchDefaultBranchQuery({
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    if (meLoading) return;
    if (meData?.me?.defaultBranch) {
      userWorkspaceDoctorVar({
        branch: meData.me.defaultBranch,
      });
      setProviderLocalStorageItem("UserWorkspaceDoctor", { branch: meData.me.defaultBranch });
    }
  }, [meData, meLoading]);

  useEffect(() => {
    if (!meData?.me?.defaultBranch || !meData?.me?.defaultBranch?.id) return;
    if (!doctorCurrentWorkspace) {
      UserWorkspaceDoctorUpsertModalApi.open({
        userWorkspaceDoctor: {
          branch: meData.me.defaultBranch,
        },
      });
    }
  }, [doctorCurrentWorkspace, meData?.me?.defaultBranch]);

  return (
    <>
      <BaseWorkspaceDoctor
        hasClose={!!isDoctorUser(userInfo)}
        doctorCurrentWorkspace={doctorCurrentWorkspace}
        refetchQueries={[FetchDefaultBranchDocument]}
      />
    </>
  );
};
