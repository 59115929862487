/* eslint-disable max-lines */
import { getMarketplaceItemInfoItems } from "@/pages/Marketplace/others/cellInfo.utils";
import { MarketplacePromotionSortingField } from "@/schema/types";
import {
  getMarketplaceHealthPackageCategoriesAutocompleteFilter,
  getMarketplaceHealthPackagesAutocompleteFilter,
  getMarketplaceProductCategoriesAutocompleteFilter,
  getMarketplaceProductsAutocompleteFilter,
} from "@health/autocompletes";
import { getAutocompleteEnumFilter } from "@health/enum-options";
import { useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps, GridImageModal } from "@toolkit/ui";
import { TogglePromotionsPublishStatus } from "pages/MarketplacePromotions/components/PublishPromotions";
import { useMemo } from "react";
import { PromotionsQueryNode } from "../../types";

export const usePromotionsListTableColumns = (): CustomTableColumnProps<PromotionsQueryNode>[] => {
  const { t } = useTranslation("provider");

  return useMemo(() => {
    return [
      {
        key: "image",
        header: t("Image"),
        accessor: ({ photo }) => <GridImageModal image={photo!} title={t("Promotion Image")} altText={t("Promotion")} />,
      },
      {
        key: "name",
        header: t("Name"),
        accessor: "name",
        filter: {
          type: "string",
          name: "name",
        },
        sort: {
          columnEnum: MarketplacePromotionSortingField.Name,
        },
      },
      {
        key: "description",
        header: t("Description"),
        accessor: "description",
      },
      {
        key: "healthPackageCategoryIds",
        accessor: "healthPackageCategoryIds",
        header: t("Health Package Categories"),
        showOnlyForFilterField: true,
        filter: getMarketplaceHealthPackageCategoriesAutocompleteFilter({
          name: "healthPackageCategoryIds",
          multiple: true,
        }),
      },
      {
        key: "startDate",
        header: t("Start Date"),
        type: "datetime",
        accessor: "startDate",
        filter: { type: "date", name: "startDate" },
        sort: {
          columnEnum: MarketplacePromotionSortingField.StartDate,
        },
      },
      {
        key: "endDate",
        header: t("End Date"),
        type: "datetime",
        accessor: "endDate",
        filter: {
          type: "date",
          name: "endDate",
        },
      },
      {
        key: "Products",
        header: t("Products"),
        type: "info",
        infoCellOptions: {
          title: t("Promotion's Products"),
          items: ({ products }) => getMarketplaceItemInfoItems(products ?? []),
          layout: "one-column",
          disableShowButton: ({ products }) => !products?.length,
        },
      },
      {
        key: "Products Categories",
        header: t("Products Categories"),
        type: "info",
        infoCellOptions: {
          title: t("Promotion's Products Categories"),
          items: ({ productCategories }) => getMarketplaceItemInfoItems(productCategories ?? []),
          layout: "one-column",
          disableShowButton: ({ productCategories }) => !productCategories?.length,
        },
      },
      {
        key: "health-packages",
        header: t("Health Packages"),
        type: "info",
        infoCellOptions: {
          title: t("Promotion's Health Packages"),
          items: ({ healthPackages }) => getMarketplaceItemInfoItems(healthPackages ?? []),
          layout: "one-column",
          disableShowButton: ({ healthPackages }) => !healthPackages?.length,
          emptyMessage: t("No health package provided"),
        },
      },
      {
        key: "health-packages-categories",
        header: t("Health Packages Categories"),
        type: "info",
        infoCellOptions: {
          title: t("Promotion's Health Packages Categories"),
          items: ({ healthPackageCategories }) => getMarketplaceItemInfoItems(healthPackageCategories ?? []),
          layout: "one-column",
          disableShowButton: ({ healthPackageCategories }) => !healthPackageCategories?.length,
          emptyMessage: t("Promotion's Health Packages Categories"),
        },
      },
      {
        key: "isPublished",
        header: t("Published"),
        accessor: row => <TogglePromotionsPublishStatus row={row} />,
        filter: getAutocompleteEnumFilter("YesNo", "isPublished"),
        sort: {
          columnEnum: MarketplacePromotionSortingField.Published,
        },
      },
      {
        key: "healthPackageIds",
        accessor: "healthPackageIds",
        header: t("Health Packages"),
        showOnlyForFilterField: true,
        filter: getMarketplaceHealthPackagesAutocompleteFilter({ name: "healthPackageIds", multiple: true }),
      },
      {
        key: "productCategoryIds",
        accessor: "productCategoryIds",
        header: t("Product Categories"),
        filter: getMarketplaceProductCategoriesAutocompleteFilter({ name: "productCategoryIds", multiple: true }),
        showOnlyForFilterField: true,
      },
      {
        key: "productIds",
        accessor: "productIds",
        header: t("Products"),
        filter: getMarketplaceProductsAutocompleteFilter({ name: "productIds", multiple: true }),
        showOnlyForFilterField: true,
      },
      {
        key: "isActive",
        header: t("Is Active"),
        showOnlyForFilterField: true,
        filter: getAutocompleteEnumFilter("YesNo", "isActive"),
      },
    ];
  }, []);
};
