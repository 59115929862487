import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import { DigitalTwinHealthParameterFragmentFragmentDoc } from '../../fragments/__generated__/DigitalTwinHealthParameterFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DigitalTwinGuidedCareTemplateGetQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type DigitalTwinGuidedCareTemplateGetQuery = { __typename?: 'Query', healthProgramTemplate?: { __typename?: 'HealthProgramTemplate', id: string, overAllHealthParameter?: { __typename?: 'HealthParameter', id?: string | null, code?: string | null, chartType?: Types.ChartType | null, display?: string | null, arabicDisplay?: string | null, unit?: { __typename?: 'FieldUnit', id?: string | null, display?: string | null, arabicDisplay?: string | null } | null } | null, healthParameters?: Array<{ __typename?: 'HealthParameter', id?: string | null, code?: string | null, chartType?: Types.ChartType | null, display?: string | null, arabicDisplay?: string | null, riskParameter?: { __typename?: 'HealthParameter', id?: string | null, code?: string | null, chartType?: Types.ChartType | null, display?: string | null, arabicDisplay?: string | null, unit?: { __typename?: 'FieldUnit', id?: string | null, display?: string | null, arabicDisplay?: string | null } | null } | null, unit?: { __typename?: 'FieldUnit', id?: string | null, display?: string | null, arabicDisplay?: string | null } | null } | null> | null } | null };


export const DigitalTwinGuidedCareTemplateGetDocument = gql`
    query DigitalTwinGuidedCareTemplateGet($id: ID!) {
  healthProgramTemplate(id: $id) {
    id
    overAllHealthParameter {
      ...DigitalTwinHealthParameterFragment
    }
    healthParameters {
      ...DigitalTwinHealthParameterFragment
      riskParameter {
        ...DigitalTwinHealthParameterFragment
      }
    }
  }
}
    ${DigitalTwinHealthParameterFragmentFragmentDoc}`;

/**
 * __useDigitalTwinGuidedCareTemplateGetQuery__
 *
 * To run a query within a React component, call `useDigitalTwinGuidedCareTemplateGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useDigitalTwinGuidedCareTemplateGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDigitalTwinGuidedCareTemplateGetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDigitalTwinGuidedCareTemplateGetQuery(baseOptions: Apollo.QueryHookOptions<DigitalTwinGuidedCareTemplateGetQuery, DigitalTwinGuidedCareTemplateGetQueryVariables> & ({ variables: DigitalTwinGuidedCareTemplateGetQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DigitalTwinGuidedCareTemplateGetQuery, DigitalTwinGuidedCareTemplateGetQueryVariables>(DigitalTwinGuidedCareTemplateGetDocument, options);
      }
export function useDigitalTwinGuidedCareTemplateGetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DigitalTwinGuidedCareTemplateGetQuery, DigitalTwinGuidedCareTemplateGetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DigitalTwinGuidedCareTemplateGetQuery, DigitalTwinGuidedCareTemplateGetQueryVariables>(DigitalTwinGuidedCareTemplateGetDocument, options);
        }
export function useDigitalTwinGuidedCareTemplateGetSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<DigitalTwinGuidedCareTemplateGetQuery, DigitalTwinGuidedCareTemplateGetQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DigitalTwinGuidedCareTemplateGetQuery, DigitalTwinGuidedCareTemplateGetQueryVariables>(DigitalTwinGuidedCareTemplateGetDocument, options);
        }
export type DigitalTwinGuidedCareTemplateGetQueryHookResult = ReturnType<typeof useDigitalTwinGuidedCareTemplateGetQuery>;
export type DigitalTwinGuidedCareTemplateGetLazyQueryHookResult = ReturnType<typeof useDigitalTwinGuidedCareTemplateGetLazyQuery>;
export type DigitalTwinGuidedCareTemplateGetSuspenseQueryHookResult = ReturnType<typeof useDigitalTwinGuidedCareTemplateGetSuspenseQuery>;
export type DigitalTwinGuidedCareTemplateGetQueryResult = Apollo.QueryResult<DigitalTwinGuidedCareTemplateGetQuery, DigitalTwinGuidedCareTemplateGetQueryVariables>;