import { Grid, Typography, useTheme, PageWrapper, ActiveFilters, StaticFilterDialog } from "@toolkit/ui";
import { FC } from "react";
import { ActivityBasedDoctorsQuery } from "../gql/queries";
import { useTranslation } from "@toolkit/i18n";
import BarLineChart from "@/shared/modules/patient/components/Charts/BarLineChart";
import { useFiltersFields } from "../components";
import { DoctorsTable } from "../components/OptimaWidgetTable";
import { useOptimaWidgetHook } from "./useOptimaWidgetHook";

export const OptimaWidgetsList: FC = () => {
  const { t } = useTranslation("provider");
  const theme = useTheme();
  const {
    activeFiltersProps,
    chartData,
    dataWithActivity,
    dataWithoutActivity,
    handleGoToPrevious,
    handleGotoNext,
    staticFiltersProps,
    totalWithActivity,
    totalWithoutActivity,
  } = useOptimaWidgetHook();

  return (
    <PageWrapper
      filters={
        <>
          <ActiveFilters {...activeFiltersProps} />
          <StaticFilterDialog filterFields={useFiltersFields()} {...staticFiltersProps} />
        </>
      }
    >
      <Grid item xs={12} bgcolor={theme.palette.common.white} marginY={2}>
        <BarLineChart
          widthValue={"100%"}
          title={t("Doctors Activity Overview")}
          height={250}
          data={chartData}
          color={theme.palette.primary.main}
          minRangeValue={0}
          maxRangeValue={Math.max(totalWithActivity, totalWithoutActivity) + 10}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant='subtitle1' fontSize={theme.mixins.fonts.fontSize.md} fontWeight={theme.mixins.fonts.fontWeight.medium} mb={1}>
          {t("Doctors With Activity")}
        </Typography>

        <DoctorsTable
          doctorsWithActivities={true}
          data={dataWithActivity as ActivityBasedDoctorsQuery}
          onGoToNext={handleGotoNext}
          onGoToPrevious={handleGoToPrevious}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant='subtitle1' fontSize={theme.mixins.fonts.fontSize.md} fontWeight={theme.mixins.fonts.fontWeight.medium} mb={1}>
          {t("Doctors With No Activity")}
        </Typography>

        <DoctorsTable
          doctorsWithActivities={false}
          data={dataWithoutActivity as ActivityBasedDoctorsQuery}
          onGoToNext={handleGotoNext}
          onGoToPrevious={handleGoToPrevious}
        />
      </Grid>
    </PageWrapper>
  );
};
