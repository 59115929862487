import { PatientHealthParameterFilterInput, TemplateFieldType } from "@/schema/types";
import React, { FC } from "react";
import { Grid, WaveSurferAudio } from "@toolkit/ui";
import { PatientHealthParameterCard } from "../PatientHealthParameterCard/PatientHealthParameterCard";
import { usePatientHealthParameters } from "../../hooks";
import { PatientHealthParameterHistoryModalApi } from "../../modals";

type PatientHealthParameterAudioProps = {
  token?: string;
  filter: PatientHealthParameterFilterInput;
};

export const PatientHealthParameterAudio: FC<PatientHealthParameterAudioProps> = props => {
  const { token, filter } = props;

  const { healthParameter, patientHealthParameters, loading } = usePatientHealthParameters({
    first: 1,
    token,
    filter,
  });

  const patientHealthParameter = patientHealthParameters?.[0];
  const patientHealthParameterAudioFile = patientHealthParameter?.valueAudioFile;

  const onPatientHealthParameterCardChange = () => {
    PatientHealthParameterHistoryModalApi.open({
      type: TemplateFieldType.AudioFile,
      healthParameter,
      filter,
    });
  };

  return (
    <PatientHealthParameterCard
      healthParameter={healthParameter}
      patientHealthParameter={patientHealthParameter}
      isLoading={loading}
      isExpandIconShown={patientHealthParameterAudioFile ? !filter?.visitId : false}
      onChange={onPatientHealthParameterCardChange}
    >
      {patientHealthParameterAudioFile && (
        <Grid item xs={4}>
          <WaveSurferAudio url={patientHealthParameterAudioFile} />
        </Grid>
      )}
    </PatientHealthParameterCard>
  );
};
