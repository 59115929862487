import { createPathDefinition } from "@toolkit/core";

export const optimaClaimsRoute = `/optima/claims`;

export const optimaClaimsPaths = createPathDefinition(optimaClaimsRoute, {
  list: "",
  authorizationList: ":authorizationId/authorization-claims",
  authorizationClaimsList: ":claimId/claim-authorizations",
  authorizationClaimsActivitiesList: ":authorizationId/authorization-claims-activities",
  authorizationClaimsActivities: ":claimId/claim-activities",
});
