import { FC, MouseEvent, PropsWithChildren, useCallback, useState } from "react";
import { useSelectCall } from "@health/meeting";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  CustomIcon,
  Grid,
  MuiMicIcon,
  MuiMicOffIcon,
  MuiVideocamIcon,
  MuiVideocamOffIcon,
  useTheme,
} from "@toolkit/ui";
import { useTranslation } from "@toolkit/i18n";
import { useNavigate } from "react-router-dom";
import { VisitsView } from "../VisitStartingNowNotification/VisitsView";
import { DeclineModel } from "../VisitStartingNowNotification/DeclineModel";
import { ConferenceProviderType, VisitStatus } from "@/schema/types";

type VisitCallJoinStatusBarProps = PropsWithChildren<{
  visitId: string;
  onDecline: () => void;
  onClose: () => void;
  onUserPress: () => void;
  status: VisitStatus;
}>;

export const VisitCallJoinStatusBar: FC<VisitCallJoinStatusBarProps> = ({ visitId, status, onDecline, onClose, onUserPress }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { t } = useTranslation("provider");
  const { call } = useSelectCall(visitId) || {};

  const { muteAudio, muteVideo, joinCall, callType } = call || {};
  const { audioMuted, videoMuted } = call?.state || {};
  const handleToggleAudio = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      onUserPress();
      muteAudio?.(m => !m);
    },
    [muteAudio, onUserPress]
  );
  const handleToggleVideo = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      onUserPress();
      muteVideo?.(m => !m);
    },
    [muteVideo, onUserPress]
  );
  const [loading, setLoading] = useState(false);
  const handleJoin = useCallback(
    async (e: MouseEvent<HTMLButtonElement>) => {
      try {
        e.stopPropagation();
        onUserPress();
        setLoading(true);
        await joinCall?.();
        if (callType !== ConferenceProviderType.Sanar) {
          navigate(`/visit-call?visitId=${visitId}`);
        }
      } finally {
        setLoading(false);
      }
    },
    [callType, joinCall, navigate, onUserPress, visitId]
  );

  if (!call) {
    return null;
  }

  return (
    <>
      <Box display={"flex"} bgcolor={theme.palette.common.white} width='100vw' boxShadow={theme.mixins.shadows.md}>
        <Container fixed>
          <Grid container spacing={2} justifyContent='center' alignItems='center' height='100px'>
            <VisitsView number={visitId!} />

            <Grid item xs='auto'>
              <Box height='100%' display='flex' alignItems='center'>
                <Button
                  disabled={!muteVideo}
                  title={!videoMuted ? t("Stop video") : t("Start video")}
                  variant='text'
                  onClick={handleToggleVideo}
                >
                  {!videoMuted ? <MuiVideocamIcon /> : <MuiVideocamOffIcon />}
                </Button>

                <Button disabled={!muteAudio} title={!audioMuted ? t("Mute") : t("Unmute")} variant='text' onClick={handleToggleAudio}>
                  {!audioMuted ? <MuiMicIcon /> : <MuiMicOffIcon />}
                </Button>
                <Button disabled={!call || loading || !joinCall} onClick={handleJoin}>
                  {loading ? <CircularProgress color='inherit' size={20} /> : t("Join")}
                </Button>
                <DeclineModel onDecline={onDecline} status={status} />
              </Box>
            </Grid>

            <Grid item xs='auto'>
              <Button variant='text' onClick={onClose} startIcon={<CustomIcon icon='close' />}>
                {t("Close")}
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};
