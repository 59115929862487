import { makeStyles } from "@toolkit/ui";

export const useDigitalTwinStyle = makeStyles()({
  loadingCard: {
    display: "flex",
    justifyContent: "center",
    paddingBlock: 32,
  },
  emptyCard: {
    paddingBlock: 32,
  },
});
