import { ExtractNodeType } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { Box, format, ValueWithLabel } from "@toolkit/ui";
import { OptimaClaimsListQuery } from "@/pages/OptimaClaims/gql";
import { useRemittanceDrawerStyles } from "./RemittanceDrawerStyles";
import { FC } from "react";
import { DATE_FORMAT_FOR_TABLE_LONG } from "@toolkit/core";
import { transactionStatusTypeOptionsMap } from "@health/enum-options";
import { getOptimaAuthorizationStatusColor } from "../../../../utils";
import { useSiteSettingsGetQuery } from "@/shared/gql/queries";

type RemittanceDrawerDetailsProps = {
  claim: ExtractNodeType<OptimaClaimsListQuery>;
};

export const RemittanceDrawerDetails: FC<RemittanceDrawerDetailsProps> = ({ claim }) => {
  const { remittance } = claim;
  const { classes, theme } = useRemittanceDrawerStyles();
  const { t } = useTranslation("domains");
  const { data } = useSiteSettingsGetQuery();
  const defaultCurrency = data?.siteSettings?.defaultCurrency ? " " + data?.siteSettings?.defaultCurrency : "";

  const commonValueProps = {
    labelProps: {
      className: classes.label,
    },
    valueProps: {
      className: classes.value,
    },
  };

  return (
    <Box className={classes.sectionContainer}>
      <ValueWithLabel
        {...commonValueProps}
        valueProps={{
          ...commonValueProps.valueProps,
          color: getOptimaAuthorizationStatusColor(remittance?.status || "", theme),
        }}
        label={t("Status")}
        value={remittance?.status ? transactionStatusTypeOptionsMap[remittance.status].label : "-"}
      />
      <ValueWithLabel
        {...commonValueProps}
        label={t("Date Settlement")}
        value={remittance?.dateSettlement ? format(remittance.dateSettlement, DATE_FORMAT_FOR_TABLE_LONG) : "-"}
      />
      <ValueWithLabel {...commonValueProps} label={t("Payment Reference")} value={remittance?.paymentReference || "-"} />
      <ValueWithLabel {...commonValueProps} label={t("Remittance ID")} value={remittance?.id || "-"} />
      <ValueWithLabel {...commonValueProps} label={t("Gross Amount")} value={claim.gross ? claim.gross + defaultCurrency : "-"} />
      <ValueWithLabel {...commonValueProps} label={t("Net Amount")} value={claim.net ? claim.net + defaultCurrency : "-"} />
    </Box>
  );
};
