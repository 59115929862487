import { PermissionEnum } from "@/schema/types";
import { hasAnyPermission } from "@toolkit/core";
import { TFunction } from "@toolkit/i18n";
import { ListViewIcon } from "@toolkit/ui";
import { NavigateFunction } from "react-router-dom";
import RouteItem from "@/shared/components/Root/routeTypes";
import { optimaClaimsRoutes } from "@/pages/Optima/OptimaClaims/constants";
import { optimaPriorRequestsRoutes } from "@/pages/Optima/OptimaPriorRequests/constants";
import { optimaValidatedRequestsRoutes } from "@/pages/Optima/OptimaValidatedRequests/constants";
import { optimaPatientsEligibilityScanHistoryRoutes } from "@/pages/Optima/OptimaPatientsEligibilityRequestsHistory/constants";
import { optimaValidationRequestsRoutes } from "../OptimaRcmValidationRequests/constants";
import { optimaPayerCredentialRoutes } from "../OptimaPayerCredentials/constants/OptimaPayerCredentialRoutes";
import { optimaTransactionValidateRequestRoutes } from "../OptimaTransactionValidateRequest/constants";

export const optimaRoutes: (props: { navigate: NavigateFunction; t: TFunction }) => RouteItem = ({ navigate, t }) => {
  const canViewOptimaModule = hasAnyPermission([
    PermissionEnum.ViewTransaction,
    PermissionEnum.ManageValidationRequests,
    PermissionEnum.ManageOptimaPayerCredentials,
  ]);
  return {
    id: "optima-routes",
    text: t("Optima", { ns: "provider" }),
    icon: <ListViewIcon />,
    hidden: !canViewOptimaModule,
    isProhibited: !canViewOptimaModule,
    subItems: [
      optimaPriorRequestsRoutes({ navigate, t }),
      optimaClaimsRoutes({ navigate, t }),
      optimaValidationRequestsRoutes({ navigate, t }),
      optimaValidatedRequestsRoutes({ navigate, t }),
      optimaPatientsEligibilityScanHistoryRoutes({ navigate, t }),
      optimaPayerCredentialRoutes(navigate),

      optimaTransactionValidateRequestRoutes(navigate), // this route for product team only check this ISSUE-13002
    ],
  };
};
