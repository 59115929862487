import { useCallback, useEffect } from "react";
import { useTranslation } from "@toolkit/i18n";
import { useBreadCrumbs } from "@toolkit/ui";

export const useSetOptimaTransactionValidateRequestBreadcrumbs = (containerType: "VALIDATE") => {
  const { t } = useTranslation("provider");
  const { setBreadCrumb } = useBreadCrumbs();

  const breadcrumbTitle = t("Transaction Validate Request");

  const setOptimaTransactionValidateRequestBreadcrumb = useCallback(() => {
    setBreadCrumb({
      title: breadcrumbTitle,
    });
  }, [breadcrumbTitle, setBreadCrumb]);

  useEffect(() => {
    if (containerType === "VALIDATE") {
      setOptimaTransactionValidateRequestBreadcrumb();
    }
  }, [containerType, setOptimaTransactionValidateRequestBreadcrumb]);
};
