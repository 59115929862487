import { useTranslation } from "@toolkit/i18n";
import { FilterGrid, PageWrapper, TableGrid, TableSettingComponent, useBreadCrumbs } from "@toolkit/ui";
import { FC, useEffect, useMemo } from "react";
import { GridProvider } from "shared/components";
import { optimaPatientsEligibilityScanHistoryRoutesBreadcrumb } from "../../constants";
import { usePatientEligibilityRequestHistoriesListQuery } from "../../gql";
import { useOptimaPatientsEligibilityRequestsHistoryListContainerColumns } from "./useOptimaPatientsEligibilityRequestsHistoryListContainerColumns";

export const OptimaPatientsEligibilityRequestsHistoryListContainer: FC = () => {
  const { t } = useTranslation("provider");

  const { setBreadCrumb } = useBreadCrumbs();

  useEffect(() => {
    setBreadCrumb({
      title: optimaPatientsEligibilityScanHistoryRoutesBreadcrumb(t),
    });
  }, []);
  const filterInput = useMemo(() => {
    return {};
  }, []);
  return (
    <GridProvider
      gridName={"OptimaPatientsEligibilityRequestsHistoryListContainer"}
      query={usePatientEligibilityRequestHistoriesListQuery}
      columns={useOptimaPatientsEligibilityRequestsHistoryListContainerColumns()}
      filterInput={filterInput}
      hasTableSetting
      hideFilterInput
    >
      <PageWrapper filters={<FilterGrid />} actions={<TableSettingComponent />}>
        <TableGrid />
      </PageWrapper>
    </GridProvider>
  );
};
