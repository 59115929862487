import { useCustomFormContext } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Button, CustomTable, Grid, PlusIcon, Typography, useTheme } from "@toolkit/ui";
import { FieldArrayWithId, useFieldArray } from "react-hook-form";

import {
  TransactionValidateRequestItemUpsertModal,
  TransactionValidateRequestItemUpsertModalApi,
} from "../../modals/TransactionValidateRequestItemUpsertModal/TransactionValidateRequestItemUpsertModal";
import { ITransactionValidateRequestFormValues } from "../TransactionValidateRequest/TransactionValidateRequestFormSchema";
import {
  ITransactionValidateRequestItemFormValues,
  ITransactionValidateRequestItemUpsertFormEvent,
} from "../TransactionValidateRequestItem/TransactionValidateRequestItemFormSchema";
import { useTransactionValidateRequestItemsColumns } from "./useTransactionValidateRequestItemsColumns";

export const TransactionValidateRequestItemForm = () => {
  const { t } = useTranslation("provider");
  const theme = useTheme();

  const form = useCustomFormContext<ITransactionValidateRequestFormValues>();
  const {
    control,
    isFormDisabled,
    formState: { errors },
  } = form;

  const { fields, append, remove, update } = useFieldArray<ITransactionValidateRequestFormValues, "items">({
    control,
    name: "items",
  });

  const onAddClick = () => {
    TransactionValidateRequestItemUpsertModalApi.open();
  };

  const onItemUpsertModalChange = (event: ITransactionValidateRequestItemUpsertFormEvent) => {
    if (event.type === "CREATE") {
      append(event.payload.values);
      TransactionValidateRequestItemUpsertModalApi.close();
    } else if (event.type === "UPDATE") {
      const updatedFieldIndex = fields.findIndex(field => field.id === event.payload.values.index);
      if (updatedFieldIndex > -1) {
        update(updatedFieldIndex, event.payload.values);
      }
      TransactionValidateRequestItemUpsertModalApi.close();
    }
  };

  const onEditRowClick = (row: FieldArrayWithId<ITransactionValidateRequestItemFormValues>) => {
    const item = fields.find(field => field.id === row.id) as ITransactionValidateRequestItemFormValues | undefined;
    if (item) {
      TransactionValidateRequestItemUpsertModalApi.open({ ...item, index: row.id });
    }
  };

  const onDeleteRowClick = (_: FieldArrayWithId<ITransactionValidateRequestItemFormValues>, index: number) => {
    remove(index);
  };

  return (
    <Grid container spacing={2}>
      <TransactionValidateRequestItemUpsertModal onChange={onItemUpsertModalChange} />

      <Grid item xs={12}>
        <Grid container>
          <Grid xs={8} lg={10}>
            <Typography fontSize={theme.mixins.fonts.fontSize.lg} fontWeight={theme.mixins.fonts.fontWeight.medium}>
              {t("Items")}
            </Typography>
          </Grid>

          {!isFormDisabled && (
            <Grid xs={4} lg={2}>
              <Button fullWidth onClick={onAddClick} startIcon={<PlusIcon />}>
                {t("Add Item")}
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {errors.items && (
          <Typography color='error' fontSize={theme.mixins.fonts.fontSize.sm}>
            {errors.items.message}
          </Typography>
        )}
      </Grid>

      <Grid item xs={12}>
        <CustomTable
          data={fields}
          columns={useTransactionValidateRequestItemsColumns()}
          isEditVisible
          isDeleteVisible
          isRowEditable={() => !isFormDisabled}
          isRowDeletable={() => !isFormDisabled}
          onEditRow={onEditRowClick}
          onDeleteRow={onDeleteRowClick}
          hasFooter={false}
          scrollable
        />
      </Grid>
    </Grid>
  );
};
