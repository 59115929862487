import { OptimaCreateDetailsModalApi } from "@/pages/OptimaVisitValidationRequest/modals/OptimaCreateAction/OptimaCreateAction";
import { OptimaUserAction } from "@/schema/types";
import { runningFrontEndAppUtils } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Box, Button, PlusIcon, Typography } from "@toolkit/ui";
import React from "react";
import { useOptimaSaveUserActionMutation } from "../../gql";
import { useOptimaVisitBadgeGroupStyles } from "./useOptimaVisitBadgeGroupStyles";

interface OptimaVisitBadgeGroupProps {
  passedItemsCount: number;
  itemsNeedReviewCount: number;
  visitId?: string;
  canAddDoctorsAction?: boolean;
}

export const OptimaVisitBadgeGroup: React.FC<OptimaVisitBadgeGroupProps> = ({
  passedItemsCount,
  itemsNeedReviewCount,
  visitId,
  canAddDoctorsAction,
}) => {
  const { classes } = useOptimaVisitBadgeGroupStyles();
  const { t } = useTranslation("domains");

  const [fetchOptimaSaveUserActionMutation] = useOptimaSaveUserActionMutation();

  const onCreateDoctorsActionModalOpen = () => {
    fetchOptimaSaveUserActionMutation({
      variables: {
        userAction: runningFrontEndAppUtils.isOptima()
          ? OptimaUserAction.OptimaDoctorMyActionsResolveAction
          : OptimaUserAction.OptimaNavigatorActiveClaimsVisitSendDoctorAction,
      },
    });

    OptimaCreateDetailsModalApi.open({ visitId: visitId! });
  };

  return (
    <Box className={classes.badgesWrapper}>
      <Box className={classes.badgeWrapper}>
        <Typography className={classes.badge} sx={{ backgroundColor: "success.main" }}>
          {passedItemsCount.toFixed(0)}
        </Typography>
        <Typography className={classes.badgeText}>{t("Passed")}</Typography>
        <Typography className={classes.badge} sx={{ backgroundColor: "error.main" }}>
          {itemsNeedReviewCount.toFixed(0)}
        </Typography>
        <Typography className={classes.badgeText}>{t("Items Need Review")}</Typography>
      </Box>
      {canAddDoctorsAction && (
        <Box className={classes.buttonWrapper}>
          <Button startIcon={<PlusIcon />} onClick={onCreateDoctorsActionModalOpen}>
            {t("Doctors Action")}
          </Button>
        </Box>
      )}
    </Box>
  );
};
