import { healthProviderGuidedCareTeamsPaths } from "@/pages/HealthProviderManagement/constants/HealthProvidersManagementPaths";
import { ProviderGuidedCareHealthProgramTeam } from "@/schema/types";
import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast, useBreadCrumbs } from "@toolkit/ui";
import { createGuidedCareTeamBreadcrumbs } from "pages/HealthProviderManagement/GuidedCare/constants";
import { GuidedCareTeamUpsertForm } from "pages/HealthProviderManagement/GuidedCare/forms/TeamUpsert/GuidedCareTeamUpsertForm";
import {
  GuidedCareTeamCreateMutation,
  useGuidedCareProgramListQuery,
  useGuidedCareTeamCreateMutation,
  useGuidedCareTeamGetQuery,
} from "pages/HealthProviderManagement/GuidedCare/gql";
import { convertTeamFormValuesToBackEndValues } from "pages/HealthProviderManagement/GuidedCare/others";
import { IGuidedCareTeamUpsertFormEvent } from "pages/HealthProviderManagement/GuidedCare/types";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useOidcUserProfile } from "shared/hooks/useOidcUserProfile";

export const GuidedCareTeamCreateContainer = () => {
  const { succeeded, failed } = useAddToast();
  const navigate = useNavigate();
  const { t } = useTranslation("provider");
  const { setBreadCrumb } = useBreadCrumbs();

  const { userInfo } = useOidcUserProfile();

  const [params] = useSearchParams();
  const teamId = params.get("teamId");

  const { data, loading } = useGuidedCareTeamGetQuery({
    variables: {
      id: teamId!,
    },
    skip: !teamId,
    fetchPolicy: "no-cache",
  });

  const team = data?.providerGuidedCareHealthProgramTeam as ProviderGuidedCareHealthProgramTeam;

  const { data: programData, loading: isProgramLoading } = useGuidedCareProgramListQuery({
    variables: {
      filter: {
        name: data?.providerGuidedCareHealthProgramTeam?.guidedCareHealthProgram?.name!,
      },
    },
    skip: !teamId || !data,
    fetchPolicy: "no-cache",
  });

  const program = programData?.guidedCareHealthPrograms?.edges?.[0]?.node;

  const [fetchGuidedCareTeamCreateMutation, { loading: isSubmitting }] = useGuidedCareTeamCreateMutation({
    onCompleted: (mutationData: GuidedCareTeamCreateMutation) => {
      const errors = mutationData?.providerGuidedCareHealthProgramTeamCreate?.providerGuidedCareHealthProgramTeamErrors;

      if (errors?.length) {
        failed(t(formatMessageErrors(errors)));
      } else {
        succeeded(t("Care Team created successfully"));
        navigate(healthProviderGuidedCareTeamsPaths.main.fullPath);
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  const onGuidedCareTeamUpsertFormChange = (event: IGuidedCareTeamUpsertFormEvent) => {
    if (event.type === "SUBMIT") {
      const values = convertTeamFormValuesToBackEndValues(event.payload.values, userInfo?.vendor_id);

      fetchGuidedCareTeamCreateMutation({
        variables: {
          input: values,
        },
      });
    }
  };

  useEffect(() => {
    const { title, main, create } = createGuidedCareTeamBreadcrumbs(t);
    setBreadCrumb({ title, values: [main, create] });
  }, [setBreadCrumb, t]);

  return (
    <GuidedCareTeamUpsertForm
      submitButtonLabel={t("Create")}
      team={team}
      program={program}
      isLoading={loading || isProgramLoading || isSubmitting}
      onChange={onGuidedCareTeamUpsertFormChange}
    />
  );
};
