import { BACKEND_DATE_TIME_FORMAT, useCustomForm } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FormDateTimePickerField, FormTextField, Grid } from "@toolkit/ui";
import { ForwardRefRenderFunction, forwardRef, useEffect, useImperativeHandle } from "react";
import { FormProvider, UseFormReturn } from "react-hook-form";
import {
  ITransactionValidateRequestAuditFormValues,
  ITransactionValidateRequestAuditUpsertFormEvent,
  transactionValidateRequestAuditFormDefaultValues,
  transactionValidateRequestAuditFormSchema,
} from "./TransactionValidateRequestAuditFormSchema";

type TransactionValidateRequestAuditUpsertFormProps = {
  audit?: ITransactionValidateRequestAuditFormValues;
  index?: string;
  onChange: (event: ITransactionValidateRequestAuditUpsertFormEvent) => void;
};

export type TransactionValidateRequestAuditUpsertFormRef = {
  getForm: () => UseFormReturn<ITransactionValidateRequestAuditFormValues>;
  submit: () => void;
};

const TransactionValidateRequestAuditUpsertFormForwardRef: ForwardRefRenderFunction<
  TransactionValidateRequestAuditUpsertFormRef,
  TransactionValidateRequestAuditUpsertFormProps
> = (props, ref) => {
  const { audit, index, onChange } = props;

  const { t } = useTranslation("provider");

  const form = useCustomForm<ITransactionValidateRequestAuditFormValues>({
    defaultValues: transactionValidateRequestAuditFormDefaultValues,
    schema: transactionValidateRequestAuditFormSchema,
  });

  const { setValues, handleSubmit } = form;

  const onSubmit = (values: ITransactionValidateRequestAuditFormValues) => {
    if (audit) {
      if (index) {
        onChange({
          type: "UPDATE",
          payload: { values: { ...values, index } },
        });
      }
    } else {
      onChange({
        type: "CREATE",
        payload: { values },
      });
    }
  };

  useImperativeHandle(ref, () => ({
    getForm: () => form,
    submit: handleSubmit(onSubmit),
  }));

  useEffect(() => {
    if (audit) {
      setValues(audit);
    }
  }, [audit, setValues]);

  return (
    <FormProvider {...form}>
      <form>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormTextField name='sequence' label={t("Sequence")} placeholder={t("Sequence")} />
          </Grid>
          <Grid item xs={12}>
            <FormTextField name='userName' label={t("User Name")} placeholder={t("User Name")} />
          </Grid>
          <Grid item xs={12}>
            <FormTextField name='source' label={t("Source")} placeholder={t("Source")} />
          </Grid>
          <Grid item xs={12}>
            <FormTextField name='reference' label={t("Reference")} placeholder={t("Reference")} />
          </Grid>
          <Grid item xs={12}>
            <FormTextField name='details' label={t("Details")} placeholder={t("Details")} />
          </Grid>
          <Grid item xs={12}>
            <FormDateTimePickerField format={BACKEND_DATE_TIME_FORMAT} name='created' label={t("Created")} placeholder={t("Created")} />
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export const TransactionValidateRequestAuditUpsertForm = forwardRef(TransactionValidateRequestAuditUpsertFormForwardRef);
