import { OptimaUserAction } from "@/schema/types";
import { runningFrontEndAppUtils, useOpenState } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Box, Button, Collapse, Divider, Typography } from "@toolkit/ui";
import { FC } from "react";
import { useOptimaSaveUserActionMutation } from "../../gql";
import { usePossibleSuggestedFixesStyle } from "./usePossibleSuggestedFixesStyle";

interface PossibleSuggestedFixesProps {
  possibleFixes: string[];
}
export const PossibleSuggestedFixes: FC<PossibleSuggestedFixesProps> = props => {
  const { possibleFixes } = props;
  const { open, handleToggle } = useOpenState();
  const { t } = useTranslation("provider");
  const { classes, cx } = usePossibleSuggestedFixesStyle();
  const [fetchOptimaSaveUserActionMutation] = useOptimaSaveUserActionMutation();

  const onToggle = () => {
    if (open) {
      fetchOptimaSaveUserActionMutation({
        variables: {
          userAction: runningFrontEndAppUtils.isOptima()
            ? OptimaUserAction.OptimaDoctorVisitAiEditOpenShowMore
            : OptimaUserAction.OptimaNavigatorActiveClaimsVisitAiEditOpenShowMore,
        },
      });
    }
    handleToggle();
  };
  return (
    <>
      <Box className={classes.root}>
        <Box className={classes.headerWrapper}>
          <Typography className={classes.header}>
            {t("Historically the insurance had rejected similar activities, accordingly consider the following:")}
          </Typography>
          <Button variant='text' onClick={onToggle} className={classes.showButton}>
            {open ? t("show less") : t("show more")}
          </Button>
        </Box>
        {open && <Divider orientation='horizontal' className={cx(classes.divider, classes.dividerOpen)} color='error.200' />}
        <Collapse in={open}>
          <Box className={classes.possibleFixesWrapper}>
            {possibleFixes.map((fix, index, arr) => (
              <Box className={classes.possibleFix} key={index}>
                <Typography className={classes.fixText}>{fix}</Typography>
                {index < arr.length - 1 && <Divider orientation='horizontal' className={classes.divider} color='error.200' />}
              </Box>
            ))}
          </Box>
        </Collapse>
      </Box>
    </>
  );
};
