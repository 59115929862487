import { BACKEND_DATE_TIME_FORMAT, useCustomFormContext } from "@toolkit/core";
import { get } from "lodash";
import React, { FC } from "react";
import { CustomDateTimePickerField, CustomDateTimePickerProps } from "../CustomDateTimePicker";

type FormDateTimePickerFieldProps = Omit<
  CustomDateTimePickerProps,
  "defaultValue" | "error" | "helperText" | "type" | "control" | "controllerProps"
>;

export const FormDateTimePickerField: FC<FormDateTimePickerFieldProps> = props => {
  const {
    name,
    variant = "filled",
    size = "small",
    fullWidth = true,
    disabled,
    format = BACKEND_DATE_TIME_FORMAT,
    inputFormat = "MM/DD/yyyy HH:mm",
    ...rest
  } = props;

  const {
    control,
    register,
    isFormDisabled,
    formState: { errors },
  } = useCustomFormContext();

  const error = get(errors, name + ".message", "") as string;

  return (
    <CustomDateTimePickerField
      {...rest}
      control={control}
      {...register(name)}
      variant={variant}
      size={size}
      format={format}
      inputFormat={inputFormat}
      fullWidth={fullWidth}
      error={!!error}
      helperText={error}
      disabled={isFormDisabled || disabled}
      name={name}
    />
  );
};
