import { ZoomConfiguration } from "@/schema/types";
import { formGirdBreakPoints, useCustomFormContext } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Grid, TextField } from "@toolkit/ui";
import { FC } from "react";
import { IVirtualIntegrationFormValues } from "./VirtualIntegrationFormSchema";

type ZoomConfigurationFormProps = {
  zoomConfigurations: ZoomConfiguration | undefined;
};
export const ZoomConfigurationForm: FC<ZoomConfigurationFormProps> = ({ zoomConfigurations }) => {
  const { t } = useTranslation("provider");
  const {
    register,
    formState: { errors },
  } = useCustomFormContext<IVirtualIntegrationFormValues>();

  return (
    <>
      <Grid container spacing={2}>
        <Grid item {...formGirdBreakPoints}>
          <TextField
            defaultValue={zoomConfigurations?.apiKey}
            label={t("Api Key")}
            fullWidth
            {...register("zoomConfiguration.apiKey")}
            error={Boolean(errors?.zoomConfiguration?.apiKey?.message)}
            helperText={errors?.zoomConfiguration?.apiKey?.message}
          />
        </Grid>
        <Grid item {...formGirdBreakPoints}>
          <TextField
            label={t("Api Secret")}
            fullWidth
            {...register("zoomConfiguration.apiSecret")}
            error={Boolean(errors?.zoomConfiguration?.apiSecret?.message)}
            helperText={errors?.zoomConfiguration?.apiSecret?.message}
          />
        </Grid>
        <Grid item {...formGirdBreakPoints}>
          <TextField
            defaultValue={zoomConfigurations?.sdkKey}
            label={t("Sdk Key")}
            fullWidth
            {...register("zoomConfiguration.sdkKey")}
            error={Boolean(errors?.zoomConfiguration?.sdkKey?.message)}
            helperText={errors?.zoomConfiguration?.sdkKey?.message}
          />
        </Grid>
        <Grid item {...formGirdBreakPoints}>
          <TextField
            label={t("Sdk Secret")}
            fullWidth
            {...register("zoomConfiguration.sdkSecret")}
            error={Boolean(errors?.zoomConfiguration?.sdkSecret?.message)}
            helperText={errors?.zoomConfiguration?.sdkSecret?.message}
          />
        </Grid>
        <Grid item {...formGirdBreakPoints}>
          <TextField
            defaultValue={zoomConfigurations?.secretToken}
            label={t("Secret Token")}
            fullWidth
            {...register("zoomConfiguration.secretToken")}
            error={Boolean(errors?.zoomConfiguration?.secretToken?.message)}
            helperText={errors?.zoomConfiguration?.secretToken?.message}
          />
        </Grid>
        <Grid item {...formGirdBreakPoints}>
          <TextField
            label={t("Secret Key")}
            fullWidth
            {...register("zoomConfiguration.secretKey")}
            error={Boolean(errors?.zoomConfiguration?.secretKey?.message)}
            helperText={errors?.zoomConfiguration?.secretKey?.message}
          />
        </Grid>
        <Grid item {...formGirdBreakPoints}>
          <TextField
            label={t("Url")}
            fullWidth
            {...register("zoomConfiguration.url")}
            error={Boolean(errors?.zoomConfiguration?.url?.message)}
            helperText={errors?.zoomConfiguration?.url?.message}
          />
        </Grid>
      </Grid>
    </>
  );
};
