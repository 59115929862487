import { useCustomForm } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Box, FormTextField } from "@toolkit/ui";
import { forwardRef, ForwardRefRenderFunction, useImperativeHandle } from "react";
import { FormProvider } from "react-hook-form";
import { NoteFormSchema, INoteFormValues, noteFormDefaultValues } from "./NoteFormSchema";

type NoteFormProps = {
  label?: string;
  onChange?: (values: INoteFormValues) => void;
  placeholder?: string;
};

export type NoteFormRef = {
  submit: () => void;
};

const NoteFormForwardRef: ForwardRefRenderFunction<NoteFormRef, NoteFormProps> = (props, ref) => {
  const { t } = useTranslation("provider");
  const { onChange, label = t("Note"), placeholder = t("Enter your note here...") } = props;

  const form = useCustomForm<INoteFormValues>({
    schema: NoteFormSchema,
    defaultValues: noteFormDefaultValues,
    mode: "onChange",
  });

  const handleSubmit = (values: INoteFormValues) => {
    onChange?.(values);
  };

  useImperativeHandle(ref, () => ({
    submit: () => form.handleSubmit(handleSubmit)(),
  }));

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(handleSubmit)}>
        <Box>
          <FormTextField name={"note"} label={t(label)} InputLabelProps={{ shrink: true }} placeholder={placeholder} multiline rows={4} />
        </Box>
      </form>
    </FormProvider>
  );
};

export const NoteForm = forwardRef(NoteFormForwardRef);
