import { useEffect, useMemo, useState } from "react";
import { useActivityBasedDoctorsQuery } from "../gql/queries";
import { useBreadCrumbs, useGridFilter } from "@toolkit/ui";
import { useTranslation } from "@toolkit/i18n";

export const useOptimaWidgetHook = () => {
  const { setBreadCrumb } = useBreadCrumbs();
  const { t } = useTranslation("provider");

  const pageSize = 3;
  const date = new Date();
  const initialDateRange = {
    toDate: date.toISOString().split("T")[0],
    fromDate: new Date(date.setDate(date.getDate() - 7)).toISOString().split("T")[0],
  };

  const [input, setInput] = useState<{
    first: number;
    after: string | null;
    before: string | null;
    last: number | null;
    fromDate: string;
    toDate: string;
  }>({
    first: pageSize,
    after: null,
    before: null,
    last: null,
    ...initialDateRange,
  });

  const { data: dataWithActivity, refetch } = useActivityBasedDoctorsQuery({
    variables: {
      ...input,
      doctorsWithActivities: true,
    },
  });

  const { data: dataWithoutActivity, refetch: refetchData } = useActivityBasedDoctorsQuery({
    variables: {
      ...input,
      doctorsWithActivities: false,
    },
  });

  const totalWithActivity = dataWithActivity?.activityBasedDoctors?.totalCount || 0;
  const totalWithoutActivity = dataWithoutActivity?.activityBasedDoctors?.totalCount || 0;

  const chartData = useMemo(
    () => [
      {
        value: totalWithActivity,
        currentData: true,
        date: input.fromDate,
        unit: "Doctors",
        source: "Activity",
      },
      {
        value: totalWithoutActivity,
        currentData: false,
        date: input.fromDate,
        unit: "Doctors",
        source: "No Activity",
      },
    ],
    [totalWithActivity, totalWithoutActivity, input.fromDate]
  );
  const handleFilter = filter => {
    const newDateRange = { fromDate: filter.fromDate, toDate: filter.toDate };
    setInput({
      first: pageSize,
      after: null,
      before: null,
      last: null,
      ...newDateRange,
    });

    refetch({
      first: pageSize,
      after: null,
      before: null,
      last: null,
      ...newDateRange,
      doctorsWithActivities: true,
    });

    refetchData({
      first: pageSize,
      after: null,
      before: null,
      last: null,
      ...newDateRange,
      doctorsWithActivities: false,
    });
  };

  const handleGotoNext = () => {
    setInput(prev => ({
      ...prev,
      first: pageSize,
      after: dataWithActivity?.activityBasedDoctors?.pageInfo?.endCursor || null,
      last: null,
      before: null,
    }));
  };

  const handleGoToPrevious = () => {
    setInput(prev => ({
      ...prev,
      last: pageSize,
      before: dataWithActivity?.activityBasedDoctors?.pageInfo?.startCursor || null,
      first: 0,
      after: null,
    }));
  };

  const { activeFiltersProps, staticFiltersProps } = useGridFilter({
    doFilter: handleFilter,
    filterInput: {
      fromDate: initialDateRange.fromDate,
      toDate: initialDateRange.toDate,
    },
  });

  useEffect(() => {
    setBreadCrumb({ title: t("Optima Widget"), values: [] });
  }, [setBreadCrumb, t]);

  return {
    activeFiltersProps,
    staticFiltersProps,
    chartData,
    totalWithActivity,
    totalWithoutActivity,
    dataWithActivity,
    dataWithoutActivity,
    handleGotoNext,
    handleGoToPrevious,
  };
};
