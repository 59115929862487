import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ApproveManualOrderMutationVariables = Types.Exact<{
  orderId: Types.Scalars['ID']['input'];
  note?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type ApproveManualOrderMutation = { __typename?: 'Mutation', approveManualOrder?: { __typename?: 'ManualOrder', id: string, status: Types.ManualOrderStatus } | null };


export const ApproveManualOrderDocument = gql`
    mutation ApproveManualOrder($orderId: ID!, $note: String) {
  approveManualOrder(orderId: $orderId, note: $note) {
    id
    status
  }
}
    `;
export type ApproveManualOrderMutationFn = Apollo.MutationFunction<ApproveManualOrderMutation, ApproveManualOrderMutationVariables>;

/**
 * __useApproveManualOrderMutation__
 *
 * To run a mutation, you first call `useApproveManualOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveManualOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveManualOrderMutation, { data, loading, error }] = useApproveManualOrderMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *      note: // value for 'note'
 *   },
 * });
 */
export function useApproveManualOrderMutation(baseOptions?: Apollo.MutationHookOptions<ApproveManualOrderMutation, ApproveManualOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApproveManualOrderMutation, ApproveManualOrderMutationVariables>(ApproveManualOrderDocument, options);
      }
export type ApproveManualOrderMutationHookResult = ReturnType<typeof useApproveManualOrderMutation>;
export type ApproveManualOrderMutationResult = Apollo.MutationResult<ApproveManualOrderMutation>;
export type ApproveManualOrderMutationOptions = Apollo.BaseMutationOptions<ApproveManualOrderMutation, ApproveManualOrderMutationVariables>;