import { useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps } from "@toolkit/ui";
import { useMemo } from "react";
import { ITransactionValidateRequestAuditFormValues } from "../TransactionValidateRequestAudit/TransactionValidateRequestAuditFormSchema";

export const useTransactionValidateRequestAuditsColumns = (): CustomTableColumnProps<ITransactionValidateRequestAuditFormValues>[] => {
  const { t } = useTranslation("provider");
  return useMemo(() => {
    return [
      {
        key: "sequence",
        header: t("Sequence"),
        accessor: ({ sequence }) => sequence,
      },
      {
        key: "userName",
        header: t("User Name"),
        accessor: ({ userName }) => userName,
      },
      {
        key: "source",
        header: t("Source"),
        accessor: ({ source }) => source,
      },
      {
        key: "reference",
        header: t("Reference"),
        accessor: ({ reference }) => reference,
      },
      {
        key: "details",
        header: t("Details"),
        type: "truncated-text",
        accessor: ({ details }) => details,
      },
      {
        key: "created",
        header: t("Created"),
        type: "datetime",
        accessor: ({ created }) => created,
      },
    ];
  }, [t]);
};
