import { ProviderSettingsInputForPayment } from "@/schema/types";
import { formatGraphQLError } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import { useForm } from "react-hook-form";
import {
  ProviderSettingsForPaymentDocument,
  useGetVendorIdQuery,
  useProviderSettingsForPaymentQuery,
  useSaveProviderSettingsForPaymentMutation,
} from "../gql";

export const useSettingsForPaymentForm = () => {
  const { t } = useTranslation("provider");
  const { succeeded, failed } = useAddToast();

  const methods = useForm();
  const { handleSubmit } = methods;

  const { data } = useGetVendorIdQuery();
  const id = data?.me?.vendor?.id;

  const { data: providerPaymentData, loading: isLoadingData } = useProviderSettingsForPaymentQuery({
    variables: {
      vendorId: id!,
    },
    skip: !id,
  });
  const [saveProviderSettingsForPaymentMutation, { loading }] = useSaveProviderSettingsForPaymentMutation({
    onCompleted: response => {
      if (response?.saveProviderSettingsForPayment) {
        succeeded(t("Health Programs Site Settings Updated Successfully"));
      }
    },
    onError: ({ graphQLErrors }) => failed(formatGraphQLError(graphQLErrors)),
    refetchQueries: [
      {
        query: ProviderSettingsForPaymentDocument,
        variables: {
          vendorId: id,
        },
      },
    ],
  });

  const onSubmit = (input: ProviderSettingsInputForPayment) => {
    const request: ProviderSettingsInputForPayment = {
      hasOwnPaymentGateway: input.hasOwnPaymentGateway,
      paymentApiAccessKey: input.paymentApiAccessKey,
      paymentCheckoutEndPointUrl: input.paymentCheckoutEndPointUrl,
      paymentRefundEndPointUrl: input.paymentRefundEndPointUrl,
    };

    saveProviderSettingsForPaymentMutation({
      variables: {
        input: request,
      },
    });
  };

  const providerPayment = providerPaymentData?.vendor?.providerSettingsForPayment;

  return {
    handleSubmit,
    isLoading: isLoadingData || loading,
    methods,
    onSubmit,
    providerPayment,
  };
};
