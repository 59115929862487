import { useOpenState } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Button, CustomDrawer, Divider, Typography, useTheme } from "@toolkit/ui";
import { FC } from "react";
import { EligibilityScanResultWidget } from "../EligibilityScanResultWidget/EligibilityScanResultWidget";

export const EligibilityScanResultModal: FC<{ id: string; patientNationalId: string }> = props => {
  const { t } = useTranslation("optima");
  const theme = useTheme();
  const { id, patientNationalId } = props;
  const { open: isOpen, handleClose, handleOpen } = useOpenState();
  return (
    <>
      <Button onClick={handleOpen} variant='contained' color='primary'>
        {t("Show Details")}
      </Button>
      <CustomDrawer
        PaperProps={{
          sx: {
            padding: theme.spacing(2),
          },
        }}
        isOpen={isOpen}
        onClose={handleClose}
      >
        <Typography fontSize={theme.mixins.fonts.fontSize.xl} fontWeight={theme.mixins.fonts.fontWeight.semiBold}>
          {t("Eligibility Scan Result")}
        </Typography>
        <Divider sx={{ mx: 1, mb: 2 }} />
        {isOpen && id && <EligibilityScanResultWidget id={id} nationalId={patientNationalId} />}
      </CustomDrawer>
    </>
  );
};
