import { Department, VendorDepartmentOrderField } from "@/schema/types";
import { getBranchesAutocompleteFilter } from "@health/autocompletes";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps } from "@toolkit/ui";
import { useMemo } from "react";
import { DepartmentQRCode } from "../../components/DepartmentQRCode/DepartmentQRCode";
import { getDepartmentMedicalServiceInfoItems } from "../../others/cellInfo.utils";

export const useDepartmentListContainerColumns = (): CustomTableColumnProps<Department>[] => {
  const { t } = useTranslation("provider");

  return useMemo(
    () => [
      {
        key: "name",
        header: t("Name"),
        accessor: "name",
        sort: {
          columnEnum: VendorDepartmentOrderField.Name,
        },
        filter: {
          type: "string",
          name: "name_Icontains",
        },
      },
      {
        key: "code",
        header: t("Code"),
        accessor: "code",
        sort: {
          columnEnum: VendorDepartmentOrderField.Code,
        },
        filter: {
          type: "string",
          name: "code",
        },
      },
      {
        key: "branch",
        header: t("Branch"),
        accessor: ({ branch }) => pickLocalizedValue(branch?.name, branch?.nameAr),
        sort: {
          columnEnum: VendorDepartmentOrderField.Branch,
        },
        filter: getBranchesAutocompleteFilter({ name: "branch" }),
      },
      {
        key: "medicalServices",
        header: t("Medical Services"),
        type: "info",
        infoCellOptions: {
          title: t("Department Medical Services"),
          items: ({ medicalServices }) => getDepartmentMedicalServiceInfoItems(medicalServices?.edges || []),
          emptyMessage: t("There is No Medical Services"),
          disableShowButton: ({ medicalServices }) => !medicalServices?.edges?.length,
          layout: "one-column",
        },
      },
      {
        key: "qrCode",
        header: t("QR Code"),
        accessor: ({ id, name }) => <DepartmentQRCode id={id} name={name} />,
      },
    ],
    [t]
  );
};
