import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OptimaPayerCredentialCreateMutationVariables = Types.Exact<{
  input: Types.PayerCredentialsInput;
}>;


export type OptimaPayerCredentialCreateMutation = { __typename?: 'Mutation', payerCredentialsCreate?: { __typename?: 'PayerCredentials', id: string } | null };


export const OptimaPayerCredentialCreateDocument = gql`
    mutation OptimaPayerCredentialCreate($input: PayerCredentialsInput!) {
  payerCredentialsCreate(input: $input) {
    id
  }
}
    `;
export type OptimaPayerCredentialCreateMutationFn = Apollo.MutationFunction<OptimaPayerCredentialCreateMutation, OptimaPayerCredentialCreateMutationVariables>;

/**
 * __useOptimaPayerCredentialCreateMutation__
 *
 * To run a mutation, you first call `useOptimaPayerCredentialCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOptimaPayerCredentialCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [optimaPayerCredentialCreateMutation, { data, loading, error }] = useOptimaPayerCredentialCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOptimaPayerCredentialCreateMutation(baseOptions?: Apollo.MutationHookOptions<OptimaPayerCredentialCreateMutation, OptimaPayerCredentialCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OptimaPayerCredentialCreateMutation, OptimaPayerCredentialCreateMutationVariables>(OptimaPayerCredentialCreateDocument, options);
      }
export type OptimaPayerCredentialCreateMutationHookResult = ReturnType<typeof useOptimaPayerCredentialCreateMutation>;
export type OptimaPayerCredentialCreateMutationResult = Apollo.MutationResult<OptimaPayerCredentialCreateMutation>;
export type OptimaPayerCredentialCreateMutationOptions = Apollo.BaseMutationOptions<OptimaPayerCredentialCreateMutation, OptimaPayerCredentialCreateMutationVariables>;