import { PatientHealthParameter } from "@/schema/types";
import { sourceOptionsMap } from "@health/enum-options";
import { useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps, Typography, useTheme } from "@toolkit/ui";
import { useMemo } from "react";

export const useVitalSignsColumns = (): CustomTableColumnProps<PatientHealthParameter>[] => {
  const { t } = useTranslation("provider");
  const theme = useTheme();
  return useMemo(() => {
    return [
      {
        key: "value",
        header: t("Value"),
        accessor: row =>
          row?.valueNumber ? (
            <Typography fontSize={theme.mixins.fonts.fontSize.xs} fontWeight={theme.mixins.fonts.fontWeight.medium}>
              {row?.valueNumber}
            </Typography>
          ) : undefined,
      },
      {
        key: "date",
        header: t("Date"),
        type: "datetime",
        accessor: "createdDate",
      },
      {
        key: "source",
        header: t("Source"),
        accessor: ({ source }) => sourceOptionsMap[source!]?.label,
      },
    ];
  }, [t]);
};
