import { PatientHealthParameterFilterInput } from "@/schema/types";
import { CustomTable, Grid } from "@toolkit/ui";
import React, { FC } from "react";
import { usePatientHealthParametersHistory } from "../../hooks";
import { PatientHealthParameterChartLineZoom } from "../PatientHealthParameterChartLineZoom/PatientHealthParameterChartLineZoom";
import { usePatientHealthParameterHistoryNumericColumns } from "./usePatientHealthParameterHistoryNumericColumns";

type PatientHealthParameterHistoryAudioProps = {
  token?: string;
  filter: PatientHealthParameterFilterInput;
};

export const PatientHealthParameterHistoryNumeric: FC<PatientHealthParameterHistoryAudioProps> = props => {
  const { token, filter } = props;

  const { selectedItem, loading, tableProps, handleSelectItem } = usePatientHealthParametersHistory({
    token,
    filter,
  });

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {!!selectedItem?.valueNumericList?.length && (
          <PatientHealthParameterChartLineZoom dataSets={[{ data: selectedItem?.valueNumericList }]} isLoading={loading} />
        )}
      </Grid>

      <Grid item xs={12}>
        <CustomTable
          {...tableProps}
          columns={usePatientHealthParameterHistoryNumericColumns({ selectedItem, onChange: handleSelectItem })}
        />
      </Grid>
    </Grid>
  );
};
