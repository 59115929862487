import { LikeDislike, OptimaAction, OptimaEdit, OptimaUserAction } from "@/schema/types";
import { priorityOptionsMap } from "@health/enum-options";
import { runningFrontEndAppUtils } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Box, Button, Card, CircularProgress, IconButton, ThumbDownIcon, ThumbUpIcon, Typography } from "@toolkit/ui";
import { FC } from "react";
import { useOptimaSaveUserActionMutation } from "../../gql";
import { OptimaEditActionsModalApi } from "../../modals/OptimaEditActions/OptimaEditActionsModal";
import { OptimaEditDetailsModalApi } from "../../modals/OptimaEditDetails/OptimaEditDetailsModal";
import { getOptimaEditPropertiesByPriority } from "../../others";
import { useOptimaEditCardStyle } from "./useOptimaEditCardStyle";

type OptimaEditCardProps = {
  display?: string;
  edit?: OptimaEdit | null;
  likeStatus?: LikeDislike | null;
  isLikeStatusUpdating?: boolean;
  isLikeDislikeEnabled?: boolean;
  onLikeClick: () => void;
  onDislikeClick: () => void;
};

export const OptimaEditCard: FC<OptimaEditCardProps> = props => {
  const { display, edit, likeStatus, isLikeStatusUpdating, onLikeClick, onDislikeClick, isLikeDislikeEnabled } = props;
  const { t } = useTranslation("provider");
  const isOptima = runningFrontEndAppUtils.isOptima();
  // const isProvider = runningFrontEndAppUtils.isProvider();
  const { classes, theme } = useOptimaEditCardStyle();
  const [fetchOptimaSaveUserActionMutation] = useOptimaSaveUserActionMutation();

  const optimaEditProperties = getOptimaEditPropertiesByPriority(edit?.priority!, theme);

  const onFixClick = () => {
    OptimaEditActionsModalApi.open({ actions: (edit?.actions || []) as OptimaAction[] });
    fetchOptimaSaveUserActionMutation({
      variables: {
        userAction: isOptima
          ? OptimaUserAction.OptimaDoctorVisitEditOpenAction
          : OptimaUserAction.OptimaNavigatorActiveClaimsVisitOpenEditAction,
      },
    });
  };

  const onSeeEvidenceClick = () => {
    fetchOptimaSaveUserActionMutation({
      variables: {
        userAction: isOptima
          ? OptimaUserAction.OptimaDoctorVisitEditOpenEvidence
          : OptimaUserAction.OptimaNavigatorActiveClaimsVisitOpenEditEvidence,
      },
    });

    OptimaEditDetailsModalApi.open({ display: display!, edit: edit as OptimaEdit });
  };

  return (
    <Card className={classes.root}>
      <Box className={classes.wrapper} sx={{ background: optimaEditProperties?.color }}>
        {optimaEditProperties?.icon}

        <Box className={classes.infoWrapper}>
          <Typography className={classes.category}>
            {edit?.category} - {edit?.subCategory} ({priorityOptionsMap[edit?.priority!]?.label})
          </Typography>

          <Typography className={classes.message}>{edit?.message}</Typography>

          <Box className={classes.buttonsWrapper}>
            <Button className={classes.fixButton} onClick={onFixClick}>
              {t("Actions")}
            </Button>

            <Button variant='outlined' className={classes.seeEvidenceButton} onClick={onSeeEvidenceClick}>
              {t("See Evidence")}
            </Button>
            {isLikeDislikeEnabled && (
              <Box className={classes.likeDislikeButtonsWrapper}>
                <IconButton onClick={onLikeClick} sx={{ fontSize: 12 }}>
                  {isLikeStatusUpdating ? (
                    <CircularProgress size={12} />
                  ) : (
                    <ThumbUpIcon fontSize='small' color={likeStatus === LikeDislike.Like ? "primary" : "disabled"} />
                  )}
                </IconButton>

                <IconButton onClick={onDislikeClick} sx={{ fontSize: 12 }}>
                  {isLikeStatusUpdating ? (
                    <CircularProgress size={12} />
                  ) : (
                    <ThumbDownIcon fontSize='small' color={likeStatus === LikeDislike.Dislike ? "error" : "disabled"} />
                  )}
                </IconButton>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Card>
  );
};
