import { ProviderGuidedCareHealthProgramTeam } from "@/schema/types";
import { useTranslation } from "@toolkit/i18n";
import { FilterGrid, MainListActions, PageWrapper, TableGrid, TableSettingComponent, useBreadCrumbs } from "@toolkit/ui";
import { createGuidedCareTeamBreadcrumbs } from "pages/HealthProviderManagement/GuidedCare/constants";
import { useGuidedCareTeamListQuery } from "pages/HealthProviderManagement/GuidedCare/gql";
import { healthProviderGuidedCareTeamsPaths } from "pages/HealthProviderManagement/constants/HealthProvidersManagementPaths";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { GridProvider } from "shared/components";
import { useGuidedCareTeamListTableColumns } from "./useGuidedCareTeamListTableColumns";

export const GuidedCareTeamListContainer = () => {
  const { t } = useTranslation("provider");
  const navigate = useNavigate();
  const { setBreadCrumb } = useBreadCrumbs();

  const onAddNewItemClick = () => {
    navigate(healthProviderGuidedCareTeamsPaths.new.fullPath);
  };

  const onEditRowClick = (item: ProviderGuidedCareHealthProgramTeam) => {
    navigate(healthProviderGuidedCareTeamsPaths.edit.fullPathWithParams({ teamId: item?.id }));
  };

  useEffect(() => {
    setBreadCrumb({ title: createGuidedCareTeamBreadcrumbs(t).title });
  }, [setBreadCrumb, t]);

  return (
    <GridProvider
      gridName='guidedCareTeamList'
      columns={useGuidedCareTeamListTableColumns()}
      query={useGuidedCareTeamListQuery}
      hasTableSetting
      skipUrlState={false}
      tableAction={{
        isEditVisible: true,
        isDeleteVisible: false,
        onEditRow: onEditRowClick,
      }}
    >
      <PageWrapper
        filters={<FilterGrid />}
        actions={
          <>
            <TableSettingComponent />
            <MainListActions hasAddNew onAddNewItem={onAddNewItemClick} />
          </>
        }
      >
        <TableGrid />
      </PageWrapper>
    </GridProvider>
  );
};
