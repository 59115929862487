import { ExtractNodeType } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { Box, ValueWithLabel } from "@toolkit/ui";
import { OptimaClaimsListQuery } from "@/pages/OptimaClaims/gql";
import { FC } from "react";
import { useRemittanceDrawerStyles } from "../RemittanceDrawer/RemittanceDrawerStyles";

type GeneralInformationProps = {
  claim: ExtractNodeType<OptimaClaimsListQuery>;
};

export const ClaimGeneralInformation: FC<GeneralInformationProps> = ({ claim }) => {
  const { classes } = useRemittanceDrawerStyles();
  const { t } = useTranslation("domains");

  return (
    <Box className={classes.sectionContainer}>
      <ValueWithLabel
        labelProps={{ className: classes.label }}
        valueProps={{ className: classes.value }}
        label={t("Transaction ID")}
        value={claim.transactionId || "-"}
      />
      <ValueWithLabel
        labelProps={{ className: classes.label }}
        valueProps={{ className: classes.value }}
        label={t("Patient ID")}
        value={claim.patientId || "-"}
      />
      <ValueWithLabel
        labelProps={{ className: classes.label }}
        valueProps={{ className: classes.value }}
        label={t("Member ID")}
        value={claim.memberId || "-"}
      />
      <ValueWithLabel
        labelProps={{ className: classes.label }}
        valueProps={{ className: classes.value }}
        label={t("Emirate ID")}
        value={claim.emiratesIDNumber || "-"}
      />
    </Box>
  );
};
