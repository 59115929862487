/* eslint-disable max-lines */
import { GuidedCareJourneyIntervention, GuidedCareJourneyStatus, HealthHistoryType } from "@/schema/types";
import { IGuidedCareJourneyInterventionsActivitiesEvent } from "@/shared/modules/patient/components/GuidedCareJourneyInterventions/GuidedCareJourneyInterventionsActivities/GuidedCareJourneyInterventionsActivities";
import {
  GuidedCareJourneyInterventionsActivitiesModal,
  GuidedCareJourneyInterventionsActivitiesModalApi,
} from "@/shared/modules/patient/modals/GuidedCareJourneyInterventionsActivities/GuidedCareJourneyInterventionsActivitiesModal";
import { formatGraphQLError } from "@toolkit/apollo";
import { formatUserNameFull, getMediaLink } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Avatar, AvatarGroup, Box, CustomIcon, Grid, IconButton, Typography, useAddToast, useMediaQuery, useTheme } from "@toolkit/ui";
import moment from "moment/moment";
import { useProfileContext } from "../../context/ProfileContext";
import {
  GuidedCareJourneyTemplateInterventionListDocument,
  useGuidedCareJourneyInterventionActivateMutation,
  useGuidedCareJourneyTemplateInterventionListQuery,
} from "../../gql";
import { GuidedCareJourneyEligibleInterventionsAlert } from "../GuidedCareJourneyInterventions/GuidedCareJourneyEligibleInterventionsAlert/GuidedCareJourneyEligibleInterventionsAlert";
import { GuidedCareJourneyGoals } from "../GuidedCareJourneyInterventions/GuidedCareJourneyGoals/GuidedCareJourneyGoals";
import { HealthConditions } from "./Conditions";
import { JourneyStatistics } from "./JourneyStatistics";
import { MaskProfilePhoto } from "./MaskProfilePhoto";
import { PatientGuidedCarePrograms } from "./PatientGuidedCarePrograms/PatientGuidedCarePrograms";
import { PatientInformationSection } from "./PatientInformationSection";
import { FC } from "react";
import { useLocation } from "react-router-dom";

const msg = "N/A";

export const PatientInformation: FC<{ hideClose?: boolean }> = props => {
  const { hideClose = false } = props;
  const { t } = useTranslation("provider");
  const { succeeded, failed } = useAddToast();
  const theme = useTheme();
  const location = useLocation();

  const isMobile = useMediaQuery("(max-width:1340px)");
  const isVisitCallView = location?.pathname === "/visit-call";

  const {
    patient,
    userId,
    patientHealthHistory,
    selectedHealthProgram,
    onClose: handleToggleDrawer,
    hasGuidedCareHealthProgramTeam,
    guidedCareProgramTeamLeaderPhoto,
    guidedCareProgramTeamMembersWithoutTeamLeader,
  } = useProfileContext();

  const { data, loading } = useGuidedCareJourneyTemplateInterventionListQuery({
    variables: {
      first: 1,
      filter: {
        healthProgramId: selectedHealthProgram,
        patientsIds: [userId!],
        statuses: [GuidedCareJourneyStatus.InProgress],
      },
    },
    skip: !selectedHealthProgram || !userId,
  });

  const guidedCareJourney = data?.guidedCareJourneys?.edges?.[0]?.node;

  const eligibleInterventions = (guidedCareJourney?.eligibleInterventions || []) as GuidedCareJourneyIntervention[];

  const insuranceCards = patient?.activeHealthProgramMembers?.[0];

  const allergies = patientHealthHistory
    ?.filter(item => item?.healthHistoryType! === HealthHistoryType.Allergy)
    .map(item => item?.display || item?.code);

  const getAge = () => {
    const birthdate = moment(patient?.dateOfBirth);
    const currentDate = moment();
    const years = currentDate.diff(birthdate, "years");
    birthdate.add(years, "years");
    const months = currentDate.diff(birthdate, "months");
    let age = `${years} ${t("Years")}`;
    if (months > 0) {
      age = `${age} ${months} ${t("Months")}`;
    }
    return age;
  };

  const patientInfo = [t(patient?.gender!), patient?.dateOfBirth && getAge(), t(patient?.user?.preferredLanguage?.display!)].filter(
    Boolean
  );

  const [activateGuidedCareJourneyIntervention, { loading: isSubmitting }] = useGuidedCareJourneyInterventionActivateMutation({
    onCompleted: mutationData => {
      if (mutationData?.guidedCareJourneyInterventionActivate?.id) {
        succeeded(t("Intervention has been successfully activated for the patient"));
      } else {
        failed(t("Failed to activate interventions for the patient"));
      }

      GuidedCareJourneyInterventionsActivitiesModalApi.close();
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
    refetchQueries: [
      {
        query: GuidedCareJourneyTemplateInterventionListDocument,
        variables: {
          first: 1,
          filter: {
            healthProgramId: selectedHealthProgram,
            patientsIds: [userId!],
            statuses: [GuidedCareJourneyStatus.InProgress],
          },
        },
      },
    ],
  });

  const onGuidedCareJourneyInterventionsActivitiesModalChange = (event: IGuidedCareJourneyInterventionsActivitiesEvent) => {
    activateGuidedCareJourneyIntervention({
      variables: {
        input: {
          guidedCareJourneyId: guidedCareJourney?.id!,
          interventionActivationType: event.type,
          templateInterventionId: event.payload.selectedIntervention?.id,
          startDate: moment(event.payload.selectedDate).format(),
          templateInterventionActivityItemsIds: event.payload.interventionActivitiesItems?.map(item => item?.id) || [],
        },
      },
    });
  };

  return (
    <Grid container padding='20px 0px' spacing={2}>
      <GuidedCareJourneyInterventionsActivitiesModal
        isLoading={isSubmitting}
        onChange={onGuidedCareJourneyInterventionsActivitiesModalChange}
      />

      {!loading && eligibleInterventions?.length > 0 && (
        <Grid item xs={12}>
          <GuidedCareJourneyEligibleInterventionsAlert
            eligibleInterventions={eligibleInterventions}
            patientName={`${patient?.firstName} ${patient?.lastName}`}
          />
        </Grid>
      )}

      <Grid item xs={12}>
        <Grid container padding='20px 0px' flexWrap={"nowrap"}>
          {!hideClose && (
            <Grid item>
              <IconButton
                onClick={handleToggleDrawer}
                sx={{
                  width: 30,
                  height: 30,
                  color: theme.palette.primary.main,
                  alignSelf: "start",
                }}
              >
                <CustomIcon icon='close' viewBox='-1 0 26 25' />
              </IconButton>
            </Grid>
          )}

          <Grid item>
            <Box sx={{ width: "90px", height: "90px" }}>
              <MaskProfilePhoto src={patient?.user?.photo!} />
            </Box>
          </Grid>

          <Grid container spacing={1} item flexWrap={isMobile || isVisitCallView ? "wrap" : "nowrap"}>
            <Grid item>
              <PatientInformationSection title={patient?.firstName || patient?.lastName ? formatUserNameFull(patient) : msg}>
                <Box display='flex' alignItems='center' flexWrap='wrap'>
                  {patientInfo?.map((item, index) => (
                    <Box display='flex' alignItems='center' key={item}>
                      {index > 0 && (
                        <Box sx={{ borderRadius: 25, marginInline: 1, width: 7, height: 7, background: theme.palette.primary[200] }} />
                      )}

                      <Typography fontSize={theme.mixins.fonts.fontSize.sm}>{item}</Typography>
                    </Box>
                  ))}
                </Box>

                <Typography mt={1} fontSize={theme.mixins.fonts.fontSize.sm}>
                  {insuranceCards?.payer?.name || ""}
                </Typography>
              </PatientInformationSection>
            </Grid>

            <Grid item xs container spacing={1} justifyContent='center' flexWrap={isMobile ? "wrap" : "nowrap"}>
              <Grid item xs='auto'>
                <HealthConditions />
              </Grid>

              <Grid item xs='auto'>
                <PatientInformationSection title={t("Allergies")} hasItems items={allergies} />
              </Grid>
            </Grid>

            <Grid item>
              <PatientGuidedCarePrograms />

              <PatientInformationSection title={t("Care Team")}>
                {hasGuidedCareHealthProgramTeam ? (
                  <AvatarGroup
                    max={5}
                    sx={{
                      justifyContent: "flex-end",
                    }}
                  >
                    {guidedCareProgramTeamLeaderPhoto && (
                      <Avatar
                        sx={{
                          border: `1.5px solid ${theme.palette.secondary.main} !important`,
                          backgroundColor: theme.palette.common.white,
                          zIndex: 1,
                        }}
                        alt='Leader'
                        src={getMediaLink(guidedCareProgramTeamLeaderPhoto!)}
                      />
                    )}
                    {guidedCareProgramTeamMembersWithoutTeamLeader?.map(item => {
                      return <Avatar key={item?.id} alt={item?.id} src={getMediaLink(item?.photo!)} />;
                    })}
                  </AvatarGroup>
                ) : (
                  <Box
                    sx={{
                      width: "fit-content",
                      border: `1px solid ${theme.palette.primary[100]}`,
                      borderRadius: "5px",
                      padding: "5px 10px",
                      background: theme.palette.background.default,
                    }}
                  >
                    <Typography fontSize={theme.mixins.fonts.fontSize.xs} flexWrap={"wrap"}>
                      {msg}
                    </Typography>
                  </Box>
                )}
              </PatientInformationSection>
            </Grid>

            <Grid item>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <GuidedCareJourneyGoals />
                </Grid>

                <Grid item xs={12}>
                  <JourneyStatistics
                    healthProgramId={selectedHealthProgram!}
                    activeInterventionsValue={guidedCareJourney?.activeInterventions?.length}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
