import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PatientEligibilityRequestHistoriesListQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  filter?: Types.InputMaybe<Types.PatientEligibilityRequestHistoryFilterInput>;
  sortBy?: Types.InputMaybe<Types.PatientEligibilityRequestHistorySortingInput>;
}>;


export type PatientEligibilityRequestHistoriesListQuery = { __typename?: 'Query', patientEligibilityRequestHistories?: { __typename?: 'PatientEligibilityRequestHistoryCountableConnection', pageInfo?: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null } | null, edges: Array<{ __typename?: 'PatientEligibilityRequestHistoryCountableEdge', node: { __typename?: 'PatientEligibilityRequestHistory', id: string, isFounded: boolean, isCompleted: boolean, createdDate?: any | null, updatedDate?: any | null, branchName?: string | null, patientEligibility: { __typename?: 'OptimaPatientEligibility', id: string, firstName?: string | null, lastName?: string | null, mobile?: string | null, mobileCountryCode?: string | null, emiratesId: string, memberName?: string | null, insuranceName?: string | null, dhaId?: string | null, policyNumber?: string | null, companyName?: string | null, network?: string | null, effectiveTo?: any | null }, payerEligibilityRequestHistories?: Array<{ __typename?: 'PayerEligibilityRequestHistory', insuranceName?: string | null, isFounded?: boolean | null, failed?: boolean | null, exceptionMessage?: string | null, id: string } | null> | null } }> } | null };


export const PatientEligibilityRequestHistoriesListDocument = gql`
    query PatientEligibilityRequestHistoriesList($first: Int, $last: Int, $after: String, $before: String, $filter: PatientEligibilityRequestHistoryFilterInput, $sortBy: PatientEligibilityRequestHistorySortingInput) {
  patientEligibilityRequestHistories(
    first: $first
    last: $last
    after: $after
    before: $before
    filter: $filter
    sortBy: $sortBy
  ) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      node {
        id
        isFounded
        isCompleted
        createdDate
        updatedDate
        branchName
        patientEligibility {
          id
          firstName
          lastName
          mobile
          mobileCountryCode
          emiratesId
          memberName
          insuranceName
          dhaId
          policyNumber
          companyName
          network
          effectiveTo
        }
        payerEligibilityRequestHistories {
          insuranceName
          isFounded
          failed
          exceptionMessage
          id
        }
      }
    }
  }
}
    `;

/**
 * __usePatientEligibilityRequestHistoriesListQuery__
 *
 * To run a query within a React component, call `usePatientEligibilityRequestHistoriesListQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientEligibilityRequestHistoriesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientEligibilityRequestHistoriesListQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function usePatientEligibilityRequestHistoriesListQuery(baseOptions?: Apollo.QueryHookOptions<PatientEligibilityRequestHistoriesListQuery, PatientEligibilityRequestHistoriesListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PatientEligibilityRequestHistoriesListQuery, PatientEligibilityRequestHistoriesListQueryVariables>(PatientEligibilityRequestHistoriesListDocument, options);
      }
export function usePatientEligibilityRequestHistoriesListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PatientEligibilityRequestHistoriesListQuery, PatientEligibilityRequestHistoriesListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PatientEligibilityRequestHistoriesListQuery, PatientEligibilityRequestHistoriesListQueryVariables>(PatientEligibilityRequestHistoriesListDocument, options);
        }
export function usePatientEligibilityRequestHistoriesListSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<PatientEligibilityRequestHistoriesListQuery, PatientEligibilityRequestHistoriesListQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PatientEligibilityRequestHistoriesListQuery, PatientEligibilityRequestHistoriesListQueryVariables>(PatientEligibilityRequestHistoriesListDocument, options);
        }
export type PatientEligibilityRequestHistoriesListQueryHookResult = ReturnType<typeof usePatientEligibilityRequestHistoriesListQuery>;
export type PatientEligibilityRequestHistoriesListLazyQueryHookResult = ReturnType<typeof usePatientEligibilityRequestHistoriesListLazyQuery>;
export type PatientEligibilityRequestHistoriesListSuspenseQueryHookResult = ReturnType<typeof usePatientEligibilityRequestHistoriesListSuspenseQuery>;
export type PatientEligibilityRequestHistoriesListQueryResult = Apollo.QueryResult<PatientEligibilityRequestHistoriesListQuery, PatientEligibilityRequestHistoriesListQueryVariables>;