import { FeatureEnum, GridProvider, IsFeatureEnabledType } from "@health/domains";
import { FilterGrid, PageWrapper, TableGrid, TableSettingComponent } from "@toolkit/ui";
import { FC } from "react";
import { useManualSubmittedOrderListContainerColumns } from "./useManualSubmittedOrdersListContainerColumns";
import { useSetManualSubmittedOrderBreadcrumbs } from "../../hooks/useSetManualSubmittedOrderBreadcrumbs";
import { useManualSubmittedOrderListQuery } from "../../gql";
import { ManualOrderSortField, OrderDirection } from "@/schema/types";

type ManualSubmittedOrderListContainerProps = {
  isFeatureEnabled: IsFeatureEnabledType;
};
export const ManualSubmittedOrderListContainer: FC<ManualSubmittedOrderListContainerProps> = props => {
  const { isFeatureEnabled } = props;
  useSetManualSubmittedOrderBreadcrumbs("LIST", !isFeatureEnabled([FeatureEnum.Prescription]));

  return (
    <GridProvider
      gridName={"ManualSubmittedOrderList"}
      query={useManualSubmittedOrderListQuery}
      columns={useManualSubmittedOrderListContainerColumns()}
      hasTableSetting
      variables={{
        sortBy: {
          direction: OrderDirection.Desc,
          field: ManualOrderSortField.CreationDate,
        },
      }}
    >
      <PageWrapper
        filters={<FilterGrid />}
        actions={
          <>
            <TableSettingComponent />
          </>
        }
      >
        <TableGrid />
      </PageWrapper>
    </GridProvider>
  );
};
