import { FC } from "react";
import { ActivityBasedDoctorsQuery } from "../gql/queries";
import { useTranslation } from "@toolkit/i18n";
import { useOptimaWidgetsListColumns } from "../List/useOptimaWidgetsListColumns";
import { CustomTable } from "@toolkit/ui";

export const DoctorsTable: FC<{
  doctorsWithActivities: boolean;
  data: ActivityBasedDoctorsQuery;
  onGoToNext: () => void;
  onGoToPrevious: () => void;
}> = ({ doctorsWithActivities, data, onGoToNext, onGoToPrevious }) => {
  const { t } = useTranslation("provider");
  const columns = useOptimaWidgetsListColumns();

  return (
    <>
      <CustomTable
        TableContainerProps={{
          sx: {
            height: 250,
          },
        }}
        columns={columns as any}
        data={data?.activityBasedDoctors?.edges?.map(item => item?.node) || []}
        withoutDataMessage={doctorsWithActivities ? t("No Doctors With Activity") : t("No Doctors With No Activity")}
        emptyIconHeight={70}
        hasPreviousPage={data?.activityBasedDoctors?.pageInfo?.hasPreviousPage! ?? false}
        hasNextPage={data?.activityBasedDoctors?.pageInfo?.hasNextPage! ?? false}
        onGoToNext={onGoToNext}
        onGoToPrevious={onGoToPrevious}
        iconBoxProps={{
          marginBottom: "15px",
          height: "70px",
        }}
      />
    </>
  );
};
