import { ExtractNodeType } from "@toolkit/apollo";
import { useOpenState } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Box, MuiDrawer, ShowButton, Typography } from "@toolkit/ui";
import { OptimaClaimsListQuery } from "@/pages/OptimaClaims/gql";
import { FC } from "react";
import { useRemittanceDrawerStyles } from "./RemittanceDrawerStyles";
import { RemittanceDrawerDetails } from "./RemittanceDrawerDetails";
import { ClaimGeneralInformation } from "../ClaimGeneralInformation/ClaimGeneralInformation";
import { ClaimTransactionDetails } from "../ClaimTransactionDetails/ClaimTransactionDetails";
import { ClaimEncounterDetails } from "../ClaimEncounterDetails/ClaimEncounterDetails";
import { RemittanceActivitiesList } from "@/pages/OptimaClaims/containers";

type RemittanceDrawerProps = {
  claim: ExtractNodeType<OptimaClaimsListQuery>;
};

export const RemittanceDrawer: FC<RemittanceDrawerProps> = props => {
  const { claim } = props;
  const { remittance } = claim;
  const { classes } = useRemittanceDrawerStyles();
  const { t } = useTranslation("domains");
  const { handleOpen, handleToggle, open } = useOpenState();

  return remittance?.id ? (
    <>
      <ShowButton onClick={handleOpen} />
      <MuiDrawer
        anchor='right'
        open={open}
        onClose={handleToggle}
        PaperProps={{
          className: classes.drawer,
        }}
      >
        <Box padding={2}>
          <Typography className={classes.sectionTitle}>{t("General Information")}</Typography>
          <ClaimGeneralInformation claim={claim} />
          <Typography className={classes.sectionTitle}>{t("Transaction Details")}</Typography>
          <ClaimTransactionDetails claim={claim} />
          <Typography className={classes.sectionTitle}>{t("Encounter Details")}</Typography>
          <ClaimEncounterDetails claim={claim} />
          <Typography className={classes.sectionTitle}>{t("Remittance Summary")}</Typography>
          <RemittanceDrawerDetails claim={claim} />
          <RemittanceActivitiesList id={claim?.remittance?.id} />
        </Box>
      </MuiDrawer>
    </>
  ) : (
    <Typography className={classes.pending}>{t("Pending")}</Typography>
  );
};
