import { useCustomFormContext } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Button, CustomTable, Grid, PlusIcon, Typography, useTheme } from "@toolkit/ui";
import { FieldArrayWithId, useFieldArray } from "react-hook-form";
import {
  TransactionValidateRequestEncounterUpsertModal,
  TransactionValidateRequestEncounterUpsertModalApi,
} from "../../modals/TransactionValidateRequestEncounterUpsertModal/TransactionValidateRequestEncounterUpsertModal";
import { ITransactionValidateRequestFormValues } from "../TransactionValidateRequest/TransactionValidateRequestFormSchema";
import {
  ITransactionValidateRequestEncounterFormValues,
  ITransactionValidateRequestEncounterUpsertFormEvent,
} from "../TransactionValidateRequestEncounter/TransactionValidateRequestEncounterFormSchema";
import { useTransactionValidateRequestEncountersColumns } from "./useTransactionValidateRequestEncountersColumns";

export const TransactionValidateRequestEncounterForm = () => {
  const { t } = useTranslation("provider");
  const theme = useTheme();

  const form = useCustomFormContext<ITransactionValidateRequestFormValues>();
  const {
    control,
    isFormDisabled,
    formState: { errors },
  } = form;

  const { fields, append, remove, update } = useFieldArray<ITransactionValidateRequestFormValues, "encounter">({
    control,
    name: "encounter",
  });

  const onAddClick = () => {
    TransactionValidateRequestEncounterUpsertModalApi.open();
  };

  const onEncounterUpsertModalChange = (event: ITransactionValidateRequestEncounterUpsertFormEvent) => {
    if (event.type === "CREATE") {
      append(event.payload.values);
      TransactionValidateRequestEncounterUpsertModalApi.close();
    } else if (event.type === "UPDATE") {
      const updatedFieldIndex = fields.findIndex(field => field.id === event.payload.values.index);
      if (updatedFieldIndex > -1) {
        update(updatedFieldIndex, event.payload.values);
      }
      TransactionValidateRequestEncounterUpsertModalApi.close();
    }
  };

  const onEditRowClick = (row: FieldArrayWithId<ITransactionValidateRequestEncounterFormValues>) => {
    const item = fields.find(field => field.id === row.id) as ITransactionValidateRequestEncounterFormValues | undefined;
    if (item) {
      TransactionValidateRequestEncounterUpsertModalApi.open({ ...item, index: row.id });
    }
  };

  const onDeleteRowClick = (_: FieldArrayWithId<ITransactionValidateRequestEncounterFormValues>, index: number) => {
    remove(index);
  };

  return (
    <Grid container spacing={2}>
      <TransactionValidateRequestEncounterUpsertModal onChange={onEncounterUpsertModalChange} />

      <Grid item xs={12}>
        <Grid container>
          <Grid xs={8} lg={10}>
            <Typography fontSize={theme.mixins.fonts.fontSize.lg} fontWeight={theme.mixins.fonts.fontWeight.medium}>
              {t("Encounters")}
            </Typography>
          </Grid>

          {!isFormDisabled && (
            <Grid xs={4} lg={2}>
              <Button fullWidth onClick={onAddClick} startIcon={<PlusIcon />}>
                {t("Add Encounter")}
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {errors.encounter && (
          <Typography color='error' fontSize={theme.mixins.fonts.fontSize.sm}>
            {errors.encounter.message}
          </Typography>
        )}
      </Grid>

      <Grid item xs={12}>
        <CustomTable
          data={fields}
          columns={useTransactionValidateRequestEncountersColumns()}
          isEditVisible
          isDeleteVisible
          isRowEditable={() => !isFormDisabled}
          isRowDeletable={() => !isFormDisabled}
          onEditRow={onEditRowClick}
          onDeleteRow={onDeleteRowClick}
          hasFooter={false}
          scrollable
        />
      </Grid>
    </Grid>
  );
};
