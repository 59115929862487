import { DeliveryTimeSlot, ManualOrderStatus } from "@/schema/types";
import { Theme } from "@toolkit/ui";
import moment, { Moment } from "moment";

export const getSlotsMoment = (
  data: string,
  slotPart: DeliveryTimeSlot["startTime"] | DeliveryTimeSlot["endTime"],
  systemTimeZoneHours: number // Now in hours (±)
): Moment => {
  // Convert hours to minutes for moment.js operations
  const timeZoneOffsetMinutes = systemTimeZoneHours * 60;

  // Extract clean date from possible ISO string
  const cleanDate = data.split("T")[0];

  return moment.utc(`${cleanDate}T${slotPart}`).subtract(timeZoneOffsetMinutes, "minutes").utcOffset(timeZoneOffsetMinutes);
};

export const getDeliveryTimeFromSlots = (data: string, deliveryTimeSlot: Partial<DeliveryTimeSlot>, systemTimeZoneHours: number) => {
  const startTime = getSlotsMoment(data, deliveryTimeSlot.startTime!, systemTimeZoneHours);
  const endTime = getSlotsMoment(data, deliveryTimeSlot.endTime!, systemTimeZoneHours);

  const sameDate = startTime.isSame(endTime, "day");

  return `${startTime.format("DD MMM hh:mm A")} - ${sameDate ? endTime.format("hh:mm A") : endTime.format("DD MMM hh:mm A")}`;
};

export const getOrderAttachmentFileName = (userFullName: string, orderId: string, created: string) => {
  return `eRx-${userFullName}_order-${orderId}_${moment(created).format("DD-MM-YYYY")}`;
};

export const getManualStatusToColorMap = (theme: Theme): Record<ManualOrderStatus, string> => ({
  [ManualOrderStatus.AuthorizationApproved]: theme.palette.success.main,
  [ManualOrderStatus.AuthorizationReject]: theme.palette.error.main,
  [ManualOrderStatus.Cancelled]: theme.palette.error.main,
  [ManualOrderStatus.Delivered]: theme.palette.success.main,
  [ManualOrderStatus.Failed]: theme.palette.error.main,
  [ManualOrderStatus.OutForDelivery]: theme.palette.info.main,
  [ManualOrderStatus.PickedUp]: theme.palette.success.main,
  [ManualOrderStatus.Rejected]: theme.palette.error.main,
  [ManualOrderStatus.Timeout]: theme.palette.warning.main,
  [ManualOrderStatus.WaitingAuthorizationApproval]: theme.palette.info.main,
  [ManualOrderStatus.WaitingPatientApproval]: theme.palette.info.main,
  [ManualOrderStatus.WaitingProviderApproval]: theme.palette.info.main,
});
