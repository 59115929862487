import { useTranslation } from "@toolkit/i18n";
import { CustomDialog, Button, CircularProgress } from "@toolkit/ui";
import { FC, useEffect, useRef, useState } from "react";
import { useOpenState } from "@toolkit/core";
import { ModalApi } from "../../others/types";
import {
  ITransactionValidateRequestAuditFormValues,
  ITransactionValidateRequestAuditUpsertFormEvent,
} from "../../forms/TransactionValidateRequestAudit/TransactionValidateRequestAuditFormSchema";
import {
  TransactionValidateRequestAuditUpsertForm,
  TransactionValidateRequestAuditUpsertFormRef,
} from "../../forms/TransactionValidateRequestAudit/TransactionValidateRequestAuditUpsertForm";

export const TransactionValidateRequestAuditUpsertModalApi: ModalApi<ITransactionValidateRequestAuditFormValues & { index: string }> = {
  open: () => {},
  close: () => {},
};

type TransactionValidateRequestAuditUpsertModalProps = {
  isLoading?: boolean;
  hasClose?: boolean;
  onChange: (event: ITransactionValidateRequestAuditUpsertFormEvent) => void;
};

export const TransactionValidateRequestAuditUpsertModal: FC<TransactionValidateRequestAuditUpsertModalProps> = ({
  isLoading = false,
  hasClose = true,
  onChange,
}) => {
  const { t } = useTranslation("provider");
  const { open: isOpen, handleClose, handleOpen } = useOpenState();

  const [audit, setAudit] = useState<(ITransactionValidateRequestAuditFormValues & { index: string }) | null>(null);

  const formRef = useRef<TransactionValidateRequestAuditUpsertFormRef>(null);

  const handleSubmit = () => formRef.current?.submit();

  const onOpen = (data?: ITransactionValidateRequestAuditFormValues & { index: string }) => {
    setAudit(data || null);
    handleOpen();
  };

  useEffect(() => {
    TransactionValidateRequestAuditUpsertModalApi.open = onOpen;
    TransactionValidateRequestAuditUpsertModalApi.close = handleClose;
  }, []);

  return (
    <CustomDialog
      type='base'
      maxWidth='md'
      open={isOpen}
      onClose={hasClose ? handleClose : undefined}
      DialogTitleProps={{
        title: audit ? t("Update Audit Log") : t("Add Audit Log"),
        hasCloseButton: hasClose,
      }}
      DialogActionsProps={{
        children: (
          <Button disabled={isLoading} endIcon={isLoading ? <CircularProgress color='inherit' size={20} /> : null} onClick={handleSubmit}>
            {audit ? t("Update") : t("Add")}
          </Button>
        ),
      }}
    >
      <TransactionValidateRequestAuditUpsertForm index={audit?.index} audit={audit || undefined} ref={formRef} onChange={onChange} />
    </CustomDialog>
  );
};
