import { OptimaValidationRequest } from "@/schema/types";
import { i18n } from "@toolkit/i18n";
import { countBy } from "lodash";

export const mapMessage = (key: string) => {
  return (
    {
      encounter_type_claim:
        "Check patient insurance policy specific terms related to patient condition and activities ordered in correlation with current encouter type.",
      PLANCODE_member: "Check patient insurance policy specific terms related to patient condition and activities ordered.",
      code_claim_activity: "Check for patient insurance policy specific terms related to ordered activities.",
      receiver_id_claim: "Check for patient insurance policy specific terms related to ordered activities.",
      principal_diagnosis_claim:
        "Ensure the principal diagnosis is documented correctly as it impacts claim approval, Also Check if patient insurance covers primary diagnosis selected.",
      claim_activity_observation_text:
        "Review the claim activity notes/observations for completeness and relevance to the submitted activities.",
      payer_id_claim: "Check for patient insurance policy specific terms related to ordered activities.",
      codes_claim_diagnosis:
        "Review the diagnosis codes to ensure alignment with the requested service/procedure/treatment.Also Check if patient insurance covers selected condition.",
      GENDER_member:
        "Review diagnoses & procedures compliance with patient gender as it seems there is misalignment or policy coverage issue.",
      quantity_claim_activity:
        "Verify if the quantity of the requested service/procedure/treatment aligns with the claim's details and justification.",
      AGE_member:
        "Confirm service/procedure/treatment suitability with patient age, Also check age-related restrictions or requirements for the procedures claimed to avoid discrepancies.",
      specialty_name_sgh_clinician_lookup:
        "Confirm the clinician's specialty is appropriate for the requested service/procedure/treatment, Also Check if patient insurance covers current speciality.",
    }[key] || i18n.t(key, { ns: "optima" })
  );
};

export const getOptimaItemsResult = (items?: OptimaValidationRequest["items"], transactionEdits?: OptimaValidationRequest["edits"]) => {
  const counts = countBy(items, item =>
    (item?.linkedDiagnoses?.reduce((sum, ld) => sum + (ld?.edits?.length || 0), 0) || 0) > 0 ||
    (item?.edits?.length || 0) > 0 ||
    (item?.riskFactors?.length || 0) > 0
      ? "needReview"
      : "passed"
  );

  const generalNotesCount = transactionEdits?.length || 0;

  return {
    passedCount: counts.passed || 0,
    needReviewCount: (counts.needReview || 0) + generalNotesCount,
  };
};
