import { OptimaValidationRequestsSortingField } from "@/schema/types";
import { GridCellText } from "@health/domains";
import { toTitleCase } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps } from "@toolkit/ui";
import { useMemo } from "react";
import OptimaVisitResultDetailsModal from "../../components/OptimaVisitResultDetails/OptimaVisitResultDetails";
import { OptimaValidationResult } from "../../components/requestResult/requestResult";
import { OptimaValidationStatus } from "../../components/requestStatus/requestStatus";
import { IOptimaValidationRequestsListNode } from "../../types";

export const useOptimaValidationRequestsListContainerColumns = (): CustomTableColumnProps<IOptimaValidationRequestsListNode>[] => {
  const { t } = useTranslation("provider");

  return useMemo(() => {
    return [
      {
        key: "id",
        header: t("Request Id"),
        type: "truncated-text",
        accessor: "requestId",
      },
      {
        key: "visitId",
        header: t("Visit Id"),
        accessor: ({ visitId }) => <GridCellText text={visitId} />,
        filter: {
          type: "string",
          name: "visitId",
        },
      },
      {
        key: "patientName",
        header: t("Patient Name"),
        accessor: ({ patientName }) => <GridCellText text={toTitleCase(patientName || "")} />,
      },
      {
        key: "insurancePayer",
        header: t("Payer"),
        accessor: ({ insurancePayer }) => <GridCellText text={insurancePayer} />,
      },
      {
        key: "insuranceReceiver",
        header: t("Receiver"),
        accessor: ({ insuranceReceiver }) => <GridCellText text={insuranceReceiver} />,
      },
      {
        key: "billNumber",
        header: t("Bill Number"),
        accessor: ({ billNumber }) => <GridCellText text={billNumber} />,
      },
      {
        key: "createdDate",
        header: t("Created Date"),
        sort: {
          columnEnum: OptimaValidationRequestsSortingField.Created,
        },
        type: "datetime",
        accessor: "createdDate",
      },
      {
        key: "visitDateTime",
        header: t("Visit Date"),
        sort: {
          columnEnum: OptimaValidationRequestsSortingField.VisitDate,
        },
        type: "datetime",
        accessor: "visitDateTime",
      },
      {
        key: "billDateTime",
        header: t("Bill Date"),
        sort: {
          columnEnum: OptimaValidationRequestsSortingField.BillDate,
        },
        type: "datetime",
        accessor: "billDateTime",
      },
      {
        key: "claimStatus",
        header: t("Claim Status"),
        accessor: "claimStatus",
        sort: {
          columnEnum: OptimaValidationRequestsSortingField.ClaimStatus,
        },
      },
      {
        key: "status",
        header: t("Potential Status"),
        accessor: row => <OptimaValidationStatus request={row} />,
      },
      {
        key: "result",
        header: t("Result"),
        accessor: row => <OptimaValidationResult request={row} />,
      },
      {
        key: "showResultDetails",
        header: t("Result Details"),
        accessor: row => <OptimaVisitResultDetailsModal optimaValidationRequest={row} />,
      },
    ];
  }, [t]);
};
