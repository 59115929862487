import { useTranslation } from "@toolkit/i18n";
import { PageWrapper, TableGrid, useBreadCrumbs } from "@toolkit/ui";
import { truncate } from "lodash";
import { useGuidedCareTeamGetNameQuery, useGuidedCareTeamPatientListQuery } from "pages/HealthProviderManagement/GuidedCare/gql";
import { IGuidedCarePatientsContainerParams } from "pages/HealthProviderManagement/GuidedCare/types";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { GridProvider } from "shared/components";
import { createGuidedCareTeamBreadcrumbs } from "../../constants";
import { useGuidedCareTeamPatientListTableColumns } from "./useGuidedCareTeamPatientListTableColumns";

export const GuidedCareTeamPatientListContainer = () => {
  const { teamId } = useParams<IGuidedCarePatientsContainerParams>();
  const { setBreadCrumb } = useBreadCrumbs();
  const { t } = useTranslation("provider");
  const { data } = useGuidedCareTeamGetNameQuery({
    variables: {
      id: teamId!,
    },
  });
  const teamName = data?.providerGuidedCareHealthProgramTeam?.name;
  const truncatedName = truncate(teamName || "", { length: 50 });
  useEffect(() => {
    setBreadCrumb({
      title: createGuidedCareTeamBreadcrumbs(t).title,
      values: truncatedName
        ? [
            createGuidedCareTeamBreadcrumbs(t).main,
            createGuidedCareTeamBreadcrumbs(t).careTeam(truncatedName),
            createGuidedCareTeamBreadcrumbs(t).patients,
          ]
        : undefined,
    });
  }, [setBreadCrumb, teamName]);

  return (
    <GridProvider
      gridName='guidedCareTeamPatientList'
      columns={useGuidedCareTeamPatientListTableColumns()}
      query={useGuidedCareTeamPatientListQuery}
      variables={{ teamId: teamId! }}
      skipCall={!teamId}
      skipUrlState={false}
    >
      <PageWrapper>
        <TableGrid />
      </PageWrapper>
    </GridProvider>
  );
};
