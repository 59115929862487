import { ManualOrderStatus } from "@/schema/types";
import { useOpenState } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Button, CustomDialog, useAddToast } from "@toolkit/ui";
import { useSetAuthorizationApprovalMutation } from "../../gql/mutations";
import { INoteFormValues, NoteForm, NoteFormRef } from "../../forms";
import { useRef } from "react";
import { ManualSubmittedOrderListDocument } from "../../gql";

type OrderAuthorizationModalProps = {
  orderId: string;
  orderStatus: ManualOrderStatus;
};

export const OrderAuthorizationModal: React.FC<OrderAuthorizationModalProps> = props => {
  const { orderId, orderStatus } = props;
  const { t } = useTranslation();
  const { handleToggle, open } = useOpenState();
  const { failed, succeeded } = useAddToast();
  const noteFormRef = useRef<NoteFormRef>(null);
  const isApprovedRef = useRef<boolean>(true); // Track authorization state using ref

  const [orderAuthorizeMutation, { loading }] = useSetAuthorizationApprovalMutation({
    onCompleted: data => {
      if (data?.setAuthorizationApproval?.id) {
        handleToggle();
        succeeded(t("Order authorization status updated successfully!"));
      } else {
        failed(t("Failed to update order authorization status!"));
      }
    },
    onError: () => {
      failed(t("Failed to update order authorization status!"));
    },
    refetchQueries: [ManualSubmittedOrderListDocument],
  });

  const isAuthorizeDisabled = loading || ManualOrderStatus.WaitingAuthorizationApproval !== orderStatus;

  const handleAuthorizeOrder = async () => {
    isApprovedRef.current = true;
    noteFormRef.current?.submit();
  };

  const handleUnauthorizeOrder = async () => {
    isApprovedRef.current = false;
    noteFormRef.current?.submit();
  };

  const handleOrderAuthorization = (values: INoteFormValues) => {
    orderAuthorizeMutation({
      variables: {
        orderId: orderId,
        note: values?.note,
        isApproved: isApprovedRef.current,
      },
    });
  };

  return (
    <CustomDialog
      type='base'
      open={open}
      DialogTitleProps={{
        title: t("Authorize Order"),
        onClose: handleToggle,
      }}
      button={
        <Button disabled={isAuthorizeDisabled} onClick={handleToggle} sx={{ width: "max-content" }}>
          {t("Authorize Order")}
        </Button>
      }
      DialogActionsProps={{
        children: (
          <>
            <Button onClick={handleAuthorizeOrder} disabled={loading} color='success'>
              {t("Authorize")}
            </Button>
            <Button onClick={handleUnauthorizeOrder} disabled={loading} color='error'>
              {t("Unauthorize")}
            </Button>
          </>
        ),
      }}
    >
      <NoteForm ref={noteFormRef} label={t("Approval Note")} onChange={handleOrderAuthorization} />
    </CustomDialog>
  );
};
