import { AffectedType, AppointmentStatus, CodeSystemCode, Maybe, Type } from "@/schema/types";
import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { useOpenState } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import {
  DoctorAppointmentCancelMutation,
  useDoctorAppointmentCancelMutation,
  useGetDoctorAppointmentCancellationReasonsQuery,
} from "pages/appointments/gql";
import { getAppointmentStatusTitle } from "pages/appointments/utils";
import { ChangeEvent, useState } from "react";
import { NotificationItemAction } from "shared/components";

type AppointmentCancelType = {
  id: string;
  status?: Maybe<AppointmentStatus>;
} & NotificationItemAction;

export const useAppointmentCancel = ({ id, status, handleNotificationActionComplete }: AppointmentCancelType) => {
  const [state, setState] = useState({ reason: "", other: "", isOther: false });

  const { t } = useTranslation("provider");
  const { open: isOpen, handleToggle } = useOpenState();
  const { succeeded, failed } = useAddToast();

  const { data, loading: reasonsLoading } = useGetDoctorAppointmentCancellationReasonsQuery({
    skip: !isOpen,
    variables: {
      codeSystemCode: CodeSystemCode.CancellationReason,
      searchParameters: [
        {
          code: "Actor",
          type: Type.String,
          valueString: "Doctor",
        },
        {
          code: "AffectedType",
          type: Type.String,
          valueString: AffectedType.Appointment,
        },
      ],
    },
  });

  const reasons = data?.getCodeSystemConceptsByCode?.edges?.length
    ? data?.getCodeSystemConceptsByCode?.edges?.map(cancellationReason => cancellationReason?.node)
    : [{ id: "1", text: "Other", text_ar: t("Other") }];

  const title = getAppointmentStatusTitle(status);

  const [doctorAppointmentCancelMutation, { loading: isSubmitting }] = useDoctorAppointmentCancelMutation({
    onCompleted: (mutationData: DoctorAppointmentCancelMutation) => {
      const errors = mutationData?.doctorAppointmentCancel?.appointmentErrors;
      if (errors?.length) {
        failed(formatMessageErrors(errors));
      } else {
        succeeded(t("Appointment Canceled"));
        handleNotificationActionComplete?.();
        handleToggle();
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
    update: (cache, { data }) => {
      const normalizedId = cache.identify({ id: data?.doctorAppointmentCancel?.appointment?.id, __typename: "Appointment" });
      cache.evict({ id: normalizedId });
      cache.gc();
    },
  });

  const disabled = reasonsLoading || isSubmitting || state.isOther ? !state.other : !state.reason;

  const handleChangeReason = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === "Other") {
      setState({ ...state, isOther: true, reason: event.target.value });
    } else {
      setState({ isOther: false, reason: event.target.value, other: "" });
    }
  };

  const handleChangeOtherReason = (event: ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, other: event.target.value, isOther: true });
  };

  const handleSubmit = () => {
    doctorAppointmentCancelMutation({
      variables: {
        id,
        input: {
          cancellationReason: state.isOther ? state.other : state.reason,
        },
      },
    });
  };

  return {
    state,
    isOpen,
    title,
    reasons,
    disabled,
    handleSubmit,
    handleToggle,
    handleChangeOtherReason,
    handleChangeReason,
  };
};
