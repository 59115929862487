import { PayerCredentials, PayerCredentialsInput } from "@/schema/types";
import { IOptimaPayerCredentialUpsertFormValues } from "../forms/OptimaPayerCredentialUpsert/OptimaPayerCredentialUpsertFormSchema";
import { optimaEligabilityPayersTypeOptionsMap } from "@health/enum-options";
import { createBranchHealthLicenseAutocompleteOption } from "@health/autocompletes";

export const convertOptimaPayerCredentialToFormValues = (
  optimaPayerCredential: PayerCredentials,
  mode: "create" | "update"
): IOptimaPayerCredentialUpsertFormValues => {
  return {
    username: optimaPayerCredential.username || "",
    name: optimaEligabilityPayersTypeOptionsMap[optimaPayerCredential.name!],

    license: createBranchHealthLicenseAutocompleteOption({
      id: optimaPayerCredential.licence || "",
      healthLicense: optimaPayerCredential.licence || "",
      name: optimaPayerCredential.branchName || "",
      nameAr: optimaPayerCredential.branchName || optimaPayerCredential.licence || "",
    }),
    password: optimaPayerCredential.password || "",
    mode: mode,
  };
};

export const convertOptimaPayerCredentialFormValuesToBackEndValues = (
  values: IOptimaPayerCredentialUpsertFormValues
): PayerCredentialsInput => {
  return {
    password: values.password,
    username: values.username,
    name: values?.name?.value,
    licence: values.license?.value?.healthLicense,
  };
};
