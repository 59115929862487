import { useCallback, useEffect } from "react";
import { useTranslation } from "@toolkit/i18n";
import { useBreadCrumbs } from "@toolkit/ui";

export const useSetManualSubmittedOrderBreadcrumbs = (containerType: "LIST" | "CREATE" | "UPDATE", isMainSystemOrders) => {
  const { t } = useTranslation("provider");
  const { setBreadCrumb } = useBreadCrumbs();

  const breadcrumbTitle = isMainSystemOrders ? t("Orders") : t("Manual Submitted Orders");

  const setManualSubmittedOrderListContainerBreadcrumb = useCallback(() => {
    setBreadCrumb({
      title: breadcrumbTitle,
    });
  }, [breadcrumbTitle, setBreadCrumb]);

  useEffect(() => {
    if (containerType === "LIST") {
      setManualSubmittedOrderListContainerBreadcrumb();
    }
  }, [containerType, setManualSubmittedOrderListContainerBreadcrumb]);
};
