import { useNavigate } from "react-router-dom";
import { BranchListContainer, IBranchListContainerEvent } from "@health/domains";
import { useSetBranchesBreadcrumbs } from "../../hooks";
import { branchesPaths } from "../../constants";
import { useProviderBranchesInfoGetQuery } from "../../gql";

export const ProviderBranchListContainer = () => {
  const navigate = useNavigate();

  const onBranchListContainerChange = (event: IBranchListContainerEvent) => {
    if (event.type === "ADD_ITEM_CLICK") {
      navigate(branchesPaths.create.fullPath);
    } else if (event.type === "EDIT_ROW_CLICK") {
      navigate(branchesPaths.update.fullPathWithParams({ branchId: event.payload?.item?.id! }));
    }
  };

  useSetBranchesBreadcrumbs("LIST");

  const { data } = useProviderBranchesInfoGetQuery({});

  return <BranchListContainer vendorHasMultipleBranches={data?.me?.vendor?.hasMultipleBranches} onChange={onBranchListContainerChange} />;
};
