import { IAutocompleteFilter, getAutocompleteFilter, createAutocompleteOption } from "@toolkit/ui";
import { IOptimaPayerAutocomplete } from "./schema";
import { useOptimaPayersAutocompleteQuery } from "./gql";

export const createOptimaPayerAutocompleteOption = (payer: IOptimaPayerAutocomplete) => {
  return createAutocompleteOption(
    {
      id: payer?.id!,
      name: payer?.name!,
      licenseNumber: payer?.licenseNumber!,
    },
    "licenseNumber",
    item => item?.name!
  );
};

export const getOptimaPayersAutocompleteFilter = ({ name, multiple }: IAutocompleteFilter) => {
  return getAutocompleteFilter({
    name,
    multiple,
    accessor: "optimaPayers",
    query: useOptimaPayersAutocompleteQuery,
    labelBy: item => item?.name!,
    backendAccessor: ({ licenseNumber }) => licenseNumber,
    isFilterSearchKeyAQueryVariable: true,
    filterSearchKey: "nameContains",
  });
};
