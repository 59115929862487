import { useCustomFormContext } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Button, CustomTable, Grid, PlusIcon, Typography, useTheme } from "@toolkit/ui";
import { FieldArrayWithId, useFieldArray } from "react-hook-form";
import { ITransactionValidateRequestFormValues } from "../TransactionValidateRequest/TransactionValidateRequestFormSchema";
import {
  TransactionValidateRequestObservationUpsertModal,
  TransactionValidateRequestObservationUpsertModalApi,
} from "../../modals/TransactionValidateRequestObservationUpsertModal/TransactionValidateRequestObservationUpsertModal";
import {
  ITransactionValidateRequestObservationFormValues,
  ITransactionValidateRequestObservationUpsertFormEvent,
} from "../TransactionValidateRequestObservation/TransactionValidateRequestObservationFormSchema";
import { useTransactionValidateRequestObservationsColumns } from "./useTransactionValidateRequestObservationsColumns";

export const TransactionValidateRequestObservationForm = () => {
  const { t } = useTranslation("provider");
  const theme = useTheme();

  const form = useCustomFormContext<ITransactionValidateRequestFormValues>();
  const {
    control,
    isFormDisabled,
    formState: { errors },
  } = form;

  const { fields, append, remove, update } = useFieldArray<ITransactionValidateRequestFormValues, "observations">({
    control,
    name: "observations",
  });

  const onAddClick = () => {
    TransactionValidateRequestObservationUpsertModalApi.open();
  };

  const onObservationUpsertModalChange = (event: ITransactionValidateRequestObservationUpsertFormEvent) => {
    if (event.type === "CREATE") {
      append(event.payload.values);
      TransactionValidateRequestObservationUpsertModalApi.close();
    } else if (event.type === "UPDATE") {
      const updatedFieldIndex = fields.findIndex(field => field.id === event.payload.values.index);
      if (updatedFieldIndex > -1) {
        update(updatedFieldIndex, event.payload.values);
      }
      TransactionValidateRequestObservationUpsertModalApi.close();
    }
  };

  const onEditRowClick = (row: FieldArrayWithId<ITransactionValidateRequestObservationFormValues>) => {
    const item = fields.find(field => field.id === row.id) as ITransactionValidateRequestObservationFormValues | undefined;
    if (item) {
      TransactionValidateRequestObservationUpsertModalApi.open({ ...item, index: row.id });
    }
  };

  const onDeleteRowClick = (_: FieldArrayWithId<ITransactionValidateRequestObservationFormValues>, index: number) => {
    remove(index);
  };

  return (
    <Grid container spacing={2}>
      <TransactionValidateRequestObservationUpsertModal onChange={onObservationUpsertModalChange} />

      <Grid item xs={12}>
        <Grid container>
          <Grid xs={8} lg={10}>
            <Typography fontSize={theme.mixins.fonts.fontSize.lg} fontWeight={theme.mixins.fonts.fontWeight.medium}>
              {t("Observations")}
            </Typography>
          </Grid>

          {!isFormDisabled && (
            <Grid xs={4} lg={2}>
              <Button fullWidth onClick={onAddClick} startIcon={<PlusIcon />}>
                {t("Add Observation")}
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {errors.observations && (
          <Typography color='error' fontSize={theme.mixins.fonts.fontSize.sm}>
            {errors.observations.message}
          </Typography>
        )}
      </Grid>

      <Grid item xs={12}>
        <CustomTable
          data={fields}
          columns={useTransactionValidateRequestObservationsColumns()}
          isEditVisible
          isDeleteVisible
          isRowEditable={() => !isFormDisabled}
          isRowDeletable={() => !isFormDisabled}
          onEditRow={onEditRowClick}
          onDeleteRow={onDeleteRowClick}
          hasFooter={false}
          scrollable
        />
      </Grid>
    </Grid>
  );
};
