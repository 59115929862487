import { PatientHealthParameter } from "@/schema/types";
import { useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps, IconButton, MuiVisibilityIcon } from "@toolkit/ui";
import { isEqual } from "lodash";
import { useMemo } from "react";

type PatientHealthParameterHistoryAudioColumnsProps = {
  selectedItem?: PatientHealthParameter;
  onChange: (value: PatientHealthParameter) => void;
};

export const usePatientHealthParameterHistoryNumericColumns = (
  props: PatientHealthParameterHistoryAudioColumnsProps
): CustomTableColumnProps<PatientHealthParameter>[] => {
  const { selectedItem, onChange } = props;

  const { t } = useTranslation("provider");

  return useMemo(() => {
    return [
      {
        key: "source",
        header: t("Source"),
        accessor: "source",
      },
      {
        key: "date",
        header: t("Date"),
        accessor: "createdDate",
        type: "date",
      },
      {
        key: "preview",
        header: t("Preview"),
        accessor: item => {
          return (
            <IconButton disabled={isEqual(item?.valueNumericList, selectedItem?.valueNumericList)} onClick={() => onChange(item)}>
              <MuiVisibilityIcon color={"primary"} />
            </IconButton>
          );
        },
      },
    ];
  }, [onChange, selectedItem, t]);
};
