import { z } from "zod";

export const transactionValidateRequestDiagnosisFormSchema = z.object({
  sequence: z.string(),
  code: z.string(),
  display: z.string(),
  codeSystem: z.string(),
  type: z.string(),
  onAdmission: z.boolean(),
});

export type ITransactionValidateRequestDiagnosisFormValues = z.infer<typeof transactionValidateRequestDiagnosisFormSchema>;

export const transactionValidateRequestDiagnosisFormDefaultValues: ITransactionValidateRequestDiagnosisFormValues = {
  sequence: "1",
  code: "H57.9",
  display: "Unspecified Disorder of Eye and Adnexa",
  codeSystem: "ICD10CM",
  type: "Primary",
  onAdmission: true,
};

export type ITransactionValidateRequestDiagnosisUpsertFormEvent =
  | { type: "CREATE"; payload: { values: ITransactionValidateRequestDiagnosisFormValues } }
  | { type: "UPDATE"; payload: { values: ITransactionValidateRequestDiagnosisFormValues & { index: string } } };
