import { PayerCredentials } from "@/schema/types";
import { formatGraphQLError } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import { useNavigate, useParams } from "react-router-dom";
import { IOptimaPayerCredentialUpdateContainerParams } from "../../types";
import {
  OptimaPayerCredentialUpsertForm,
  IOptimaPayerCredentialUpsertFormEvent,
} from "../../forms/OptimaPayerCredentialUpsert/OptimaPayerCredentialUpsertForm";
import { convertOptimaPayerCredentialFormValuesToBackEndValues } from "../../others";
import { useOptimaPayerCredentialGetQuery } from "../../gql/queries/__generated__/OptimaPayerCredentialGet";
import { useSetOptimaPayerCredentialBreadcrumbs } from "../../hooks/useSetOptimaPayerCredentialBreadcrumbs";
import { useOptimaPayerCredentialUpdateMutation } from "../../gql/mutations/__generated__/OptimaPayerCredentialUpdate";

export const OptimaPayerCredentialUpdateContainer = () => {
  const { t } = useTranslation("provider");
  const navigate = useNavigate();
  const { succeeded, failed } = useAddToast();

  const { payerCredentialsId } = useParams<IOptimaPayerCredentialUpdateContainerParams>();

  const { data, loading } = useOptimaPayerCredentialGetQuery({
    variables: { payerCredentialsId: payerCredentialsId! },
    skip: !payerCredentialsId,
    fetchPolicy: "no-cache",
  });

  const optimaPayerCredential = data?.payerCredentials as PayerCredentials;

  const [updateOptimaPayerCredential, { loading: isSubmitting }] = useOptimaPayerCredentialUpdateMutation({
    onCompleted: mutationData => {
      if (mutationData?.payerCredentialsUpdate?.id) {
        succeeded(t("Optima payer credentials updated successfully"));
        navigate(-1);
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  const onOptimaPayerCredentialUpsertFormChange = (event: IOptimaPayerCredentialUpsertFormEvent) => {
    if (event.type === "SUBMIT") {
      const values = convertOptimaPayerCredentialFormValuesToBackEndValues(event.payload.values);

      updateOptimaPayerCredential({
        variables: {
          payerCredentialsUpdateId: payerCredentialsId!,
          input: values,
        },
      });
    }
  };

  useSetOptimaPayerCredentialBreadcrumbs("UPDATE", optimaPayerCredential?.name || "");

  return (
    <OptimaPayerCredentialUpsertForm
      mode='update'
      buttonLabel={t("Update")}
      optimaPayerCredential={optimaPayerCredential}
      isLoading={loading}
      isSubmitting={isSubmitting}
      onChange={onOptimaPayerCredentialUpsertFormChange}
    />
  );
};
