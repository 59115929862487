import { OptimaDiagnosis } from "@/schema/types";
import { Box, CustomTable } from "@toolkit/ui";
import { useOptimaDiagnosesColumns } from "./useOptimaVisitDiagnosesListColumns";
import { FC } from "react";

interface OptimaVisitDiagnosesListProps {
  diagnoses: OptimaDiagnosis[];
}

export const OptimaVisitDiagnosesList: FC<OptimaVisitDiagnosesListProps> = props => {
  const { diagnoses } = props;
  return (
    <Box>
      <CustomTable
        columns={useOptimaDiagnosesColumns()}
        data={diagnoses}
        TableContainerProps={{
          sx: {
            height: 250,
          },
        }}
      />
    </Box>
  );
};
