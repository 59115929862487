import { useCustomFormContext } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Button, CustomTable, Grid, PlusIcon, Typography, useTheme } from "@toolkit/ui";
import { FieldArrayWithId, useFieldArray } from "react-hook-form";
import {
  ITransactionValidateRequestDiagnosisFormValues,
  ITransactionValidateRequestDiagnosisUpsertFormEvent,
} from "../TransactionValidateRequestDiagnosisUpsert/TransactionValidateRequestDiagnosisFormSchema";
import {
  TransactionValidateRequestDiagnosisUpsertModal,
  TransactionValidateRequestDiagnosisUpsertModalApi,
} from "../../modals/TransactionValidateRequestDiagnosisUpsertModal/TransactionValidateRequestDiagnosisUpsertModal";
import { useTransactionValidateRequestDiagnosisColumns } from "./useTransactionValidateRequestDiagnosisColumns";
import { ITransactionValidateRequestFormValues } from "../TransactionValidateRequest/TransactionValidateRequestFormSchema";

export const TransactionValidateRequestDiagnosisForm = () => {
  const { t } = useTranslation("provider");
  const theme = useTheme();

  const form = useCustomFormContext<ITransactionValidateRequestFormValues>();
  const {
    control,
    isFormDisabled,
    formState: { errors },
  } = form;

  const { fields, append, remove, update } = useFieldArray<ITransactionValidateRequestFormValues, "diagnoses">({
    control,
    name: "diagnoses",
  });

  const onAddClick = () => {
    TransactionValidateRequestDiagnosisUpsertModalApi.open();
  };

  const onDiagnosisUpsertModalChange = (event: ITransactionValidateRequestDiagnosisUpsertFormEvent) => {
    if (event.type === "CREATE") {
      append(event.payload.values);
      TransactionValidateRequestDiagnosisUpsertModalApi.close();
    } else if (event.type === "UPDATE") {
      const updatedFieldIndex = fields.findIndex(field => field.id === event.payload.values.index);
      if (updatedFieldIndex > -1) {
        update(updatedFieldIndex, event.payload.values);
      }
      TransactionValidateRequestDiagnosisUpsertModalApi.close();
    }
  };

  const onEditRowClick = (row: FieldArrayWithId<ITransactionValidateRequestDiagnosisFormValues>) => {
    const item = fields.find(field => field.id === row.id) as ITransactionValidateRequestDiagnosisFormValues | undefined;
    if (item) {
      TransactionValidateRequestDiagnosisUpsertModalApi.open({ ...item, index: row.id });
    }
  };

  const onDeleteRowClick = (_: FieldArrayWithId<ITransactionValidateRequestDiagnosisFormValues>, index: number) => {
    remove(index);
  };

  return (
    <Grid container spacing={2}>
      <TransactionValidateRequestDiagnosisUpsertModal onChange={onDiagnosisUpsertModalChange} />

      <Grid item xs={12}>
        <Grid container>
          <Grid xs={8} lg={10}>
            <Typography fontSize={theme.mixins.fonts.fontSize.lg} fontWeight={theme.mixins.fonts.fontWeight.medium}>
              {" "}
              {t("Diagnoses")}{" "}
            </Typography>
          </Grid>

          {!isFormDisabled && (
            <Grid xs={4} lg={2}>
              <Button fullWidth onClick={onAddClick} startIcon={<PlusIcon />}>
                {t("Add Diagnosis")}
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {errors.diagnoses && (
          <Typography color='error' fontSize={theme.mixins.fonts.fontSize.sm}>
            {errors?.diagnoses?.message}
          </Typography>
        )}
      </Grid>

      <Grid item xs={12}>
        <CustomTable
          data={fields}
          columns={useTransactionValidateRequestDiagnosisColumns()}
          isEditVisible
          isDeleteVisible
          isRowEditable={() => !isFormDisabled}
          isRowDeletable={() => !isFormDisabled}
          onEditRow={onEditRowClick}
          onDeleteRow={onDeleteRowClick}
          hasFooter={false}
          scrollable
        />
      </Grid>
    </Grid>
  );
};
