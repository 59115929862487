import { FC } from "react";
import { FormTextField, Grid, FormCard } from "@toolkit/ui";
import { useTranslation } from "@toolkit/i18n";

export const InsuranceInformationForm: FC = () => {
  const { t } = useTranslation("provider");

  return (
    <FormCard title={t("Insurance Information")} loading={false} doYouHaveData>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <FormTextField name='insurancePayer' label={t("Payer")} placeholder={t("Payer")} />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormTextField name='insuranceReceiver' label={t("Receiver")} placeholder={t("Receiver")} />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormTextField name='insuranceMemberId' label={t("Member ID")} placeholder={t("Member ID")} />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormTextField name='insurancePolicy' label={t("Policy")} placeholder={t("Policy")} />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormTextField name='insurancePolicyLocation' label={t("Policy Location")} placeholder={t("Policy Location")} />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormTextField name='insurancePlan' label={t("Plan")} placeholder={t("Plan")} />
        </Grid>
      </Grid>
    </FormCard>
  );
};
