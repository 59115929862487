import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SetAuthorizationApprovalMutationVariables = Types.Exact<{
  orderId: Types.Scalars['ID']['input'];
  note?: Types.InputMaybe<Types.Scalars['String']['input']>;
  isApproved?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type SetAuthorizationApprovalMutation = { __typename?: 'Mutation', setAuthorizationApproval?: { __typename?: 'ManualOrder', id: string, status: Types.ManualOrderStatus } | null };


export const SetAuthorizationApprovalDocument = gql`
    mutation SetAuthorizationApproval($orderId: ID!, $note: String, $isApproved: Boolean) {
  setAuthorizationApproval(
    orderId: $orderId
    note: $note
    isApproved: $isApproved
  ) {
    id
    status
  }
}
    `;
export type SetAuthorizationApprovalMutationFn = Apollo.MutationFunction<SetAuthorizationApprovalMutation, SetAuthorizationApprovalMutationVariables>;

/**
 * __useSetAuthorizationApprovalMutation__
 *
 * To run a mutation, you first call `useSetAuthorizationApprovalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetAuthorizationApprovalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setAuthorizationApprovalMutation, { data, loading, error }] = useSetAuthorizationApprovalMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *      note: // value for 'note'
 *      isApproved: // value for 'isApproved'
 *   },
 * });
 */
export function useSetAuthorizationApprovalMutation(baseOptions?: Apollo.MutationHookOptions<SetAuthorizationApprovalMutation, SetAuthorizationApprovalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetAuthorizationApprovalMutation, SetAuthorizationApprovalMutationVariables>(SetAuthorizationApprovalDocument, options);
      }
export type SetAuthorizationApprovalMutationHookResult = ReturnType<typeof useSetAuthorizationApprovalMutation>;
export type SetAuthorizationApprovalMutationResult = Apollo.MutationResult<SetAuthorizationApprovalMutation>;
export type SetAuthorizationApprovalMutationOptions = Apollo.BaseMutationOptions<SetAuthorizationApprovalMutation, SetAuthorizationApprovalMutationVariables>;