import { useCustomForm } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FormTextField, Grid } from "@toolkit/ui";
import { ForwardRefRenderFunction, forwardRef, useEffect, useImperativeHandle } from "react";
import { FormProvider, UseFormReturn } from "react-hook-form";
import {
  ITransactionValidateRequestCareTeamFormValues,
  ITransactionValidateRequestCareTeamUpsertFormEvent,
  transactionValidateRequestCareTeamFormDefaultValues,
  transactionValidateRequestCareTeamFormSchema,
} from "./TransactionValidateRequestCareTeamFormSchema";

type TransactionValidateRequestCareTeamUpsertFormProps = {
  careTeamMember?: ITransactionValidateRequestCareTeamFormValues;
  index?: string;
  onChange: (event: ITransactionValidateRequestCareTeamUpsertFormEvent) => void;
};

export type TransactionValidateRequestCareTeamUpsertFormRef = {
  getForm: () => UseFormReturn<ITransactionValidateRequestCareTeamFormValues>;
  submit: () => void;
};

const TransactionValidateRequestCareTeamUpsertFormForwardRef: ForwardRefRenderFunction<
  TransactionValidateRequestCareTeamUpsertFormRef,
  TransactionValidateRequestCareTeamUpsertFormProps
> = (props, ref) => {
  const { careTeamMember, index, onChange } = props;

  const { t } = useTranslation("provider");

  const form = useCustomForm<ITransactionValidateRequestCareTeamFormValues>({
    defaultValues: transactionValidateRequestCareTeamFormDefaultValues,
    schema: transactionValidateRequestCareTeamFormSchema,
  });

  const { setValues, handleSubmit } = form;

  const onSubmit = (values: ITransactionValidateRequestCareTeamFormValues) => {
    if (careTeamMember) {
      if (index) {
        onChange({
          type: "UPDATE",
          payload: { values: { ...values, index } },
        });
      }
    } else {
      onChange({
        type: "CREATE",
        payload: { values },
      });
    }
  };

  useImperativeHandle(ref, () => ({
    getForm: () => form,
    submit: handleSubmit(onSubmit),
  }));

  useEffect(() => {
    if (careTeamMember) {
      setValues(careTeamMember);
    }
  }, [careTeamMember, setValues]);

  return (
    <FormProvider {...form}>
      <form>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormTextField name='sequence' label={t("Sequence")} placeholder={t("Sequence")} />
          </Grid>
          <Grid item xs={12}>
            <FormTextField name='practitionerId' label={t("Practitioner ID")} placeholder={t("Practitioner ID")} />
          </Grid>
          <Grid item xs={12}>
            <FormTextField name='practitionerRole' label={t("Practitioner Role")} placeholder={t("Practitioner Role")} />
          </Grid>
          <Grid item xs={12}>
            <FormTextField name='speciality' label={t("Speciality")} placeholder={t("Speciality")} />
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export const TransactionValidateRequestCareTeamUpsertForm = forwardRef(TransactionValidateRequestCareTeamUpsertFormForwardRef);
