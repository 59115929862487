import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import { PageInfoFragmentFragmentDoc } from '../../../../../gql/fragments/__generated__/pageInfoFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ManualSubmittedOrderListQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  filter?: Types.InputMaybe<Types.ManualOrderFilterInput>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  sortBy?: Types.InputMaybe<Types.ManualOrderSortingInput>;
}>;


export type ManualSubmittedOrderListQuery = { __typename?: 'Query', manualOrders?: { __typename?: 'ManualOrderCountableConnection', edges: Array<{ __typename?: 'ManualOrderCountableEdge', node: { __typename?: 'ManualOrder', id: string, attachmentUrl?: string | null, type: Types.OrderType, deliveryDate?: any | null, created: any, status: Types.ManualOrderStatus, user: { __typename?: 'User', id: string, fullName?: string | null }, healthProgramMember?: { __typename?: 'HealthProgramMember', id: string, insuranceId?: string | null } | null, address?: { __typename?: 'PrescriptionAddress', streetAddress1?: string | null, coordinates?: string | null, id: string } | null, deliveryTimeSlot?: { __typename?: 'DeliveryTimeSlot', endTime: string, id: string, startTime: string } | null, notes?: Array<{ __typename?: 'Note', id: string, text: string, created: any, createdBy?: { __typename?: 'User', fullName?: string | null } | null } | null> | null, events?: Array<{ __typename?: 'ManualOrderEvent', id: string, status: Types.ManualOrderStatus, date: any, user: { __typename?: 'User', id: string, fullName?: string | null, defaultBranch?: { __typename?: 'Branch', id: string, name: string, nameAr?: string | null } | null } } | null> | null, branch?: { __typename?: 'Branch', id: string, name: string, nameAr?: string | null } | null } }>, pageInfo?: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null } | null } | null };


export const ManualSubmittedOrderListDocument = gql`
    query ManualSubmittedOrderList($after: String, $before: String, $filter: ManualOrderFilterInput, $first: Int, $last: Int, $sortBy: ManualOrderSortingInput) {
  manualOrders(
    after: $after
    before: $before
    filter: $filter
    first: $first
    last: $last
    sortBy: $sortBy
  ) {
    edges {
      node {
        id
        attachmentUrl
        user {
          id
          fullName
        }
        healthProgramMember {
          id
          insuranceId
        }
        type
        address {
          streetAddress1
          coordinates
          id
        }
        deliveryDate
        deliveryTimeSlot {
          endTime
          id
          startTime
        }
        created
        notes {
          id
          text
          created
          createdBy {
            fullName
          }
        }
        status
        events {
          id
          status
          date
          user {
            id
            fullName
            defaultBranch {
              id
              name
              nameAr
            }
          }
        }
        branch {
          id
          name
          nameAr
        }
      }
    }
    pageInfo {
      ...PageInfoFragment
    }
  }
}
    ${PageInfoFragmentFragmentDoc}`;

/**
 * __useManualSubmittedOrderListQuery__
 *
 * To run a query within a React component, call `useManualSubmittedOrderListQuery` and pass it any options that fit your needs.
 * When your component renders, `useManualSubmittedOrderListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useManualSubmittedOrderListQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useManualSubmittedOrderListQuery(baseOptions?: Apollo.QueryHookOptions<ManualSubmittedOrderListQuery, ManualSubmittedOrderListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ManualSubmittedOrderListQuery, ManualSubmittedOrderListQueryVariables>(ManualSubmittedOrderListDocument, options);
      }
export function useManualSubmittedOrderListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ManualSubmittedOrderListQuery, ManualSubmittedOrderListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ManualSubmittedOrderListQuery, ManualSubmittedOrderListQueryVariables>(ManualSubmittedOrderListDocument, options);
        }
export function useManualSubmittedOrderListSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ManualSubmittedOrderListQuery, ManualSubmittedOrderListQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ManualSubmittedOrderListQuery, ManualSubmittedOrderListQueryVariables>(ManualSubmittedOrderListDocument, options);
        }
export type ManualSubmittedOrderListQueryHookResult = ReturnType<typeof useManualSubmittedOrderListQuery>;
export type ManualSubmittedOrderListLazyQueryHookResult = ReturnType<typeof useManualSubmittedOrderListLazyQuery>;
export type ManualSubmittedOrderListSuspenseQueryHookResult = ReturnType<typeof useManualSubmittedOrderListSuspenseQuery>;
export type ManualSubmittedOrderListQueryResult = Apollo.QueryResult<ManualSubmittedOrderListQuery, ManualSubmittedOrderListQueryVariables>;