/* eslint-disable sonarjs/cognitive-complexity */
import { useMemo } from "react";
import { CustomTableColumnProps } from "@toolkit/ui";
import { Note } from "../../types";
import { useTranslation } from "@toolkit/i18n";
export const useOrderNotesListContainerColumns = (): CustomTableColumnProps<Note>[] => {
  const { t } = useTranslation("provider");
  return useMemo(() => {
    return [
      {
        key: "Note",
        header: t("Note"),
        type: "truncated-text",
        accessor: ({ text }) => text,
      },
      {
        key: "Created-by",
        header: t("Created by"),
        accessor: ({ createdBy }) => createdBy?.fullName,
      },
      {
        key: "Created-date",
        header: t("Date"),
        type: "date",
        accessor: ({ created }) => created,
      },
      {
        key: "Created-time",
        header: t("Time"),
        type: "time",
        accessor: ({ created }) => created,
      },
    ];
  }, [t]);
};
