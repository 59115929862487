import { PermissionEnum } from "@/schema/types";
import { hasPermission } from "@toolkit/core";
import { PageWrapper } from "@toolkit/ui";
import GeneralSettings from "pages/Settings/GeneralSettings";
import AppointmentsRemindersSettings from "./AppointmentsRemindersSettings";
import { SettingsTabs } from "./components/SettingsTabs";
import { SettingsForPayment } from "./ProviderPaymentSettings";
import { useSettingsPageTabs } from "./useSettingsPageTabs";
import { SettingsForCalls } from "./ProviderCallsSettings";

export const SettingsPage: React.FC = () => {
  const { selectedTab, handleChangeTab } = useSettingsPageTabs();

  const shouldShowRemindersTab = hasPermission(PermissionEnum.ManageSettings);

  return (
    <PageWrapper
      headerComponent={
        <SettingsTabs selectedTab={selectedTab} onChangeTab={handleChangeTab} shouldShowRemindersTab={shouldShowRemindersTab} />
      }
    >
      {selectedTab === "General" && <GeneralSettings />}
      {selectedTab === "AppointmentsReminder" && shouldShowRemindersTab && <AppointmentsRemindersSettings />}
      {selectedTab === "PaymentSettings" && <SettingsForPayment />}
      {selectedTab === "CallsSettings" && <SettingsForCalls />}
    </PageWrapper>
  );
};

export default SettingsPage;
