import { MarketplaceProduct } from "@/schema/types";
import { pickLocalizedValue } from "@toolkit/i18n";
import { InfoItems } from "@toolkit/ui";
import { Maybe } from "@/schema/types";

export const getMarketplaceItemInfoItems = (items?: Array<Maybe<Pick<MarketplaceProduct, "name" | "nameAr"> | null>>): InfoItems => {
  const _items = items?.map(item => pickLocalizedValue(item?.name!, item?.nameAr!));
  return items
    ? [
        {
          value: _items,
          valueDisplayMode: "list",
        },
      ]
    : undefined;
};
