import { Box, CustomTable, Typography } from "@toolkit/ui";
import { FC } from "react";
import { useRemittanceActivitiesList } from "./useRemittanceActivitiesList.hook";
import { useRemittanceDrawerStyles } from "@/pages/OptimaClaims/Components/RemittanceDrawer/RemittanceDrawerStyles";

export const RemittanceActivitiesList: FC<{ id?: string }> = props => {
  const { id } = props;
  const { t, tableData, hasNextPage, hasPreviousPage, isLoading, handleGotoNext, handleGoToPrevious, pageSize, columns, totalCount } =
    useRemittanceActivitiesList(id);
  const { classes } = useRemittanceDrawerStyles();
  return (
    <Box>
      <Typography mb={2} className={classes.sectionTitle}>
        {t("Remittance Activities: ({{totalCount}})", { totalCount })}
      </Typography>
      <CustomTable
        isLoading={isLoading}
        data={tableData}
        columns={columns}
        pageSize={pageSize}
        pageIndex={0}
        pagesCount={pageSize}
        hasFooter={true}
        hasNextPage={hasNextPage}
        hasPreviousPage={hasPreviousPage}
        onGoToNext={handleGotoNext}
        onGoToPrevious={handleGoToPrevious}
        TableContainerProps={{
          sx: {
            height: 237.5,
          },
        }}
        withoutDataMessage={t("No Activities")}
        emptyIconHeight={100}
      />
    </Box>
  );
};
