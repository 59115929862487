import { departmentsRoutes } from "@/pages/HealthProviderManagement/Departments/constants/DepartmentsRoutes";
import { usersRoutes } from "@/pages/HealthProviderManagement/Users/constants/UsersRoutes";
import { PermissionEnum } from "@/schema/types";
import { hasAnyPermission, hasPermission } from "@toolkit/core";
import { TFunction } from "@toolkit/i18n";
import { ListViewIcon } from "@toolkit/ui";
import { NavigateFunction } from "react-router-dom";
import RouteItem from "shared/components/Root/routeTypes";
import {
  GuidedCareTeamCreateContainer,
  GuidedCareTeamListContainer,
  GuidedCareTeamPatientListContainer,
  GuidedCareTeamUpdateContainer,
} from "../GuidedCare/containers";
import {
  healthProviderGuidedCareTeamsPaths,
  healthProviderGuidedCareTeamsRoute,
  healthProviderManagementRoute,
} from "./HealthProvidersManagementPaths";
import { branchesRoutes } from "@/pages/HealthProviderManagement/Branches/constants";

export const healthProvidersManagementRoutes: (props: { navigate: NavigateFunction; t: TFunction }) => RouteItem = ({ navigate, t }) => {
  return {
    id: "health-providers-management-routes",
    text: t("Health Providers Management", { ns: "provider" }),
    icon: <ListViewIcon />,
    isProhibited: !hasAnyPermission([
      PermissionEnum.ManageBranches,
      PermissionEnum.ManageDepartments,
      PermissionEnum.ManageProgramTeams,
      PermissionEnum.ManageUsers,
    ]),
    hidden: !hasAnyPermission([
      PermissionEnum.ManageBranches,
      PermissionEnum.ManageDepartments,
      PermissionEnum.ManageProgramTeams,
      PermissionEnum.ManageUsers,
    ]),
    route: healthProviderManagementRoute,
    subItems: [
      branchesRoutes(navigate),
      departmentsRoutes(navigate),
      {
        id: "guided-care-teams-routes",
        text: t("Care Teams", { ns: "provider" }),
        route: healthProviderGuidedCareTeamsRoute,
        isProhibited: !hasPermission(PermissionEnum.ManageProgramTeams),
        hidden: !hasPermission(PermissionEnum.ManageProgramTeams),
        subItems: [
          {
            id: "guided-care-team-list-route",
            text: "",
            route: healthProviderGuidedCareTeamsPaths.main.route,
            fullPath: healthProviderGuidedCareTeamsPaths.main.fullPath,
            icon: <ListViewIcon />,
            element: <GuidedCareTeamListContainer />,
            onClick: (route: string) => navigate(route),
          },
          {
            id: "guided-care-templates-new-route",
            text: "",
            route: healthProviderGuidedCareTeamsPaths.new.route,
            fullPath: healthProviderGuidedCareTeamsPaths.new.fullPath,
            icon: <ListViewIcon />,
            element: <GuidedCareTeamCreateContainer />,
            hidden: true,
            onClick: (route: string) => navigate(route),
          },
          {
            id: "guided-care-templates-edit-route",
            text: "",
            route: healthProviderGuidedCareTeamsPaths.edit.route,
            fullPath: healthProviderGuidedCareTeamsPaths.edit.fullPath,
            icon: <ListViewIcon />,
            element: <GuidedCareTeamUpdateContainer />,
            hidden: true,
            onClick: (route: string) => navigate(route),
          },
          {
            id: "guided-care-templates-patients-route",
            text: "",
            route: healthProviderGuidedCareTeamsPaths.patients.route,
            fullPath: healthProviderGuidedCareTeamsPaths.patients.fullPath,
            icon: <ListViewIcon />,
            element: <GuidedCareTeamPatientListContainer />,
            hidden: true,
            onClick: (route: string) => navigate(route),
          },
        ],
      },
      usersRoutes(navigate),
    ],
  };
};
