import { ConferenceConfiguration } from "@/schema/types";
import { formGirdBreakPoints, useCustomFormContext } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Grid, TextField } from "@toolkit/ui";
import { FC } from "react";
import { IVirtualIntegrationFormValues } from "./VirtualIntegrationFormSchema";

type SettingsForCallsFormProps = {
  callsIntegration: ConferenceConfiguration | undefined;
};
export const SettingsForCallsForm: FC<SettingsForCallsFormProps> = ({ callsIntegration }) => {
  const { t } = useTranslation("provider");
  const {
    register,
    formState: { errors },
  } = useCustomFormContext<IVirtualIntegrationFormValues>();

  return (
    <>
      <Grid container spacing={2}>
        <Grid item {...formGirdBreakPoints}>
          <TextField
            type='number'
            defaultValue={Number(callsIntegration?.expirationDuration)}
            label={t("Expiration Duration")}
            fullWidth
            {...register("expirationDuration")}
            error={Boolean(errors?.expirationDuration?.message)}
            helperText={String(errors?.expirationDuration?.message || "")}
          />
        </Grid>
      </Grid>
    </>
  );
};
