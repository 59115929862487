import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OptimaPayerCredentialGetQueryVariables = Types.Exact<{
  payerCredentialsId: Types.Scalars['ID']['input'];
}>;


export type OptimaPayerCredentialGetQuery = { __typename?: 'Query', payerCredentials?: { __typename?: 'PayerCredentials', id: string, licence?: string | null, name?: Types.EligabilityPayersEnum | null, username?: string | null, vendorId?: number | null, branchName?: string | null, password?: string | null } | null };


export const OptimaPayerCredentialGetDocument = gql`
    query OptimaPayerCredentialGet($payerCredentialsId: ID!) {
  payerCredentials(id: $payerCredentialsId) {
    id
    licence
    name
    username
    vendorId
    branchName
    password
  }
}
    `;

/**
 * __useOptimaPayerCredentialGetQuery__
 *
 * To run a query within a React component, call `useOptimaPayerCredentialGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useOptimaPayerCredentialGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOptimaPayerCredentialGetQuery({
 *   variables: {
 *      payerCredentialsId: // value for 'payerCredentialsId'
 *   },
 * });
 */
export function useOptimaPayerCredentialGetQuery(baseOptions: Apollo.QueryHookOptions<OptimaPayerCredentialGetQuery, OptimaPayerCredentialGetQueryVariables> & ({ variables: OptimaPayerCredentialGetQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OptimaPayerCredentialGetQuery, OptimaPayerCredentialGetQueryVariables>(OptimaPayerCredentialGetDocument, options);
      }
export function useOptimaPayerCredentialGetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OptimaPayerCredentialGetQuery, OptimaPayerCredentialGetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OptimaPayerCredentialGetQuery, OptimaPayerCredentialGetQueryVariables>(OptimaPayerCredentialGetDocument, options);
        }
export function useOptimaPayerCredentialGetSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<OptimaPayerCredentialGetQuery, OptimaPayerCredentialGetQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OptimaPayerCredentialGetQuery, OptimaPayerCredentialGetQueryVariables>(OptimaPayerCredentialGetDocument, options);
        }
export type OptimaPayerCredentialGetQueryHookResult = ReturnType<typeof useOptimaPayerCredentialGetQuery>;
export type OptimaPayerCredentialGetLazyQueryHookResult = ReturnType<typeof useOptimaPayerCredentialGetLazyQuery>;
export type OptimaPayerCredentialGetSuspenseQueryHookResult = ReturnType<typeof useOptimaPayerCredentialGetSuspenseQuery>;
export type OptimaPayerCredentialGetQueryResult = Apollo.QueryResult<OptimaPayerCredentialGetQuery, OptimaPayerCredentialGetQueryVariables>;