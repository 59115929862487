import { useTranslation } from "@toolkit/i18n";
import { useState } from "react";
import { usePriorAuthorizationActivitiesListContainerColumns } from "./usePriorAuthorizationActivitiesListContainerColumns";
import { OptimaPriorAuthorizationActivitiesQuery, useOptimaPriorAuthorizationActivitiesQuery } from "@/pages/OptimaPriorRequests/gql";
import { ExtractNodeType } from "@toolkit/apollo";

type InputState = {
  first: number | null;
  after: string | null;
  before: string | null;
  last: number | null;
  filter: Record<"priorAuthorizationId", string>;
};

export const usePriorAuthorizationActivitiesList = (id?: string) => {
  const { t } = useTranslation("domains");
  const columns = usePriorAuthorizationActivitiesListContainerColumns();
  const pageSize = 3;
  const [input, setInput] = useState<InputState>({
    first: pageSize,
    after: null,
    before: null,
    last: null,
    filter: {
      priorAuthorizationId: id as string,
    },
  });
  const { data: activitiesData, loading: isLoading } = useOptimaPriorAuthorizationActivitiesQuery({
    variables: input,
    fetchPolicy: "no-cache",
  });
  const pageInfo = activitiesData?.optimaPriorAuthorizationActivities?.pageInfo;
  const tableData = activitiesData?.optimaPriorAuthorizationActivities?.edges?.map(
    e => e?.node
  ) as ExtractNodeType<OptimaPriorAuthorizationActivitiesQuery>[];
  const hasNextPage = pageInfo?.hasNextPage;
  const hasPreviousPage = pageInfo?.hasPreviousPage;
  const handleGotoNext = () => {
    setInput(prev => ({
      ...prev,
      first: pageSize,
      after: pageInfo?.endCursor || null,
      last: null,
      before: null,
    }));
  };

  const handleGoToPrevious = () => {
    setInput(prev => ({
      ...prev,
      last: pageSize,
      before: pageInfo?.startCursor || null,
      first: null,
      after: null,
    }));
  };
  const totalCount = activitiesData?.optimaPriorAuthorizationActivities?.totalCount || "0";
  return {
    t,
    tableData,
    hasNextPage,
    hasPreviousPage,
    isLoading,
    handleGotoNext,
    handleGoToPrevious,
    columns,
    pageSize,
    totalCount,
  };
};
