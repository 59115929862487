import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RequestPatientApprovalManualOrderMutationVariables = Types.Exact<{
  orderId: Types.Scalars['ID']['input'];
  note?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type RequestPatientApprovalManualOrderMutation = { __typename?: 'Mutation', requestPatientApprovalManualOrder?: { __typename?: 'ManualOrder', id: string, status: Types.ManualOrderStatus } | null };


export const RequestPatientApprovalManualOrderDocument = gql`
    mutation RequestPatientApprovalManualOrder($orderId: ID!, $note: String) {
  requestPatientApprovalManualOrder(orderId: $orderId, note: $note) {
    id
    status
  }
}
    `;
export type RequestPatientApprovalManualOrderMutationFn = Apollo.MutationFunction<RequestPatientApprovalManualOrderMutation, RequestPatientApprovalManualOrderMutationVariables>;

/**
 * __useRequestPatientApprovalManualOrderMutation__
 *
 * To run a mutation, you first call `useRequestPatientApprovalManualOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestPatientApprovalManualOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestPatientApprovalManualOrderMutation, { data, loading, error }] = useRequestPatientApprovalManualOrderMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *      note: // value for 'note'
 *   },
 * });
 */
export function useRequestPatientApprovalManualOrderMutation(baseOptions?: Apollo.MutationHookOptions<RequestPatientApprovalManualOrderMutation, RequestPatientApprovalManualOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestPatientApprovalManualOrderMutation, RequestPatientApprovalManualOrderMutationVariables>(RequestPatientApprovalManualOrderDocument, options);
      }
export type RequestPatientApprovalManualOrderMutationHookResult = ReturnType<typeof useRequestPatientApprovalManualOrderMutation>;
export type RequestPatientApprovalManualOrderMutationResult = Apollo.MutationResult<RequestPatientApprovalManualOrderMutation>;
export type RequestPatientApprovalManualOrderMutationOptions = Apollo.BaseMutationOptions<RequestPatientApprovalManualOrderMutation, RequestPatientApprovalManualOrderMutationVariables>;