import { Box, Typography, AutocompleteComponent } from "@toolkit/ui";
import { useTranslation } from "@toolkit/i18n";
import { patientEligibilityBenefitsEnumOptions } from "@health/enum-options";
import moment from "moment";
import { FC } from "react";
import { useBasicInfoSectionStyles } from "./useBasicInfoSection.styles";
import {
  IOptimaBenefitOptionType,
  IOptimaBenefitEligibilityType,
  IOptimaPatientEligibilityRequestHistoryDataType,
} from "@/pages/OptimaPatientEligibilityScan/types";

type BasicInfoSectionProps = {
  benefit: IOptimaBenefitOptionType;
  benefitData?: IOptimaBenefitEligibilityType;
  nodeData: IOptimaPatientEligibilityRequestHistoryDataType;
  handleBenefitAutocompleteChange: (event: React.SyntheticEvent, value: IOptimaBenefitOptionType | null) => void;
};

export const BasicInfoSection: FC<BasicInfoSectionProps> = ({ benefit, benefitData, nodeData, handleBenefitAutocompleteChange }) => {
  const { t } = useTranslation("optima");
  const { classes } = useBasicInfoSectionStyles();

  return (
    <Box sx={{ display: "flex", columnGap: 4, my: 1, mx: 1, alignItems: "center" }}>
      <AutocompleteComponent
        options={patientEligibilityBenefitsEnumOptions}
        getOptionLabel={option => option?.label}
        TextFieldProps={{
          placeholder: t("Benefit"),
          sx: { maxWidth: 200 },
        }}
        sx={{ maxWidth: 200 }}
        value={benefit}
        onChange={handleBenefitAutocompleteChange}
      />

      <Typography>
        <b>{t("Patient")}:</b> {nodeData?.patientEligibility?.firstName} {nodeData?.patientEligibility?.lastName}
      </Typography>

      <Typography>
        <b>{t("Insurance")}:</b> {nodeData?.patientEligibility?.insuranceName}
      </Typography>

      <Typography>
        <b> {t("Scanned On")}: </b>
        {moment(nodeData?.updatedDate).format("YYYY-MM-DD HH:mm a")}
      </Typography>
      <Typography className={classes.cardlessText} color={benefitData?.isEligible ? "success.main" : "error.main"}>
        {benefitData?.isEligible ? t("Active") : t("Not Active")}
      </Typography>
    </Box>
  );
};
