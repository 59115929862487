import { OptimaDirection, PayerCredentialsSortingInputSortField } from "@/schema/types";
import { GridProvider } from "@health/domains";
import { FilterGrid, MainListActions, PageWrapper, TableGrid, TableSettingComponent } from "@toolkit/ui";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { optimaPayerCredentialPaths } from "../../constants";
import { IOptimaPayerCredentialListNode } from "../../types";
import { useOptimaPayerCredentialListContainerColumns } from "./useOptimaPayerCredentialsListContainerColumns";
import { useSetOptimaPayerCredentialBreadcrumbs } from "../../hooks/useSetOptimaPayerCredentialBreadcrumbs";
import { useOptimaPayerCredentialListQuery } from "../../gql/queries/__generated__/OptimaPayerCredentialList";
import { useOptimaPayerCredentialDeleteMutation } from "../../gql/mutations/__generated__/OptimaPayerCredentialDelete";

export const OptimaPayerCredentialListContainer: FC = () => {
  const navigate = useNavigate();

  const onAddNewItemClick = () => {
    navigate(optimaPayerCredentialPaths.create.fullPath);
  };

  const onEditRowClick = (item: IOptimaPayerCredentialListNode) => {
    navigate(optimaPayerCredentialPaths.update.fullPathWithParams({ payerCredentialsId: item?.id }));
  };

  useSetOptimaPayerCredentialBreadcrumbs("LIST");

  return (
    <GridProvider
      gridName={"optimaPayerCredentialList"}
      query={useOptimaPayerCredentialListQuery}
      columns={useOptimaPayerCredentialListContainerColumns()}
      hasTableSetting
      tableAction={{
        isEditVisible: true,
        onEditRow: onEditRowClick,
        isDeleteVisible: true,
      }}
      variables={{
        sortBy: {
          field: PayerCredentialsSortingInputSortField.CreatedAt,
          direction: OptimaDirection.Desc,
        },
      }}
      deleteItemProps={{
        entityTypeName: "PayerCredentials",
        name: "Optima Payer Portal Credential",
        useDeleteMutation: useOptimaPayerCredentialDeleteMutation,
        idPropName: "id",
      }}
    >
      <PageWrapper
        filters={<FilterGrid />}
        actions={
          <>
            <TableSettingComponent />
            <MainListActions hasAddNew onAddNewItem={onAddNewItemClick} />
          </>
        }
      >
        <TableGrid />
      </PageWrapper>
    </GridProvider>
  );
};
