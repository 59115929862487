import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "@toolkit/i18n";
import { useBreadCrumbs } from "@toolkit/ui";
import { branchesPaths } from "../constants";

export const useSetBranchesBreadcrumbs = (containerType: "LIST" | "CREATE" | "UPDATE", name?: string) => {
  const { t } = useTranslation("provider");
  const { setBreadCrumb } = useBreadCrumbs();

  const breadcrumbTitle = t("Branches");

  const branchListBreadcrumb = useMemo(() => {
    return {
      name: t("Branches"),
      route: branchesPaths.list.fullPath,
    };
  }, []);

  const setBranchListContainerBreadcrumb = useCallback(() => {
    setBreadCrumb({
      title: breadcrumbTitle,
    });
  }, [breadcrumbTitle, setBreadCrumb]);

  const setBranchCreateContainerBreadcrumb = useCallback(() => {
    setBreadCrumb({
      title: breadcrumbTitle,
      values: [branchListBreadcrumb, { name: t("New") }],
    });
  }, [breadcrumbTitle, setBreadCrumb, branchListBreadcrumb]);

  const setBranchUpdateContainerBreadcrumb = useCallback(() => {
    if (name) {
      setBreadCrumb({
        title: breadcrumbTitle,
        values: [branchListBreadcrumb, { name }],
      });
    }
  }, [name, setBreadCrumb, breadcrumbTitle, branchListBreadcrumb]);

  useEffect(() => {
    if (containerType === "LIST") {
      setBranchListContainerBreadcrumb();
    } else if (containerType === "CREATE") {
      setBranchCreateContainerBreadcrumb();
    } else if (containerType === "UPDATE") {
      setBranchUpdateContainerBreadcrumb();
    }
  }, [containerType, setBranchListContainerBreadcrumb, setBranchCreateContainerBreadcrumb, setBranchUpdateContainerBreadcrumb]);
};
