import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
export type DigitalTwinHealthParameterFragmentFragment = { __typename?: 'HealthParameter', id?: string | null, code?: string | null, chartType?: Types.ChartType | null, display?: string | null, arabicDisplay?: string | null, unit?: { __typename?: 'FieldUnit', id?: string | null, display?: string | null, arabicDisplay?: string | null } | null };

export const DigitalTwinHealthParameterFragmentFragmentDoc = gql`
    fragment DigitalTwinHealthParameterFragment on HealthParameter {
  id
  code
  chartType
  display
  arabicDisplay
  unit {
    id
    display
    arabicDisplay
  }
}
    `;