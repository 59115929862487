import { useTranslation } from "@toolkit/i18n";
import { CustomDialog, Button, CircularProgress } from "@toolkit/ui";
import { FC, useEffect, useRef, useState } from "react";
import { useOpenState } from "@toolkit/core";

import { ModalApi } from "../../others/types";
import {
  ITransactionValidateRequestEncounterFormValues,
  ITransactionValidateRequestEncounterUpsertFormEvent,
} from "../../forms/TransactionValidateRequestEncounter/TransactionValidateRequestEncounterFormSchema";
import {
  TransactionValidateRequestEncounterUpsertForm,
  TransactionValidateRequestEncounterUpsertFormRef,
} from "../../forms/TransactionValidateRequestEncounter/TransactionValidateRequestEncounterUpsertForm";

export const TransactionValidateRequestEncounterUpsertModalApi: ModalApi<
  ITransactionValidateRequestEncounterFormValues & { index: string }
> = {
  open: () => {},
  close: () => {},
};

type TransactionValidateRequestEncounterUpsertModalProps = {
  isLoading?: boolean;
  hasClose?: boolean;
  onChange: (event: ITransactionValidateRequestEncounterUpsertFormEvent) => void;
};

export const TransactionValidateRequestEncounterUpsertModal: FC<TransactionValidateRequestEncounterUpsertModalProps> = ({
  isLoading = false,
  hasClose = true,
  onChange,
}) => {
  const { t } = useTranslation("provider");
  const { open: isOpen, handleClose, handleOpen } = useOpenState();

  const [encounter, setEncounter] = useState<(ITransactionValidateRequestEncounterFormValues & { index: string }) | null>(null);

  const formRef = useRef<TransactionValidateRequestEncounterUpsertFormRef>(null);

  const handleSubmit = () => formRef.current?.submit();

  const onOpen = (data?: ITransactionValidateRequestEncounterFormValues & { index: string }) => {
    setEncounter(data || null);
    handleOpen();
  };

  useEffect(() => {
    TransactionValidateRequestEncounterUpsertModalApi.open = onOpen;
    TransactionValidateRequestEncounterUpsertModalApi.close = handleClose;
  }, []);

  return (
    <CustomDialog
      type='base'
      maxWidth='md'
      open={isOpen}
      onClose={hasClose ? handleClose : undefined}
      DialogTitleProps={{
        title: encounter ? t("Update Encounter") : t("Add Encounter"),
        hasCloseButton: hasClose,
      }}
      DialogActionsProps={{
        children: (
          <Button disabled={isLoading} endIcon={isLoading ? <CircularProgress color='inherit' size={20} /> : null} onClick={handleSubmit}>
            {encounter ? t("Update") : t("Add")}
          </Button>
        ),
      }}
    >
      <TransactionValidateRequestEncounterUpsertForm
        index={encounter?.index}
        encounter={encounter || undefined}
        ref={formRef}
        onChange={onChange}
      />
    </CustomDialog>
  );
};
