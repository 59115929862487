import { Card, Typography } from "@toolkit/ui";
import { useTranslation } from "@toolkit/i18n";
import { MDTextEditor } from "@toolkit/ui";
import { useBenefitsCardStyles } from "./useBenefitsCard.styles";
import { FC } from "react";
import { useCardStyles } from "@/pages/OptimaPatientEligibilityScan/styles/useCard.styles";
import { IOptimaPatientEligibilityRequestHistoryDataType } from "@/pages/OptimaPatientEligibilityScan/types";

type BenefitsExtractedCardProps = {
  eligibilityData: IOptimaPatientEligibilityRequestHistoryDataType["patientEligibility"];
};

export const BenefitsExtractedCard: FC<BenefitsExtractedCardProps> = ({ eligibilityData }) => {
  const { t } = useTranslation("optima");
  const cardStyles = useCardStyles();
  const { classes } = useBenefitsCardStyles();

  return (
    <Card className={`${cardStyles.classes.cardBase} ${classes.benefitsCard}`}>
      <Typography className={cardStyles.classes.cardTitle}>{t("Benefits Extracted Texts")}</Typography>
      {eligibilityData?.mdSummary ? (
        <MDTextEditor value={eligibilityData?.mdSummary || ""} height={600} readonly />
      ) : (
        <Typography className={cardStyles.classes.cardBodyText}>{eligibilityData?.summary}</Typography>
      )}
    </Card>
  );
};
