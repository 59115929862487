/* eslint-disable max-lines */
import { Visit, VisitSortField, VisitStatus } from "@/schema/types";
import { getBranchesAutocompleteFilter, getVendorDepartmentsAutocompleteFilter } from "@health/autocompletes";
import { getAutocompleteEnumFilter, visitStatusOptionsMap, visitTypeOptionsMap } from "@health/enum-options";
import { BACKEND_DATE_TIME_FORMAT } from "@toolkit/core";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps, EyeIconVisitHistory, Grid, HighPriority, IconButton, useTheme } from "@toolkit/ui";
import { produce } from "immer";
import moment from "moment";
import { VisitDetailsDialog } from "pages/e-visit/VitalSigns/components";
import { useGetVisitDetailsStatusQuery } from "pages/programs-calls/gql";
import { useMemo } from "react";
import { ChatDialog } from "shared/components/Chat/ChatDialog";
import { PatientProfile } from "shared/modules/patient";
import { useGetDoctorsFilters } from "./useGetDoctorsFilters.hook";
import { useGetVisitActionColumn } from "./useGetVisitActionColumn";
import { useGetVisitsDoctorNameColumns } from "./useGetVisitsDoctorNameColumn.hook";

export type VisitsColumnsTypes = CustomTableColumnProps<Visit>[];

export const useVisitsColumns = ({ selfOnly }: { selfOnly: boolean }): VisitsColumnsTypes => {
  const { t } = useTranslation("provider");
  const doctorNameColumns = useGetVisitsDoctorNameColumns(selfOnly);
  const doctorFilters = useGetDoctorsFilters(selfOnly);
  const visitCallAction = useGetVisitActionColumn(selfOnly);
  const { data } = useGetVisitDetailsStatusQuery();
  const displayVisitDetails = data?.me?.vendor?.enableVisitDetails;
  const theme = useTheme();
  return useMemo(() => {
    return [
      {
        key: "id",
        header: t("Visit ID"),
        accessor: "id",
        filter: {
          type: "string",
          name: "id",
        },
      },
      {
        key: "patientName",
        header: t("Patient Name"),
        accessor: ({ patient }) => {
          return (
            <PatientProfile
              patientId={patient?.id}
              patientName={{
                firstName: patient?.firstName,
                lastName: patient?.lastName,
              }}
            />
          );
        },
        isHidden: false,
        type: "string",
        filter: {
          type: "string",
          name: "patientName",
        },
      },
      {
        key: "contactNumber",
        header: t("Contact Number"),
        accessor: ({ patient }) => patient?.contactNumber,
        isHidden: false,
        type: "mobile",
        settings: {
          hideFromSettings: true,
          disableToggleVisibility: true,
        },
      },
      {
        key: "created",
        header: t("Visit Time"),
        type: "datetime",
        accessor: "created",
        isHidden: false,
        sort: {
          columnEnum: VisitSortField.Date,
        },
      },
      {
        key: "type",
        header: t("Visit Type"),
        accessor: ({ type }) => visitTypeOptionsMap[type!]?.label,
      },
      {
        key: "status",
        header: t("Visit Status"),
        accessor: ({ status }) => visitStatusOptionsMap[status!]?.label,
        filter: getAutocompleteEnumFilter("VisitStatus", "status"),
      },
      {
        key: "status-reason",
        header: t("Status Reason"),
        type: "truncated-text",
        accessor: "cancellationReason",
      },
      {
        key: "branch-name",
        header: t("Branch Name"),
        accessor: ({ branch }) => pickLocalizedValue(branch?.name, branch?.nameAr),
      },
      {
        key: "startDate",
        header: t("Visit Date From"),
        showOnlyForFilterField: true,
        filter: {
          type: "dateTime",
          name: "date.gte",
          label: t("Visit Date From"),
        },
      },
      {
        key: "endDate",
        header: t("Visit Date To"),
        showOnlyForFilterField: true,
        filter: {
          type: "dateTime",
          name: "date.lte",
          getValueForBackend: value =>
            produce(value, draft => {
              draft["gte"] = draft?.gte ? moment(draft?.gte).format(BACKEND_DATE_TIME_FORMAT) : null;
              draft["lte"] = draft?.lte ? moment(draft?.lte).format(BACKEND_DATE_TIME_FORMAT) : null;
            }),
          label: t("Visit Date To"),
        },
      },
      {
        key: "careType",
        header: t("Care Type"),
        showOnlyForFilterField: true,
        filter: getAutocompleteEnumFilter("VisitCareType", "careType"),
      },
      {
        key: "careInitialType",
        header: t("Care Initial Type"),
        showOnlyForFilterField: true,
        filter: getAutocompleteEnumFilter("CareType", "careInitialType"),
      },
      {
        key: "visitTypeFilter",
        header: t("Visit Type"),
        showOnlyForFilterField: true,
        filter: getAutocompleteEnumFilter("VisitType", "types"),
      },
      ...doctorNameColumns,
      {
        key: "branches",
        header: t("Branches"),
        showOnlyForFilterField: true,
        filter: getBranchesAutocompleteFilter({ name: "branches", multiple: true }),
      },
      {
        key: "departments",
        header: t("Departments"),
        showOnlyForFilterField: true,
        filter: getVendorDepartmentsAutocompleteFilter({ name: "departments", multiple: true }),
      },
      ...doctorFilters,
      {
        key: "patient.id",
        header: t("Sehacity ID"),
        showOnlyForFilterField: true,
        accessor: ({ patient }) => patient?.id,
      },
      ...visitCallAction,
      {
        key: "chat",
        header: t("Chat with Patient"),
        accessor: ({ patient }) => (
          <Grid
            sx={{
              "& .MuiIconButton-root": {
                width: "100%",
              },
              "& svg": {
                color: theme.palette.primary.main,
                "& path": {
                  width: 26,
                  height: 25,
                },
              },
            }}
          >
            <ChatDialog userId={patient?.user?.id!} />
          </Grid>
        ),
      },
      {
        key: "gaps",
        header: t("Care Gaps"),
        accessor: () => (
          <Grid
            sx={{
              "& .MuiIconButton-root": {
                width: "100%",
              },
              "& svg": {
                color: theme.palette.warning.main,
                "& path": {
                  width: 26.21,
                  height: 26.21,
                },
              },
            }}
          >
            <IconButton>
              <HighPriority />
            </IconButton>
          </Grid>
        ),
      },
      {
        key: "details",
        header: t("Visit Details"),
        settings: {
          hideFromPreferencesAndTable: !displayVisitDetails,
        },
        accessor: ({ id, status, healthProgramMember }) => (
          <VisitDetailsDialog
            visitStatus={status as VisitStatus}
            visitId={id}
            color={theme.palette.primary.main}
            payerId={healthProgramMember?.insuranceCompanyId!}
            icon={<EyeIconVisitHistory fill={theme.palette.primary.main} />}
          />
        ),
      },
    ];
  }, [doctorNameColumns, displayVisitDetails, doctorFilters, visitCallAction]);
};
