import { z } from "zod";
import { i18n } from "@toolkit/i18n";

export const NoteFormSchema = z.object({
  note: z.string().min(1, { message: i18n.t("Required", { ns: "provider" }) }),
});

export type INoteFormValues = z.infer<typeof NoteFormSchema>;

export const noteFormDefaultValues: Partial<INoteFormValues> = {
  note: "",
};
