import { MarketplacePromotionSortingField, MarketplaceSortDirection } from "@/schema/types";
import { FilterGrid, MainListActions, PageWrapper, TableGrid, TableSettingComponent, useBreadCrumbs } from "@toolkit/ui";
import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { GridProvider } from "shared/components";
import { promotionsBreadcrumb } from "../../constants/PromotionsBreadcrumbs";
import { promotionsPaths } from "../../constants/PromotionsPaths";
import { useMarketplacePromotionsQuery } from "../../gql/queries";
import { PromotionsQueryNode } from "../../types";
import { usePromotionsListTableColumns } from "./usePromotionsListTableColumns";

export const PromotionsList: FC = () => {
  const { setBreadCrumb } = useBreadCrumbs();
  const navigate = useNavigate();
  const onEditRowClick = (item: PromotionsQueryNode) => {
    navigate(promotionsPaths.update.fullPathWithParams({ promotionId: item?.id }));
  };
  const onAddNewItemClick = () => {
    navigate(promotionsPaths.new.fullPath);
  };
  useEffect(() => {
    setBreadCrumb({
      title: promotionsBreadcrumb().title,
    });
  }, []);

  return (
    <GridProvider
      gridName='marketplace-promotions'
      query={useMarketplacePromotionsQuery}
      columns={usePromotionsListTableColumns()}
      hasTableSetting
      skipUrlState={false}
      tableAction={{
        isEditVisible: true,
        onEditRow: onEditRowClick,
      }}
      variables={{
        sortBy: {
          field: MarketplacePromotionSortingField.Created,
          direction: MarketplaceSortDirection.Desc,
        },
      }}
    >
      <PageWrapper
        filters={<FilterGrid />}
        actions={
          <>
            <TableSettingComponent />
            <MainListActions hasAddNew onAddNewItem={onAddNewItemClick} />
          </>
        }
      >
        <TableGrid />
      </PageWrapper>
    </GridProvider>
  );
};
