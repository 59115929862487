import { useTranslation } from "@toolkit/i18n";
import { CustomDialog, Button, CircularProgress } from "@toolkit/ui";
import { FC, useEffect, useRef, useState } from "react";
import { useOpenState } from "@toolkit/core";

import { ModalApi } from "../../others/types";
import {
  ITransactionValidateRequestObservationFormValues,
  ITransactionValidateRequestObservationUpsertFormEvent,
} from "../../forms/TransactionValidateRequestObservation/TransactionValidateRequestObservationFormSchema";
import {
  TransactionValidateRequestObservationUpsertFormRef,
  TransactionValidateRequestObservationUpsertForm,
} from "../../forms/TransactionValidateRequestObservation/TransactionValidateRequestObservationUpsertForm";

export const TransactionValidateRequestObservationUpsertModalApi: ModalApi<
  ITransactionValidateRequestObservationFormValues & { index: string }
> = {
  open: () => {},
  close: () => {},
};

type TransactionValidateRequestObservationUpsertModalProps = {
  isLoading?: boolean;
  hasClose?: boolean;
  onChange: (event: ITransactionValidateRequestObservationUpsertFormEvent) => void;
};

export const TransactionValidateRequestObservationUpsertModal: FC<TransactionValidateRequestObservationUpsertModalProps> = ({
  isLoading = false,
  hasClose = true,
  onChange,
}) => {
  const { t } = useTranslation("provider");
  const { open: isOpen, handleClose, handleOpen } = useOpenState();

  const [observation, setObservation] = useState<(ITransactionValidateRequestObservationFormValues & { index: string }) | null>(null);

  const formRef = useRef<TransactionValidateRequestObservationUpsertFormRef>(null);

  const handleSubmit = () => formRef.current?.submit();

  const onOpen = (data?: ITransactionValidateRequestObservationFormValues & { index: string }) => {
    setObservation(data || null);
    handleOpen();
  };

  useEffect(() => {
    TransactionValidateRequestObservationUpsertModalApi.open = onOpen;
    TransactionValidateRequestObservationUpsertModalApi.close = handleClose;
  }, []);

  return (
    <CustomDialog
      type='base'
      maxWidth='md'
      open={isOpen}
      onClose={hasClose ? handleClose : undefined}
      DialogTitleProps={{
        title: observation ? t("Update Observation") : t("Add Observation"),
        hasCloseButton: hasClose,
      }}
      DialogActionsProps={{
        children: (
          <Button disabled={isLoading} endIcon={isLoading ? <CircularProgress color='inherit' size={20} /> : null} onClick={handleSubmit}>
            {observation ? t("Update") : t("Add")}
          </Button>
        ),
      }}
    >
      <TransactionValidateRequestObservationUpsertForm
        index={observation?.index}
        observation={observation || undefined}
        ref={formRef}
        onChange={onChange}
      />
    </CustomDialog>
  );
};
