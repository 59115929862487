import { H_OrderDirection, PatientHealthParameterSortField } from "@/schema/types";
import { TableGrid } from "@toolkit/ui";
import { FC } from "react";
import { GridProvider } from "shared/components";
import { getApolloContextFormToken } from "shared/utils";
import { usePatientHealthParametersQuery } from "../../gql";
import { useOptionColumns } from "./useOptionColumns";

type ChartTableProps = {
  code: string;
  type: string;
  token: string;
  patientId: string;
  onSelectECG: (value: number[], source: string, date: string) => void;
};
export const HealthParameterTable: FC<ChartTableProps> = ({ token, code, type, patientId, onSelectECG }) => {
  return (
    <GridProvider
      isCardView
      gridName='patientHealthParameters'
      columns={useOptionColumns({ type, onSelectECG })}
      query={usePatientHealthParametersQuery}
      context={getApolloContextFormToken(token!)}
      variables={{
        first: 10,
        filter: {
          codes: [code],
          patientId: [patientId!],
        },
        sortBy: {
          field: PatientHealthParameterSortField.ReadingDate,
          direction: H_OrderDirection.Desc,
        },
      }}
    >
      <TableGrid />
    </GridProvider>
  );
};
