import { Box, Button, CustomIcon, IconButton, InfiniteScroll, Menu, MenuItem, PlusIcon, Typography } from "@toolkit/ui";
import React, { MouseEvent, useState } from "react";
import { useTranslation } from "@toolkit/i18n";
import { useProfileContext } from "@/shared/modules/patient/context/ProfileContext";
import { IPatientGuidedCareProgram } from "../../../types/types";
import { usePatientGuidedCareProgramsStyle } from "./usePatientGuidedCareProgramsStyle";
import { Patient } from "@/schema/types";
import { PatientGuidedCareProgramMemberAddModal } from "@health/domains";

export const PatientGuidedCarePrograms = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const { t } = useTranslation("provider");

  const { classes, theme } = usePatientGuidedCareProgramsStyle();

  const {
    patient,
    selectedHealthProgram,
    guidedCareHealthProgramTeamPageInfo,
    guidedCareHealthPrograms,
    handleProgramSelected,
    guidedCareHealthProgramTeamFetchMore,
  } = useProfileContext();

  const selectedItem = guidedCareHealthPrograms?.find(item => selectedHealthProgram === item?.id);

  const handleMenuClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (value: IPatientGuidedCareProgram) => {
    return () => {
      setAnchorEl(null);
      handleProgramSelected?.(value?.id);
    };
  };

  return (
    <Box>
      <Box className={classes.titleWrapper}>
        {!!guidedCareHealthPrograms?.length && <Typography className={classes.title}>{t("Programs")}</Typography>}

        <PatientGuidedCareProgramMemberAddModal
          patient={patient as Patient}
          button={
            guidedCareHealthPrograms?.length ? (
              <IconButton>
                <PlusIcon fontSize={"small"} fill={theme.palette.primary.main} />
              </IconButton>
            ) : (
              <Button fullWidth sx={{ whiteSpace: "nowrap", marginInline: 0 }}>
                {t("Add to care journey")}
              </Button>
            )
          }
        />
      </Box>

      {!!guidedCareHealthPrograms?.length && (
        <>
          <Button
            id={"patient-add-activity-menu-button"}
            aria-controls={anchorEl ? "patient-add-activity-menu" : undefined}
            aria-haspopup={"true"}
            aria-expanded={anchorEl ? "true" : undefined}
            variant={"text"}
            disableElevation
            onClick={handleMenuClick}
            className={classes.button}
            endIcon={
              <Box>
                <CustomIcon icon={"expandArrow"} color={"inherit"} viewBox={"-5 -5 22 22"} />
              </Box>
            }
          >
            <Box display={"flex"} flexDirection={"column"} alignItems={"baseline"}>
              <Typography fontSize={theme.mixins.fonts.fontSize.sm} whiteSpace={"nowrap"}>
                {selectedItem?.name}
              </Typography>
            </Box>
          </Button>

          <Menu
            className={classes.menu}
            id={"patient-add-activity-menu"}
            anchorEl={anchorEl}
            open={!!anchorEl}
            onClose={handleMenuClose}
            classes={{ paper: classes.menuPaper }}
          >
            <Box id={"scrollableBox"} sx={{ maxHeight: 200, overflow: "auto" }}>
              <InfiniteScroll
                scrollableTarget={"scrollableBox"}
                dataLength={guidedCareHealthPrograms?.length!}
                next={guidedCareHealthProgramTeamFetchMore}
                hasMore={guidedCareHealthProgramTeamPageInfo?.hasNextPage}
                loader={<Typography className={classes.loadingText}>{t("Loading...")}</Typography>}
              >
                {guidedCareHealthPrograms?.map(item => (
                  <MenuItem key={item?.id} onClick={handleSelect(item)}>
                    <Typography fontSize={theme.mixins.fonts.fontSize.sm}>{item?.name}</Typography>
                  </MenuItem>
                ))}
              </InfiniteScroll>
            </Box>
          </Menu>
        </>
      )}
    </Box>
  );
};
