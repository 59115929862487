import { OptimaItem } from "@/schema/types";
import { useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps, Typography } from "@toolkit/ui";
import { useMemo } from "react";

export const useItemsListColumns = (): CustomTableColumnProps<OptimaItem>[] => {
  const { t } = useTranslation("provider");

  return useMemo(() => {
    return [
      {
        key: "display",
        header: t("Display"),
        accessor: "display",
      },
      {
        key: "code",
        header: t("Code"),
        accessor: "code",
      },
      {
        key: "type",
        header: t("Type"),
        accessor: "type",
      },
      {
        key: "percentageOfAcceptance",
        header: t("Percentage Of Acceptance"),
        accessor: ({ percentageOfAcceptance }) => {
          return <Typography>{percentageOfAcceptance || 0} </Typography>;
        },
      },
      {
        key: "quantity",
        header: t("Quantity"),
        accessor: "quantity",
      },
      {
        key: "price",
        header: t("Price"),
        accessor: ({ currency, unitPrice }) => (
          <Typography>
            {currency} {unitPrice}
          </Typography>
        ),
      },
    ];
  }, [t]);
};
