import { useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps } from "@toolkit/ui";
import { useMemo } from "react";
import { OptimaDiagnosis } from "@/schema/types";

export type OptimaDiagnosesColumnsTypes = CustomTableColumnProps<OptimaDiagnosis>[];

export const useOptimaDiagnosesColumns = (): OptimaDiagnosesColumnsTypes => {
  const { t } = useTranslation("domains");

  return useMemo(() => {
    return [
      {
        key: "code",
        header: t("Code"),
        accessor: "code",
      },
      {
        key: "codeSystem",
        header: t("Code System"),
        accessor: "codeSystem",
      },
      {
        key: "display",
        header: t("Display"),
        accessor: "display",
      },
      {
        key: "type",
        header: t("Type"),
        accessor: "type",
      },
    ];
  }, []);
};
