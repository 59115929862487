import RouteItem from "@/shared/components/Root/routeTypes";
import { i18n } from "@toolkit/i18n";
import { NavigateFunction } from "react-router-dom";
import { OptimaTransactionValidateRequestContainer } from "../containers/OptimaTransactionValidateRequest/OptimaTransactionValidateRequestContainer";
import { optimaTransactionValidateRequestPaths } from "./OptimaTransactionValidateRequestPaths";

export const optimaTransactionValidateRequestRoutes: (navigate: NavigateFunction) => RouteItem = navigate => {
  return {
    id: "optima-transaction-validate-request",
    text: i18n.t("Transaction Validate Request", { ns: "provider" }),
    hidden: true,
    subItems: [
      {
        id: "optima-transaction-validate-request-validate",
        route: optimaTransactionValidateRequestPaths.validate.fullPath,
        fullPath: optimaTransactionValidateRequestPaths.validate.fullPath,
        element: <OptimaTransactionValidateRequestContainer />,
        onClick: (route: string) => navigate(route),
      },
    ],
  };
};
