import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RejectManualOrderMutationVariables = Types.Exact<{
  orderId: Types.Scalars['ID']['input'];
  cancelationReason?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type RejectManualOrderMutation = { __typename?: 'Mutation', rejectManualOrder?: { __typename?: 'ManualOrder', id: string, status: Types.ManualOrderStatus } | null };


export const RejectManualOrderDocument = gql`
    mutation RejectManualOrder($orderId: ID!, $cancelationReason: String) {
  rejectManualOrder(orderId: $orderId, cancelationReason: $cancelationReason) {
    id
    status
  }
}
    `;
export type RejectManualOrderMutationFn = Apollo.MutationFunction<RejectManualOrderMutation, RejectManualOrderMutationVariables>;

/**
 * __useRejectManualOrderMutation__
 *
 * To run a mutation, you first call `useRejectManualOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectManualOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectManualOrderMutation, { data, loading, error }] = useRejectManualOrderMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *      cancelationReason: // value for 'cancelationReason'
 *   },
 * });
 */
export function useRejectManualOrderMutation(baseOptions?: Apollo.MutationHookOptions<RejectManualOrderMutation, RejectManualOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RejectManualOrderMutation, RejectManualOrderMutationVariables>(RejectManualOrderDocument, options);
      }
export type RejectManualOrderMutationHookResult = ReturnType<typeof useRejectManualOrderMutation>;
export type RejectManualOrderMutationResult = Apollo.MutationResult<RejectManualOrderMutation>;
export type RejectManualOrderMutationOptions = Apollo.BaseMutationOptions<RejectManualOrderMutation, RejectManualOrderMutationVariables>;