import React, { FC, useEffect, useRef, useState } from "react";
import { useTranslation } from "@toolkit/i18n";
import { Button, CircularProgress, CustomDialog } from "@toolkit/ui";
import {
  UserWorkspaceDoctorUpsertForm,
  UserWorkspaceDoctorUpsertFormRef,
  IUserWorkspaceDoctorUpsertFormEvent,
} from "../../forms/UserWorkspaceDoctorUpsert/UserWorkspaceDoctorUpsertForm";
import { IUserWorkspaceDoctorValue } from "../../types";
import { useOpenState } from "@toolkit/core";

type IUserWorkspaceDoctorUpsertModalData = {
  userWorkspaceDoctor: IUserWorkspaceDoctorValue | null;
};

type IOpen = (data: IUserWorkspaceDoctorUpsertModalData) => void;
type IClose = () => void;

export const UserWorkspaceDoctorUpsertModalApi: { open: IOpen; close: IClose } = {
  open: () => {},
  close: () => {},
};

type UserWorkspaceDoctorUpsertModalProps = {
  isLoading: boolean;
  hasClose: boolean;
  onChange: (values: IUserWorkspaceDoctorUpsertFormEvent) => void;
};

export const UserWorkspaceDoctorUpsertModal: FC<UserWorkspaceDoctorUpsertModalProps> = props => {
  const { isLoading, hasClose, onChange } = props;

  const { t } = useTranslation("provider");

  const { open: isOpen, handleClose, handleOpen } = useOpenState();
  const [data, setData] = useState<IUserWorkspaceDoctorUpsertModalData | null>(null);

  const userWorkspaceDoctorUpsertFormRef = useRef<UserWorkspaceDoctorUpsertFormRef>(null);

  const open: IOpen = _data => {
    setData(_data);
    handleOpen();
  };

  const close: IClose = () => {
    handleClose();
  };

  const onAssignClick = () => {
    userWorkspaceDoctorUpsertFormRef.current?.submit();
  };

  useEffect(() => {
    UserWorkspaceDoctorUpsertModalApi.open = open;
    UserWorkspaceDoctorUpsertModalApi.close = close;
  }, []);

  return (
    <CustomDialog
      type={"base"}
      maxWidth={"sm"}
      open={isOpen}
      onClose={hasClose ? close : undefined}
      DialogTitleProps={{
        title: t("Assign Branch"),
        hasCloseButton: hasClose,
      }}
      DialogActionsProps={{
        children: (
          <Button
            disabled={isLoading}
            endIcon={isLoading ? <CircularProgress color={"inherit"} size={20} /> : null}
            onClick={onAssignClick}
          >
            {t("Assign")}
          </Button>
        ),
      }}
    >
      <UserWorkspaceDoctorUpsertForm
        userWorkspaceDoctor={data?.userWorkspaceDoctor || null}
        ref={userWorkspaceDoctorUpsertFormRef}
        onChange={onChange}
      />
    </CustomDialog>
  );
};
