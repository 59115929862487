import { PermissionEnum } from "@/schema/types";
import { hasPermission } from "@toolkit/core";
import { i18n } from "@toolkit/i18n";
import { NavigateFunction } from "react-router-dom";
import RouteItem from "shared/components/Root/routeTypes";
import { DepartmentCreateContainer, DepartmentListContainer, DepartmentUpdateContainer } from "../containers";
import { departmentsPaths, departmentsRoute } from "./DepartmentsPaths";

export const departmentsRoutes: (navigate: NavigateFunction) => RouteItem = navigate => {
  return {
    id: "departments-routes",
    text: i18n.t("Departments", { ns: "provider" }),
    route: departmentsRoute,
    isProhibited: !hasPermission(PermissionEnum.ManageDepartments),
    subItems: [
      {
        id: "department-list-route",
        route: departmentsPaths.list.route,
        fullPath: departmentsPaths.list.fullPath,
        element: <DepartmentListContainer />,
        onClick: route => navigate(route),
      },
      {
        id: "department-create-route",
        route: departmentsPaths.create.route,
        fullPath: departmentsPaths.create.fullPath,
        hidden: true,
        element: <DepartmentCreateContainer />,
      },
      {
        id: "department-update-route",
        route: departmentsPaths.update.route,
        fullPath: departmentsPaths.update.fullPath,
        hidden: true,
        element: <DepartmentUpdateContainer />,
      },
    ],
  };
};
