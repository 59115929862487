import { makeStyles } from "@toolkit/ui";

export const usePatientEligibilityPageStyles = makeStyles()({
  cardsStack: {
    display: "flex",
    flexDirection: "column",
    gap: 8,
    paddingInline: 8,
  },
});
