/* eslint-disable max-lines */
import { useTranslation } from "@toolkit/i18n";
import { Button, CustomTableColumnProps, TruncateTypography, PaperPinIcon, Typography, Box } from "@toolkit/ui";
import { useMemo } from "react";
import { IManualSubmittedOrderListNode } from "../../types";
import { deliveryMethodTypeMap, getAutocompleteEnumFilter, manualOrderStatusOptionsMap } from "@health/enum-options";
import { useSiteSettingsGetQuery } from "@/gql/queries";
import { getDeliveryTimeFromSlots, getManualStatusToColorMap, getOrderAttachmentFileName } from "../../others/utils";
import { useFileDownload } from "@toolkit/core";
import { ManualOrderStatus, OrderType } from "@/schema/types";
import { last } from "lodash";
import { useDownloadButtonStyles } from "../../styles/useDownloadButton.styles";
import { OrderRejectionModal } from "../../components/OrderRejectionModal/OrderRejectionModal";
import { OrderApprovalModal } from "../../components/OrderApprovalModal/OrderApprovalModal";
import { OrderAuthorizationModal } from "../../components/OrderAuthorizationModal/OrderAuthorizationModal";
import { AuthorizedOrderActions } from "../../components/AuthorizedOrderActions/AuthorizedOrderActions";
import { ManualOrderLines } from "../../components/ManualOrderLines/ManualOrderLines";
import { RequestPatientApprovalModal } from "../../components/RequestPatientApprovalModal/RequestPatientApprovalModal";

export const useManualSubmittedOrderListContainerColumns = (): CustomTableColumnProps<IManualSubmittedOrderListNode>[] => {
  const { t } = useTranslation("provider");
  const { data } = useSiteSettingsGetQuery();
  const timeZone = data?.siteSettings?.timeZone;
  const { downloadFile: handleDownload, isDownloading } = useFileDownload();
  const { classes, theme } = useDownloadButtonStyles();

  return useMemo(
    () => [
      {
        key: "eRxAttachment",
        header: t("eRx"),
        accessor: ({ attachmentUrl, user, id, created }) => (
          <Button
            startIcon={<PaperPinIcon width={12} height={12} />}
            variant='text'
            className={classes.button}
            disabled={!!isDownloading || !attachmentUrl}
            onClick={() => {
              handleDownload({
                url: attachmentUrl || "",
                filename: getOrderAttachmentFileName(user?.fullName || "", id, created),
              });
            }}
          >
            <TruncateTypography maxWidth={"100px"} text={getOrderAttachmentFileName(user?.fullName || "", id, created)} />
          </Button>
        ),
      },
      {
        key: "patient-name",
        header: t("Patient Name"),
        accessor: ({ user }) => user?.fullName,
      },
      {
        key: "insurance-id",
        header: t("Insurance ID"),
        accessor: ({ healthProgramMember }) => healthProgramMember?.insuranceId,
      },
      {
        key: "order-id",
        header: t("Order ID"),
        accessor: "id",
      },
      {
        key: "delivery-type",
        header: t("Delivery Type"),
        accessor: ({ type }) => deliveryMethodTypeMap[type].label,
        filter: {
          ...getAutocompleteEnumFilter("DeliveryMethodsType", "isDelivery"),
          getValueForBackend: option => {
            switch (option?.value) {
              case OrderType.Delivery:
                return true;
              case OrderType.Pickup:
                return false;
              default:
                return undefined;
            }
          },
        },
      },
      {
        key: "status-filter",
        header: t("Status"),
        showOnlyForFilterField: true,
        filter: getAutocompleteEnumFilter("ManualOrderStatus", "status", { multiple: true }),
      },
      {
        key: "delivery-time",
        header: t("Delivery Time"),
        type: "datetime",
        accessor: ({ deliveryDate, deliveryTimeSlot }) =>
          deliveryTimeSlot ? getDeliveryTimeFromSlots(deliveryDate, deliveryTimeSlot!, timeZone || 0) : "-",
      },
      {
        key: "address",
        header: t("Delivery Address"),
        type: "truncated-text",
        accessor: ({ address }) => address?.streetAddress1,
      },
      {
        key: "status",
        header: t("Status"),
        accessor: ({ status }) => (
          <Typography
            minWidth={"max-content"}
            color={getManualStatusToColorMap(theme)[status]}
            fontWeight={theme.mixins.fonts.fontWeight.medium}
          >
            {manualOrderStatusOptionsMap[status].label}
          </Typography>
        ),
      },
      {
        key: "requestedStartTimeGte",
        header: t("Requested Start Time From"),
        showOnlyForFilterField: true,
        filter: {
          type: "date",
          name: "created.gte",
          label: t("Requested Start Time From"),
        },
      },
      {
        key: "requestedStartTimeLte",
        header: t("Requested Start Time To"),
        showOnlyForFilterField: true,
        filter: {
          type: "date",
          name: "created.lte",
          label: t("Requested Start Time To"),
        },
      },
      {
        key: "user-id-filter",
        header: t("User ID"),
        showOnlyForFilterField: true,
        filter: {
          type: "string",
          name: "userId",
        },
      },
      {
        key: "notes",
        header: t("Notes"),
        type: "truncated-text",
        accessor: ({ notes }) => last(notes)?.text,
      },
      {
        key: "orderEvents",
        header: t("Order Events"),
        accessor: order => <ManualOrderLines {...order} />,
      },
      {
        key: "actions",
        header: t("Actions"),
        accessor: props => (
          <Box display='flex' flexDirection='row' gap={2}>
            {[ManualOrderStatus.WaitingPatientApproval, ManualOrderStatus.WaitingProviderApproval].includes(props.status) && (
              <>
                <RequestPatientApprovalModal notes={props?.notes ?? undefined} orderId={props.id} orderStatus={props.status} />
                <OrderApprovalModal orderId={props.id} orderStatus={props.status} />
                <OrderRejectionModal orderId={props.id} orderStatus={props.status} />
              </>
            )}
            {[ManualOrderStatus.WaitingAuthorizationApproval].includes(props.status) && (
              <>
                <OrderAuthorizationModal orderId={props.id} orderStatus={props.status} />
              </>
            )}
            {[ManualOrderStatus.AuthorizationApproved, ManualOrderStatus.OutForDelivery].includes(props.status) && (
              <AuthorizedOrderActions orderId={props.id} orderStatus={props.status} orderType={props.type} />
            )}
          </Box>
        ),
      },
      {
        key: "activeFilters",
        header: t("Is Active"),
        showOnlyForFilterField: true,
        filter: getAutocompleteEnumFilter("YesNo", "active"),
      },
    ],
    [t]
  );
};
