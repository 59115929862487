import { PatientHealthParameter, TemplateFieldType } from "@/schema/types";
import { sourceOptionsMap } from "@health/enum-options";
import { useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps, ShowButton, Typography, useTheme } from "@toolkit/ui";
import { useMemo } from "react";

export type OptionTypes = CustomTableColumnProps<PatientHealthParameter>[];

export const useOptionColumns = ({ type, onSelectECG }): OptionTypes => {
  const { t } = useTranslation("provider");
  const theme = useTheme();
  const handleSelectECG = (list, source, date) => {
    onSelectECG(list, source, date);
  };

  return useMemo(() => {
    return [
      {
        key: "value",
        header: t("Value"),
        accessor: row => (
          <Typography fontSize={theme.mixins.fonts.fontSize.xs} fontWeight={theme.mixins.fonts.fontWeight.medium}>
            {row?.valueBoolean ? t("Yes") : t("No")}
          </Typography>
        ),
        isHidden: type !== TemplateFieldType.Boolean,
      },
      {
        key: "valueString",
        header: t("Value"),
        accessor: ({ valueString }) => (
          <Typography fontSize={theme.mixins.fonts.fontSize.xs} fontWeight={theme.mixins.fonts.fontWeight.medium}>
            {valueString}
          </Typography>
        ),
        isHidden: type !== TemplateFieldType.String,
      },
      {
        key: "numericList",
        header: t("Value"),
        accessor: ({ valueNumericList, source, deviceName, createdDate }) => (
          <ShowButton
            onClick={() =>
              handleSelectECG(valueNumericList, sourceOptionsMap[source!]?.label + (deviceName ? " - " + deviceName : ""), createdDate)
            }
          />
        ),
        isHidden: type !== TemplateFieldType.NumericList,
      },
      {
        key: "number",
        header: t("Number"),
        accessor: ({ valueNumber }) => (
          <Typography fontSize={theme.mixins.fonts.fontSize.xs} fontWeight={theme.mixins.fonts.fontWeight.medium}>
            {valueNumber}
          </Typography>
        ),
        isHidden: type !== TemplateFieldType.Number,
      },
      {
        key: "date",
        header: t("Date"),
        type: "datetime",
        accessor: "createdDate",
      },
      {
        key: "source",
        header: t("Source"),
        accessor: ({ source, deviceName }) => sourceOptionsMap[source!]?.label + (deviceName ? " - " + deviceName : ""),
      },
    ];
  }, [t, type]);
};
