import { formatGraphQLError } from "@toolkit/apollo";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { useAddToast, useBreadCrumbs } from "@toolkit/ui";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { productsBreadcrumb } from "../../constants";
import { IProductUpsertFormValues, ProductUpsertForm } from "../../forms";
import { MarketplaceProductUpdateMutation, useMarketplaceProductQuery, useMarketplaceProductUpdateMutation } from "../../gql";
import { IProductUpdateContainerParams } from "../../types";
import { convertProductFormValuesToBackEndValues } from "../../utils";

export const ProductUpdateContainer: React.FC = () => {
  const { t } = useTranslation("provider");
  const { productId } = useParams<IProductUpdateContainerParams>();

  const { data, loading } = useMarketplaceProductQuery({
    variables: { marketplaceProductId: productId! },
    skip: !productId,
  });
  const product = data?.marketplaceProduct;

  const navigate = useNavigate();
  const { setBreadCrumb } = useBreadCrumbs();
  const { succeeded, failed } = useAddToast();
  const [fetchProductUpdateMutation, { loading: isSubmitting }] = useMarketplaceProductUpdateMutation({
    onCompleted: (data: MarketplaceProductUpdateMutation) => {
      if (data?.marketplaceProductUpdate?.id) {
        succeeded(t("Product updated successfully!"));
        navigate(-1);
      } else {
        failed(t("Product update failed!"));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(t(formatGraphQLError(graphQLErrors)));
    },
  });
  const onProductUpsertFormChange = (values: IProductUpsertFormValues) => {
    const _values = convertProductFormValuesToBackEndValues(values);

    fetchProductUpdateMutation({
      variables: {
        marketplaceProductUpdateId: productId!,
        input: _values,
      },
    });
  };
  useEffect(() => {
    const { title, main, update } = productsBreadcrumb();
    setBreadCrumb({ title, values: [main, update(pickLocalizedValue(product?.name!, product?.nameAr!))] });
  }, [setBreadCrumb, product, t]);
  return (
    <ProductUpsertForm
      isLoading={loading}
      product={product!}
      isSubmitting={isSubmitting}
      submitButtonLabel={t("Update")}
      onChange={onProductUpsertFormChange}
    />
  );
};
