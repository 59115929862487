import { CodeSystemCode, ManualOrderStatus, Type } from "@/schema/types";
import { useGetCodeSystemConceptSearchQuery } from "@/shared/modules/patient/gql";
import { useOpenState } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Button, CustomDialog, IconButton, Tooltip, useAddToast, XIcon } from "@toolkit/ui";
import { FC, useState } from "react";
import { useRejectManualOrderMutation } from "../../gql/mutations";
import { ReasonsForm } from "@health/domains";
import { ManualSubmittedOrderListDocument } from "../../gql";

type OrderRejectionModalProps = {
  orderId: string;
  orderStatus: ManualOrderStatus;
};

export const OrderRejectionModal: FC<OrderRejectionModalProps> = props => {
  const { orderId, orderStatus } = props;
  const [value, setValue] = useState({ value: "", isThereValue: false });
  const { failed, succeeded } = useAddToast();
  const { handleToggle, open } = useOpenState();
  const { t } = useTranslation("provider");
  const { data, loading: isLoading } = useGetCodeSystemConceptSearchQuery({
    variables: {
      keyword: "",
      first: 10,
      codeSystemCode: CodeSystemCode.RejectionReason,
      searchParameters: [{ valueString: "manual-order", code: "Type", type: Type.String }],
    },
    skip: !open,
  });
  const reasons =
    data?.codeSystemConceptSearch?.edges?.map(item => Object.create({ label: item?.node?.display, value: item?.node?.display })) || [];

  const [orderRejectMutation, { loading: isSubmitting }] = useRejectManualOrderMutation({
    onCompleted: data => {
      if (data?.rejectManualOrder?.status === ManualOrderStatus.Rejected) {
        handleToggle();
        succeeded(t("Order rejected successfully!"));
      } else {
        failed(t("Failed to reject order!"));
      }
    },
    refetchQueries: [ManualSubmittedOrderListDocument],
  });
  const handleApproveOrder = () => {
    orderRejectMutation({
      variables: {
        orderId: orderId,
        cancelationReason: value?.value,
      },
    });
  };
  const onReasonChange = (reason, isThereValue) => {
    setValue({ value: reason, isThereValue: isThereValue });
  };
  return (
    <CustomDialog
      type='base'
      open={open}
      DialogTitleProps={{
        title: t("Reject Order"),
        onClose: handleToggle,
      }}
      maxWidth='sm'
      button={
        <Tooltip title={t("Reject the order")}>
          <IconButton
            onClick={handleToggle}
            disabled={orderStatus !== ManualOrderStatus.WaitingProviderApproval}
            sx={{
              "& svg": {
                width: "19px",
                height: "19px",
              },
            }}
          >
            <XIcon />
          </IconButton>
        </Tooltip>
      }
      DialogActionsProps={{
        children: (
          <Button disabled={!value?.isThereValue || isSubmitting || isLoading} onClick={handleApproveOrder} color='error'>
            {t("Reject")}
          </Button>
        ),
      }}
    >
      <ReasonsForm reasons={reasons} isLoading={isLoading} onChange={onReasonChange} />
    </CustomDialog>
  );
};
