import { ProviderSettingsForPayment, ProviderSettingsInputForPayment } from "@/schema/types";
import { formGirdBreakPoints } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { CustomToggleButton, FormControlLabel, Grid, TextField } from "@toolkit/ui";
import { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";

type SettingsForPaymentFormProps = {
  providerPayment: ProviderSettingsForPayment | undefined;
};
export const SettingsForPaymentForm: FC<SettingsForPaymentFormProps> = ({ providerPayment }) => {
  const { t } = useTranslation("admin");
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext<ProviderSettingsInputForPayment>();

  return (
    <>
      <Grid container spacing={2}>
        <Grid item {...formGirdBreakPoints}>
          <TextField
            defaultValue={providerPayment?.paymentApiAccessKey}
            label={t("Payment Api Access Key")}
            fullWidth
            {...register("paymentApiAccessKey")}
            error={Boolean(errors?.paymentApiAccessKey?.message)}
            helperText={errors?.paymentApiAccessKey?.message}
          />
        </Grid>
        <Grid item {...formGirdBreakPoints}>
          <TextField
            defaultValue={providerPayment?.paymentCheckoutEndPointUrl}
            label={t("Payment Checkout End Point Url")}
            fullWidth
            {...register("paymentCheckoutEndPointUrl")}
            error={Boolean(errors?.paymentCheckoutEndPointUrl?.message)}
            helperText={errors?.paymentCheckoutEndPointUrl?.message}
          />
        </Grid>
        <Grid item {...formGirdBreakPoints}>
          <TextField
            defaultValue={providerPayment?.paymentRefundEndPointUrl}
            label={t("Payment Refund End Point Url")}
            fullWidth
            {...register("paymentRefundEndPointUrl")}
            error={Boolean(errors?.paymentRefundEndPointUrl?.message)}
            helperText={errors?.paymentRefundEndPointUrl?.message}
          />
        </Grid>
      </Grid>
      <Grid container sx={{ marginTop: 1 }}>
        <Grid item xs={12}>
          <Controller
            name='hasOwnPaymentGateway'
            defaultValue={providerPayment ? providerPayment?.hasOwnPaymentGateway : true}
            control={control}
            render={({ field: { onChange: handleC, value } }) => (
              <FormControlLabel
                control={<CustomToggleButton onChange={e => handleC(e.target.checked)} checked={value!} />}
                label={t("has Own Payment Gateway")}
              />
            )}
          />
        </Grid>
      </Grid>
    </>
  );
};
