import { useCustomFormContext } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Button, CustomTable, Grid, PlusIcon, Typography, useTheme } from "@toolkit/ui";
import { FieldArrayWithId, useFieldArray } from "react-hook-form";
import { ITransactionValidateRequestFormValues } from "../TransactionValidateRequest/TransactionValidateRequestFormSchema";
import {
  TransactionValidateRequestCareTeamUpsertModalApi,
  TransactionValidateRequestCareTeamUpsertModal,
} from "../../modals/TransactionValidateRequestCareTeamUpsertModal/TransactionValidateRequestCareTeamUpsertModal";
import {
  ITransactionValidateRequestCareTeamUpsertFormEvent,
  ITransactionValidateRequestCareTeamFormValues,
} from "../TransactionValidateRequestCareTeam/TransactionValidateRequestCareTeamFormSchema";
import { useTransactionValidateRequestCareTeamsColumns } from "./useTransactionValidateRequestCareTeamColumns";

export const TransactionValidateRequestCareTeamsForm = () => {
  const { t } = useTranslation("provider");
  const theme = useTheme();

  const form = useCustomFormContext<ITransactionValidateRequestFormValues>();
  const {
    control,
    isFormDisabled,
    formState: { errors },
  } = form;

  const { fields, append, remove, update } = useFieldArray<ITransactionValidateRequestFormValues, "careTeam">({
    control,
    name: "careTeam",
  });

  const onAddClick = () => {
    TransactionValidateRequestCareTeamUpsertModalApi.open();
  };

  const onCareTeamUpsertModalChange = (event: ITransactionValidateRequestCareTeamUpsertFormEvent) => {
    if (event.type === "CREATE") {
      append(event.payload.values);
      TransactionValidateRequestCareTeamUpsertModalApi.close();
    } else if (event.type === "UPDATE") {
      const updatedFieldIndex = fields.findIndex(field => field.id === event.payload.values.index);
      if (updatedFieldIndex > -1) {
        update(updatedFieldIndex, event.payload.values);
      }
      TransactionValidateRequestCareTeamUpsertModalApi.close();
    }
  };

  const onEditRowClick = (row: FieldArrayWithId<ITransactionValidateRequestCareTeamFormValues>) => {
    const item = fields.find(field => field.id === row.id) as ITransactionValidateRequestCareTeamFormValues | undefined;
    if (item) {
      TransactionValidateRequestCareTeamUpsertModalApi.open({ ...item, index: row.id });
    }
  };

  const onDeleteRowClick = (_: FieldArrayWithId<ITransactionValidateRequestCareTeamFormValues>, index: number) => {
    remove(index);
  };

  return (
    <Grid container spacing={2}>
      <TransactionValidateRequestCareTeamUpsertModal onChange={onCareTeamUpsertModalChange} />

      <Grid item xs={12}>
        <Grid container>
          <Grid xs={8} lg={10}>
            <Typography fontSize={theme.mixins.fonts.fontSize.lg} fontWeight={theme.mixins.fonts.fontWeight.medium}>
              {t("Care Teams")}
            </Typography>
          </Grid>

          {!isFormDisabled && (
            <Grid xs={4} lg={2}>
              <Button fullWidth onClick={onAddClick} startIcon={<PlusIcon />}>
                {t("Add Care Team Member")}
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {errors.careTeam && (
          <Typography color='error' fontSize={theme.mixins.fonts.fontSize.sm}>
            {errors?.careTeam?.message}
          </Typography>
        )}
      </Grid>

      <Grid item xs={12}>
        <CustomTable
          data={fields}
          columns={useTransactionValidateRequestCareTeamsColumns()}
          isEditVisible
          isDeleteVisible
          isRowEditable={() => !isFormDisabled}
          isRowDeletable={() => !isFormDisabled}
          onEditRow={onEditRowClick}
          onDeleteRow={onDeleteRowClick}
          hasFooter={false}
          scrollable
        />
      </Grid>
    </Grid>
  );
};
