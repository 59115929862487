import { useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps } from "@toolkit/ui";
import { useMemo } from "react";

export const usePatientHealthParameterHistoryVitalSignColumns = (): CustomTableColumnProps<{
  value: number | string;
  date: string;
  source?: string;
}>[] => {
  const { t } = useTranslation("provider");

  return useMemo(() => {
    return [
      {
        key: "value",
        header: t("Value"),
        accessor: "value",
      },
      {
        key: "date",
        header: t("Date"),
        accessor: "date",
        type: "date",
      },
      {
        key: "source",
        header: t("Source"),
        accessor: "source",
      },
    ];
  }, [t]);
};
