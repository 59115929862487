/* eslint-disable sonarjs/no-identical-functions */
import { useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps } from "@toolkit/ui";
import { useMemo } from "react";
import { IOptimaValidationRequestsListNode } from "../../types";
import {
  getBranchesHealthLicenseAutocompleteFilter,
  getOptimaCliniciansAutocompleteFilter,
  getOptimaPayersAutocompleteFilter,
} from "@health/autocompletes";
import { getAutocompleteEnumFilter } from "@health/enum-options";
import { split } from "lodash";

export const useFiltersFields = (): CustomTableColumnProps<Partial<IOptimaValidationRequestsListNode>>[] => {
  const { t } = useTranslation("provider");

  return useMemo(() => {
    return [
      {
        key: "id",
        header: t("Request Id"),
        showOnlyForFilter: true,
        filter: {
          type: "string",
          name: "requestId",
        },
      },
      {
        key: "visitId",
        header: t("Visit Id"),
        showOnlyForFilter: true,
        filter: {
          type: "string",
          name: "visitId",
        },
      },
      {
        key: "patientId",
        header: t("Patient Id"),
        showOnlyForFilterField: true,
        filter: {
          type: "string",
          name: "patientId",
        },
      },

      {
        key: "patientName",
        header: t("Patient Name"),
        showOnlyForFilterField: true,
        filter: {
          type: "string",
          name: "patientName",
        },
      },
      {
        key: "insurancePayer",
        header: t("Payer"),
        showOnlyForFilterField: true,
        filter: getOptimaPayersAutocompleteFilter({ name: "payers", multiple: true }),
      },
      {
        key: "fromBillDate",
        header: t("From Bill Date"),
        showOnlyForFilterField: true,
        filter: {
          type: "date",
          name: "fromBillDate",
        },
      },
      {
        key: "toBillDate",
        header: t("To Bill Date"),
        showOnlyForFilterField: true,
        filter: {
          type: "date",
          name: "toBillDate",
        },
      },
      {
        key: "fromVisitDate",
        header: t("From Visit Date"),
        showOnlyForFilterField: true,
        filter: {
          type: "date",
          name: "fromVisitDate",
        },
      },
      {
        key: "toVisitDate",
        header: t("To Visit Date"),
        showOnlyForFilterField: true,
        filter: {
          type: "date",
          name: "toVisitDate",
        },
      },
      {
        key: "claimStatus",
        header: t("Claim Status"),
        showOnlyForFilterField: true,
        filter: getAutocompleteEnumFilter("ClaimStatus", "claimStatuses", { multiple: true }),
      },
      {
        key: "hasDislikedEdits",
        header: t("Has Disliked Edits"),
        showOnlyForFilterField: true,
        filter: getAutocompleteEnumFilter("YesNo", "hasDislikedEdits"),
      },
      {
        key: "createdDateFrom",
        header: t("Created Date From"),
        showOnlyForFilterField: true,
        filter: {
          type: "date",
          name: "fromDate",
        },
      },
      {
        key: "createdDateTo",
        header: t("Created Date To"),
        showOnlyForFilterField: true,
        filter: {
          type: "date",
          name: "toDate",
        },
      },
      {
        key: "potentialStatus",
        header: t("Potential Status"),
        showOnlyForFilterField: true,
        filter: getAutocompleteEnumFilter("ApprovalStatusType", "approvalStatuses", { multiple: true }),
      },
      {
        key: "branch",
        header: t("Branch"),
        filter: getBranchesHealthLicenseAutocompleteFilter({ name: "facility" }),
      },
      {
        key: "clinician-filter",
        header: t("Clinician"),
        showOnlyForFilterField: true,
        filter: getOptimaCliniciansAutocompleteFilter({ name: "practitionerId" }),
      },
      {
        key: "bill-number-filter",
        header: t("Bill Number"),
        showOnlyForFilterField: true,
        filter: {
          type: "string",
          name: "billNumber",
          getValueForBackend: value =>
            value
              ? split(value, /[\r?\n,]+/)
                  .map(item => item.trim())
                  .filter(Boolean)
              : undefined,
          rows: 2,
        },
      },
      {
        key: "recivers-filter",
        header: t("Receivers"),
        showOnlyForFilterField: true,
        filter: {
          type: "string",
          name: "receivers",
          getValueForBackend: value =>
            value
              ? split(value, /[\r?\n,]+/)
                  .map(item => item.trim())
                  .filter(Boolean)
              : undefined,
          rows: 2,
        },
      },
    ];
  }, [t]);
};
