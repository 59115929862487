import { FC } from "react";
import { BasicInfoSection } from "../BasicInfoSection/BasicInfoSection";
import { DetailedInfoSection } from "../DetailedInfoSection/DetailedInfoSection";
import { Box } from "@toolkit/ui";
import {
  IOptimaPatientEligibilityRequestHistoryDataType,
  IOptimaBenefitOptionType,
  IOptimaBenefitEligibilityType,
} from "@/pages/OptimaPatientEligibilityScan/types";

type EligibilityDetailsProps = {
  nodeData: IOptimaPatientEligibilityRequestHistoryDataType;
  eligibilityData: IOptimaPatientEligibilityRequestHistoryDataType["patientEligibility"];
  benefit: IOptimaBenefitOptionType;
  benefitData: IOptimaBenefitEligibilityType;
  handleBenefitAutocompleteChange: (event: React.SyntheticEvent, value: IOptimaBenefitOptionType | null) => void;
};

export const EligibilityDetails: FC<EligibilityDetailsProps> = ({
  benefitData,
  benefit,
  nodeData,
  eligibilityData,
  handleBenefitAutocompleteChange,
}) => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <BasicInfoSection
        benefit={benefit}
        benefitData={benefitData}
        nodeData={nodeData}
        handleBenefitAutocompleteChange={handleBenefitAutocompleteChange}
      />

      <DetailedInfoSection eligibilityData={eligibilityData} />
    </Box>
  );
};
