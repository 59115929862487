import { Box, CircularProgress, Typography } from "@toolkit/ui";
import { useLoadingIndicatorStyles } from "./useLoadingIndicator.styles";
import { FC } from "react";

type LoadingIndicatorProps = {
  message: string;
};

export const LoadingIndicator: FC<LoadingIndicatorProps> = ({ message }) => {
  const { classes } = useLoadingIndicatorStyles();

  return (
    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
      <CircularProgress size={40} />
      <Typography className={classes.loadingText}>{message}</Typography>
    </Box>
  );
};
