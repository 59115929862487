import { Card, Typography, Box } from "@toolkit/ui";
import { useTranslation } from "@toolkit/i18n";
import { ImagesViewer } from "@toolkit/ui";
import { useScreenshotCardStyles } from "./useScreenshotCard.styles";
import { FC } from "react";
import { IOptimaPatientEligibilityRequestHistoryDataType } from "../../types";
import { useCardStyles } from "@/pages/OptimaPatientEligibilityScan/styles/useCard.styles";

type ScreenshotCardProps = {
  snapshotList?: string[];
  eligibilityData: IOptimaPatientEligibilityRequestHistoryDataType["patientEligibility"];
};

export const ScreenshotCard: FC<ScreenshotCardProps> = ({ snapshotList, eligibilityData }) => {
  const { t } = useTranslation("optima");
  const cardStyles = useCardStyles();
  const { classes } = useScreenshotCardStyles();

  return (
    <Card className={`${cardStyles.classes.cardBase} ${classes.screenShotCard}`}>
      <Typography className={cardStyles.classes.cardTitle}>{t("Screenshot")}</Typography>
      <Box className={classes.snapshotsWrapper}>
        {snapshotList?.length ? (
          <ImagesViewer images={snapshotList} />
        ) : (
          eligibilityData?.snapshots?.length === 0 && <Typography>{t("No Screenshot")}</Typography>
        )}
      </Box>
    </Card>
  );
};
