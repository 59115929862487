import { formGirdSpacing } from "@toolkit/core";
import { Button, Divider, FormCard, Grid, PlusIcon } from "@toolkit/ui";
import React, { FC } from "react";
import { AppointmentTypeField } from "../components";
import ReminderSetting from "../components/ReminderSetting";
import ReminderSettingFormModal from "../components/ReminderSettingFormModal";
import ReminderSettingLoader from "../components/ReminderSettingLoader";
import { useAppointmentsRemindersSettings } from "./useAppointmentsRemindersSettings";

export const AppointmentsRemindersSettings: FC = () => {
  const {
    isLoading,
    appointmentType,
    isFormModalOpen,
    isMaxRemindersReached,
    reminderSettings,
    selectedNotificationSetting,
    t,
    onCloseFormModal,
    onOpenFormModal,
    handleAppointmentTypeChange,
    handleRefetchSettings,
    handleAddReminder,
  } = useAppointmentsRemindersSettings();

  return (
    <>
      <Grid container spacing={formGirdSpacing}>
        <Grid item xs={12}>
          <FormCard title={t("Visit Type")} loading={false} doYouHaveData>
            <Grid container spacing={formGirdSpacing}>
              <Grid item xs={12} md={4}>
                <AppointmentTypeField onAppointmentTypeChange={handleAppointmentTypeChange} />
              </Grid>
            </Grid>
          </FormCard>
        </Grid>
        {isLoading ? (
          <Grid item xs={12}>
            <FormCard title={t("Appointments Reminders")} loading={false} doYouHaveData>
              <Grid item container spacing={formGirdSpacing}>
                <ReminderSettingLoader />
              </Grid>
            </FormCard>
          </Grid>
        ) : (
          !!reminderSettings.length && (
            <Grid item xs={12}>
              <FormCard title={t("Appointments Reminders")} loading={isLoading} doYouHaveData>
                <Grid item container spacing={formGirdSpacing}>
                  {reminderSettings.map((setting, index) => (
                    <React.Fragment key={setting.id}>
                      <ReminderSetting
                        onOpenFormModal={onOpenFormModal}
                        onRefetchSettings={handleRefetchSettings}
                        title={index === 0 ? t("Confirmation Appointment") : t("reminder", { count: index })}
                        isConfirmation={index === 0}
                        reminderSetting={setting}
                      />
                      {index + 1 < reminderSettings.length && (
                        <Grid item xs={12}>
                          <Divider />
                        </Grid>
                      )}
                    </React.Fragment>
                  ))}
                </Grid>
              </FormCard>
            </Grid>
          )
        )}
        <Grid item>
          <ReminderSettingFormModal
            appointmentType={appointmentType}
            isOpen={isFormModalOpen}
            buttonRender={
              isLoading ? (
                <></>
              ) : (
                <Button startIcon={<PlusIcon />} sx={{ borderRadius: 24 }} onClick={handleAddReminder} disabled={isMaxRemindersReached}>
                  {t("Add Reminder")}
                </Button>
              )
            }
            onClose={onCloseFormModal}
            isConfirmation={!reminderSettings.length}
            reminderSetting={selectedNotificationSetting}
            onRefetchSettings={handleRefetchSettings}
          />
        </Grid>
      </Grid>
    </>
  );
};
