import React, { FC } from "react";
import { Grid } from "@toolkit/ui";
import { PatientHealthParameterNumeric } from "../PatientHealthParameterNumeric/PatientHealthParameterNumeric";
import { healthParameterCodes } from "../../constants";
import { PatientHealthParameterNumber } from "../PatientHealthParameterNumber/PatientHealthParameterNumber";
import { PatientHealthParameterAudio } from "../PatientHealthParameterAudio/PatientHealthParameterAudio";
import { PatientHealthParameterVideo } from "../PatientHealthParameterVideo/PatientHealthParameterVideo";
import { PatientHealthParametersVitalSignsReadings } from "../PatientHealthParametersVitalSignsReadings/PatientHealthParametersVitalSignsReadings";

type PatientHealthParametersVisitProps = {
  visitId: string;
  token: string;
};

export const PatientHealthParametersVisit: FC<PatientHealthParametersVisitProps> = props => {
  const { visitId, token } = props;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <PatientHealthParametersVitalSignsReadings visitId={visitId} token={token} />
      </Grid>

      <Grid item xs={12}>
        <PatientHealthParameterNumeric token={token} filter={{ visitId, codes: [healthParameterCodes.ecg] }} />
      </Grid>

      <Grid item xs={12}>
        <PatientHealthParameterNumber token={token} filter={{ visitId, codes: [healthParameterCodes.bloodGlucose] }} />
      </Grid>

      <Grid item xs={12}>
        <PatientHealthParameterAudio token={token} filter={{ visitId, codes: [healthParameterCodes.stethoscopeHeartSound] }} />
      </Grid>

      <Grid item xs={12}>
        <PatientHealthParameterAudio token={token} filter={{ visitId, codes: [healthParameterCodes.stethoscopeLungSound] }} />
      </Grid>

      <Grid item xs={12}>
        <PatientHealthParameterVideo token={token} filter={{ visitId, codes: [healthParameterCodes.otoscopePicture] }} />
      </Grid>

      <Grid item xs={12}>
        <PatientHealthParameterVideo token={token} filter={{ visitId, codes: [healthParameterCodes.otoscopeVideo] }} />
      </Grid>
    </Grid>
  );
};
