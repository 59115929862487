import { FC } from "react";
import { FormTextField, Grid, FormCard } from "@toolkit/ui";
import { useTranslation } from "@toolkit/i18n";

export const ProviderInformationForm: FC = () => {
  const { t } = useTranslation("provider");

  return (
    <FormCard title={t("Provider Information")} loading={false} doYouHaveData>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <FormTextField name='providerId' label={t("Provider ID")} placeholder={t("Provider ID")} />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormTextField name='facility' label={t("Facility")} placeholder={t("Facility")} />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormTextField name='prescriptionId' label={t("Prescription ID")} placeholder={t("Prescription ID")} />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormTextField name='payeeType' label={t("Payee Type")} placeholder={t("Payee Type")} />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormTextField name='referral' label={t("Referral")} placeholder={t("Referral")} />
        </Grid>
      </Grid>
    </FormCard>
  );
};
