import { usePatientHealthParameters } from "./usePatientHealthParameters";
import { convertPatientHealthParametersNumberToChartData } from "../others";
import { PatientHealthParameterFilterInput } from "@/schema/types";

type PatientHealthParametersVitalSignsProps = {
  token?: string;
  filter: PatientHealthParameterFilterInput;
};

export const usePatientHealthParametersNumber = (props: PatientHealthParametersVitalSignsProps) => {
  const { token, filter } = props;

  const { healthParameter, patientHealthParameters, loading } = usePatientHealthParameters({
    first: filter?.codes?.length === 1 ? 10 : 20,
    token,
    filter,
  });

  const patientHealthParametersByCode = filter?.codes?.map(code => patientHealthParameters?.filter(item => item?.hpCode === code)) || [];

  const formattedData = patientHealthParametersByCode
    .filter(item => item?.length)
    .map(item => convertPatientHealthParametersNumberToChartData(item!));

  const chartData = formattedData.map(item => ({ data: item }));

  const [value, date, source] = [
    formattedData?.map(item => item?.[0]?.value)?.join("/"),
    formattedData?.map(item => item?.[0]?.date)?.[0],
    formattedData?.map(item => item?.[0]?.source)?.[0],
  ];

  return {
    healthParameter,
    chartData,
    value,
    date,
    source,
    loading,
  };
};
