/* eslint-disable react/prop-types */
import { VisitPrescriptionActivity, VisitPrescriptionTransaction } from "@/schema/types";
import { useOpenState } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Button, Card, CardContent, CustomTableColumnProps, List, ListItem, ListItemText, Popover, Typography } from "@toolkit/ui";
import { useRef } from "react";
import { getInstructions } from "./utils";

interface MedicationInstructionsProps {
  activities: VisitPrescriptionActivity[];
}

const MedicationInstructions: React.FC<MedicationInstructionsProps> = ({ activities }) => {
  const { handleClose, handleOpen, open } = useOpenState();
  const { t } = useTranslation("provider");
  const ref = useRef<HTMLButtonElement>(null);

  return (
    <>
      <Button variant='contained' onClick={handleOpen} ref={ref}>
        Medications
      </Button>
      <Popover
        open={open}
        onClose={handleClose}
        anchorEl={ref.current}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Card>
          <CardContent>
            <List>
              {activities.map(activity => (
                <ListItem key={activity.code}>
                  <ListItemText>
                    <Typography>{getInstructions(activity, t)}</Typography>
                  </ListItemText>
                </ListItem>
              ))}
            </List>
          </CardContent>
        </Card>
      </Popover>
    </>
  );
};

export const useColumns = () => {
  const { t } = useTranslation("provider");

  return [
    {
      key: "erx",
      header: t("Erx Reference"),
      type: "string",
      settings: {
        disableToggleVisibility: true,
        hideFromSettings: true,
      },
      accessor: d => d?.referenceId,
    },
    {
      key: "medication",
      header: t("Medications"),
      type: "string",
      settings: {
        hideFromSettings: true,
        disableToggleVisibility: true,
      },
      accessor: d => <MedicationInstructions activities={d.activities as VisitPrescriptionActivity[]} />,
    },
  ] as CustomTableColumnProps<VisitPrescriptionTransaction>[];
};
