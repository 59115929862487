import { TransactionClaimActivityFilterInput } from "@/schema/types";
import { useOnOptimaHaveNewNotification } from "@health/sse";
import { FilterGrid, PageWrapper, TableGrid, TableSettingComponent, useGridContext } from "@toolkit/ui";
import { useOptimaClaimActivitiesQuery } from "@/pages/OptimaClaims/gql";
import { FC } from "react";
import { GridProvider } from "@/shared/components";
import { useOptimaClaimActivitiesColumns } from "./useOptimaClaimActivitiesColumns";

type OptimaClaimsActivitiesListProps = {
  filters?: Partial<TransactionClaimActivityFilterInput>;
};

export const OptimaClaimsActivitiesGridContainer: FC<OptimaClaimsActivitiesListProps> = props => {
  const { filters } = props;
  const { refetch } = useGridContext();

  useOnOptimaHaveNewNotification(() => {
    refetch({ first: 10 });
  });

  return (
    <GridProvider
      gridName={"OptimaClaimsActivitiesList"}
      query={useOptimaClaimActivitiesQuery}
      columns={useOptimaClaimActivitiesColumns()}
      hasTableSetting
      filterInput={filters}
    >
      <PageWrapper filters={<FilterGrid />} actions={<TableSettingComponent />}>
        <TableGrid />
      </PageWrapper>
    </GridProvider>
  );
};
