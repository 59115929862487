import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetVendorIdQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetVendorIdQuery = { __typename?: 'Query', me?: { __typename?: 'User', vendor?: { __typename?: 'Vendor', id: string } | null } | null };


export const GetVendorIdDocument = gql`
    query getVendorId {
  me {
    vendor {
      id
    }
  }
}
    `;

/**
 * __useGetVendorIdQuery__
 *
 * To run a query within a React component, call `useGetVendorIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVendorIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVendorIdQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetVendorIdQuery(baseOptions?: Apollo.QueryHookOptions<GetVendorIdQuery, GetVendorIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetVendorIdQuery, GetVendorIdQueryVariables>(GetVendorIdDocument, options);
      }
export function useGetVendorIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVendorIdQuery, GetVendorIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetVendorIdQuery, GetVendorIdQueryVariables>(GetVendorIdDocument, options);
        }
export function useGetVendorIdSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetVendorIdQuery, GetVendorIdQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetVendorIdQuery, GetVendorIdQueryVariables>(GetVendorIdDocument, options);
        }
export type GetVendorIdQueryHookResult = ReturnType<typeof useGetVendorIdQuery>;
export type GetVendorIdLazyQueryHookResult = ReturnType<typeof useGetVendorIdLazyQuery>;
export type GetVendorIdSuspenseQueryHookResult = ReturnType<typeof useGetVendorIdSuspenseQuery>;
export type GetVendorIdQueryResult = Apollo.QueryResult<GetVendorIdQuery, GetVendorIdQueryVariables>;