import { Card, Typography } from "@toolkit/ui";
import { useTranslation } from "@toolkit/i18n";
import { useAutoCoverageCardStyles } from "./useAutoCoverageCard.styles";
import { FC } from "react";
import { IOptimaBenefitEligibilityType } from "../../types";
import { useCardStyles } from "../../styles/useCard.styles";

type AutoCoverageCardProps = {
  benefitData?: IOptimaBenefitEligibilityType;
};

export const AutoCoverageCard: FC<AutoCoverageCardProps> = ({ benefitData }) => {
  const { t } = useTranslation("optima");
  const cardStyles = useCardStyles();
  const { classes } = useAutoCoverageCardStyles();

  return (
    <Card className={cardStyles.classes.cardBase}>
      <Typography className={cardStyles.classes.cardTitle}>{t("Auto Coverage")}</Typography>
      <Typography className={classes.disclaimer} color={"error.main"}>
        {t("Disclaimer")}: {t("The system is still in the testing phase; it's essential to double-check the results from the screenshots.")}
      </Typography>
      {benefitData?.aiJustification ? (
        <>
          <Typography className={cardStyles.classes.cardBodyText}>{benefitData?.aiJustification}</Typography>
        </>
      ) : (
        <>
          <Typography className={cardStyles.classes.cardBodyText}>{t("No Data")}</Typography>
        </>
      )}
    </Card>
  );
};
