/* eslint-disable max-lines */
import { TransactionSortField } from "@/schema/types";
import { getAutocompleteEnumFilter, transactionSourceTypeOptionsMap, transactionStatusTypeOptionsMap } from "@health/enum-options";
import { BACKEND_DATE_TIME_FORMAT, toTitleCase } from "@toolkit/core";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps, Typography, useTheme } from "@toolkit/ui";
import { produce } from "immer";
import moment from "moment";
import { PriorAuthorizationDrawer } from "@/pages/OptimaPriorRequests/components";
import { requestsKpiMode } from "@/pages/OptimaPriorRequests/utils";
import { useMemo } from "react";
import { OptimaPriorRequestNodeFragmentFragment } from "@/shared/gql/fragments";
import { GridCellText } from "../../../../components";
import { getOptimaAuthorizationStatusColor } from "../../../../utils";
import { getBranchesAutocompleteFilter, getOptimaEncounterTypesAutocompleteFilter } from "@health/autocompletes";

type useOptimaPriorRequestListContainerColumnsProps = {
  isProviderAdmin?: boolean;
  selectedKpiMode: requestsKpiMode;
};

export const useOptimaPriorRequestListContainerColumns = (
  props: useOptimaPriorRequestListContainerColumnsProps
): CustomTableColumnProps<OptimaPriorRequestNodeFragmentFragment>[] => {
  const { isProviderAdmin, selectedKpiMode } = props;
  const { t } = useTranslation("domains");
  const theme = useTheme();
  const getFilterBasedOnKpiMode = <T extends object>(filter: T): T | undefined => {
    return selectedKpiMode === "all" ? filter : undefined;
  };

  return useMemo(() => {
    return [
      {
        key: "id",
        header: t("ID"),
        accessor: ({ id }) => <GridCellText text={id} />,
        filter: getFilterBasedOnKpiMode({
          type: "string",
          name: "ids",
        }),
      },
      {
        key: "transactionId",
        header: t("Transaction Id"),
        accessor: ({ transactionId }) => <GridCellText text={transactionId} />,
        filter: getFilterBasedOnKpiMode({
          type: "string",
          name: "transactionId",
        }),
      },
      {
        key: "transactionDate",
        header: t("Transaction Date"),
        sort: {
          columnEnum: TransactionSortField.TransactionDate,
        },
        type: "datetime",
        accessor: "transactionDate",
      },
      {
        key: "transactionType",
        header: t("Transaction Type"),
        accessor: "transactionType",
      },
      {
        key: "branch",
        header: t("Branch"),
        accessor: ({ Branch }) => pickLocalizedValue(Branch?.name, Branch?.nameAr),
        settings: {
          hideFromPreferencesAndTable: !isProviderAdmin,
        },
      },
      {
        key: "encounterType",
        header: t("Encounter Type"),
        accessor: ({ encounterType, encounterTypeLookup }) => encounterTypeLookup?.name || encounterType,
        filter: getOptimaEncounterTypesAutocompleteFilter({ name: "encounterType", multiple: true }),
        showOnlyForFilter: true,
      },
      {
        key: "authorizationStatus",
        header: t("Status"),
        accessor: ({ priorAuthorization }) => (
          <Typography
            fontWeight={theme.mixins.fonts.fontWeight.medium}
            color={getOptimaAuthorizationStatusColor(priorAuthorization?.status || "", theme)}
          >
            {transactionStatusTypeOptionsMap[priorAuthorization?.status || ""]?.label || t("Pending")}
          </Typography>
        ),
        filter: getFilterBasedOnKpiMode(getAutocompleteEnumFilter("TransactionStatusType", "authorizationStatus", { multiple: true })),
      },
      {
        key: "branch",
        header: t("Branch"),
        showOnlyForFilterField: true,
        filter: getBranchesAutocompleteFilter({ name: "branches", multiple: true }),
      },
      {
        key: "patientId",
        header: t("Patient Id"),
        accessor: ({ patientId }) => <GridCellText text={patientId} />,
      },
      {
        key: "memberId",
        header: t("Member Id"),
        accessor: ({ memberId }) => <GridCellText text={memberId} />,
        filter: getFilterBasedOnKpiMode({
          type: "string",
          name: "memberId",
        }),
      },
      {
        key: "emiratesIDNumber",
        header: t("Emirates ID"),
        accessor: ({ emiratesIDNumber }) => <GridCellText text={emiratesIDNumber} />,
        filter: getFilterBasedOnKpiMode({
          type: "string",
          name: "emirateIdNumber",
        }),
      },
      {
        key: "payer",
        header: t("Payer"),
        type: "truncated-text",
        accessor: ({ payer }) => toTitleCase(payer?.name ?? ""),
      },
      {
        key: "payerId",
        header: t("Payer Id"),
        showOnlyForFilter: true,
        settings: {
          hideFromPreferencesAndTable: true,
        },
        filter: {
          type: "string",
          name: "payerId",
        },
      },
      {
        key: "receiver",
        header: t("Receiver"),
        type: "truncated-text",
        accessor: ({ receiver }) => toTitleCase(receiver?.name ?? ""),
      },
      {
        key: "receiverId",
        header: t("Receiver Id"),
        showOnlyForFilter: true,
        settings: {
          hideFromPreferencesAndTable: true,
        },
        filter: {
          type: "string",
          name: "receiverId",
        },
      },
      {
        key: "source",
        header: t("Source"),
        accessor: ({ source }) => transactionSourceTypeOptionsMap[source].label,
        filter: getFilterBasedOnKpiMode(getAutocompleteEnumFilter("TransactionSourceType", "source")),
      },
      {
        key: "authorization",
        header: t("Authorization"),
        accessor: request => <PriorAuthorizationDrawer request={request} />,
      },
      {
        key: "transactionDateInputGte",
        header: t("Transaction Date From"),
        showOnlyForFilter: true,
        settings: {
          hideFromPreferencesAndTable: true,
        },
        filter: {
          type: "date",
          name: "transactionDateInput.gte",
        },
      },
      {
        key: "transactionDateInputLte",
        header: t("Transaction Date To"),
        showOnlyForFilter: true,
        settings: {
          hideFromPreferencesAndTable: true,
        },
        filter: {
          type: "date",
          name: "transactionDateInput.lte",
          getValueForBackend: value =>
            produce(value, draft => {
              draft["gte"] = draft?.gte ? moment(draft?.gte).format(BACKEND_DATE_TIME_FORMAT) : null;
              draft["lte"] = draft?.lte ? moment(draft?.lte).format(BACKEND_DATE_TIME_FORMAT) : null;
            }),
        },
      },
    ];
  }, [t, selectedKpiMode]);
};
