/* eslint-disable max-lines */
import { useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps } from "@toolkit/ui";
import { ActivityFormData } from "./types";
import { getInstructions } from "./utils";

const useFormColumns = () => {
  const { t } = useTranslation("provider");

  return [
    {
      key: "Medication",
      header: t("Medication"),
      sx: {
        width: 200,
      },
      accessor: data => data.code?.value?.display,
    },
    {
      key: "Amount",
      header: t("Amount"),
      sx: {
        width: 200,
      },
      settings: {
        hideFromSettings: true,
        disableToggleVisibility: true,
      },
      accessor: data => data.frequencyValue,
    },
    {
      key: "doseUnitType",
      header: t("Dose Unit Type"),
      sx: {
        width: 200,
      },
      settings: {
        disableToggleVisibility: true,
      },
      accessor: data => data.frequencyUnitType?.value?.display,
    },
    {
      key: "quantity",
      header: t("Frequency"),
      settings: {
        hideFromSettings: true,
      },
      accessor: data => data.quantity,
    },
    {
      key: "frequencyType",
      header: t("Frequency Type"),
      settings: {
        hideFromSettings: true,
      },
      accessor: data => data.frequencyType?.display,
    },
    {
      key: "duration",
      header: t("Duration (Days)"),
      settings: {
        hideFromSettings: true,
      },
      accessor: data => data.duration,
    },
    {
      key: "Refill",
      header: t("Refill"),
      accessor: data => data.refills,
    },
    {
      key: "Notes",
      header: t("Notes"),
      accessor: data => data.observation,
    },

    {
      key: "Instructions",
      header: t("Instructions"),
      accessor: data => getInstructions(data, t),
    },
  ] as CustomTableColumnProps<ActivityFormData>[];
};

export default useFormColumns;
