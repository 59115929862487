import { Card, CircularProgress, Grid } from "@toolkit/ui";
import { HealthParameter } from "@/schema/types";
import React from "react";
import { useProfileContext } from "@/shared/modules/patient/context/ProfileContext";
import {
  PatientHealthParameterNumber,
  PatientHealthParameterHistoryModal,
  PatientHealthParameterEmpty,
} from "@/pages/PatientHealthParameters";
import { useDigitalTwinGuidedCareTemplateGetQuery } from "../../gql";
import { DigitalTwinPatientHealthParameter } from "../DigitalTwinPatientHealthParameter/DigitalTwinPatientHealthParameter";
import { useDigitalTwinStyle } from "./useDigitalTwinStyle";

export const DigitalTwin = () => {
  const { classes } = useDigitalTwinStyle();

  const { guidedCareProgramTemplateId } = useProfileContext();

  const { data, loading } = useDigitalTwinGuidedCareTemplateGetQuery({
    variables: {
      id: guidedCareProgramTemplateId!,
    },
    fetchPolicy: "no-cache",
    skip: !guidedCareProgramTemplateId,
  });

  const healthProgramTemplate = data?.healthProgramTemplate;
  const templateOverAllHealthParameter = healthProgramTemplate?.overAllHealthParameter as HealthParameter;
  const templateHealthParameters = (healthProgramTemplate?.healthParameters || []) as HealthParameter[];

  return (
    <Grid container spacing={2}>
      {loading ? (
        <Grid xs={12}>
          <Card className={classes.loadingCard}>
            <CircularProgress size={40} />
          </Card>
        </Grid>
      ) : (
        <>
          {healthProgramTemplate ? (
            <>
              <PatientHealthParameterHistoryModal />

              {templateOverAllHealthParameter?.code && (
                <Grid item xs={12}>
                  <DigitalTwinPatientHealthParameter filter={{ codes: [templateOverAllHealthParameter?.code] }} />
                </Grid>
              )}

              {!!templateHealthParameters?.length && (
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    {templateHealthParameters?.map(item => (
                      <>
                        {item?.code && (
                          <Grid key={item?.code} item xs={item?.riskParameter?.code ? 6 : 12}>
                            <PatientHealthParameterNumber filter={{ codes: [item?.code] }} />
                          </Grid>
                        )}

                        {item?.riskParameter?.code && (
                          <Grid key={item?.riskParameter?.code} item xs={6}>
                            <DigitalTwinPatientHealthParameter filter={{ codes: [item?.riskParameter?.code] }} />
                          </Grid>
                        )}
                      </>
                    ))}
                  </Grid>
                </Grid>
              )}
            </>
          ) : (
            <Grid xs={12}>
              <Card className={classes.emptyCard}>
                <PatientHealthParameterEmpty />
              </Card>
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
};
