import { PatientHealthDocument } from "@/schema/types";
import { healthDocumentTypeOptionsMap } from "@health/enum-options";
import { useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps } from "@toolkit/ui";
import { useMemo } from "react";
import { HealthDocumentDownload } from "../HealthDocumentDownload/HealthDocumentDownload";

export const useHealthDocumentsColumns = (): CustomTableColumnProps<PatientHealthDocument>[] => {
  const { t } = useTranslation("provider");

  return useMemo(() => {
    return [
      {
        key: "name",
        header: t("Name"),
        accessor: "name",
      },
      {
        key: "type",
        header: t("Type"),
        accessor: ({ type }) => healthDocumentTypeOptionsMap[type]?.label,
      },
      {
        key: "date",
        header: t("Date"),
        type: "datetime",
        accessor: "resultDate",
      },
      {
        key: "description",
        header: t("Description"),
        type: "truncated-text",
        accessor: "description",
      },
      {
        key: "download",
        header: t("Download"),
        accessor: ({ fileLinks }) => <HealthDocumentDownload fileLinks={fileLinks} />,
      },
    ];
  }, [t]);
};
