import { useMemo, FC } from "react";
import { OrderEventsDrawer } from "@/pages/GlobalOrders/components/OrderEvents";
import { TruncateTypography, ValueWithLabel } from "@toolkit/ui";
import { IManualSubmittedOrderListNode, Note, ProcessedEvent } from "../../types";
import moment from "moment";
import { i18n } from "@toolkit/i18n";

const NotesDisplay: FC<{ notes: Note[] }> = ({ notes }) => (
  <ValueWithLabel
    px={1}
    label={i18n.t("Notes", { ns: "provider" })}
    value={<TruncateTypography text={notes.map(note => note?.text).join(", ")} />}
  />
);

export const ManualOrderLines: FC<IManualSubmittedOrderListNode> = props => {
  const { events: orderEvents, id, notes, user } = props;

  const processedEvents = useMemo((): ProcessedEvent[] => {
    if (!orderEvents || !notes) return [];

    const sortedEvents = [...orderEvents].filter(event => event?.date).sort((a, b) => moment(a?.date).unix() - moment(b?.date).unix());

    const sortedNotes = [...notes].filter(note => note?.created).sort((a, b) => moment(a?.created).unix() - moment(b?.created).unix());

    const notesMap = new Map<string, Note[]>();

    sortedNotes.forEach(note => {
      if (!note?.createdBy?.fullName) return;
      const timestamp = moment(note.created).unix();
      const roundedTime = Math.round(timestamp / 3) * 3;
      const key = `${note.createdBy.fullName}-${roundedTime}`;
      notesMap.set(key, [...(notesMap.get(key) || []), note]);
    });

    return sortedEvents
      .map(event => {
        if (!event?.user?.fullName) return null;
        const timestamp = moment(event.date).unix();
        const roundedTime = Math.round(timestamp / 3) * 3;
        const key = `${event.user.fullName}-${roundedTime}`;
        const matchingNotes = notesMap.get(key) || [];

        return {
          date: event.date,
          id: event.id,
          status: event.status,
          firstName: event.user.fullName,
          lastName: "",
          defaultBranchName: event.user.defaultBranch?.name || "-",
          hideBranch: user?.id === event.user.id,
          extraNode: matchingNotes.length > 0 ? <NotesDisplay notes={matchingNotes} /> : null,
        };
      })
      .filter((event): event is ProcessedEvent => Boolean(event));
  }, [orderEvents, notes, user?.id]);

  return <OrderEventsDrawer orderId={id} events={processedEvents} />;
};

export default ManualOrderLines;
