import { GuidedCareActivityType, Maybe, TemplateIntervention } from "@/schema/types";
import { flatMap, sortBy } from "lodash";
import { ITemplateInterventionActivityItem } from "../types/types";

export const getInterventionActivityIcon = (activityType: GuidedCareActivityType) => {
  switch (activityType) {
    case GuidedCareActivityType.HomeVisit:
      return { icon: "home3", viewBox: "1 1 22 22" };
    case GuidedCareActivityType.OnlineVisit:
      return { icon: "video", viewBox: "-2 -2 25 25" };
    case GuidedCareActivityType.FacilityVisit:
      return { icon: "inPerson", viewBox: "-1.5 -1.5 26 26" };
    case GuidedCareActivityType.MedicalForm:
      return { icon: "medical-form", viewBox: "0 -2 30 30" };
    case GuidedCareActivityType.MedicalMessageInstruction:
    case GuidedCareActivityType.MedicalMessageGeneral:
    case GuidedCareActivityType.MedicalMessageReminder:
    case GuidedCareActivityType.MedicalMessageMedical:
    case GuidedCareActivityType.MedicalMessageMonitoring:
      return { icon: "medical-massage", viewBox: "0 2 22 22" };
    case GuidedCareActivityType.Lab:
      return { icon: "blood", viewBox: "-2 -4 30 30" };
    case GuidedCareActivityType.Rad:
      return { icon: "job", viewBox: "1 0 25 25" };
    default:
      return { icon: "pills" };
  }
};

export const getInterventionActivitiesItems = (intervention?: Maybe<TemplateIntervention>): ITemplateInterventionActivityItem[] => {
  if (!intervention) return [];

  const interventionActivityItems = flatMap(
    intervention?.interventionTemplateActivities,
    activity =>
      activity?.interventionActivityEventItems?.map(item => ({
        ...item!,
        templateActivityType: activity?.templateActivityType!,
        isDeleted: false,
      })) || []
  );

  return sortBy(interventionActivityItems, "offsetInDays");
};
