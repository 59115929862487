import { PatientHealthParameterFilterInput, TemplateFieldType } from "@/schema/types";
import React, { FC } from "react";
import { Grid } from "@toolkit/ui";
import {
  PatientHealthParameterCard,
  PatientHealthParameterHistoryModalApi,
  convertPatientHealthParametersNumberToChartData,
  usePatientHealthParameters,
} from "@/pages/PatientHealthParameters";
import { DigitalTwinPatientHealthParameterChart } from "../DigitalTwinPatientHealthParameterChart/DigitalTwinPatientHealthParameterChart";

type DigitalTwinPatientHealthParameterProps = {
  filter: PatientHealthParameterFilterInput;
};

export const DigitalTwinPatientHealthParameter: FC<DigitalTwinPatientHealthParameterProps> = props => {
  const { filter } = props;

  const { healthParameter, patientHealthParameters, loading } = usePatientHealthParameters({
    first: 10,
    filter,
  });

  const patientHealthParameter = patientHealthParameters?.[0];

  const formattedData = convertPatientHealthParametersNumberToChartData(patientHealthParameters);

  const chartData = formattedData?.length ? [{ data: formattedData }] : [];

  const onPatientHealthParameterCardChange = () => {
    PatientHealthParameterHistoryModalApi.open({
      type: TemplateFieldType.Number,
      healthParameter,
      filter,
    });
  };

  return (
    <PatientHealthParameterCard
      healthParameter={healthParameter}
      patientHealthParameter={patientHealthParameter}
      isLoading={loading}
      isExpandIconShown={!!chartData?.length}
      onChange={onPatientHealthParameterCardChange}
    >
      {!!chartData?.length && (
        <Grid container>
          <Grid item xs={12}>
            <DigitalTwinPatientHealthParameterChart dataSets={chartData} isLoading={loading} />
          </Grid>
        </Grid>
      )}
    </PatientHealthParameterCard>
  );
};
