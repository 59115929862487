import { ManualOrderStatus, MarketplaceOrderStatus } from "@/schema/types";
import { useTranslation } from "@toolkit/i18n";
import {
  Card,
  Divider,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
  Typography,
} from "@toolkit/ui";
import moment from "moment/moment";
import { FC, ReactNode } from "react";
import { CardContent } from "./CardContent";
import { useOrderEventStyles } from "./useOrderEventStyles";

export const getStatus = (item: string) => {
  const result = item
    .toLowerCase()
    .split("_")
    .map(str => str?.charAt(0)?.toUpperCase() + str?.slice(1));
  return result.join(" ");
};

export type EventType = {
  id: string;
  status?: MarketplaceOrderStatus | ManualOrderStatus;
  date: any;
  firstName: string;
  lastName: string;
  defaultBranchName: string;
  hideBranch?: boolean;
  extraNode?: ReactNode;
};

export const OrderEventTimelineItem: FC<{ event: EventType }> = ({
  event: { date, defaultBranchName, firstName, lastName, status, hideBranch, extraNode },
}) => {
  const { i18n, t } = useTranslation("provider");
  const { classes } = useOrderEventStyles();

  return (
    <TimelineItem>
      <TimelineOppositeContent className={classes.content}>
        <Typography className={classes.dateBold}>{moment(date).locale(i18n.language).format("D MMM YYYY")}</Typography>
      </TimelineOppositeContent>

      <TimelineSeparator sx={{ position: "relative" }}>
        <TimelineDot variant='outlined' className={classes.dot} />
        <TimelineConnector className={classes.separator} />
      </TimelineSeparator>

      <TimelineContent className={classes.position}>
        <Card className={classes.card}>
          <div className={classes.flex}>
            <Typography className={classes.textBold}>{getStatus(status!)}</Typography>
            <Typography className={classes.date}>{moment(date).locale(i18n.language).format("h:mm:ss a")}</Typography>
          </div>
          <Divider className={classes.line} />
          <div className={classes.flex}>
            <div>
              <CardContent flex='column' title={t("User Name")} subTitle={`${firstName} ${lastName}`} />
            </div>

            {!hideBranch && (
              <div>
                <CardContent flex='column' title={t("Branch")} subTitle={defaultBranchName} />
              </div>
            )}
          </div>
          {extraNode}
        </Card>
      </TimelineContent>
    </TimelineItem>
  );
};
