import { HealthParameter, PatientHealthParameterFilterInput, TemplateFieldType } from "@/schema/types";
import { CustomDialog } from "@toolkit/ui";
import { useOpenState } from "@toolkit/core";
import React, { FC, useEffect, useState } from "react";
import {
  PatientHealthParameterHistoryAudio,
  PatientHealthParameterHistoryNumber,
  PatientHealthParameterHistoryVideo,
} from "../../components";
import { PatientHealthParameterHistoryNumeric } from "../../components/PatientHealthParameterHistoryNumeric/PatientHealthParameterHistoryNumeric";
import { pickLocalizedValue } from "@toolkit/i18n";

type IPatientHealthParameterHistoryModalData = {
  type: TemplateFieldType;
  healthParameter: HealthParameter;
  filter: PatientHealthParameterFilterInput;
};

type IOpen = (data: IPatientHealthParameterHistoryModalData) => void;
type IClose = () => void;

export const PatientHealthParameterHistoryModalApi: { open: IOpen; close: IClose } = {
  open: () => {},
  close: () => {},
};

type PatientHealthParameterHistoryModalProps = {
  token?: string;
};

export const PatientHealthParameterHistoryModal: FC<PatientHealthParameterHistoryModalProps> = props => {
  const { token } = props;

  const [data, setData] = useState<IPatientHealthParameterHistoryModalData>();

  const { open: isOpen, handleOpen, handleClose } = useOpenState();

  const open: IOpen = _data => {
    handleOpen();
    setData(_data);
  };

  const close: IClose = () => {
    handleClose();
  };

  useEffect(() => {
    PatientHealthParameterHistoryModalApi.open = open;
    PatientHealthParameterHistoryModalApi.close = close;
  }, []);

  return (
    <CustomDialog
      type={"base"}
      open={isOpen}
      DialogTitleProps={{
        title: pickLocalizedValue(data?.healthParameter?.display, data?.healthParameter?.arabicDisplay)!,
        onClose: close,
      }}
    >
      {data?.type === TemplateFieldType.Number && <PatientHealthParameterHistoryNumber token={token} filter={data?.filter!} />}

      {data?.type === TemplateFieldType.NumericList && <PatientHealthParameterHistoryNumeric token={token} filter={data?.filter!} />}

      {data?.type === TemplateFieldType.AudioFile && <PatientHealthParameterHistoryAudio token={token} filter={data?.filter!} />}

      {data?.type === TemplateFieldType.VideoFile && <PatientHealthParameterHistoryVideo token={token} filter={data?.filter!} />}
    </CustomDialog>
  );
};
