import { FC } from "react";
import { Grid, FormCard, FormNumberField } from "@toolkit/ui";
import { useTranslation } from "@toolkit/i18n";

export const FinancialInformationForm: FC = () => {
  const { t } = useTranslation("provider");

  return (
    <FormCard title={t("Financial Information")} loading={false} doYouHaveData>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <FormNumberField name='gross' label={t("Gross Amount")} placeholder={t("Gross Amount")} />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormNumberField name='patientShare' label={t("Patient Share")} placeholder={t("Patient Share")} />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormNumberField name='net' label={t("Net Amount")} placeholder={t("Net Amount")} />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormNumberField name='tax' label={t("Tax")} placeholder={t("Tax")} />
        </Grid>
      </Grid>
    </FormCard>
  );
};
