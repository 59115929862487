import { transactionStatusTypeOptionsMap } from "@health/enum-options";
import { useTranslation } from "@toolkit/i18n";
import { ValueWithLabel } from "@toolkit/ui";
import { getOptimaAuthorizationStatusColor } from "../../../../utils";
import { usePriorAuthorizationDrawerStyles } from "../PriorAuthorizationDrawer/PriorAuthorizationDrawerStyle";
import { FC } from "react";
import { OptimaPriorRequestNodeFragmentFragment } from "@/shared/gql/fragments";
import { toTitleCase } from "@toolkit/core";

type PriorAuthorizationDrawerSummaryProps = {
  request: OptimaPriorRequestNodeFragmentFragment;
};

export const PriorAuthorizationDrawerSummary: FC<PriorAuthorizationDrawerSummaryProps> = props => {
  const { request } = props;
  const { priorAuthorization } = request;
  const { classes, theme } = usePriorAuthorizationDrawerStyles();
  const { t } = useTranslation("domains");
  return (
    <>
      <ValueWithLabel
        label={t("Authorization Status")}
        value={priorAuthorization?.status ? transactionStatusTypeOptionsMap[priorAuthorization?.status].label : "-"}
        labelProps={{
          className: classes.label,
        }}
        valueProps={{
          className: classes.value,
          color: getOptimaAuthorizationStatusColor(priorAuthorization?.status || "", theme),
        }}
      />
      <ValueWithLabel
        labelProps={{
          className: classes.label,
        }}
        valueProps={{
          className: classes.value,
        }}
        label={t("Authorization ID")}
        value={priorAuthorization?.id}
      />
      <ValueWithLabel
        labelProps={{
          className: classes.label,
        }}
        valueProps={{
          className: classes.value,
        }}
        label={t("Member ID")}
        value={request?.memberId || "-"}
      />
      <ValueWithLabel
        labelProps={{
          className: classes.label,
        }}
        valueProps={{
          className: classes.value,
        }}
        label={t("Patient ID")}
        value={request?.patientId || "-"}
      />
      <ValueWithLabel
        labelProps={{
          className: classes.label,
        }}
        valueProps={{
          className: classes.value,
        }}
        label={t("Service Type")}
        value={request?.encounterTypeLookup?.name || request?.encounterType || "-"}
      />
      <ValueWithLabel
        labelProps={{
          className: classes.label,
        }}
        valueProps={{
          className: classes.value,
        }}
        label={t("Payer")}
        value={toTitleCase(request?.payer?.name || "-") || "-"}
      />
    </>
  );
};
