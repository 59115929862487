import { PatientHealthParameterFilterInput, TemplateFieldType } from "@/schema/types";
import React, { FC } from "react";
import { Grid } from "@toolkit/ui";
import { convertPatientHealthParametersNumberToChartData } from "../../others";
import { usePatientHealthParameters } from "../../hooks";
import { PatientHealthParameterHistoryModalApi } from "../../modals";
import { PatientHealthParameterCard } from "../PatientHealthParameterCard/PatientHealthParameterCard";
import { PatientHealthParameterChart } from "../PatientHealthParameterChart/PatientHealthParameterChart";

type PatientHealthParameterNumberProps = {
  token?: string;
  filter: PatientHealthParameterFilterInput;
};

export const PatientHealthParameterNumber: FC<PatientHealthParameterNumberProps> = props => {
  const { token, filter } = props;

  const { healthParameter, patientHealthParameters, loading } = usePatientHealthParameters({
    first: 10,
    token,
    filter,
  });

  const patientHealthParameter = patientHealthParameters?.[0];

  const formattedData = convertPatientHealthParametersNumberToChartData(patientHealthParameters);

  const chartData = formattedData?.length ? [{ data: formattedData }] : [];

  const onPatientHealthParameterCardChange = () => {
    PatientHealthParameterHistoryModalApi.open({
      type: TemplateFieldType.Number,
      healthParameter,
      filter,
    });
  };

  return (
    <PatientHealthParameterCard
      healthParameter={healthParameter}
      patientHealthParameter={patientHealthParameter}
      isLoading={loading}
      isExpandIconShown={chartData?.length ? !filter?.visitId : false}
      onChange={onPatientHealthParameterCardChange}
    >
      {!!chartData?.length && (
        <Grid container>
          <Grid item xs={12}>
            <PatientHealthParameterChart healthParameter={healthParameter} chartData={chartData} isLoading={loading} />
          </Grid>
        </Grid>
      )}
    </PatientHealthParameterCard>
  );
};
