import { userWorkspaceNurseVar } from "@/pages/UserWorkspace/vars";
import { useReactiveVar } from "@apollo/client";
import { useState } from "react";
import { useOidcUserProfile } from "shared/hooks/useOidcUserProfile";
import { isNurseUser } from "utils";

export const useVisitsHistoryListPage = () => {
  const { userInfo } = useOidcUserProfile();
  const [isMeOnly, setIsMeOnly] = useState(true);

  const isVendorAdmin = userInfo?.app_role?.toLowerCase() === "admin";
  const selfOnly = isVendorAdmin ? isMeOnly : true;
  const currentNurseWorkDetails = useReactiveVar(userWorkspaceNurseVar);
  const skipCall = isNurseUser(userInfo) && !currentNurseWorkDetails?.doctors?.length;
  const doctors = currentNurseWorkDetails?.doctors?.map(e => e?.id);
  const filters = { doctors: isNurseUser(userInfo) ? doctors : undefined };
  const handleToggleMyOnly = (_, value) => setIsMeOnly(value);

  return {
    isMeOnly,
    isVendorAdmin,
    selfOnly,
    handleToggleMyOnly,
    skipCall,
    filters,
  };
};
