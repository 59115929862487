import React, { FC } from "react";
import { Card, SkeletonCard } from "@toolkit/ui";
import { PatientHealthParameterFilterInput, TemplateFieldType } from "@/schema/types";
import { pickLocalizedValue } from "@toolkit/i18n";
import { usePatientHealthParametersNumber } from "../../hooks";
import { PatientHealthParameterHistoryModalApi } from "../../modals";
import { PatientHealthParameterChart } from "../PatientHealthParameterChart/PatientHealthParameterChart";
import { PatientHealthParameterVitalSignCardInformation } from "../PatientHealthParameterVitalSignCardInformation/PatientHealthParameterVitalSignCardInformation";

type PatientHealthParameterVitalSignCardProps = {
  token?: string;
  title: string;
  changePercentage?: string;
  filter: PatientHealthParameterFilterInput;
};

export const PatientHealthParameterVitalSignCard: FC<PatientHealthParameterVitalSignCardProps> = props => {
  const { token, title, changePercentage, filter } = props;

  const { healthParameter, chartData, value, date, source, loading } = usePatientHealthParametersNumber({
    token,
    filter,
  });

  const onPatientHealthParameterVitalSignCardInformationChange = () => {
    PatientHealthParameterHistoryModalApi.open({
      type: TemplateFieldType.Number,
      healthParameter,
      filter,
    });
  };

  return (
    <>
      {loading ? (
        <SkeletonCard height={200} />
      ) : (
        <Card>
          <PatientHealthParameterVitalSignCardInformation
            title={title}
            value={value}
            date={date}
            unit={pickLocalizedValue(healthParameter?.unit?.display, healthParameter?.unit?.arabicDisplay)!}
            source={source}
            changePercentage={changePercentage}
            isExpandIconShown={!!chartData?.length}
            onChange={onPatientHealthParameterVitalSignCardInformationChange}
          />

          <PatientHealthParameterChart healthParameter={healthParameter} chartData={chartData} isLoading={loading} />
        </Card>
      )}
    </>
  );
};
