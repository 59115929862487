import { z } from "zod";

export const transactionValidateRequestCareTeamFormSchema = z.object({
  sequence: z.string(),
  practitionerId: z.string(),
  practitionerRole: z.string(),
  speciality: z.string(),
});

export type ITransactionValidateRequestCareTeamFormValues = z.infer<typeof transactionValidateRequestCareTeamFormSchema>;

export const transactionValidateRequestCareTeamFormDefaultValues: ITransactionValidateRequestCareTeamFormValues = {
  sequence: "1",
  practitionerId: "123",
  practitionerRole: "Specialist",
  speciality: "MD",
};

export type ITransactionValidateRequestCareTeamUpsertFormEvent =
  | { type: "CREATE"; payload: { values: ITransactionValidateRequestCareTeamFormValues } }
  | { type: "UPDATE"; payload: { values: ITransactionValidateRequestCareTeamFormValues & { index: string } } };
