import { Card, Typography } from "@toolkit/ui";
import { useTranslation } from "@toolkit/i18n";
import { BenefitsList } from "../BenefitsList/BenefitsList";
import { FC } from "react";
import { PatientEligibilityBenefit } from "@/schema/types";
import { useCardStyles } from "@/pages/OptimaPatientEligibilityScan/styles/useCard.styles";
import { IOptimaPatientEligibilityRequestHistoryDataType } from "@/pages/OptimaPatientEligibilityScan/types";

type BenefitsCardProps = {
  eligibilityData: IOptimaPatientEligibilityRequestHistoryDataType["patientEligibility"];
};

export const BenefitsCard: FC<BenefitsCardProps> = ({ eligibilityData }) => {
  const { t } = useTranslation("optima");
  const { classes } = useCardStyles();

  const benefits = eligibilityData?.benefits?.filter(benefit => benefit !== null) as PatientEligibilityBenefit[];

  return (
    <Card sx={{ padding: 2, mt: 2 }}>
      <Typography className={classes.cardTitle}>{t("Benefits")}</Typography>
      <BenefitsList benefits={benefits} />
    </Card>
  );
};
