import { TFunction } from "@toolkit/i18n";
import { NavigateFunction } from "react-router-dom";
import RouteItem from "shared/components/Root/routeTypes";
import { optimaWidgetsPaths, optimaWidgetsRoute } from "./constants/optimaWidgetsPaths.constants";
import { OptimaWidgetsList } from "./List/OptimaWidgetsList";

export const optimaWidgetUsageRoutes: (props: { navigate: NavigateFunction; t: TFunction }) => RouteItem = ({ navigate, t }): RouteItem => {
  return {
    id: "optima-widgets",
    text: t("Optima Widgets"),
    route: optimaWidgetsRoute,
    hidden: false,
    fullPath: optimaWidgetsPaths.indexPage.fullPath,
    element: <OptimaWidgetsList />,
    onClick: route => navigate(route),
  };
};
