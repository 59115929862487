import { useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps } from "@toolkit/ui";
import { useMemo } from "react";
import { ITransactionValidateRequestCareTeamFormValues } from "../TransactionValidateRequestCareTeam/TransactionValidateRequestCareTeamFormSchema";

export const useTransactionValidateRequestCareTeamsColumns =
  (): CustomTableColumnProps<ITransactionValidateRequestCareTeamFormValues>[] => {
    const { t } = useTranslation("provider");
    return useMemo(() => {
      return [
        {
          key: "sequence",
          header: t("Sequence"),
          accessor: ({ sequence }) => sequence,
        },
        {
          key: "practitionerId",
          header: t("Practitioner ID"),
          accessor: ({ practitionerId }) => practitionerId,
        },
        {
          key: "practitionerRole",
          header: t("Practitioner Role"),
          accessor: ({ practitionerRole }) => practitionerRole,
        },
        {
          key: "speciality",
          header: t("Speciality"),
          accessor: ({ speciality }) => speciality,
        },
      ];
    }, [t]);
  };
