import { useOptimaSaveUserActionMutation } from "@/pages/Optima/OptimaPayerCredentials/gql";
import { OptimaUserAction, OptimaValidationRequestFilterInput } from "@/schema/types";
import { useGridFilter } from "@toolkit/ui";
import { useEffect, useState } from "react";
import { useGetRcmOptimaValidationRequestsKpiCountsQuery } from "../../gql";
import { IOptimaRequestKpi, optimaRequestKpi, requestsKpiMode } from "../../utils";

export const useOptimaValidationRequestsListKpiController = () => {
  const [selectedKpiMode, setSelectedKpiMode] = useState<IOptimaRequestKpi>(optimaRequestKpi.all);

  const [activeInnerFilters, setActiveInnerFilters] = useState<OptimaValidationRequestFilterInput>();
  const { activeFiltersProps, staticFiltersProps } = useGridFilter({
    doFilter: (filters: OptimaValidationRequestFilterInput) => setActiveInnerFilters(filters),
    filterInput: {},
  });
  const [fetchOptimaSaveUserActionMutation] = useOptimaSaveUserActionMutation();
  const { data } = useGetRcmOptimaValidationRequestsKpiCountsQuery({
    variables: {
      filterAll: activeInnerFilters,
      filterRejected: optimaRequestKpi.rejected.filters,
      filterPartially: optimaRequestKpi.partially.filters,
    },
  });

  const hasFilter = selectedKpiMode?.type === "all";

  const onActiveFiltersChange = (filtersValue: OptimaValidationRequestFilterInput | undefined) => {
    setActiveInnerFilters(filtersValue);
  };

  const totalCounts = {
    all: data?.allRequests?.totalCount || 0,
    rejected: data?.rejectedRequests?.totalCount || 0,
    partially: data?.partiallyApprovedRequests?.totalCount || 0,
  };

  const handleKpiModeChange = (mode: requestsKpiMode) => {
    setSelectedKpiMode(optimaRequestKpi[mode]);
  };
  useEffect(() => {
    if (staticFiltersProps.isOpen) {
      fetchOptimaSaveUserActionMutation({
        variables: {
          userAction: OptimaUserAction.OptimaDoctorVisitFilter,
        },
      });
    }
  }, [staticFiltersProps.isOpen]);

  return {
    selectedKpiMode,
    handleKpiModeChange,
    totalCounts,
    hasFilter,
    activeFilters: activeInnerFilters,
    onActiveFiltersChange,
    activeFiltersProps,
    staticFiltersProps,
  };
};
