import { useOpenState } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Button, CircularProgress, CustomDialog, useAddToast } from "@toolkit/ui";
import { OptimaCreateDetails } from "@/pages/OptimaVisitValidationRequest/components/OptimaCreateDetails/OptimaCreateDetails";
import { useOptimaValidationRequestActionCreateMutation } from "@/pages/OptimaVisitValidationRequest/gql";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";

type IOptimaEditDetailsModalData = {
  visitId?: string;
};

type IOpen = (data: IOptimaEditDetailsModalData) => void;
type IClose = () => void;

export const OptimaCreateDetailsModalApi: { open: IOpen; close: IClose } = {
  open: () => {},
  close: () => {},
};

export const OptimaCreateDetailsModal = () => {
  const { t } = useTranslation();
  const [data, setData] = useState<IOptimaEditDetailsModalData>();

  const { open: isOpen, handleOpen, handleClose } = useOpenState();
  const { failed, succeeded } = useAddToast();
  const methods = useForm();
  const { handleSubmit, reset } = methods;
  const [optimaValidationRequestActionCreateMutation, { loading: isRequestActionLoading }] = useOptimaValidationRequestActionCreateMutation(
    {
      onCompleted: data => {
        if (data?.optimaValidationRequestActionCreate?.id) {
          succeeded(t("Action created successfully"));
          handleClose();
          reset();
        } else {
          failed(t("Action creation failed"));
        }
      },
      onError: () => {
        failed(t("Action creation failed"));
      },
    }
  );

  const onRequestActionCreate = input => {
    if (data?.visitId) {
      optimaValidationRequestActionCreateMutation({
        variables: {
          visitId: data?.visitId,
          action: input?.action,
        },
      });
    }
  };

  const open: IOpen = _data => {
    handleOpen();
    setData(_data);
  };

  const close: IClose = () => {
    handleClose();
  };

  useEffect(() => {
    OptimaCreateDetailsModalApi.open = open;
    OptimaCreateDetailsModalApi.close = close;
  }, []);

  const onSubmit = data => {
    onRequestActionCreate(data);
  };

  return (
    <CustomDialog
      type={"base"}
      open={isOpen}
      DialogTitleProps={{
        title: t("Create Action"),
        onClose: close,
      }}
      DialogActionsProps={{
        children: (
          <Button
            color={"primary"}
            disabled={isRequestActionLoading}
            endIcon={isRequestActionLoading ? <CircularProgress color={"inherit"} size={20} /> : null}
            onClick={handleSubmit(onSubmit)}
          >
            {t("Create")}
          </Button>
        ),
      }}
    >
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <OptimaCreateDetails />
        </form>
      </FormProvider>
    </CustomDialog>
  );
};
