import { getAutocompleteEnumFilter, optimaEligabilityPayersTypeOptionsMap } from "@health/enum-options";
import { useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps } from "@toolkit/ui";
import { useMemo } from "react";
import { IOptimaPayerCredentialListNode } from "../../types";
import { getBranchesHealthLicenseAutocompleteFilter } from "@health/autocompletes";

export const useOptimaPayerCredentialListContainerColumns = (): CustomTableColumnProps<IOptimaPayerCredentialListNode>[] => {
  const { t } = useTranslation("provider");

  return useMemo(
    () => [
      {
        key: "payerTpaName",
        header: t("Payer Name"),
        accessor: ({ name }) => (name ? optimaEligabilityPayersTypeOptionsMap[name]?.label : undefined),
        filter: getAutocompleteEnumFilter("OptimaEligabilityPayersType", "name"),
      },
      {
        key: "username",
        header: t("Username"),
        accessor: "username",
      },
      {
        key: "branchName",
        header: t("Branch Name"),
        accessor: "branchName",
      },
      {
        key: "license",
        header: t("Branch License"),
        accessor: "licence",
        filter: getBranchesHealthLicenseAutocompleteFilter({ name: "licence" }),
      },
      {
        key: "password",
        header: t("Password"),
        accessor: "password",
      },
    ],
    [t]
  );
};
