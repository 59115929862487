import { getEnvVariable } from "@/env";
import { ApolloProvider } from "@apollo/client";
import { useIsInEmbedView } from "@health/domains";
import { CallProvider } from "@health/meeting";
import { SadaProvider } from "@health/sada";
import { NotificationsServiceProvider } from "@health/sse";
import { I18nextProvider } from "@toolkit/i18n";
import { FallbackComponent, ToolkitUiProvider } from "@toolkit/ui";
import React, { FC, PropsWithChildren, Suspense, useMemo } from "react";
import { AuthProvider, AuthProviderProps } from "react-oidc-context";
import { BrowserRouter, useLocation } from "react-router-dom";
import { removeUserWorkspaceData } from "@/pages/UserWorkspace/others";
import { client } from "shared/configs/apollo";
import { oidcUserManager } from "shared/configs/oidc";
import { useOidcUserProfile } from "shared/hooks/useOidcUserProfile";
import { i18nProvider } from "shared/i18n";
import { AuthUserProfileProvider } from "shared/providers";
import SiteSettingsVarProvider from "shared/siteSettings.var";
import { ErrorBoundary } from "./ErrorBoundary";
import { RootRouter } from "./shared/components/Root/RootRoutes";
import { visitCallGuestBaseRoute } from "./pages/VisitCallGuest/route";
import { ChatProvider } from "@health/chat";

const EmbedRootProviders: FC<PropsWithChildren> = ({ children }) => {
  const { accessToken: token, userInfo } = useOidcUserProfile();
  const { isInEmbedView } = useIsInEmbedView();
  const { pathname } = useLocation();
  if (isInEmbedView || pathname.startsWith(`/${visitCallGuestBaseRoute}`)) {
    return children;
  }

  return (
    <SadaProvider token={token || ""}>
      <ChatProvider accessToken={token} senderId={userInfo?.user_id}>
        <CallProvider accessToken={token} user={userInfo}>
          {children}
        </CallProvider>
      </ChatProvider>
    </SadaProvider>
  );
};

const AppRootProviders: FC<PropsWithChildren> = ({ children }) => {
  const { sseToken } = useOidcUserProfile();
  return (
    <ErrorBoundary>
      <ApolloProvider client={client}>
        <SiteSettingsVarProvider />
        <I18nextProvider i18n={i18nProvider}>
          <BrowserRouter>
            <ToolkitUiProvider>
              <Suspense fallback={<FallbackComponent />}>
                <NotificationsServiceProvider eventSourceUrl={getEnvVariable("SUBSCRIPTION_URL")} token={sseToken || ""}>
                  <EmbedRootProviders>{children}</EmbedRootProviders>
                </NotificationsServiceProvider>
              </Suspense>
            </ToolkitUiProvider>
          </BrowserRouter>
        </I18nextProvider>
      </ApolloProvider>
    </ErrorBoundary>
  );
};

function App(): React.ReactElement {
  const oidcConfig: AuthProviderProps = useMemo(
    () =>
      ({
        ...oidcUserManager.settings,
        onSigninCallback: user => {
          if (!user || !user?.access_token) return;
          oidcUserManager.storeUser(user);
          removeUserWorkspaceData((user?.profile?.vendor_user_type as string) || "");
        },
      } as AuthProviderProps),
    []
  );
  return (
    <AuthProvider {...oidcConfig}>
      <AuthUserProfileProvider>
        <AppRootProviders>
          <RootRouter />
        </AppRootProviders>
      </AuthUserProfileProvider>
    </AuthProvider>
  );
}

export default App;
