import { IAutocompleteFilter, getAutocompleteFilter, createAutocompleteOption } from "@toolkit/ui";
import { useOptimaCliniciansAutocompleteQuery } from "./gql";
import { IOptimaCliniciansAutocomplete } from "./schema";

export const createOptimaClinicianAutocompleteOption = (clinician: IOptimaCliniciansAutocomplete) => {
  return createAutocompleteOption({ id: clinician?.id!, clinicianName: clinician?.clinicianName! }, "id", "clinicianName");
};

export const getOptimaCliniciansAutocompleteFilter = ({ name, multiple }: IAutocompleteFilter) => {
  return getAutocompleteFilter({
    name,
    multiple,
    accessor: "optimaClinicians",
    query: useOptimaCliniciansAutocompleteQuery,
    labelBy: "clinicianName",
    backendAccessor: "doctorId",
    filterSearchKey: "clinicianNameContains",
  });
};
