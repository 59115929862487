import { EligibilityScanStatusResult } from "@health/domains";
import { useTranslation } from "@toolkit/i18n";
import { Box } from "@toolkit/ui";
import { LoadingIndicator } from "../LoadingIndicator/LoadingIndicator";
import { OptimaCreateEligibilityRequestModal } from "../OptimaCreateEligibilityRequestModal";
import { useEligibilityScanResult } from "./useEligibilityScanResult";
import { usePatientEligibilityPageStyles } from "./usePatientEligibilityPageStyles";

interface IEligibilityScanResultWidgetProps {
  id: string;
  nationalId: string;
}

export const EligibilityScanResultWidget: React.FC<IEligibilityScanResultWidgetProps> = props => {
  const { id, nationalId } = props;
  const { t } = useTranslation("optima");
  const { classes } = usePatientEligibilityPageStyles();

  const {
    // benefit,
    loading,
    nodeData,
    isScanCompleted,
    isFounded,
    eligibilityData,
    payerEligibilityRequestHistories,
    isDoingOptimisticScanCheck,
    handleOpenCreateModal,
    // handleBenefitAutocompleteChange,
    handleSubmitSuccess,
  } = useEligibilityScanResult({ id, nationalId });

  if (loading) {
    return <LoadingIndicator message={t("Loading...")} />;
  }

  return (
    <>
      <Box className={classes.cardsStack}>
        <EligibilityScanStatusResult
          nodeData={nodeData!}
          loading={loading}
          isScanCompleted={isScanCompleted}
          isFounded={isFounded}
          eligibilityData={eligibilityData!}
          isDoingOptimisticScanCheck={isDoingOptimisticScanCheck}
          payerEligibilityRequestHistories={payerEligibilityRequestHistories!}
          handleOpenCreateModal={handleOpenCreateModal}
        />

        <OptimaCreateEligibilityRequestModal onSubmitSuccess={handleSubmitSuccess} />
      </Box>
    </>
  );
};
