import { AntMediaConfiguration } from "@/schema/types";
import { formGirdBreakPoints, useCustomFormContext } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Grid, TextField } from "@toolkit/ui";
import { FC } from "react";
import { IVirtualIntegrationFormValues } from "./VirtualIntegrationFormSchema";

type AntMediaConfigurationFormProps = {
  antMediaConfigurations: AntMediaConfiguration | undefined;
};
export const AntMediaConfigurationForm: FC<AntMediaConfigurationFormProps> = ({ antMediaConfigurations }) => {
  const { t } = useTranslation("provider");
  const {
    register,
    formState: { errors },
  } = useCustomFormContext<IVirtualIntegrationFormValues>();
  console.log(antMediaConfigurations);
  return (
    <>
      <Grid container spacing={2}>
        <Grid item {...formGirdBreakPoints}>
          <TextField
            defaultValue={antMediaConfigurations?.applicationName}
            label={t("Application Name")}
            fullWidth
            {...register("antmediaConfiguration.applicationName")}
            error={Boolean(errors?.antmediaConfiguration?.applicationName?.message)}
            helperText={errors?.antmediaConfiguration?.applicationName?.message}
          />
        </Grid>
        <Grid item {...formGirdBreakPoints}>
          <TextField
            defaultValue={antMediaConfigurations?.url}
            label={t("Base Url")}
            fullWidth
            {...register("antmediaConfiguration.baseUrl")}
            error={Boolean(errors?.antmediaConfiguration?.baseUrl?.message)}
            helperText={errors?.antmediaConfiguration?.baseUrl?.message}
          />
        </Grid>
        <Grid item {...formGirdBreakPoints}>
          <TextField
            label={t("Sdk Secret")}
            fullWidth
            {...register("antmediaConfiguration.sdkSecret")}
            error={Boolean(errors?.antmediaConfiguration?.sdkSecret?.message)}
            helperText={errors?.antmediaConfiguration?.sdkSecret?.message}
          />
        </Grid>
      </Grid>
    </>
  );
};
