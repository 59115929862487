import { MedicalFormAnswer } from "@/schema/types";
import { useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps } from "@toolkit/ui";
import { useMemo } from "react";
import { Classification } from "./Classification";
import { MedicalFormDialog } from "./MedicalFormDialog";

export type CareEngagementColumnType = CustomTableColumnProps<MedicalFormAnswer>;
export type CareEngagementColumnsTypes = CareEngagementColumnType[];

export const useCareEngagementColumns = (): CareEngagementColumnsTypes => {
  const { t } = useTranslation("provider");

  return useMemo(() => {
    return [
      {
        key: "id",
        header: t("Id"),
        accessor: "id",
        type: "string",
      },
      {
        key: "label",
        header: t("Label"),
        accessor: ({ medicalForm, questionAnswers, classificationName }) => (
          <MedicalFormDialog
            label={medicalForm?.name!}
            date={medicalForm?.updateDate! || medicalForm?.createdDate!}
            questionAnswers={questionAnswers!}
            classificationName={classificationName!}
            numberOfQuestions={questionAnswers?.length!}
          />
        ),
        type: "string",
      },
      {
        key: "description",
        header: t("Description"),
        type: "truncated-text",
        accessor: ({ medicalForm }) => medicalForm?.description,
      },
      {
        key: "date",
        header: t("Fulfillment Date"),
        type: "date",
        accessor: ({ medicalForm }) => medicalForm?.updateDate || medicalForm?.createdDate,
      },
      {
        key: "Questions",
        header: t("Questions"),
        accessor: ({ questionAnswers }) => questionAnswers?.length || 0,
        type: "string",
      },
      {
        key: "classification",
        header: t("Status"),
        accessor: ({ classificationName }) => {
          return <Classification name={classificationName!} />;
        },
      },
    ];
  }, []);
};
