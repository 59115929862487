import { useCustomForm } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FormDateTimePickerField, FormTextField, Grid } from "@toolkit/ui";
import { ForwardRefRenderFunction, forwardRef, useEffect, useImperativeHandle } from "react";
import { FormProvider, UseFormReturn } from "react-hook-form";
import {
  ITransactionValidateRequestObservationFormValues,
  ITransactionValidateRequestObservationUpsertFormEvent,
  transactionValidateRequestObservationFormDefaultValues,
  transactionValidateRequestObservationFormSchema,
} from "./TransactionValidateRequestObservationFormSchema";

type TransactionValidateRequestObservationUpsertFormProps = {
  observation?: ITransactionValidateRequestObservationFormValues;
  index?: string;
  onChange: (event: ITransactionValidateRequestObservationUpsertFormEvent) => void;
};

export type TransactionValidateRequestObservationUpsertFormRef = {
  getForm: () => UseFormReturn<ITransactionValidateRequestObservationFormValues>;
  submit: () => void;
};

const TransactionValidateRequestObservationUpsertFormForwardRef: ForwardRefRenderFunction<
  TransactionValidateRequestObservationUpsertFormRef,
  TransactionValidateRequestObservationUpsertFormProps
> = (props, ref) => {
  const { observation, index, onChange } = props;

  const { t } = useTranslation("provider");

  const form = useCustomForm<ITransactionValidateRequestObservationFormValues>({
    defaultValues: transactionValidateRequestObservationFormDefaultValues,
    schema: transactionValidateRequestObservationFormSchema,
  });

  const { setValues, handleSubmit } = form;

  const onSubmit = (values: ITransactionValidateRequestObservationFormValues) => {
    if (observation) {
      if (index) {
        onChange({
          type: "UPDATE",
          payload: { values: { ...values, index } },
        });
      }
    } else {
      onChange({
        type: "CREATE",
        payload: { values },
      });
    }
  };

  useImperativeHandle(ref, () => ({
    getForm: () => form,
    submit: handleSubmit(onSubmit),
  }));

  useEffect(() => {
    if (observation) {
      setValues(observation);
    }
  }, [observation, setValues]);

  return (
    <FormProvider {...form}>
      <form>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormTextField name='sequence' label={t("Sequence")} placeholder={t("Sequence")} />
          </Grid>
          <Grid item xs={12}>
            <FormTextField name='code' label={t("Code")} placeholder={t("Code")} />
          </Grid>
          <Grid item xs={12}>
            <FormTextField name='codeSystem' label={t("Code System")} placeholder={t("Code System")} />
          </Grid>
          <Grid item xs={12}>
            <FormTextField name='type' label={t("Type")} placeholder={t("Type")} />
          </Grid>
          <Grid item xs={12}>
            <FormDateTimePickerField name='periodStart' label={t("Period Start")} placeholder={t("Period Start")} />
          </Grid>
          <Grid item xs={12}>
            <FormDateTimePickerField name='periodEnd' label={t("Period End")} placeholder={t("Period End")} />
          </Grid>
          <Grid item xs={12}>
            <FormTextField name='value' label={t("Value")} placeholder={t("Value")} />
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export const TransactionValidateRequestObservationUpsertForm = forwardRef(TransactionValidateRequestObservationUpsertFormForwardRef);
