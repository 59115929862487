import { Maybe, OptimaDiagnosis } from "@/schema/types";
import { Box } from "@toolkit/ui";
import { FC } from "react";
import { OptimaDiagnosisEditCard } from "../OptimaEditCard/OptimaDiagnosisEditCard";

interface OptimaDiagnosisEditsListProps {
  display?: string;
  diagnosis?: Maybe<OptimaDiagnosis>[] | null;
}

export const OptimaDiagnosisEditsList: FC<OptimaDiagnosisEditsListProps> = ({ display = "", diagnosis: diagnosis }) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", rowGap: 2, marginTop: 1.5 }}>
      {diagnosis?.map(diagnosis => {
        return diagnosis?.edits?.map(editItem => (
          <OptimaDiagnosisEditCard key={editItem?.edit?.id} display={display} edit={editItem?.edit} diagnosisCode={diagnosis.code!} />
        ));
      })}
    </Box>
  );
};
