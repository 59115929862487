import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OptimaPayerCredentialDeleteMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type OptimaPayerCredentialDeleteMutation = { __typename?: 'Mutation', payerCredentialsDelete?: { __typename?: 'PayerCredentials', id: string } | null };


export const OptimaPayerCredentialDeleteDocument = gql`
    mutation OptimaPayerCredentialDelete($id: ID!) {
  payerCredentialsDelete(id: $id) {
    id
  }
}
    `;
export type OptimaPayerCredentialDeleteMutationFn = Apollo.MutationFunction<OptimaPayerCredentialDeleteMutation, OptimaPayerCredentialDeleteMutationVariables>;

/**
 * __useOptimaPayerCredentialDeleteMutation__
 *
 * To run a mutation, you first call `useOptimaPayerCredentialDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOptimaPayerCredentialDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [optimaPayerCredentialDeleteMutation, { data, loading, error }] = useOptimaPayerCredentialDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOptimaPayerCredentialDeleteMutation(baseOptions?: Apollo.MutationHookOptions<OptimaPayerCredentialDeleteMutation, OptimaPayerCredentialDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OptimaPayerCredentialDeleteMutation, OptimaPayerCredentialDeleteMutationVariables>(OptimaPayerCredentialDeleteDocument, options);
      }
export type OptimaPayerCredentialDeleteMutationHookResult = ReturnType<typeof useOptimaPayerCredentialDeleteMutation>;
export type OptimaPayerCredentialDeleteMutationResult = Apollo.MutationResult<OptimaPayerCredentialDeleteMutation>;
export type OptimaPayerCredentialDeleteMutationOptions = Apollo.BaseMutationOptions<OptimaPayerCredentialDeleteMutation, OptimaPayerCredentialDeleteMutationVariables>;