import { UserSortField } from "@/schema/types";
import { getBranchesAutocompleteFilter } from "@health/autocompletes";
import { getAutocompleteEnumFilter } from "@health/enum-options";
import { useTranslation } from "@toolkit/i18n";
import { ConnectionDocumentNode, CustomTableColumnProps } from "@toolkit/ui";
import { useMemo } from "react";
import { GetAllVendorUsersQuery } from "../../gql";
export type AllDoctorsListColumnsTypes = CustomTableColumnProps<ConnectionDocumentNode<GetAllVendorUsersQuery, "users">>[];

export const useAllDoctorsListColumns = (): AllDoctorsListColumnsTypes => {
  const { t } = useTranslation("provider");
  return useMemo(() => {
    return [
      {
        key: "name",
        header: t("Name"),
        showOnlyForFilterField: true,
        filter: {
          type: "string",
          name: "search",
        },
      },
      {
        key: "App Role",
        header: t("App Role"),
        showOnlyForFilterField: true,
        filter: getAutocompleteEnumFilter("AppRoleType", "appRole"),
      },
      {
        key: "branchId",
        header: t("Branch"),
        showOnlyForFilterField: true,
        filter: getBranchesAutocompleteFilter({ name: "branchId" }),
      },
      {
        key: "dateJoinedFrom",
        header: t("Date Joined From"),
        showOnlyForFilterField: true,
        filter: {
          type: "date",
          name: "dateJoined.lte",
        },
      },
      {
        key: "dateJoinedTo",
        header: t("Date Joined To"),
        showOnlyForFilterField: true,
        filter: {
          type: "date",
          name: "dateJoined.gte",
        },
      },
      {
        key: "firstName",
        header: t("First Name"),
        type: "truncated-text",
        accessor: row => row?.firstName,
        sort: {
          columnEnum: UserSortField.FirstName,
        },
      },
      {
        key: "lastName",
        header: t("Last Name"),
        type: "truncated-text",
        accessor: row => row?.lastName,
        sort: {
          columnEnum: UserSortField.LastName,
        },
      },
      {
        key: "email",
        header: t("Email"),
        accessor: "email",
        sort: {
          columnEnum: UserSortField.Email,
        },
      },
      {
        key: "mobile",
        header: t("Mobile"),
        accessor: "mobile",
        type: "mobile",
      },
      {
        key: "isSuperUser",
        header: t("Is Super User"),
        accessor: row => (row?.isSuperuser === true ? t("Yes") : t("No")),
      },
      {
        key: "dateJoined",
        header: t("Date Joined"),
        type: "date",
        accessor: "dateJoined",
        isHidden: false,
        sort: {
          columnEnum: UserSortField.DateJoined,
        },
      },
    ];
  }, []);
};
