import { Box, CustomIcon, ExpandIcon, IconButton, Typography } from "@toolkit/ui";
import React, { FC } from "react";
import { usePatientHealthParameterVitalSignCardInformationStyle } from "./usePatientHealthParameterVitalSignCardInformationStyle";
import { useTranslation } from "@toolkit/i18n";

type PatientHealthParameterVitalSignCardInformationProps = {
  title: string;
  value: string;
  date: string;
  unit?: string;
  source?: string;
  changePercentage?: string;
  isExpandIconShown?: boolean;
  onChange: () => void;
};

export const PatientHealthParameterVitalSignCardInformation: FC<PatientHealthParameterVitalSignCardInformationProps> = props => {
  const { title, value, date, unit, source, changePercentage, isExpandIconShown = true, onChange } = props;

  const { t } = useTranslation("provider");

  const { classes, theme } = usePatientHealthParameterVitalSignCardInformationStyle();

  const changePercentageNumber = changePercentage ? Number(changePercentage) : undefined;

  return (
    <Box className={classes.root}>
      <Box>
        <Typography className={classes.value}>
          {value}

          {value && unit && (
            <Typography component={"span"}>
              <sup> {unit} </sup>
            </Typography>
          )}
        </Typography>

        <Typography className={classes.title}>{title}</Typography>

        {source && (
          <Typography className={classes.source}>
            {t("By")} {source}
          </Typography>
        )}
      </Box>

      <Box className={classes.rightSection}>
        {isExpandIconShown && (
          <IconButton className={classes.expandIcon} onClick={onChange}>
            <ExpandIcon />
          </IconButton>
        )}

        {!!changePercentageNumber && (
          <Box className={classes.percentageWrapper}>
            {changePercentageNumber > 0 ? (
              <CustomIcon icon={"graphHigher"} color={theme.palette.success.main} />
            ) : (
              <CustomIcon icon={"graph"} color={theme.palette.error.main} />
            )}

            <Typography className={classes.percentage}>
              {changePercentageNumber > 0
                ? t("{{value}} Higher", { value: `${Math.floor(changePercentageNumber)}%` })
                : t("{{value}} Lower", { value: `${Math.floor(changePercentageNumber)}%` })}
            </Typography>
          </Box>
        )}

        <Typography className={classes.date}>{date}</Typography>
      </Box>
    </Box>
  );
};
