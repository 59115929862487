import { ProviderSettingsForPayment } from "@/schema/types";
import { useTranslation } from "@toolkit/i18n";
import { Button, FormCard, Grid } from "@toolkit/ui";
import { FC } from "react";
import { FormProvider } from "react-hook-form";
import { SettingsForPaymentForm } from "./SettingsForPaymentForm";
import { useSettingsForPaymentForm } from "./useSettingsForPaymentFrom";

export const SettingsForPayment: FC = () => {
  const { t } = useTranslation("admin");
  const { handleSubmit, methods, isLoading, onSubmit, providerPayment } = useSettingsForPaymentForm();

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormCard title={t("Payment")} loading={isLoading} doYouHaveData={Boolean(providerPayment)}>
              <SettingsForPaymentForm providerPayment={providerPayment as ProviderSettingsForPayment} />
            </FormCard>
          </Grid>
          <Grid item xs={12}>
            <Button type='submit' disabled={isLoading}>
              {t("Save")}
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};
