import { useCustomForm } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FormTextField, Grid, FormCheckbox } from "@toolkit/ui";
import { ForwardRefRenderFunction, forwardRef, useEffect, useImperativeHandle } from "react";
import { FormProvider, UseFormReturn } from "react-hook-form";
import {
  ITransactionValidateRequestDiagnosisFormValues,
  ITransactionValidateRequestDiagnosisUpsertFormEvent,
  transactionValidateRequestDiagnosisFormDefaultValues,
  transactionValidateRequestDiagnosisFormSchema,
} from "./TransactionValidateRequestDiagnosisFormSchema";

type TransactionValidateRequestDiagnosisUpsertFormProps = {
  diagnosis?: ITransactionValidateRequestDiagnosisFormValues;
  index?: string;
  onChange: (event: ITransactionValidateRequestDiagnosisUpsertFormEvent) => void;
};

export type TransactionValidateRequestDiagnosisUpsertFormRef = {
  getForm: () => UseFormReturn<ITransactionValidateRequestDiagnosisFormValues>;
  submit: () => void;
};

const TransactionValidateRequestDiagnosisUpsertFormForwardRef: ForwardRefRenderFunction<
  TransactionValidateRequestDiagnosisUpsertFormRef,
  TransactionValidateRequestDiagnosisUpsertFormProps
> = (props, ref) => {
  const { diagnosis, index, onChange } = props;

  const { t } = useTranslation("provider");

  const form = useCustomForm<ITransactionValidateRequestDiagnosisFormValues>({
    defaultValues: transactionValidateRequestDiagnosisFormDefaultValues,
    schema: transactionValidateRequestDiagnosisFormSchema,
  });

  const { setValues, handleSubmit } = form;

  const onSubmit = (values: ITransactionValidateRequestDiagnosisFormValues) => {
    console.log("UPDATE", { values, index });
    if (diagnosis) {
      if (index) {
        console.log("UPDATE", { values, index });
        onChange({
          type: "UPDATE",
          payload: { values: { ...values, index } },
        });
      }
    } else {
      onChange({
        type: "CREATE",
        payload: { values },
      });
    }
  };

  useImperativeHandle(ref, () => ({
    getForm: () => form,
    submit: handleSubmit(onSubmit),
  }));

  useEffect(() => {
    if (diagnosis) {
      setValues(diagnosis);
    }
  }, [diagnosis, setValues]);

  return (
    <FormProvider {...form}>
      <form>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormTextField name='sequence' label={t("Sequence")} placeholder={t("Sequence")} />
          </Grid>
          <Grid item xs={12}>
            <FormTextField name='code' label={t("Code")} placeholder={t("Code")} />
          </Grid>
          <Grid item xs={12}>
            <FormTextField name='display' label={t("Display")} placeholder={t("Display")} />
          </Grid>
          <Grid item xs={12}>
            <FormTextField name='codeSystem' label={t("Code System")} placeholder={t("Code System")} />
          </Grid>
          <Grid item xs={12}>
            <FormTextField name='type' label={t("Type")} placeholder={t("Type")} />
          </Grid>
          <Grid item xs={12}>
            <FormCheckbox name='onAdmission' label={t("On Admission")} />
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export const TransactionValidateRequestDiagnosisUpsertForm = forwardRef(TransactionValidateRequestDiagnosisUpsertFormForwardRef);
