import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SetManualOrderPickedUpMutationVariables = Types.Exact<{
  orderId: Types.Scalars['ID']['input'];
}>;


export type SetManualOrderPickedUpMutation = { __typename?: 'Mutation', setManualOrderPickedUp?: { __typename?: 'ManualOrder', id: string, status: Types.ManualOrderStatus } | null };


export const SetManualOrderPickedUpDocument = gql`
    mutation SetManualOrderPickedUp($orderId: ID!) {
  setManualOrderPickedUp(orderId: $orderId) {
    id
    status
  }
}
    `;
export type SetManualOrderPickedUpMutationFn = Apollo.MutationFunction<SetManualOrderPickedUpMutation, SetManualOrderPickedUpMutationVariables>;

/**
 * __useSetManualOrderPickedUpMutation__
 *
 * To run a mutation, you first call `useSetManualOrderPickedUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetManualOrderPickedUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setManualOrderPickedUpMutation, { data, loading, error }] = useSetManualOrderPickedUpMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useSetManualOrderPickedUpMutation(baseOptions?: Apollo.MutationHookOptions<SetManualOrderPickedUpMutation, SetManualOrderPickedUpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetManualOrderPickedUpMutation, SetManualOrderPickedUpMutationVariables>(SetManualOrderPickedUpDocument, options);
      }
export type SetManualOrderPickedUpMutationHookResult = ReturnType<typeof useSetManualOrderPickedUpMutation>;
export type SetManualOrderPickedUpMutationResult = Apollo.MutationResult<SetManualOrderPickedUpMutation>;
export type SetManualOrderPickedUpMutationOptions = Apollo.BaseMutationOptions<SetManualOrderPickedUpMutation, SetManualOrderPickedUpMutationVariables>;