import { PatientHealthParameter, PatientHealthParameterFilterInput } from "@/schema/types";
import { CustomTable, Grid } from "@toolkit/ui";
import { FC } from "react";
import { usePatientHealthParametersHistory, usePatientHealthParametersNumber } from "../../hooks";
import { convertPatientHealthParametersToTableData } from "../../others";
import { PatientHealthParameterChart } from "../PatientHealthParameterChart/PatientHealthParameterChart";
import { usePatientHealthParameterHistoryVitalSignColumns } from "./usePatientHealthParameterHistoryVitalSignColumns";

type PatientHealthParameterHistoryNumberProps = {
  token?: string;
  filter: PatientHealthParameterFilterInput;
};

export const PatientHealthParameterHistoryNumber: FC<PatientHealthParameterHistoryNumberProps> = props => {
  const { token, filter } = props;

  const { healthParameter, chartData, loading } = usePatientHealthParametersNumber({
    token,
    filter,
  });

  const { data, tableProps } = usePatientHealthParametersHistory({
    token,
    filter,
  });

  const codesData = filter?.codes?.map(code => data?.filter(item => item?.hpCode === code)) || [];

  const tableData = convertPatientHealthParametersToTableData(codesData.flat() as PatientHealthParameter[]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <PatientHealthParameterChart healthParameter={healthParameter} chartData={chartData} isLoading={loading} />
      </Grid>

      <Grid item xs={12}>
        <CustomTable {...tableProps} data={tableData} columns={usePatientHealthParameterHistoryVitalSignColumns()} />
      </Grid>
    </Grid>
  );
};
