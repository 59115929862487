import React, { FC } from "react";
import { Grid } from "@toolkit/ui";
import { PatientHealthParameterNumeric } from "../PatientHealthParameterNumeric/PatientHealthParameterNumeric";
import { healthParameterCodes } from "../../constants";
import { PatientHealthParameterNumber } from "../PatientHealthParameterNumber/PatientHealthParameterNumber";
import { PatientHealthParameterAudio } from "../PatientHealthParameterAudio/PatientHealthParameterAudio";
import { PatientHealthParameterVideo } from "../PatientHealthParameterVideo/PatientHealthParameterVideo";
import { PatientHealthParametersVitalSigns } from "../PatientHealthParametersVitalSigns/PatientHealthParametersVitalSigns";

type PatientHealthParametersPatientProps = {
  patientId: string;
  token: string;
};

export const PatientHealthParametersPatient: FC<PatientHealthParametersPatientProps> = props => {
  const { patientId, token } = props;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <PatientHealthParametersVitalSigns patientId={patientId} token={token} />
      </Grid>

      <Grid item xs={12}>
        <PatientHealthParameterNumeric token={token} filter={{ patientId: [patientId], codes: [healthParameterCodes.ecg] }} />
      </Grid>

      <Grid item xs={12}>
        <PatientHealthParameterNumber token={token} filter={{ patientId: [patientId], codes: [healthParameterCodes.bloodGlucose] }} />
      </Grid>

      <Grid item xs={12}>
        <PatientHealthParameterAudio
          token={token}
          filter={{ patientId: [patientId], codes: [healthParameterCodes.stethoscopeHeartSound] }}
        />
      </Grid>

      <Grid item xs={12}>
        <PatientHealthParameterAudio
          token={token}
          filter={{ patientId: [patientId], codes: [healthParameterCodes.stethoscopeLungSound] }}
        />
      </Grid>

      <Grid item xs={12}>
        <PatientHealthParameterVideo token={token} filter={{ patientId: [patientId], codes: [healthParameterCodes.otoscopePicture] }} />
      </Grid>

      <Grid item xs={12}>
        <PatientHealthParameterVideo token={token} filter={{ patientId: [patientId], codes: [healthParameterCodes.otoscopeVideo] }} />
      </Grid>
    </Grid>
  );
};
