import React, { FC } from "react";
import { PatientHealthParameterChartLineZoom } from "../PatientHealthParameterChartLineZoom/PatientHealthParameterChartLineZoom";
import { PatientHealthParameterCard } from "../PatientHealthParameterCard/PatientHealthParameterCard";
import { usePatientHealthParameters } from "../../hooks";
import { Grid } from "@toolkit/ui";
import { PatientHealthParameterHistoryModalApi } from "../../modals";
import { PatientHealthParameterFilterInput, TemplateFieldType } from "@/schema/types";

type PatientHealthParameterNumericProps = {
  token?: string;
  filter: PatientHealthParameterFilterInput;
};

export const PatientHealthParameterNumeric: FC<PatientHealthParameterNumericProps> = props => {
  const { token, filter } = props;

  const { healthParameter, patientHealthParameters, loading } = usePatientHealthParameters({
    first: 1,
    token,
    filter,
  });

  const patientHealthParameter = patientHealthParameters?.[0];

  const dataSets =
    patientHealthParameters?.filter(item => item?.valueNumericList)?.map(item => ({ data: item?.valueNumericList || [] })) || [];

  const onPatientHealthParameterCardChange = () => {
    PatientHealthParameterHistoryModalApi.open({
      type: TemplateFieldType.NumericList,
      healthParameter,
      filter,
    });
  };

  return (
    <PatientHealthParameterCard
      healthParameter={healthParameter}
      patientHealthParameter={patientHealthParameter}
      isLoading={loading}
      isExpandIconShown={dataSets?.length ? !filter?.visitId : false}
      onChange={onPatientHealthParameterCardChange}
    >
      {dataSets?.length > 0 && (
        <Grid container>
          <Grid item xs={12}>
            <PatientHealthParameterChartLineZoom dataSets={dataSets} isLoading={loading} />
          </Grid>
        </Grid>
      )}
    </PatientHealthParameterCard>
  );
};
