import { PatientReferral } from "@/schema/types";
import { useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps } from "@toolkit/ui";
import { useMemo } from "react";

export type ReferralColumnTypes = CustomTableColumnProps<PatientReferral>[];

export const useReferralsColumns = (): ReferralColumnTypes => {
  const { t } = useTranslation("provider");
  return useMemo(() => {
    return [
      {
        key: "code",
        header: t("Code"),
        accessor: "code",
      },
      {
        key: "startDate",
        header: t("Start Date"),
        accessor: "startDate",
        type: "date",
      },
      {
        key: "endDate",
        header: t("End Date"),
        accessor: "endDate",
        type: "date",
      },
    ];
  }, []);
};
