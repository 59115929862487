import { ProviderSettings, ProviderSettingsInput } from "@/schema/types";
import { formGirdSpacing } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FormCard, Grid, ToggleButtonController } from "@toolkit/ui";
import { FC } from "react";
import { useFormContext } from "react-hook-form";

export type OutPatientJourneyFormProps = {
  providerSettings?: ProviderSettings | null;
  isLoading?: boolean;
};

const OutPatientJourneyForm: FC<OutPatientJourneyFormProps> = props => {
  const { providerSettings, isLoading: loading = false } = props;
  const { t } = useTranslation("provider");

  const { control, register } = useFormContext<ProviderSettingsInput>();

  return (
    <FormCard title={t("Out Patient Journey")} loading={loading} doYouHaveData>
      <Grid container spacing={formGirdSpacing}>
        <Grid item xs={12}>
          <ToggleButtonController
            control={control}
            label={t("Out Patient Journey Supported")}
            defaultValue={providerSettings?.outPatientJourneySupported ?? false}
            {...register("outPatientJourneySupported")}
          />
        </Grid>
        <Grid item xs={12}>
          <ToggleButtonController
            control={control}
            label={t("Payment After Consultation")}
            defaultValue={providerSettings?.paymentAfterConsultation ?? false}
            {...register("paymentAfterConsultation")}
          />
        </Grid>
      </Grid>
    </FormCard>
  );
};

export default OutPatientJourneyForm;
