import { useParams } from "react-router-dom";
import { Branch } from "@/schema/types";
import { pickLocalizedValue } from "@toolkit/i18n";
import { BranchUpdateContainer } from "@health/domains";
import { useSetBranchesBreadcrumbs } from "../../hooks";
import { IProviderBranchUpdateContainerParams } from "../../types";
import { useProviderBranchBreadcrumbGetQuery } from "../../gql";

export const ProviderBranchUpdateContainer = () => {
  const { branchId } = useParams<IProviderBranchUpdateContainerParams>();

  const { data } = useProviderBranchBreadcrumbGetQuery({
    variables: { id: branchId! },
    skip: !branchId,
    fetchPolicy: "no-cache",
  });

  const branch = data?.branch as Branch;

  useSetBranchesBreadcrumbs("UPDATE", pickLocalizedValue(branch?.name, branch?.nameAr)!);

  return <BranchUpdateContainer branchId={branchId!} />;
};
