import { LocationType } from "@/schema/types";
import { useTranslation } from "@toolkit/i18n";
import { LocationPicker } from "@toolkit/maps";
import { Card, CardContent, LocationIcon, MapMarkerIcon, useTheme } from "@toolkit/ui";
import { FC } from "react";
import { InformationDoctor } from "./InformationDoctor.component";

export const FacilityLocationCard: FC<{
  coordinate: LocationType;
}> = ({ coordinate }) => {
  const { t } = useTranslation("provider");
  const theme = useTheme();
  return (
    <Card>
      <CardContent>
        <InformationDoctor title={t("Location")} headerIcon={<LocationIcon fill={theme.palette.primary.main} />}>
          <LocationPicker
            defaultZoom={15}
            locations={[
              {
                id: "234124",
                icon: <MapMarkerIcon fill='red' />,
                coordinates: {
                  lat: 24.4961134,
                  lng: coordinate?.lng || 54.3798649,
                },
              },
            ]}
          />
        </InformationDoctor>
      </CardContent>
    </Card>
  );
};
