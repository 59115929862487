/* eslint-disable max-lines */
import { Appointment, AppointmentSortingField } from "@/schema/types";
import { getBranchesAutocompleteFilter, getDepartmentsAutocompleteFilter } from "@health/autocompletes";
import {
  appointmentStatusOptionsMap,
  appointmentTypeOptions,
  getAutocompleteEnumFilter,
  paymentStatusOptionsMap,
  requestedByOptionsMap,
} from "@health/enum-options";
import { useTranslation } from "@toolkit/i18n";
import { Box, CustomTableColumnProps, TruncateTypography, Typography, format, useTheme } from "@toolkit/ui";
import { useGetDoctorsFilters } from "pages/VisitHistory/home/useGetDoctorsFilters.hook";
import {
  AppointmentAccept,
  AppointmentCancel,
  AppointmentReject,
  AppointmentReschedule,
  AppointmentTentativeConfirm,
  ChatWithPatient,
} from "pages/appointments/components";
import { AppointmentTabsStatus, getTimeFrame } from "pages/appointments/utils";
import { useMemo } from "react";
import { PageTabsStatusType } from "shared/components/PageTabs";
import { PatientProfile } from "shared/modules/patient";
import { AppointmentServiceDetailsManage } from "../../components/AppointmentServiceDetailsManage/AppointmentServiceDetailsManage";
import { useGetAppointmentsDoctorNameColumns } from "./useGetAppointmentsDoctorNameColumns.hook";
import { getInsuranceInformationItems } from "../../utils/InsuranceInfo.utils";

export const useAppointmentsColumnsContainer = (
  status: PageTabsStatusType,
  isSelfOnlyAppointments: boolean
): CustomTableColumnProps<Appointment>[] => {
  const { t, i18n } = useTranslation("provider");
  const doctorNameColumns = useGetAppointmentsDoctorNameColumns(isSelfOnlyAppointments);
  const doctorFilters = useGetDoctorsFilters(isSelfOnlyAppointments);
  const { mixins } = useTheme();

  return useMemo(
    () => [
      {
        key: "appointmentId",
        header: t("Appointment ID"),
        accessor: ({ id }) => <Typography sx={{ maxWidth: "100px", width: "100px" }}>{id}</Typography>,
      },
      {
        key: "patientName",
        header: t("Patient Name"),
        accessor: ({ consumer }) => (
          <PatientProfile
            patientId={consumer?.id}
            patientName={{
              firstName: consumer?.firstName,
              lastName: consumer?.lastName,
            }}
          />
        ),
      },
      ...doctorNameColumns,
      {
        key: "visitType",
        header: t("Visit Type", "Type"),
        accessor: ({ type }) => {
          return (
            <TruncateTypography
              fontSize={mixins.fonts.fontSize.sm}
              text={appointmentTypeOptions?.find(item => item.value === type)?.label!}
            />
          );
        },
        filter: getAutocompleteEnumFilter("AppointmentType", "type", { multiple: true }),
      },
      {
        key: "paymentStatus",
        header: t("Payment Status"),
        accessor: ({ paymentStatus }) => paymentStatusOptionsMap[paymentStatus!]?.label || "-",
        isHidden: !(
          status === AppointmentTabsStatus.PendingConfirmation ||
          status === AppointmentTabsStatus.Confirmed ||
          status === AppointmentTabsStatus.Done ||
          status === AppointmentTabsStatus.Cancelled
        ),
      },
      {
        key: "requestedBy",
        header: t("Requested By"),
        accessor: ({ requestedBy }) => requestedByOptionsMap[requestedBy!]?.label || "-",
        filter: getAutocompleteEnumFilter("RequestedBy", "requestedBy"),
      },
      {
        key: "requestedStartTimeGte",
        header: t("Requested Start Time From"),
        showOnlyForFilterField: true,
        filter: {
          type: "date",
          name: "requestedStartTime.gte",
          label: t("Requested Start Time From"),
        },
      },
      {
        key: "requestedStartTimeLte",
        header: t("Requested Start Time To"),
        showOnlyForFilterField: true,
        filter: {
          type: "date",
          name: "requestedStartTime.lte",
          label: t("Requested Start Time To"),
        },
      },
      {
        key: "requestedEndTimeGte",
        header: t("Requested End Time From"),
        showOnlyForFilterField: true,
        filter: {
          type: "date",
          name: "requestedEndTime.gte",
          label: t("Requested End Time From"),
        },
      },
      {
        key: "requestedEndTimeLte",
        header: t("Requested End Time To"),
        showOnlyForFilterField: true,
        filter: {
          type: "date",
          name: "requestedEndTime.lte",
          label: t("Requested End Time To"),
        },
      },
      {
        key: "confirmedStartTimeGte",
        header: t("Confirmed Start Time From"),
        showOnlyForFilterField: true,
        filter: {
          type: "date",
          name: "confirmedStartTime.gte",
          label: t("Confirmed Start Time From"),
        },
      },
      {
        key: "confirmedStartTimeLte",
        header: t("Confirmed Start Time To"),
        showOnlyForFilterField: true,
        filter: {
          type: "date",
          name: "confirmedStartTime.lte",
          label: t("Confirmed Start Time To"),
        },
      },
      {
        key: "confirmedEndTimeGte",
        header: t("Confirmed End Time From"),
        showOnlyForFilterField: true,
        filter: {
          type: "date",
          name: "confirmedEndTime.gte",
          label: t("Confirm End Time From"),
        },
      },
      {
        key: "confirmedEndTimeLte",
        header: t("Confirmed End Time To"),
        showOnlyForFilterField: true,
        filter: {
          type: "date",
          name: "confirmedEndTime.lte",
          label: t("Confirmed End Time To"),
        },
      },
      {
        key: "branches",
        header: t("Branches"),
        showOnlyForFilterField: true,
        filter: getBranchesAutocompleteFilter({ name: "branches", multiple: true }),
      },
      ...doctorFilters,
      {
        key: "departments",
        header: t("Departments"),
        showOnlyForFilterField: true,
        filter: getDepartmentsAutocompleteFilter({ name: "departments", multiple: true }),
      },
      {
        key: "confirmedStartTime",
        header: t("Appointment Time"),
        accessor: ({ confirmedStartTime }) => (
          <Box sx={{ width: "max-content" }}>
            {confirmedStartTime && format(confirmedStartTime, "DD MMMM YYYY | HH:mm A", i18n.language)}
          </Box>
        ),
        isHidden: !(status === AppointmentTabsStatus.Confirmed || status === AppointmentTabsStatus.Done),
        sort: {
          columnEnum: AppointmentSortingField.ConfirmedStartDate,
        },
      },
      {
        key: "requestedTimeframe",
        header: t("Requested Timeframe"),
        accessor: row => {
          return <Box sx={{ minWidth: "120px" }}> {getTimeFrame(row)}</Box>;
        },
        isHidden:
          status === AppointmentTabsStatus.Confirmed || status === AppointmentTabsStatus.Done || status === AppointmentTabsStatus.Cancelled,
      },
      {
        key: "status",
        header: t("Status"),
        accessor: ({ status: appointmentStatus }) => <TruncateTypography text={appointmentStatusOptionsMap[appointmentStatus!]?.label} />,
        isHidden: !(status === AppointmentTabsStatus.Cancelled),
      },
      {
        key: "insuranceInfo",
        header: t("Insurance Info"),
        type: "info",
        infoCellOptions: {
          title: t("Insurance information"),
          items: ({ consumer }) => getInsuranceInformationItems(consumer?.activeHealthProgramMembers?.[0]),
          emptyMessage: t("There is No Insurance Information"),
          disableShowButton: ({ consumer }) => !consumer?.activeHealthProgramMembers?.[0],
        },
        isHidden: status === AppointmentTabsStatus.Cancelled,
      },
      {
        key: "branchName",
        header: t("Branch Name"),
        accessor: ({ branch }) => branch?.name || "-",
      },
      {
        key: "cancellationReason",
        header: t("Cancellation Reason"),
        accessor: "cancellationReason",
        isHidden: status !== AppointmentTabsStatus.Cancelled,
      },
      {
        key: "accept",
        header: t("Accept"),
        accessor: ({ id, requestedTimeSlots, consumer }) => (
          <AppointmentAccept
            id={id}
            activeHealthProgramMember={consumer?.activeHealthProgramMembers?.[0]}
            healthProgramsByActiveMember={consumer?.healthProgramsByActiveMembers?.[0]}
            requestedTimeSlots={requestedTimeSlots || []}
          />
        ),
        isHidden: status !== AppointmentTabsStatus.NewRequests,
      },
      {
        key: "confirm",
        header: t("Confirm"),
        accessor: ({ id, requestedTimeSlots, doctor }) => (
          <AppointmentTentativeConfirm id={id} doctorId={doctor?.id!} requestedTimeSlots={requestedTimeSlots!} />
        ),
        isHidden: status !== AppointmentTabsStatus.tentative,
      },
      {
        key: "reject",
        header: t("Reject"),
        accessor: ({ id, requestedTimeSlots }) => <AppointmentReject id={id} requestedTimeSlots={requestedTimeSlots} />,
        isHidden: status !== AppointmentTabsStatus.NewRequests,
      },
      {
        key: "chatWithPatient",
        header: t("Chat with Patient"),
        accessor: ({ consumer }) => <ChatWithPatient patient={consumer} />,
      },
      {
        key: "cancel",
        header: t("Cancel"),
        accessor: ({ id, status: appointmentStatus }) => <AppointmentCancel id={id} status={appointmentStatus!} />,
        isHidden: !(status == AppointmentTabsStatus.Confirmed || status == AppointmentTabsStatus.PendingConfirmation),
      },
      {
        key: "serviceDetails",
        header: t("Service Details"),
        accessor: ({ id, appointmentServiceDetails }) => (
          <AppointmentServiceDetailsManage id={id} serviceDetails={appointmentServiceDetails} status={status} />
        ),
      },
      {
        key: "reschedule",
        header: t("Reschedule"),
        accessor: ({ id, status: appointmentStatus, consumer, paymentInfo, paymentStatus, doctor }) => (
          <AppointmentReschedule
            status={appointmentStatus!}
            id={id!}
            doctorId={doctor?.id}
            patient={consumer}
            paymentInfo={paymentInfo}
            paymentStatus={paymentStatus}
          />
        ),
        isHidden: !(
          status == AppointmentTabsStatus.NewRequests ||
          status == AppointmentTabsStatus.Confirmed ||
          status == AppointmentTabsStatus.PendingConfirmation
        ),
      },
    ],
    [status, doctorNameColumns, isSelfOnlyAppointments, doctorFilters]
  );
};
