import { useCallback, useEffect, useRef, useState } from "react";
import { OptimaCreateEligibilityRequestModalApi } from "../OptimaCreateEligibilityRequestModal";
import { patientEligibilityBenefitsEnumOptions } from "@health/enum-options";
import { IOptimaBenefitOptionType } from "../../types";
import { usePatientEligibilityRequestHistoryLazyQuery } from "@health/domains";

let optimisticInProgressIntervalId: number | null = null;

interface UseEligibilityScanResultProps {
  id: string;
  nationalId: string;
}

export const useEligibilityScanResult = (props: UseEligibilityScanResultProps) => {
  const { id, nationalId } = props;

  const [fetchPatientEligibilityRequestHistory, { loading, data, refetch }] = usePatientEligibilityRequestHistoryLazyQuery({
    fetchPolicy: "no-cache",
  });
  const [isDoingOptimisticScanCheck, setIsDoingOptimisticScanCheck] = useState<boolean>(false);
  const [benefit, setBenefit] = useState<IOptimaBenefitOptionType>(patientEligibilityBenefitsEnumOptions[0]);

  const nodeData = data?.patientEligibilityRequestHistory;
  const nodeDataRef = useRef<typeof nodeData>(nodeData);
  nodeDataRef.current = nodeData;

  const isScanCompleted = !!nodeData?.isCompleted;
  const isFounded = !!nodeData?.isFounded;
  const eligibilityData = nodeData?.patientEligibility;
  const payerEligibilityRequestHistories = nodeData?.payerEligibilityRequestHistories;

  const handleBenefitAutocompleteChange = useCallback((_, value: IOptimaBenefitOptionType | null) => {
    if (value) {
      setBenefit(value);
    }
  }, []);

  useEffect(() => {
    fetchPatientEligibilityRequestHistory({
      variables: {
        patientId: id,
      },
    });
  }, [fetchPatientEligibilityRequestHistory, id]);

  const handleOpenCreateModal = useCallback(() => {
    OptimaCreateEligibilityRequestModalApi.open({
      nationalId: nationalId,
    });
  }, [nationalId]);

  const handleSubmitSuccess = useCallback(() => {
    optimisticInProgressIntervalId = setInterval(() => {
      if (!nodeDataRef.current?.isCompleted) {
        refetch();
      } else {
        clearInterval(optimisticInProgressIntervalId!);
        setIsDoingOptimisticScanCheck(false);
      }
    }, 5000) as unknown as number;
    setIsDoingOptimisticScanCheck(true);
    refetch();
  }, [nodeDataRef, refetch]);

  useEffect(() => {
    isDoingOptimisticScanCheck && setIsDoingOptimisticScanCheck(false);
  }, [nodeData, isDoingOptimisticScanCheck]);

  return {
    loading,
    nodeData,
    isScanCompleted,
    isFounded,
    eligibilityData,
    payerEligibilityRequestHistories,
    isDoingOptimisticScanCheck,
    benefit,
    handleOpenCreateModal,
    handleSubmitSuccess,
    handleBenefitAutocompleteChange,
  };
};
