import { useTranslation } from "@toolkit/i18n";
import { Box, TextField, Typography } from "@toolkit/ui";
import { FC } from "react";
import { useFormContext } from "react-hook-form";

export const OptimaCreateDetails: FC = () => {
  const { t } = useTranslation();
  const { register } = useFormContext();
  return (
    <Box>
      <Typography mb={1}>{t("Assign action")}</Typography>
      <TextField label={t("Action")} fullWidth multiline {...register("action")} rows={4} />
    </Box>
  );
};
