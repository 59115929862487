import { BranchesHealthLicenseAutocomplete } from "@health/autocompletes";
import { optimaEligabilityPayersTypeOptions } from "@health/enum-options";
import { useTranslation } from "@toolkit/i18n";
import { FormAutocomplete, FormTextField, Grid, TextField } from "@toolkit/ui";
import { FC } from "react";
import { useFormContext } from "react-hook-form";

interface OptimaPayerCredentialInformationFormProps {}

export const OptimaPayerCredentialInformationForm: FC<OptimaPayerCredentialInformationFormProps> = () => {
  const { t } = useTranslation("provider");
  const { watch } = useFormContext();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={3}>
        <FormAutocomplete name='name' label={t("Payer TPA Name")} options={optimaEligabilityPayersTypeOptions} />
      </Grid>
      <Grid item xs={12} sm={4} md={4}>
        <BranchesHealthLicenseAutocomplete name='license' label={t("Branch")} />
      </Grid>
      <Grid item xs={12} sm={4} md={2}>
        <TextField
          aria-readonly
          label={t("Health License")}
          variant='filled'
          inputProps={{ readOnly: true }}
          InputProps={{ readOnly: true }}
          value={watch("license")?.value?.healthLicense || "-"}
        />
      </Grid>
      <Grid item xs={12} sm={4} md={3}>
        <FormTextField name='username' label={t("Username")} />
      </Grid>
      <Grid item xs={12} sm={4} md={3}>
        <FormTextField name='password' label={t("Password")} />
      </Grid>
    </Grid>
  );
};
