/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import { useOpenState } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { CustomDialog } from "@toolkit/ui";
import { OptimaContributingFactors } from "@/pages/OptimaVisitValidationRequest/components/OptimaContributingFactors/OptimaContributingFactors";
import { ContributingFactor } from "@/pages/OptimaVisitValidationRequest/types";
import { useEffect, useState } from "react";

type IOptimaContributingFactorsModalData = ContributingFactor[];

type IOpen = (data: IOptimaContributingFactorsModalData) => void;
type IClose = () => void;

export const OptimaContributingFactorsModalApi: { open: IOpen; close: IClose } = {
  open: () => {},
  close: () => {},
};

export const OptimaContributingFactorsModal = () => {
  const [data, setData] = useState<IOptimaContributingFactorsModalData>();

  const { open: isOpen, handleOpen, handleClose } = useOpenState();

  const { t } = useTranslation();

  const open: IOpen = _data => {
    handleOpen();
    setData(_data);
  };

  const close: IClose = () => {
    handleClose();
  };

  useEffect(() => {
    OptimaContributingFactorsModalApi.open = open;
    OptimaContributingFactorsModalApi.close = close;
  }, []);

  return (
    <CustomDialog
      type={"base"}
      maxWidth={"sm"}
      open={isOpen}
      DialogTitleProps={{
        title: t("Possible Fixes To Review"),
        onClose: close,
      }}
      DialogActionsProps={{
        hasClose: true,
      }}
    >
      <OptimaContributingFactors contributingFactors={data || []} />
    </CustomDialog>
  );
};
