import { Box, CustomTable, Typography } from "@toolkit/ui";
import { FC } from "react";
import { useOptimaClaimActivitiesList } from "./useOptimaClaimActivitiesList.hook";
import { usePriorAuthorizationDrawerStyles } from "@/pages/OptimaPriorRequests/components/PriorAuthorizationDrawer/PriorAuthorizationDrawerStyle";
import { useTranslation } from "@toolkit/i18n";

export const OptimaClaimActivitiesList: FC<{ id?: string }> = props => {
  const { id } = props;
  const { tableData, hasNextPage, hasPreviousPage, isLoading, handleGotoNext, handleGoToPrevious, pageSize, columns, totalCount } =
    useOptimaClaimActivitiesList(id);
  const { t } = useTranslation("domains");

  const { classes } = usePriorAuthorizationDrawerStyles();
  return (
    <Box>
      <Box className={classes.claimsActivityContainer}>
        <Typography className={classes.sectionTitle} mt={4} mb={2}>
          {`Activities: (${totalCount})`}
        </Typography>
      </Box>
      <CustomTable
        isLoading={isLoading}
        data={tableData}
        columns={columns}
        pageSize={pageSize}
        pageIndex={0}
        pagesCount={pageSize}
        hasFooter={true}
        hasNextPage={hasNextPage}
        hasPreviousPage={hasPreviousPage}
        onGoToNext={handleGotoNext}
        onGoToPrevious={handleGoToPrevious}
        TableContainerProps={{
          sx: {
            height: 237.5,
          },
        }}
        withoutDataMessage={t("No Activities")}
        emptyIconHeight={100}
      />
    </Box>
  );
};
