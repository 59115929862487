import { useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps } from "@toolkit/ui";
import { useMemo } from "react";
import { ITransactionValidateRequestDiagnosisFormValues } from "../TransactionValidateRequestDiagnosisUpsert/TransactionValidateRequestDiagnosisFormSchema";

export const useTransactionValidateRequestDiagnosisColumns =
  (): CustomTableColumnProps<ITransactionValidateRequestDiagnosisFormValues>[] => {
    const { t } = useTranslation("provider");
    return useMemo(() => {
      return [
        {
          key: "sequence",
          header: t("Sequence"),
          accessor: ({ sequence }) => sequence,
        },
        {
          key: "code",
          header: t("Code"),
          accessor: ({ code }) => code,
        },
        {
          key: "display",
          header: t("Display"),
          accessor: ({ display }) => display,
        },
        {
          key: "codeSystem",
          header: t("Code System"),
          accessor: ({ codeSystem }) => codeSystem,
        },
        {
          key: "type",
          header: t("Type"),
          accessor: ({ type }) => type,
        },
        {
          key: "onAdmission",
          header: t("On Admission"),
          accessor: ({ onAdmission }) => (onAdmission ? "Yes" : "No"),
        },
      ];
    }, [t]);
  };
