import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RcmOptimaValidationRequestsListQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  filter?: Types.InputMaybe<Types.OptimaValidationRequestFilterInput>;
  sortBy?: Types.InputMaybe<Types.OptimaValidationRequestsSortingInput>;
}>;


export type RcmOptimaValidationRequestsListQuery = { __typename?: 'Query', rcmOptimaValidationRequests?: { __typename?: 'OptimaValidationRequestsConnection', edges: Array<{ __typename?: 'OptimaValidationRequestsEdge', node: { __typename?: 'OptimaValidationRequest', id: string, visitId?: string | null, patientName?: string | null, patientGender?: Types.Gender | null, patientDoB?: string | null, patientId?: string | null, createdDate?: any | null, billNumber?: string | null, response?: string | null, request?: string | null, edits?: Array<{ __typename?: 'ValidationRequestEdit', id: string, edit?: { __typename?: 'OptimaEdit', id: string, priority?: Types.OptimaPriority | null, category?: string | null, subCategory?: string | null, code?: string | null, message?: string | null, details?: string | null, actions?: Array<{ __typename?: 'OptimaAction', id: string, action?: string | null, summary?: string | null } | null> | null } | null } | null> | null, diagnoses?: Array<{ __typename?: 'OptimaDiagnosis', id: string, display?: string | null, code?: string | null, type?: string | null, codeSystem?: string | null } | null> | null, items?: Array<{ __typename?: 'OptimaItem', id: string, code?: string | null, display?: string | null, percentageOfAcceptance?: number | null, edits?: Array<{ __typename?: 'OptimaItemEdit', id: string, likeDislike?: Types.LikeDislike | null, edit?: { __typename?: 'OptimaEdit', id: string, priority?: Types.OptimaPriority | null, category?: string | null, subCategory?: string | null, code?: string | null, message?: string | null, details?: string | null, actions?: Array<{ __typename?: 'OptimaAction', id: string, action?: string | null, summary?: string | null } | null> | null } | null } | null> | null, riskFactors?: Array<{ __typename?: 'RiskFactor', display?: string | null, percentage?: number | null } | null> | null } | null> | null } }> } | null };


export const RcmOptimaValidationRequestsListDocument = gql`
    query rcmOptimaValidationRequestsList($first: Int, $last: Int, $after: String, $before: String, $filter: OptimaValidationRequestFilterInput, $sortBy: OptimaValidationRequestsSortingInput) {
  rcmOptimaValidationRequests(
    first: $first
    last: $last
    after: $after
    before: $before
    filter: $filter
    sortBy: $sortBy
  ) {
    edges {
      node {
        id
        visitId
        patientName
        patientGender
        patientDoB
        patientId
        createdDate
        billNumber
        edits {
          id
          edit {
            id
            priority
            category
            subCategory
            code
            message
            details
            actions {
              id
              action
              summary
            }
          }
        }
        diagnoses {
          id
          display
          code
          type
          codeSystem
        }
        items {
          id
          code
          display
          percentageOfAcceptance
          edits {
            id
            likeDislike
            edit {
              id
              priority
              category
              subCategory
              code
              message
              details
              actions {
                id
                action
                summary
              }
            }
          }
          riskFactors {
            display
            percentage
          }
        }
        response
        request
        diagnoses {
          id
          display
          code
          type
          codeSystem
        }
      }
    }
  }
}
    `;

/**
 * __useRcmOptimaValidationRequestsListQuery__
 *
 * To run a query within a React component, call `useRcmOptimaValidationRequestsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useRcmOptimaValidationRequestsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRcmOptimaValidationRequestsListQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useRcmOptimaValidationRequestsListQuery(baseOptions?: Apollo.QueryHookOptions<RcmOptimaValidationRequestsListQuery, RcmOptimaValidationRequestsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RcmOptimaValidationRequestsListQuery, RcmOptimaValidationRequestsListQueryVariables>(RcmOptimaValidationRequestsListDocument, options);
      }
export function useRcmOptimaValidationRequestsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RcmOptimaValidationRequestsListQuery, RcmOptimaValidationRequestsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RcmOptimaValidationRequestsListQuery, RcmOptimaValidationRequestsListQueryVariables>(RcmOptimaValidationRequestsListDocument, options);
        }
export function useRcmOptimaValidationRequestsListSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<RcmOptimaValidationRequestsListQuery, RcmOptimaValidationRequestsListQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<RcmOptimaValidationRequestsListQuery, RcmOptimaValidationRequestsListQueryVariables>(RcmOptimaValidationRequestsListDocument, options);
        }
export type RcmOptimaValidationRequestsListQueryHookResult = ReturnType<typeof useRcmOptimaValidationRequestsListQuery>;
export type RcmOptimaValidationRequestsListLazyQueryHookResult = ReturnType<typeof useRcmOptimaValidationRequestsListLazyQuery>;
export type RcmOptimaValidationRequestsListSuspenseQueryHookResult = ReturnType<typeof useRcmOptimaValidationRequestsListSuspenseQuery>;
export type RcmOptimaValidationRequestsListQueryResult = Apollo.QueryResult<RcmOptimaValidationRequestsListQuery, RcmOptimaValidationRequestsListQueryVariables>;