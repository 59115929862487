import { User } from "@/schema/types";
import { useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps } from "@toolkit/ui";
import { useMemo } from "react";

export const useOptimaWidgetsListColumns = (): CustomTableColumnProps<User>[] => {
  const { t } = useTranslation("provider");

  return useMemo(() => {
    return [
      {
        key: "name",
        header: t("Name"),
        accessor: "fullName",
      },
      {
        key: "email",
        header: t("Email"),
        accessor: "email",
      },
      {
        key: "mobile",
        header: t("Mobile"),
        accessor: "mobile",
        type: "mobile",
      },

      {
        key: "dateJoined",
        header: t("Date Joined"),
        accessor: "dateJoined",
        type: "date",
      },
    ];
  }, [t]);
};
