import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProviderBranchesInfoGetQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ProviderBranchesInfoGetQuery = { __typename?: 'Query', me?: { __typename?: 'User', vendor?: { __typename?: 'Vendor', hasMultipleBranches: boolean } | null } | null };


export const ProviderBranchesInfoGetDocument = gql`
    query ProviderBranchesInfoGet {
  me {
    vendor {
      hasMultipleBranches
    }
  }
}
    `;

/**
 * __useProviderBranchesInfoGetQuery__
 *
 * To run a query within a React component, call `useProviderBranchesInfoGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useProviderBranchesInfoGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProviderBranchesInfoGetQuery({
 *   variables: {
 *   },
 * });
 */
export function useProviderBranchesInfoGetQuery(baseOptions?: Apollo.QueryHookOptions<ProviderBranchesInfoGetQuery, ProviderBranchesInfoGetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProviderBranchesInfoGetQuery, ProviderBranchesInfoGetQueryVariables>(ProviderBranchesInfoGetDocument, options);
      }
export function useProviderBranchesInfoGetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProviderBranchesInfoGetQuery, ProviderBranchesInfoGetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProviderBranchesInfoGetQuery, ProviderBranchesInfoGetQueryVariables>(ProviderBranchesInfoGetDocument, options);
        }
export function useProviderBranchesInfoGetSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ProviderBranchesInfoGetQuery, ProviderBranchesInfoGetQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ProviderBranchesInfoGetQuery, ProviderBranchesInfoGetQueryVariables>(ProviderBranchesInfoGetDocument, options);
        }
export type ProviderBranchesInfoGetQueryHookResult = ReturnType<typeof useProviderBranchesInfoGetQuery>;
export type ProviderBranchesInfoGetLazyQueryHookResult = ReturnType<typeof useProviderBranchesInfoGetLazyQuery>;
export type ProviderBranchesInfoGetSuspenseQueryHookResult = ReturnType<typeof useProviderBranchesInfoGetSuspenseQuery>;
export type ProviderBranchesInfoGetQueryResult = Apollo.QueryResult<ProviderBranchesInfoGetQuery, ProviderBranchesInfoGetQueryVariables>;