import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SetConferenceConfigurationMutationVariables = Types.Exact<{
  input: Types.ConferenceConfigurationInput;
}>;


export type SetConferenceConfigurationMutation = { __typename?: 'Mutation', setConferenceConfiguration?: { __typename?: 'ConferenceConfiguration', id?: string | null } | null };


export const SetConferenceConfigurationDocument = gql`
    mutation SetConferenceConfiguration($input: ConferenceConfigurationInput!) {
  setConferenceConfiguration(input: $input) {
    id
  }
}
    `;
export type SetConferenceConfigurationMutationFn = Apollo.MutationFunction<SetConferenceConfigurationMutation, SetConferenceConfigurationMutationVariables>;

/**
 * __useSetConferenceConfigurationMutation__
 *
 * To run a mutation, you first call `useSetConferenceConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetConferenceConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setConferenceConfigurationMutation, { data, loading, error }] = useSetConferenceConfigurationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetConferenceConfigurationMutation(baseOptions?: Apollo.MutationHookOptions<SetConferenceConfigurationMutation, SetConferenceConfigurationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetConferenceConfigurationMutation, SetConferenceConfigurationMutationVariables>(SetConferenceConfigurationDocument, options);
      }
export type SetConferenceConfigurationMutationHookResult = ReturnType<typeof useSetConferenceConfigurationMutation>;
export type SetConferenceConfigurationMutationResult = Apollo.MutationResult<SetConferenceConfigurationMutation>;
export type SetConferenceConfigurationMutationOptions = Apollo.BaseMutationOptions<SetConferenceConfigurationMutation, SetConferenceConfigurationMutationVariables>;