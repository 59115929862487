import {
  H_OrderDirection,
  HealthParameter,
  PatientHealthParameter,
  PatientHealthParameterFilterInput,
  PatientHealthParameterSortField,
} from "@/schema/types";
import { useHealthParametersChanged } from "@health/sse";
import { getApolloContextFormToken } from "@/shared/utils";
import { useHealthParameterListQuery, usePatientHealthParameterListQuery } from "../gql";

type PatientHealthParametersProps = {
  first: number;
  token?: string;
  filter: PatientHealthParameterFilterInput;
};

export const usePatientHealthParameters = (props: PatientHealthParametersProps) => {
  const { first, token, filter } = props;

  const { data: healthParameterListData, loading: isHealthParameterListLoading } = useHealthParameterListQuery({
    variables: {
      first: 1,
      filter: {
        code: filter?.codes,
      },
    },
    skip: !filter?.codes,
    fetchPolicy: "no-cache",
    context: getApolloContextFormToken(token!),
  });

  const healthParameter = healthParameterListData?.getHealthParameters?.edges?.[0]?.node as HealthParameter;

  const {
    data: patientHealthParameterListData,
    loading: isPatientHealthParameterListLoading,
    refetch,
  } = usePatientHealthParameterListQuery({
    variables: {
      first,
      filter,
      sortBy: {
        field: PatientHealthParameterSortField.ReadingDate,
        direction: H_OrderDirection.Desc,
      },
    },
    fetchPolicy: "no-cache",
    skip: !filter,
    context: getApolloContextFormToken(token),
  });

  const patientHealthParameters = (patientHealthParameterListData?.patientHealthParameters?.edges?.map(item => item?.node) ||
    []) as PatientHealthParameter[];

  useHealthParametersChanged(({ data: healthParametersData }) => {
    if (!healthParametersData?.healthParameters?.length) return;

    const healthParameterItems = healthParametersData.healthParameters.filter(item => filter?.codes?.includes(item.code));

    if (!healthParameterItems || !healthParameterItems?.length) return;

    refetch({
      first,
      filter: {
        ...filter,
        codes: healthParameterItems?.map(item => item?.code),
      },
      sortBy: {
        field: PatientHealthParameterSortField.ReadingDate,
        direction: H_OrderDirection.Desc,
      },
    });
  });

  return {
    healthParameter,
    patientHealthParameters,
    loading: isHealthParameterListLoading || isPatientHealthParameterListLoading,
  };
};
