import { HealthProgramMember, Maybe } from "@/schema/types";
import moment from "moment";
import { i18n, pickLocalizedValue } from "@toolkit/i18n";
import { InfoItems } from "@toolkit/ui";

export const getInsuranceInformationItems = (activeHealthProgramMember?: Maybe<HealthProgramMember>): InfoItems => {
  if (i18n.language === "ar") {
    moment.locale("ar");
  }

  const formattedMembershipEnd = activeHealthProgramMember?.membershipEnd
    ? moment(activeHealthProgramMember.membershipEnd).format("D MMM YYYY")
    : "-";

  return activeHealthProgramMember
    ? [
        {
          label: i18n.t("Company Name", { ns: "provider" }),
          value: pickLocalizedValue(activeHealthProgramMember?.payer?.name, activeHealthProgramMember?.payer?.nameAr) || "-",
        },
        {
          label: i18n.t("Card Id", { ns: "provider" }),
          value: activeHealthProgramMember?.insuranceId || "-",
        },
        {
          label: i18n.t("Expiry Date Insurance", { ns: "provider" }),
          value: formattedMembershipEnd,
        },
      ]
    : undefined;
};
