import { IAutocompleteFilter, createAutocompleteOption, getAutocompleteFilter } from "@toolkit/ui";
import { useOptimaEncounterTypesAutocompleteQuery } from "./gql";
import { IOptimaEncounterTypeAutocomplete } from "./schema";

export const createOptimaEncounterTypesAutocompleteOption = (encounterType: IOptimaEncounterTypeAutocomplete) => {
  return createAutocompleteOption(
    {
      id: encounterType?.id!,
      name: encounterType?.name!,
    },
    "id",
    item => item?.name
  );
};

export const getOptimaEncounterTypesAutocompleteFilter = ({ name, multiple }: IAutocompleteFilter) => {
  return getAutocompleteFilter<
    {
      optimaEncounterTypes: {
        edges: {
          node: {
            id: string;
            name: string;
          };
        }[];
      };
    },
    any
  >({
    name,
    multiple,
    accessor: "optimaEncounterTypes",
    query: (...params: any) => {
      const { data: _data, ...reset } = useOptimaEncounterTypesAutocompleteQuery(...params);
      const data = {
        ..._data,
        optimaEncounterTypes: {
          edges: _data?.optimaEncounterTypes?.map(item => ({ node: item })),
        },
      };
      return { data, ...reset } as any;
    },
    labelBy: item => item?.name! as any,
    backendAccessor: "id",
  });
};
