import { H_OrderDirection, PatientHealthParameterSortField } from "@/schema/types";
import { NetworkStatus } from "@apollo/client";
import { sourceOptionsMap } from "@health/enum-options";
import { useHealthParametersChanged } from "@health/sse";
import moment from "moment";
import { useEffect, useState } from "react";
import { getApolloContextFormToken } from "shared/utils";
import { usePatientHealthParametersQuery } from "../../gql";

export const useHealthParameterItem = (token, patientId, code, type, visitId?, unit?) => {
  const [loading, setLoading] = useState(false);

  const [selectedECG, setSelectedECG] = useState<
    | {
        type: string;
        value: number;
        source: string;
        date: string;
      }[]
    | undefined
  >(undefined);

  const {
    data: patientHealthParametersData,
    loading: loadingPatientHealthParameters,
    networkStatus,
    refetch,
  } = usePatientHealthParametersQuery({
    variables: {
      first: 10,
      filter: {
        visitId,
        patientId: [patientId!],
        codes: [code],
      },
      sortBy: {
        field: PatientHealthParameterSortField.ReadingDate,
        direction: H_OrderDirection.Desc,
      },
    },
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    context: getApolloContextFormToken(token!),
  });

  useHealthParametersChanged(({ data }) => {
    if (data?.healthParameters?.length) {
      const currentCode = data.healthParameters.find(item => item.code === code);
      currentCode &&
        refetch({
          first: 10,
          filter: {
            visitId,
            patientId: [patientId!],
            codes: [currentCode?.code!],
          },
          sortBy: {
            field: PatientHealthParameterSortField.ReadingDate,
            direction: H_OrderDirection.Desc,
          },
        });
    }
  });

  const formatDate = "DD MMM YYYY hh:mm";
  const patientHealthParameters = patientHealthParametersData?.patientHealthParameters?.edges?.map(edge => edge?.node);

  const options = patientHealthParameters
    ?.filter(item => item?.valueNumber!)
    .map(item => ({
      value: item?.valueNumber!,
      unit: unit,
      currentData: true,
      source: (sourceOptionsMap[item?.source!]?.label || "") + (item?.deviceName ? " - " + item?.deviceName : ""),
      date: moment(item?.createdDate).format(formatDate),
    }))
    .reverse();

  const tableOptions = patientHealthParameters
    ?.map(item => ({
      id: item?.id,
      type: type,
      valueBoolean: item?.valueBoolean!,
      valueString: item?.valueString!,
      source: (sourceOptionsMap[item?.source!]?.label || "") + (item?.deviceName ? " - " + item?.deviceName : ""),
      date: moment(item?.createdDate).format(formatDate),
    }))
    .reverse();

  const lastReading = patientHealthParameters?.filter(item => item?.valueNumericList?.length!)[0];

  const valueNumericList = lastReading?.valueNumericList?.map(value => ({
    type: type,
    value: value,
    source: (sourceOptionsMap[lastReading?.source!]?.label || "") + (lastReading?.deviceName ? " - " + lastReading?.deviceName : ""),
    date: moment(lastReading?.createdDate).format(formatDate),
  }));

  const handleSelectECG = (list: number[], source: string, date) => {
    setLoading(true);
    const numericList = list?.map(value => ({
      type: type,
      value: value,
      source: source,
      date: moment(date).format(formatDate),
    }));
    setSelectedECG(numericList);

    setInterval(() => {
      setLoading(false);
    }, 1000);
  };

  useEffect(() => {
    setSelectedECG(valueNumericList);
  }, [JSON.stringify(valueNumericList), JSON.stringify(lastReading), loadingPatientHealthParameters]);

  return {
    loading: loading || loadingPatientHealthParameters,
    options,
    tableOptions,
    valueNumericList,
    selectedECG,
    loadingPatientHealthParameters: NetworkStatus.loading === networkStatus,
    handleSelectECG,
  };
};
