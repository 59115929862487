/* eslint-disable max-statements */
import { OptimaAgentPayer } from "@/schema/types";
import { BranchesAutocomplete, OptimaCliniciansAutocomplete } from "@health/autocompletes";
import { optimaAgentPayerOptions } from "@health/enum-options";
import { useCustomForm } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Box, FormAutocomplete, FormPhoneInput, FormTextField } from "@toolkit/ui";
import { FC, useEffect, useRef } from "react";
import { FormProvider } from "react-hook-form";
import {
  CreateEligibilityRequestFormSchema,
  ICreateEligibilityRequestFormValues,
  createEligibilityRequestFormDefaultValues,
} from "./createEligibilityRequestFormSchema";
import { useCreateEligibilityRequestFormStyles } from "./useCreateEligibilityRequestFormStyles";
import { usePatientEligibilityRequestHistoriesListLazyQuery } from "../../gql";

export const OptimaCreateEligibilityRequestFormApi: { submit: () => void; isSubmitting: boolean } = {
  submit: () => {},
  isSubmitting: false,
};
export const CreateEligibilityRequestForm: FC<{
  isSubmitting?: boolean;
  onSubmit: (data: ICreateEligibilityRequestFormValues) => void;
}> = props => {
  const { isSubmitting, onSubmit } = props;

  const { t } = useTranslation("optima");
  const nationalIdChangeTimeRef = useRef<any>(null);
  const { classes } = useCreateEligibilityRequestFormStyles();
  const formMethods = useCustomForm<ICreateEligibilityRequestFormValues>({
    schema: CreateEligibilityRequestFormSchema,
    defaultValues: createEligibilityRequestFormDefaultValues,
    mode: "onChange",
  });

  const selectedClinician = formMethods.watch("clinician");
  const selectedPayer = formMethods.watch("payer");
  const showClinician = selectedPayer?.value === OptimaAgentPayer.Daman;
  const shouldShowMobileNumber = [OptimaAgentPayer.Inaya, OptimaAgentPayer.Mednet, OptimaAgentPayer.Nas, OptimaAgentPayer.Neuron].includes(
    selectedPayer?.value
  );
  const nationalId = formMethods.watch("nationalId");
  const [fetchHistoryItem] = usePatientEligibilityRequestHistoriesListLazyQuery();

  useEffect(() => {
    if (!selectedClinician) return;
    formMethods.setValue("clinicianLicense", selectedClinician?.value?.clinicianId!);
  }, [selectedClinician]);

  useEffect(() => {
    if (!nationalId) return;
    if (nationalIdChangeTimeRef.current) clearTimeout(nationalIdChangeTimeRef.current);
    nationalIdChangeTimeRef.current = setTimeout(() => {
      fetchHistoryItem({
        fetchPolicy: "no-cache",
        variables: {
          filter: {
            patientIdentifier: nationalId,
          },
        },
      }).then(({ data }) => {
        const patient = data?.patientEligibilityRequestHistories?.edges?.[0]?.node?.patientEligibility;
        if (!patient?.id) {
          formMethods.setValue("firstName", "");
          formMethods.setValue("lastName", "");
          formMethods.setValue("mobile", "");
          formMethods.setValue("mobileCountryCode", "");
        } else {
          formMethods.setValue("firstName", patient?.firstName || "");
          formMethods.setValue("lastName", patient?.lastName || "");
          formMethods.setValue("mobile", patient?.mobile ? patient?.mobileCountryCode + patient?.mobile : "");
          formMethods.setValue("mobileCountryCode", patient?.mobileCountryCode || "");
        }
      });
    }, 500);
  }, [nationalId]);

  useEffect(() => {
    OptimaCreateEligibilityRequestFormApi.submit = formMethods.handleSubmit(onSubmit);
  }, []);
  useEffect(() => {
    OptimaCreateEligibilityRequestFormApi.isSubmitting = !!isSubmitting;
  }, [isSubmitting]);

  const onChangeCountryCode = v => {
    formMethods.setValue("mobileCountryCode", v);
  };
  return (
    <FormProvider {...formMethods}>
      <Box className={classes.root}>
        <BranchesAutocomplete name={"branch"} label={t("Branch")} />
        <FormAutocomplete name={"payer"} label={t("Insurance")} options={optimaAgentPayerOptions} />
        {showClinician && <OptimaCliniciansAutocomplete name={"clinician"} label={t("Clinician")} />}
        <FormTextField name={"nationalId"} placeholder={`${t("National ID")}`} label={t("National ID")} />
        <FormTextField name={"firstName"} placeholder={`${t("First Name")}`} label={t("First Name")} />
        <FormTextField name={"lastName"} placeholder={`${t("Last Name")}`} label={t("Last Name")} />
        {shouldShowMobileNumber && (
          <FormPhoneInput name={"mobile"} placeholder={`${t("mobile")}`} label={t("Mobile")} onChangeCountryCode={onChangeCountryCode} />
        )}
      </Box>
    </FormProvider>
  );
};
