import { isValidPhoneNumber } from "libphonenumber-js";
import { zodEnumSchema } from "@health/enum-options";
import { z } from "zod";
import { OptimaAgentPayer, RequestPriority } from "@/schema/types";
import { i18n } from "@toolkit/i18n";
import { branchSchema, optimaClinicianSchema } from "@health/autocompletes";
export const CreateEligibilityRequestFormSchema = z
  .object({
    branch: branchSchema,
    firstName: z.string().min(3),
    lastName: z.string().min(3),
    nationalId: z.string().min(3),
    payer: zodEnumSchema.optimaAgentPayer,
    clinician: optimaClinicianSchema.optional(),
    clinicianLicense: z.string().min(3).optional(),
    mobile: z.string().min(3).max(15),
    mobileCountryCode: z.string().min(1).max(4),
    priority: z.nativeEnum(RequestPriority).default(RequestPriority.High),
  })
  .superRefine((data, ctx) => {
    const showClinician = [OptimaAgentPayer.Nextcare, OptimaAgentPayer.Daman].includes(data.payer?.value);
    const shouldShowMobileNumber = [
      OptimaAgentPayer.Inaya,
      OptimaAgentPayer.Mednet,
      OptimaAgentPayer.Nas,
      OptimaAgentPayer.Neuron,
    ].includes(data?.payer?.value);
    if (showClinician && !data.clinicianLicense) {
      ctx.addIssue({
        path: ["clinicianLicense"],
        code: z.ZodIssueCode.custom,
        message: i18n.t("Clinician License is required", "optima"),
      });
    }
    if (shouldShowMobileNumber && !data.mobile) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: i18n.t("Invalid mobile number", "optima"),
        path: ["mobile", "mobileCountryCode"],
      });
    }
    (data.mobileCountryCode && !data.mobile) ||
      (isValidPhoneNumber(String(data.mobileCountryCode) + String(data.mobile)) &&
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: i18n.t("Invalid mobile number"),
          path: ["mobile", "mobileCountryCode"],
        }));
  });

export type ICreateEligibilityRequestFormValues = z.infer<typeof CreateEligibilityRequestFormSchema>;

export const createEligibilityRequestFormDefaultValues: Partial<ICreateEligibilityRequestFormValues> = {
  firstName: undefined,
  lastName: undefined,
  mobile: undefined,
  mobileCountryCode: "+971",
  payer: undefined,
  priority: RequestPriority.High,
  nationalId: undefined,
  clinicianLicense: undefined,
};
