import { Box, CustomTable, Typography, useTheme } from "@toolkit/ui";
import { FC } from "react";
import { useOrderNotesListContainerColumns } from "./useOrderNotesListContainerColumns";
import { Notes } from "../../types";
import { useTranslation } from "@toolkit/i18n";

type OrderNotesListProps = {
  orderNotes?: Notes;
};
export const OrderNotesList: FC<OrderNotesListProps> = props => {
  const { orderNotes = [] } = props;
  const filteredOrderNotes = orderNotes.filter((note): note is NonNullable<Notes[number]> => note !== null);

  const columns = useOrderNotesListContainerColumns();
  const { t } = useTranslation("provider");
  const theme = useTheme();

  return (
    <Box display={"flex"} flexDirection={"column"}>
      <Typography mb={0.5} fontSize={theme.mixins.fonts.fontSize.lg} fontWeight={theme.mixins.fonts.fontWeight.medium}>
        {t("Order Notes")}:
      </Typography>
      <CustomTable
        data={filteredOrderNotes}
        columns={columns}
        pageSize={5}
        pageIndex={0}
        pagesCount={5}
        hasFooter={true}
        hasNextPage={false}
        TableContainerProps={{
          sx: {
            height: 237.5,
          },
        }}
        withoutDataMessage={t("No Notes")}
        emptyIconHeight={100}
      />
    </Box>
  );
};
