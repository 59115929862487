import { z } from "zod";
import { random } from "lodash";
import {
  transactionValidateRequestItemFormDefaultValues,
  transactionValidateRequestItemFormSchema,
} from "../TransactionValidateRequestItem/TransactionValidateRequestItemFormSchema";
import {
  transactionValidateRequestCareTeamFormDefaultValues,
  transactionValidateRequestCareTeamFormSchema,
} from "../TransactionValidateRequestCareTeam/TransactionValidateRequestCareTeamFormSchema";
import {
  transactionValidateRequestObservationFormDefaultValues,
  transactionValidateRequestObservationFormSchema,
} from "../TransactionValidateRequestObservation/TransactionValidateRequestObservationFormSchema";
import {
  transactionValidateRequestEncounterFormDefaultValues,
  transactionValidateRequestEncounterFormSchema,
} from "../TransactionValidateRequestEncounter/TransactionValidateRequestEncounterFormSchema";
import {
  transactionValidateRequestAuditFormDefaultValues,
  transactionValidateRequestAuditFormSchema,
} from "../TransactionValidateRequestAudit/TransactionValidateRequestAuditFormSchema";
import {
  transactionValidateRequestDiagnosisFormDefaultValues,
  transactionValidateRequestDiagnosisFormSchema,
} from "../TransactionValidateRequestDiagnosisUpsert/TransactionValidateRequestDiagnosisFormSchema";
import { uuid } from "@toolkit/core";
import { userGenderOptionsMap, zodEnumSchema } from "@health/enum-options";
import { UserGender } from "@/schema/types";

export const transactionValidateRequestFormSchema = z.object({
  requestId: z.string().min(1),
  visitId: z.string().min(1),
  patientName: z.string().min(1),
  patientId: z.string().min(1),
  patientGender: zodEnumSchema["userGender"].required(),
  patientDoB: z.string().min(1),
  patientWeight: z.number().min(0),
  patientHeight: z.number().min(0),
  providerId: z.string().min(1),
  status: z.string().min(1),
  type: z.string().min(1),
  subType: z.string().min(1),
  use: z.string().min(1),
  priority: z.string().min(1),
  insurancePayer: z.string().min(1),
  insuranceReceiver: z.string().min(1),
  insuranceMemberId: z.string().min(1),
  insurancePolicy: z.string().min(1),
  insurancePolicyLocation: z.string().min(1),
  insurancePlan: z.string().min(1),
  facility: z.string().min(1),
  prescriptionId: z.string().min(1),
  payeeType: z.string().min(1),
  referral: z.string().min(1),
  gross: z.number().min(0),
  patientShare: z.number().min(0),
  net: z.number().min(0),
  tax: z.number().min(0),
  diagnoses: z.array(transactionValidateRequestDiagnosisFormSchema).min(1),
  items: z.array(transactionValidateRequestItemFormSchema).min(1),
  careTeam: z.array(transactionValidateRequestCareTeamFormSchema).min(1),
  observations: z.array(transactionValidateRequestObservationFormSchema),
  encounter: z.array(transactionValidateRequestEncounterFormSchema),
  audit: z.array(transactionValidateRequestAuditFormSchema),
});

export type ITransactionValidateRequestFormValues = z.infer<typeof transactionValidateRequestFormSchema>;

export const transactionValidateRequestFormDefaultValues: Partial<ITransactionValidateRequestFormValues> = {
  requestId: uuid(),
  visitId: random(0, 999999).toString(),
  patientName: "Salem Ahamad H57.9",
  patientId: "PAT987654",
  patientGender: userGenderOptionsMap[UserGender.Male],
  patientDoB: "1985-06-15",
  patientWeight: 75.5,
  patientHeight: 175.0,
  providerId: "PROV12345",
  status: "Active",
  type: "Professional",
  subType: "Outpatient",
  use: "Claim",
  priority: "High",
  insurancePayer: "InsureCo",
  insuranceReceiver: "ProviderCo",
  insuranceMemberId: "IM1234567890",
  insurancePolicy: "POL987654321",
  insurancePolicyLocation: "ECLAIM",
  insurancePlan: "PlanA",
  facility: "FacilityA",
  prescriptionId: "RX123456",
  payeeType: "Provider",
  referral: "REF123456",
  gross: 500.0,
  patientShare: 100.0,
  net: 400.0,
  tax: 50.0,
  diagnoses: [transactionValidateRequestDiagnosisFormDefaultValues],
  items: [transactionValidateRequestItemFormDefaultValues],
  careTeam: [transactionValidateRequestCareTeamFormDefaultValues],
  observations: [transactionValidateRequestObservationFormDefaultValues],
  encounter: [transactionValidateRequestEncounterFormDefaultValues],
  audit: [transactionValidateRequestAuditFormDefaultValues],
};
