import { PatientEligibilityRequestHistorySortingInputSortField } from "@/schema/types";
import { GridCellText } from "@health/domains";
import { useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps } from "@toolkit/ui";
import { useMemo } from "react";
// import OptimaVisitResultDetailsModal from "../../components/OptimaVisitResultDetails/OptimaVisitResultDetails";
import { getBranchesHealthLicenseAutocompleteFilter } from "@health/autocompletes";
import { getAutocompleteEnumFilter } from "@health/enum-options";
import { EligibilityScanResultModal } from "../../components/EligibilityScanResultModal/EligibilityScanResultModal";
import { IOptimaValidationRequestsListNode } from "../../types";

export const useOptimaPatientsEligibilityRequestsHistoryListContainerColumns =
  (): CustomTableColumnProps<IOptimaValidationRequestsListNode>[] => {
    const { t } = useTranslation("provider");

    return useMemo(() => {
      return [
        {
          key: "id",
          header: t("Request Id"),
          type: "truncated-text",
          accessor: "id",
          // filter: {
          //   type: "string",
          //   name: "id",
          // },
        },
        {
          key: "patientName",
          header: t("Patient Name"),
          accessor: ({ patientEligibility }) => <GridCellText text={patientEligibility?.memberName} />,
          filter: {
            type: "string",
            name: "visitId",
          },
        },
        {
          key: "patientId",
          header: t("Patient Id"),
          accessor: ({ patientEligibility }) => <GridCellText text={patientEligibility?.emiratesId} />,
          filter: {
            type: "string",
            name: "patientIdentifier",
          },
        },
        {
          key: "Insurance",
          header: t("Insurance"),
          accessor: ({ patientEligibility }) => <GridCellText text={patientEligibility?.insuranceName} />,
          filter: getAutocompleteEnumFilter("OptimaAgentPayer", "insuranceName"),
        },
        {
          key: "Status",
          header: t("Status"),
          accessor: ({ isFounded, isCompleted }) => (
            <GridCellText text={!isCompleted ? t("Scanning") : `${isFounded ? t("Found") : t("Not Found")}`} />
          ),
        },
        {
          key: "branchName",
          header: t("Branch"),
          type: "truncated-text",
          accessor: "branchName",
        },
        {
          key: "createdDate",
          header: t("Created Date"),
          sort: {
            isSortable: true,
            columnEnum: PatientEligibilityRequestHistorySortingInputSortField.CreatedAt,
          },
          type: "datetime",
          accessor: "createdDate",
        },
        {
          key: "scannedDate",
          header: t("Scanned Date"),
          sort: {
            isSortable: true,
            columnEnum: PatientEligibilityRequestHistorySortingInputSortField.UpdatedAt,
          },
          type: "datetime",
          accessor: "updatedDate",
        },
        {
          key: "createdDateFrom",
          header: t("Created Date From"),
          showOnlyForFilterField: true,
          filter: {
            type: "date",
            name: "fromDate",
          },
        },
        {
          key: "createdDateTo",
          header: t("Created Date To"),
          showOnlyForFilterField: true,
          filter: {
            type: "date",
            name: "toDate",
          },
        },
        {
          key: "isFounded",
          header: t("isFounded"),
          showOnlyForFilterField: true,
          filter: getAutocompleteEnumFilter("YesNo", "isFounded"),
        },
        {
          key: "insuranceName",
          header: t("insuranceName"),
          showOnlyForFilterField: true,
          // filter: getAutocompleteEnumFilter("OptimaAgentPayer", "insuranceName"),
        },
        {
          key: "branch",
          header: t("Branch"),
          showOnlyForFilterField: true,
          filter: getBranchesHealthLicenseAutocompleteFilter({ name: "branchLicense" }),
        },
        {
          key: "isComplete",
          header: t("isComplete"),
          showOnlyForFilterField: true,
          filter: getAutocompleteEnumFilter("YesNo", "isCompleted"),
        },
        {
          key: "showResultDetails",
          header: t("Details"),
          accessor: ({ id, patientEligibility }) => (
            <EligibilityScanResultModal id={id} patientNationalId={patientEligibility?.emiratesId} />
          ),
        },
      ];
    }, [t]);
  };
