import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PatientHealthParameterListQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  filter?: Types.InputMaybe<Types.PatientHealthParameterFilterInput>;
  sortBy?: Types.InputMaybe<Types.PatientHealthParameterSortingInput>;
}>;


export type PatientHealthParameterListQuery = { __typename?: 'Query', patientHealthParameters?: { __typename?: 'PatientHealthParameterCountableConnection', pageInfo: { __typename?: 'H_PageInfo', startCursor?: string | null, endCursor?: string | null, hasPreviousPage: boolean, hasNextPage: boolean }, edges: Array<{ __typename?: 'PatientHealthParameterCountableEdge', node: { __typename?: 'PatientHealthParameter', id: string, hpCode?: string | null, source?: Types.Source | null, valueNumber?: any | null, valueNumericList?: Array<any | null> | null, valueAudioFile?: string | null, valueVideoFile?: string | null, createdDate?: any | null } }> } | null };


export const PatientHealthParameterListDocument = gql`
    query PatientHealthParameterList($first: Int, $last: Int, $before: String, $after: String, $filter: PatientHealthParameterFilterInput, $sortBy: PatientHealthParameterSortingInput) {
  patientHealthParameters(
    first: $first
    last: $last
    before: $before
    after: $after
    filter: $filter
    sortBy: $sortBy
  ) {
    pageInfo {
      startCursor
      endCursor
      hasPreviousPage
      hasNextPage
    }
    edges {
      node {
        id
        hpCode
        source
        valueNumber
        valueNumericList
        valueAudioFile
        valueVideoFile
        createdDate
      }
    }
  }
}
    `;

/**
 * __usePatientHealthParameterListQuery__
 *
 * To run a query within a React component, call `usePatientHealthParameterListQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientHealthParameterListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientHealthParameterListQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function usePatientHealthParameterListQuery(baseOptions?: Apollo.QueryHookOptions<PatientHealthParameterListQuery, PatientHealthParameterListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PatientHealthParameterListQuery, PatientHealthParameterListQueryVariables>(PatientHealthParameterListDocument, options);
      }
export function usePatientHealthParameterListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PatientHealthParameterListQuery, PatientHealthParameterListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PatientHealthParameterListQuery, PatientHealthParameterListQueryVariables>(PatientHealthParameterListDocument, options);
        }
export function usePatientHealthParameterListSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<PatientHealthParameterListQuery, PatientHealthParameterListQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PatientHealthParameterListQuery, PatientHealthParameterListQueryVariables>(PatientHealthParameterListDocument, options);
        }
export type PatientHealthParameterListQueryHookResult = ReturnType<typeof usePatientHealthParameterListQuery>;
export type PatientHealthParameterListLazyQueryHookResult = ReturnType<typeof usePatientHealthParameterListLazyQuery>;
export type PatientHealthParameterListSuspenseQueryHookResult = ReturnType<typeof usePatientHealthParameterListSuspenseQuery>;
export type PatientHealthParameterListQueryResult = Apollo.QueryResult<PatientHealthParameterListQuery, PatientHealthParameterListQueryVariables>;