import { forwardRef, ForwardRefRenderFunction, useImperativeHandle } from "react";
import { formGirdSpacing, useCustomForm, uuid } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FormActions, FormCard, Grid, PageWrapper } from "@toolkit/ui";
import { FormProvider, UseFormReturn } from "react-hook-form";
import {
  transactionValidateRequestFormDefaultValues,
  transactionValidateRequestFormSchema,
  ITransactionValidateRequestFormValues,
} from "./TransactionValidateRequestFormSchema";
import { PatientInformationForm } from "../PatientInformation/PatientInformationForm";
import { InsuranceInformationForm } from "../InsuranceInformation/InsuranceInformationForm";
import { ProviderInformationForm } from "../ProviderInformation/ProviderInformationForm";
import { FinancialInformationForm } from "../FinancialInformation/FinancialInformationForm";
import { TransactionValidateRequestDiagnosisForm } from "../TransactionValidateRequestDiagnosis/TransactionValidateRequestDiagnosisForm";
import { TransactionValidateRequestItemForm } from "../TransactionValidateRequestItems/TransactionValidateRequestItemsForm";
import { TransactionValidateRequestCareTeamsForm } from "../TransactionValidateRequestCareTeams/TransactionValidateRequestCareTeamsForm";
import { TransactionValidateRequestObservationForm } from "../TransactionValidateRequestObservations/TransactionValidateRequestObservationsForm";
import { TransactionValidateRequestEncounterForm } from "../TransactionValidateRequestEncounters/TransactionValidateRequestEncountersForm";
import { TransactionValidateRequestAuditForm } from "../TransactionValidateRequestAudits/TransactionValidateRequestAuditForm";
import { RequestInformationForm } from "../RequestInformation/RequestInformationForm";

export type ITransactionValidateRequestFormEvent = {
  type: "SUBMIT";
  payload: {
    values: ITransactionValidateRequestFormValues;
  };
};

type TransactionValidateRequestFormProps = {
  isSubmitting?: boolean;
  onChange: (event: ITransactionValidateRequestFormEvent) => void;
};

export type TransactionValidateRequestFormRef = {
  getForm: () => UseFormReturn<ITransactionValidateRequestFormValues>;
  submit: () => void;
  randomizeRequestId: () => void;
};

const TransactionValidateRequestFormForwardRef: ForwardRefRenderFunction<
  TransactionValidateRequestFormRef,
  TransactionValidateRequestFormProps
> = (props, ref) => {
  const { isSubmitting, onChange } = props;

  const { t } = useTranslation("provider");

  const form = useCustomForm<ITransactionValidateRequestFormValues>({
    defaultValues: transactionValidateRequestFormDefaultValues,
    schema: transactionValidateRequestFormSchema,
  });

  const { handleSubmit, setValue } = form;

  const onSubmit = (values: ITransactionValidateRequestFormValues) => {
    onChange({
      type: "SUBMIT",
      payload: { values },
    });
  };

  const handleRandomizeRequestId = () => {
    setValue("requestId", uuid());
  };

  useImperativeHandle(ref, () => ({
    getForm: () => form,
    submit: handleSubmit(onSubmit),
    randomizeRequestId: handleRandomizeRequestId,
  }));

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PageWrapper actions={<FormActions hasFormButton formButtonTitle={t("Validate")} newButtonDisabled={isSubmitting} />}>
          <Grid container spacing={formGirdSpacing}>
            <Grid item xs={12}>
              <RequestInformationForm />
            </Grid>
            <Grid item xs={12}>
              <PatientInformationForm />
            </Grid>

            <Grid item xs={12}>
              <InsuranceInformationForm />
            </Grid>

            <Grid item xs={12}>
              <ProviderInformationForm />
            </Grid>

            <Grid item xs={12}>
              <FinancialInformationForm />
            </Grid>
            <Grid item xs={12}>
              <FormCard loading={false} doYouHaveData>
                <TransactionValidateRequestDiagnosisForm />
              </FormCard>
            </Grid>
            <Grid item xs={12}>
              <FormCard loading={false} doYouHaveData>
                <TransactionValidateRequestCareTeamsForm />
              </FormCard>
            </Grid>
            <Grid item xs={12}>
              <FormCard loading={false} doYouHaveData>
                <TransactionValidateRequestObservationForm />
              </FormCard>
            </Grid>
            <Grid item xs={12}>
              <FormCard loading={false} doYouHaveData>
                <TransactionValidateRequestItemForm />
              </FormCard>
            </Grid>
            <Grid item xs={12}>
              <FormCard loading={false} doYouHaveData>
                <TransactionValidateRequestEncounterForm />
              </FormCard>
            </Grid>
            <Grid item xs={12}>
              <FormCard loading={false} doYouHaveData>
                <TransactionValidateRequestAuditForm />
              </FormCard>
            </Grid>
          </Grid>
        </PageWrapper>
      </form>
    </FormProvider>
  );
};

export const TransactionValidateRequestForm = forwardRef(TransactionValidateRequestFormForwardRef);
