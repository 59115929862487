import { useTranslation } from "@toolkit/i18n";
import { CustomDialog, Button, Typography, Box, CopyToClipboardButton, makeStyles } from "@toolkit/ui";
import { FC, useEffect, useState } from "react";
import { useOpenState } from "@toolkit/core";
import { ModalApi } from "../../others/types";

const useStyles = makeStyles()(theme => ({
  root: {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: theme.spacing(2),
    position: "sticky",
    top: 0,
    zIndex: 1,
  },
  jsonContainer: {
    whiteSpace: "pre-wrap",
    wordWrap: "break-word",
    fontFamily: "monospace",
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
  },
}));

export const ValidationResultModalApi: ModalApi<string> = {
  open: () => {},
  close: () => {},
};

type ValidationResultModalProps = {
  hasClose?: boolean;
};

export const ValidationResultModal: FC<ValidationResultModalProps> = ({ hasClose = true }) => {
  const { t } = useTranslation("provider");
  const { open: isOpen, handleClose, handleOpen } = useOpenState();
  const [jsonData, setJsonData] = useState<string>("");
  const { classes } = useStyles();

  const onOpen = (data?: string) => {
    setJsonData(data || "");
    handleOpen();
  };

  useEffect(() => {
    ValidationResultModalApi.open = onOpen;
    ValidationResultModalApi.close = handleClose;
  }, []);

  return (
    <CustomDialog
      type='base'
      maxWidth='md'
      open={isOpen}
      onClose={hasClose ? handleClose : undefined}
      DialogTitleProps={{
        title: t("Validation Result"),
        hasCloseButton: hasClose,
      }}
      DialogActionsProps={{
        children: <Button onClick={handleClose}>{t("Close")}</Button>,
      }}
    >
      <Box className={classes.root}>
        <CopyToClipboardButton textToCopy={jsonData} />
      </Box>
      <Typography component='div' className={classes.jsonContainer}>
        {jsonData}
      </Typography>
    </CustomDialog>
  );
};
