import { TransactionClaimFilterInput } from "@/schema/types";
import { useOnOptimaHaveNewNotification } from "@health/sse";
import { FilterGrid, PageWrapper, TableGrid, TableSettingComponent, useGridContext } from "@toolkit/ui";
import { useOptimaClaimsListQuery } from "@/pages/OptimaClaims/gql";
import { FC } from "react";
import { GridProvider } from "@/shared/components";
import { useSiteSettingsGetQuery } from "@/shared/gql/queries";
import { useOptimaClaimListContainerColumns } from "./useOptimaClaimListContainerColumns";

export type IOptimaClaimsListContainerEvent = {
  type: "SHOW_CLAIM_ACTIVITIES_LIST";
  payload: {
    claimId: string;
  };
};

type OptimaClaimsListContainerProps = {
  filters?: Partial<TransactionClaimFilterInput>;
  isProviderAdmin?: boolean;
};

export const OptimaClaimsListContainer: FC<OptimaClaimsListContainerProps> = props => {
  const { filters, isProviderAdmin } = props;
  // / to do using grid context out of the grid provider
  const { refetch } = useGridContext();

  useOnOptimaHaveNewNotification(() => {
    refetch({ first: 10 });
  });
  const { data } = useSiteSettingsGetQuery({
    fetchPolicy: "cache-first",
  });
  const defaultCurrency = data?.siteSettings?.defaultCurrency;
  const formattedCurrency = defaultCurrency ? " " + defaultCurrency : "";
  return (
    <GridProvider
      gridName={"optimaClaimsList"}
      query={useOptimaClaimsListQuery}
      columns={useOptimaClaimListContainerColumns({ isProviderAdmin, formattedCurrency })}
      hasTableSetting
      filterInput={filters}
      hideFilterInput
    >
      <PageWrapper filters={<FilterGrid />} actions={<TableSettingComponent />}>
        <TableGrid />
      </PageWrapper>
    </GridProvider>
  );
};
