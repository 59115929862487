import RouteItem from "@/shared/components/Root/routeTypes";
import { TFunction } from "@toolkit/i18n";
import { NavigateFunction } from "react-router-dom";

import { ManualSubmittedOrderPaths, manualSubmittedOrderRoute } from "./ManualSubmittedOrderPaths";
import { hasPermission } from "@toolkit/core";
import { PermissionEnum } from "@/schema/types";
import { ManualSubmittedOrderListContainer } from "../containers/ManualSubmittedOrdersList/ManualSubmittedOrdersListContainer";
import { FeatureEnum, IsFeatureEnabledType } from "@health/domains";

export const manualSubmittedOrderRoutes: (navigate: NavigateFunction, t: TFunction, isFeatureEnabled: IsFeatureEnabledType) => RouteItem = (
  navigate,
  t,
  isFeatureEnabled
) => {
  const isManualSubmittedOrderHidden = !hasPermission(PermissionEnum.ManageOrders) || !isFeatureEnabled([FeatureEnum.ManualPrescription]);
  return {
    id: "manual-submission-prescriptions-orders-routes",
    text: isFeatureEnabled([FeatureEnum.Prescription]) ? t("Manual Submitted Orders") : t("Orders"),
    route: manualSubmittedOrderRoute,
    hidden: isManualSubmittedOrderHidden,
    isProhibited: isManualSubmittedOrderHidden,
    subItems: [
      {
        id: "manual-submission-prescriptions-list-route",
        route: ManualSubmittedOrderPaths.list.route,
        fullPath: ManualSubmittedOrderPaths.list.fullPath,
        element: <ManualSubmittedOrderListContainer isFeatureEnabled={isFeatureEnabled} />,
        onClick: (route: string) => navigate(route),
      },
    ],
  };
};
