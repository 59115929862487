import { ExtractNodeType } from "@toolkit/apollo";
import { useOpenState } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Box, MuiDrawer, ShowButton, Typography } from "@toolkit/ui";
import { OptimaClaimsListQuery } from "@/pages/OptimaClaims/gql";
import { FC } from "react";
import { useResubmissionDrawerStyles } from "./ResubmissionDrawerStyles";
import { ClaimGeneralInformation } from "../ClaimGeneralInformation/ClaimGeneralInformation";
import { ClaimTransactionDetails } from "../ClaimTransactionDetails/ClaimTransactionDetails";
import { ClaimEncounterDetails } from "../ClaimEncounterDetails/ClaimEncounterDetails";
import { OptimaClaimActivitiesList } from "@/pages/OptimaClaims/containers";
import { ResubmissionDrawerDetails } from "./ResubmissionDrawerDetails";

type ResubmissionDrawerProps = {
  claim: ExtractNodeType<OptimaClaimsListQuery>;
};

export const ResubmissionDrawer: FC<ResubmissionDrawerProps> = props => {
  const { claim } = props;
  const { classes } = useResubmissionDrawerStyles();
  const { t } = useTranslation("domains");
  const { handleOpen, handleToggle, open } = useOpenState();

  return (
    <>
      {!(claim?.submissionCycle === "ORIGINAL") ? (
        <ShowButton onClick={handleOpen} disabled={claim?.submissionCycle === "ORIGINAL"} />
      ) : (
        <Box
          sx={{
            width: "100%",
            textAlign: "center",
          }}
        >
          -
        </Box>
      )}
      <MuiDrawer
        anchor='right'
        open={open}
        onClose={handleToggle}
        PaperProps={{
          className: classes.drawer,
        }}
      >
        <Box padding={2}>
          <Typography className={classes.sectionTitle}>{t("General Information")}</Typography>
          <ClaimGeneralInformation claim={claim} />
          <Typography className={classes.sectionTitle}>{t("Transaction Details")}</Typography>
          <ClaimTransactionDetails claim={claim} />
          <Typography className={classes.sectionTitle}>{t("Encounter Details")}</Typography>
          <ClaimEncounterDetails claim={claim} />
          <Typography className={classes.sectionTitle}>{t("Resubmission Details")}</Typography>
          <ResubmissionDrawerDetails claim={claim} />
          <OptimaClaimActivitiesList id={claim?.id} />
          <Typography className={classes.sectionTitle} mt={3}>
            {t("Comment:")}
          </Typography>
          <Typography className={classes.comments}>{claim?.resubmissionComment || "-"}</Typography>
        </Box>
      </MuiDrawer>
    </>
  );
};
