import { OptimaClaimsListContainer } from "@health/domains";
import { useTranslation } from "@toolkit/i18n";
import { useBreadCrumbs } from "@toolkit/ui";
import { useEffect, useMemo } from "react";
import { isVendorAdmin } from "@/utils";
import { useOidcUserProfile } from "@/shared/hooks/useOidcUserProfile";
import { OptimaClaimsRoutesBreadcrumb } from "../../constants/optima-claims-breadcrumbs";
import { userWorkspaceDoctorVar } from "@/pages/UserWorkspace/vars";
import { useReactiveVar } from "@apollo/client";

export const ProviderOptimaClaimsListContainer = () => {
  const { setBreadCrumb } = useBreadCrumbs();
  const { t } = useTranslation("provider");
  const userWorkspaceDoctor = useReactiveVar(userWorkspaceDoctorVar);
  const { userInfo } = useOidcUserProfile();

  useEffect(() => {
    setBreadCrumb({
      title: OptimaClaimsRoutesBreadcrumb(t),
    });
  }, []);
  const isProviderAdmin = isVendorAdmin(userInfo);
  const filters = useMemo(() => {
    return userWorkspaceDoctor?.branch?.id ? { branches: [userWorkspaceDoctor?.branch?.id!] } : {};
  }, [userWorkspaceDoctor]);

  return <OptimaClaimsListContainer isProviderAdmin={isProviderAdmin} filters={filters} />;
};
