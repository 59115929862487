import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import { DigitalTwinHealthParameterFragmentFragmentDoc } from '../../fragments/__generated__/DigitalTwinHealthParameterFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DigitalTwinPatientHealthParameterListQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  filter?: Types.InputMaybe<Types.PatientHealthParameterFilterInput>;
  sortBy?: Types.InputMaybe<Types.PatientHealthParameterSortingInput>;
}>;


export type DigitalTwinPatientHealthParameterListQuery = { __typename?: 'Query', patientHealthParameters?: { __typename?: 'PatientHealthParameterCountableConnection', edges: Array<{ __typename?: 'PatientHealthParameterCountableEdge', node: { __typename?: 'PatientHealthParameter', id: string, hpCode?: string | null, source?: Types.Source | null, valueNumber?: any | null, createdDate?: any | null, healthParameter?: { __typename?: 'HealthParameter', id?: string | null, code?: string | null, chartType?: Types.ChartType | null, display?: string | null, arabicDisplay?: string | null, unit?: { __typename?: 'FieldUnit', id?: string | null, display?: string | null, arabicDisplay?: string | null } | null } | null } }> } | null };


export const DigitalTwinPatientHealthParameterListDocument = gql`
    query DigitalTwinPatientHealthParameterList($first: Int, $filter: PatientHealthParameterFilterInput, $sortBy: PatientHealthParameterSortingInput) {
  patientHealthParameters(first: $first, filter: $filter, sortBy: $sortBy) {
    edges {
      node {
        id
        hpCode
        source
        valueNumber
        createdDate
        healthParameter {
          ...DigitalTwinHealthParameterFragment
        }
      }
    }
  }
}
    ${DigitalTwinHealthParameterFragmentFragmentDoc}`;

/**
 * __useDigitalTwinPatientHealthParameterListQuery__
 *
 * To run a query within a React component, call `useDigitalTwinPatientHealthParameterListQuery` and pass it any options that fit your needs.
 * When your component renders, `useDigitalTwinPatientHealthParameterListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDigitalTwinPatientHealthParameterListQuery({
 *   variables: {
 *      first: // value for 'first'
 *      filter: // value for 'filter'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useDigitalTwinPatientHealthParameterListQuery(baseOptions?: Apollo.QueryHookOptions<DigitalTwinPatientHealthParameterListQuery, DigitalTwinPatientHealthParameterListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DigitalTwinPatientHealthParameterListQuery, DigitalTwinPatientHealthParameterListQueryVariables>(DigitalTwinPatientHealthParameterListDocument, options);
      }
export function useDigitalTwinPatientHealthParameterListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DigitalTwinPatientHealthParameterListQuery, DigitalTwinPatientHealthParameterListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DigitalTwinPatientHealthParameterListQuery, DigitalTwinPatientHealthParameterListQueryVariables>(DigitalTwinPatientHealthParameterListDocument, options);
        }
export function useDigitalTwinPatientHealthParameterListSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<DigitalTwinPatientHealthParameterListQuery, DigitalTwinPatientHealthParameterListQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DigitalTwinPatientHealthParameterListQuery, DigitalTwinPatientHealthParameterListQueryVariables>(DigitalTwinPatientHealthParameterListDocument, options);
        }
export type DigitalTwinPatientHealthParameterListQueryHookResult = ReturnType<typeof useDigitalTwinPatientHealthParameterListQuery>;
export type DigitalTwinPatientHealthParameterListLazyQueryHookResult = ReturnType<typeof useDigitalTwinPatientHealthParameterListLazyQuery>;
export type DigitalTwinPatientHealthParameterListSuspenseQueryHookResult = ReturnType<typeof useDigitalTwinPatientHealthParameterListSuspenseQuery>;
export type DigitalTwinPatientHealthParameterListQueryResult = Apollo.QueryResult<DigitalTwinPatientHealthParameterListQuery, DigitalTwinPatientHealthParameterListQueryVariables>;