import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import { PageInfoFragmentFragmentDoc } from '../../../../../../gql/fragments/__generated__/pageInfoFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OptimaPayerCredentialListQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  filter?: Types.InputMaybe<Types.PayerCredentialsFilterInput>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  sortBy?: Types.InputMaybe<Types.PayerCredentialsSortingInput>;
}>;


export type OptimaPayerCredentialListQuery = { __typename?: 'Query', payerCredentialsList?: { __typename?: 'PayerCredentialsCountableConnection', edges: Array<{ __typename?: 'PayerCredentialsCountableEdge', node: { __typename?: 'PayerCredentials', id: string, licence?: string | null, name?: Types.EligabilityPayersEnum | null, username?: string | null, password?: string | null, branchName?: string | null } }>, pageInfo?: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null } | null } | null };


export const OptimaPayerCredentialListDocument = gql`
    query OptimaPayerCredentialList($after: String, $before: String, $filter: PayerCredentialsFilterInput, $first: Int, $last: Int, $sortBy: PayerCredentialsSortingInput) {
  payerCredentialsList(
    after: $after
    before: $before
    filter: $filter
    first: $first
    last: $last
    sortBy: $sortBy
  ) {
    edges {
      node {
        id
        licence
        name
        username
        password
        branchName
      }
    }
    pageInfo {
      ...PageInfoFragment
    }
  }
}
    ${PageInfoFragmentFragmentDoc}`;

/**
 * __useOptimaPayerCredentialListQuery__
 *
 * To run a query within a React component, call `useOptimaPayerCredentialListQuery` and pass it any options that fit your needs.
 * When your component renders, `useOptimaPayerCredentialListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOptimaPayerCredentialListQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useOptimaPayerCredentialListQuery(baseOptions?: Apollo.QueryHookOptions<OptimaPayerCredentialListQuery, OptimaPayerCredentialListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OptimaPayerCredentialListQuery, OptimaPayerCredentialListQueryVariables>(OptimaPayerCredentialListDocument, options);
      }
export function useOptimaPayerCredentialListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OptimaPayerCredentialListQuery, OptimaPayerCredentialListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OptimaPayerCredentialListQuery, OptimaPayerCredentialListQueryVariables>(OptimaPayerCredentialListDocument, options);
        }
export function useOptimaPayerCredentialListSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<OptimaPayerCredentialListQuery, OptimaPayerCredentialListQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OptimaPayerCredentialListQuery, OptimaPayerCredentialListQueryVariables>(OptimaPayerCredentialListDocument, options);
        }
export type OptimaPayerCredentialListQueryHookResult = ReturnType<typeof useOptimaPayerCredentialListQuery>;
export type OptimaPayerCredentialListLazyQueryHookResult = ReturnType<typeof useOptimaPayerCredentialListLazyQuery>;
export type OptimaPayerCredentialListSuspenseQueryHookResult = ReturnType<typeof useOptimaPayerCredentialListSuspenseQuery>;
export type OptimaPayerCredentialListQueryResult = Apollo.QueryResult<OptimaPayerCredentialListQuery, OptimaPayerCredentialListQueryVariables>;