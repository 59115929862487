import { EChart, EChartOption, useTheme } from "@toolkit/ui";
import { FC } from "react";
import { graphic } from "echarts";
import { IHealthParameterChartDataSet } from "@/pages/PatientHealthParameters";
import {
  getDigitalTwinPatientHealthParameterChartColor,
  getDigitalTwinPatientHealthParameterChartLabel,
  getDigitalTwinPatientHealthParameterChartXAxisLabels,
  getDigitalTwinPatientHealthParameterChartYAxisLabels,
} from "../../others";

type DigitalTwinPatientHealthParameterChartProps = {
  dataSets: IHealthParameterChartDataSet[];
  isLoading?: boolean;
};

export const DigitalTwinPatientHealthParameterChart: FC<DigitalTwinPatientHealthParameterChartProps> = props => {
  const { dataSets, isLoading } = props;

  const theme = useTheme();

  const chartOption: EChartOption = {
    grid: {
      left: "2%",
      right: "2%",
      bottom: "2%",
    },
    xAxis: {
      type: "category",
      data: dataSets[0]?.data?.map(item => item?.date),
      boundaryGap: false,
      axisTick: {
        show: false,
      },
      axisLine: {
        show: false,
      },
      axisLabel: {
        color: theme.palette.primary.main,
        formatter: getDigitalTwinPatientHealthParameterChartXAxisLabels,
      },
    },
    yAxis: {
      type: "value",
      min: 1,
      max: 3,
      interval: 1,
      splitLine: {
        lineStyle: { color: theme.palette.gray.main },
      },
      axisLabel: {
        color: theme.palette.primary.main,
        formatter: getDigitalTwinPatientHealthParameterChartYAxisLabels,
      },
    },
    series: dataSets?.map((dataSet, index) => {
      const lastIndex = dataSet.data.length - 1;
      const lastValue = dataSet.data[lastIndex]?.value;

      const color = getDigitalTwinPatientHealthParameterChartColor(lastValue, theme);

      return {
        name: `Digital Twin Smooth Line ${index + 1}`,
        type: "line",
        data: dataSet.data,
        smooth: true,
        showSymbol: false,
        lineStyle: {
          color: "transparent",
        },
        areaStyle: {
          color: new graphic.LinearGradient(0, 0, 0, 1, [
            { offset: 0, color },
            { offset: 1, color: theme.palette.common.white },
          ]),
        },
        markPoint: {
          data: [
            {
              coord: [lastIndex, lastValue],
              name: "Last Value",
            },
          ],
          symbol: "circle",
          symbolSize: 15,
          itemStyle: {
            color,
          },
          label: {
            show: true,
            formatter: getDigitalTwinPatientHealthParameterChartLabel(lastValue),
            position: lastValue === 1 ? "insideBottomRight" : "left",
            fontWeight: theme.mixins.fonts.fontWeight.semiBold,
            color,
            borderWidth: 1,
            borderColor: color,
            offset: lastValue === 1 ? [0, -10] : [0, 10],
            backgroundColor: theme.palette.stale[200],
            padding: [8, 12],
            borderRadius: 12,
          },
        },
      };
    }),
  };

  return <EChart option={chartOption} isLoading={isLoading} />;
};
