import { useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps } from "@toolkit/ui";
import { useMemo } from "react";
import { ITransactionValidateRequestObservationFormValues } from "../TransactionValidateRequestObservation/TransactionValidateRequestObservationFormSchema";

export const useTransactionValidateRequestObservationsColumns =
  (): CustomTableColumnProps<ITransactionValidateRequestObservationFormValues>[] => {
    const { t } = useTranslation("provider");
    return useMemo(() => {
      return [
        {
          key: "sequence",
          header: t("Sequence"),
          accessor: ({ sequence }) => sequence,
        },
        {
          key: "code",
          header: t("Code"),
          accessor: ({ code }) => code,
        },
        {
          key: "codeSystem",
          header: t("Code System"),
          accessor: ({ codeSystem }) => codeSystem,
        },
        {
          key: "type",
          header: t("Type"),
          accessor: ({ type }) => type,
        },
        {
          key: "periodStart",
          header: t("Period Start"),
          type: "datetime",
          accessor: ({ periodStart }) => periodStart,
        },
        {
          key: "periodEnd",
          header: t("Period End"),
          type: "datetime",
          accessor: ({ periodEnd }) => periodEnd,
        },
        {
          key: "value",
          header: t("Value"),
          type: "truncated-text",
          accessor: ({ value }) => value,
        },
      ];
    }, [t]);
  };
