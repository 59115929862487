import { OrderDirection, UserSortField, UserStatus, VendorUserTypes } from "@/schema/types";
import { FilterGrid, MainListActions, PageWrapper, TableGrid, TableSettingComponent, useBreadCrumbs } from "@toolkit/ui";

import { DashboardBreadcrumb } from "pages/dashboard/constants";
import { FC, useEffect } from "react";
import { GridProvider } from "shared/components/GridProvider/GridProvider";
import { AllDoctorsListBreadcrumb, AllDoctorsTitleBreadcrumbs } from "../constants";
import { useGetAllVendorUsersQuery } from "../gql";
import { useAllDoctorsListColumns } from "./columns/useAllDoctorsListColumns";

export const AllDoctorsList: FC = () => {
  const { setBreadCrumb } = useBreadCrumbs();
  const columns = useAllDoctorsListColumns();

  useEffect(() => {
    setBreadCrumb({
      title: AllDoctorsTitleBreadcrumbs(),
      values: [DashboardBreadcrumb(), AllDoctorsListBreadcrumb()],
    });
  }, []);

  return (
    <GridProvider
      hasTableSetting
      gridName='AllDoctorsUsers'
      columns={columns}
      variables={{
        sortBy: { field: UserSortField.DateJoined, direction: OrderDirection.Desc },
        filter: { vendorUserType: [VendorUserTypes.Doctor], status: UserStatus.Active },
      }}
      query={useGetAllVendorUsersQuery}
      skipUrlState={false}
    >
      <PageWrapper
        filters={<FilterGrid />}
        actions={
          <>
            <TableSettingComponent />
            <MainListActions />
          </>
        }
      >
        <TableGrid />
      </PageWrapper>
    </GridProvider>
  );
};
