import { FC } from "react";
import { FormTextField, Grid, FormCard } from "@toolkit/ui";
import { useTranslation } from "@toolkit/i18n";

export const RequestInformationForm: FC = () => {
  const { t } = useTranslation("provider");

  return (
    <FormCard title={t("Request General Information")} loading={false} doYouHaveData>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <FormTextField name='requestId' label={t("Request Id")} placeholder={t("Request Id")} />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormTextField name='visitId' label={t("Visit ID")} placeholder={t("Visit ID")} />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormTextField name='status' label={t("Status")} placeholder={t("Status")} />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormTextField name='type' label={t("Type")} placeholder={t("Type")} />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormTextField name='subType' label={t("Sub Type")} placeholder={t("Sub Type")} />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormTextField name='use' label={t("Use")} placeholder={t("Use")} />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormTextField name='priority' label={t("Priority")} placeholder={t("Priority")} />
        </Grid>
      </Grid>
    </FormCard>
  );
};
